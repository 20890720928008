import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../../../../components/Button';
import SimpleButton from '../../../../../../components/SimpleButton';
import InstructionsToast from '../../../../../../components/Toast/Instructions';
import { useMap } from '../../../../../../hooks/Map';
import { useAuth } from '../../../../../../hooks/Auth';
import { DashboardContext } from '../../../../index';
import api from '../../../../../../services/api';
import * as S from './styled';
import Toast from '../../../../../../components/Toast';
import { appConfig } from '../../../../../../config';

export const EditingMappingContext = createContext({});
export const EditingMappingProvider = ({ children }) => {
  const [buttonDisable, setButtonDisable] = useState(true);

  return (
    <EditingMappingContext.Provider value={{ buttonDisable, setButtonDisable }}>
      {children}
    </EditingMappingContext.Provider>
  );
};

EditingMappingProvider.propTypes = {
  children: PropTypes.node
};
const EditingMapping = () => {
  const { setDashboardState } = useContext(DashboardContext);
  const { removeMappingGeometries, removeAllInterections, editedMapping } =
    useMap();
  const { token } = useAuth();

  const handleEditMapping = async (mapping) => {
    try {
      await api.put(
        `${appConfig.apiHosts.field}/FieldMapping/UpdateFieldMapping`,
        mapping,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      toast(<Toast title="Caminhamento Editado com Sucesso" />, {
        type: 'success'
      });
      removeAllInterections();
      removeMappingGeometries();
      setDashboardState('dashboard@information');
    } catch (error) {
      toast(<Toast title="Não foi possível editar caminhamento" />, {
        type: 'error'
      });
    }
  };

  useEffect(() => {
    toast(
      <InstructionsToast title="Altere o caminhamento da sua propriedade no mapa">
        <p>
          1. Use o mouse para alterar o caminhamento.
          <br />
          2. Após editar o caminhamento no mapa clique em editar caminhamento.
        </p>
      </InstructionsToast>,
      {
        type: 'default',
        autoClose: 10000,
        hideProgressBar: true,
        style: { marginTop: '25%' }
      }
    );
  }, []);
  return (
    <EditingMappingProvider>
      <S.Container>
        <S.ContainerBackButton>
          <SimpleButton
            text="Voltar"
            onClick={() => {
              setDashboardState('dashboard@information');
              removeMappingGeometries();
              removeAllInterections();
            }}
          />
        </S.ContainerBackButton>
        <S.RegisterFarmDialog>
          <h3>Editando Caminhamento</h3>
          <span>
            Corrija a geometria do seu caminhamento e continue a usar as
            funcionalidades da plataforma.
          </span>
        </S.RegisterFarmDialog>
        <Button
          text="Editar Caminhamento"
          onClick={() => handleEditMapping(editedMapping)}
        />
      </S.Container>
    </EditingMappingProvider>
  );
};

export default EditingMapping;
