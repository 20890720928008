import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  padding: 10px 10px 0 10px;
  width: 100%;
  height: 12%;
  opacity: 0.9;
  background-color: #444444;
`;

export const MonthsContainer = styled.div`
  width: 100%;
  height: 33%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  > div {
    display: flex;
    width: 100%;
    align-items: center;

    p {
      margin: 0 2.5px;
      font-size: 12px;
      color: #fff;
      flex: 1;
      text-align: center;
    }
  }
`;

// excess height to improve interactive area / accessibility
const height = '3px';
const thumbHeight = 18;
const trackHeight = '8px';

// colours
const upperColor = '#f00';
const lowerColor = '#000';
const thumbColor = '#f0f';
const thumbHoverColor = '#00f';
const upperBackground = `linear-gradient(to bottom, ${upperColor}, ${upperColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;
const lowerBackground = `linear-gradient(to bottom, ${lowerColor}, ${lowerColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  label {
    width: 100%;
    display: flex;
    justify-content: space-around;
    color: #fff;
  }
`;

export const ProgressBar = styled.input`
  /* overflow: hidden; */
  display: block;
  appearance: none;
  width: 100%;
  margin: 0;
  height: 8px;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: #79ac34;
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #79ac34;
    border: solid 3px #fff;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 150ms;
  }

  &::-moz-range-track,
  &::-moz-range-progress {
    width: 100%;
    height: ${height};
    background: ${upperBackground};
  }

  &::-moz-range-progress {
    background: ${lowerBackground};
  }

  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${thumbColor};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
  }

  &::-ms-track {
    width: 100%;
    height: ${height};
    border: 0;
    /* color needed to hide track marks */
    color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    background: ${lowerBackground};
  }

  &::-ms-fill-upper {
    background: ${upperBackground};
  }

  &::-ms-thumb {
    appearance: none;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${thumbColor};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    /* IE Edge thinks it can support -webkit prefixes */
    top: 0;
    margin: 0;
    box-shadow: none;
  }

  &:hover,
  &:focus {
    &::-webkit-slider-thumb {
      background-color: ${thumbHoverColor};
    }
    &::-moz-range-thumb {
      background-color: ${thumbHoverColor};
    }
    &::-ms-thumb {
      background-color: ${thumbHoverColor};
    }
  }
`;

export const NextButton = styled.button`
  border: 1px solid #dcdcdc;
  border-radius: 50%;
  background-color: transparent;
  width: 28px;
  height: 28px;
  margin-right: 10px;
  svg {
    color: #fff;
    width: 28px;
    height: 28px;
  }
`;

export const DataContainer = styled.div`
  margin-top: 10px;
  display: flex;

  svg {
    color: #fff;
    width: 24px;
    height: 24px;
  }
`;

export const FilterNDVI = styled.button`
  background: transparent;
  border: none;

  &:hover {
    svg {
      color: #79ac34;
    }
  }

  ${(props) =>
    props.ndvi &&
    css`
      svg {
        color: #79ac34;
      }
      &:hover {
        svg {
          color: #fff;
        }
      }
    `}
`;
