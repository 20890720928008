/* eslint-disable eqeqeq */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useRef, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import Checkbox from 'react-custom-checkbox';
import { MdCheck } from 'react-icons/md';
import PuffLoader from 'react-spinners/PuffLoader';
import { toast } from 'react-toastify';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { useMap } from '../../../../../../../../hooks/Map';
import api from '../../../../../../../../services/api';
import { appConfig } from '../../../../../../../../config';
import Button from '../../../../../../../../components/Button';
import Toast from '../../../../../../../../components/Toast';
import * as S from './styled';

const ModalNewTechnical = () => {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const { selectedFarmId } = useMap();
  const {
    selectedManagement,
    setUpdateScreen,
    listTypeManager,
    selectedTypeTaskList,
    setSelectedTypeTaskList
  } = useContext(DashboardContext);

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [name, setName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [selectedTasks, setSelectedTasks] = useState('');
  const [commits, setCommits] = useState('');
  const [routineList, setRoutineList] = useState([]);
  const [routine, setRoutine] = useState([]);

  const ModalNewTechnicalSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    telephone: Yup.string().required('Telefone é obrigatório'),
    tasks: Yup.string().required('Tarefas é obrigatório'),
    commits: Yup.string()
  });

  const { handleSubmit, register, errors, control, reset } = useForm({
    resolver: yupResolver(ModalNewTechnicalSchema)
  });

  const updateRoutine = async (tech_id) => {
    try {
      const newTask = {
        routine_type_id: routine.routine_type_id,
        encompass_id: routine.encompass_id,
        status: routine.status,
        title: routine.title,
        date: routine.date,
        period: routine.period,
        tecnician_id: tech_id.data.data.technician_id,
        instant_monitoring: true,
        note: commits
      };

      const params = new URLSearchParams([
        ['farm_id', selectedFarmId],
        ['routine_id', routine.routine_id]
      ]);

      await api.put(
        `${appConfig.apiHosts.prague}/Routine/Update`,
        newTask,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const newTechnician = {
      name: data.name,
      phone: data.telephone,
      task: data.tasks,
      note: data.commits,
      created_by: user.user_id
    };

    try {
      const response = await api.post(
        `${appConfig.apiHosts.prague}/Technician/Create`,
        newTechnician,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      await updateRoutine(response);
      toast(<Toast title="Novo técnico criado com sucesso" />, {
        type: 'success'
      });

      setUpdateScreen(true);

      if (checked) {
        reset();
        setLoading(false);
      } else {
        reset();
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast(<Toast title="Não foi possível criar um novo técnico" />, {
        type: 'error'
      });
    }
  };

  const getRoutineList = async () => {
    const params = new URLSearchParams([
      ['farm_id', selectedFarmId]
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Routine/ListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setRoutineList(response.data.data.routines);
    } catch (error) {
      console.error(error);
    }
  };

  const getRoutineById = async (id) => {
    const params = new URLSearchParams([['routine_id', id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Routine/ListByRoutineId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterNote = response?.data?.data?.note;

      if (filterNote !== null) {
        setCommits(filterNote);
      }

      setRoutine(response?.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRoutineList();
  }, []);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Novo Técnico</S.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <S.InputWrapper>
              <S.InputContainer>
                <span>Nome</span>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      placeholder="Ex.: Mario Farias"
                      onChange={onChange}
                      style={{ marginBottom: '7px' }}
                    />
                  )}
                />
                {errors?.name?.message && (
                  <S.InputError>{errors?.name?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <span>ID (Gerado Automaticamente</span>
                <TextField
                  name="id"
                  id="id"
                  margin="dense"
                  fullWidth
                  size="small"
                  value=""
                  disabled
                  style={{ backgroundColor: '#EFEFEF', marginBottom: '7px' }}
                />
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.InputContainer>
                <span>Telefone</span>
                <Controller
                  name="telephone"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      // eslint-disable-next-line space-infix-ops
                      value={value}
                      placeholder="Ex.: 65 0000-0000"
                      onChange={(e) => onChange(e.target.value.replace(/[A-Za-z]/g, ''))}
                      style={{ marginBottom: '7px' }}
                    />
                  )}
                />
                {errors?.telephone?.message && (
                  <S.InputError>{errors?.telephone?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="tasks"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Tarefas</span>
                      <Select
                        placeholder="Selecione..."
                        value={value}
                        onChange={(e) => {
                          getRoutineById(e.target.value);
                          onChange(e.target.value);
                        }}
                        size="small"
                      >
                        {routineList.map((rout) => (
                          <MenuItem value={rout.routine_id} key={rout.routine_id}>{rout.title}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.tasks?.message && (
                  <S.InputError>{errors?.tasks?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer style={{ height: '200px' }}>
                <span style={{ marginBottom: '10px' }}>Observações</span>
                <Controller
                  name="commits"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <S.CommitArea
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>
            <S.ButtonWrapper>
              <Button
                text="Cancelar"
                className="cancelBtn"
                onClick={() => {
                  reset();
                  closeModal();
                }}
              />
              <S.CheckboxContainer>
                <Checkbox
                  checked={checked}
                  icon={(
                    <MdCheck
                      color="#507919"
                    />
                  )}
                  borderColor="#A0BD39"
                  borderRadius={3}
                  borderWidth={1}
                  size={20}
                  style={{ cursor: 'pointer' }}
                  onChange={() => {
                    setChecked(!checked);
                  }}
                />
                <label htmlFor="register">Continuar Cadastrando</label>
              </S.CheckboxContainer>
              <Button
                text={loading ? (
                  <>
                    <PuffLoader
                      color="#79ac34"
                      loading={loading}
                      size={30}
                    />
                  </>
                ) : 'Criar Novo Executor'}
                disabled={loading}
                className="saveBtn"
                type="submit"
              />
            </S.ButtonWrapper>
          </S.FormContainer>
        </form>
      </S.Container>
    </Modal>
  );
};

export default ModalNewTechnical;
