/* eslint-disable no-mixed-operators */
/* eslint-disable prettier/prettier */
import React, { useRef, useContext, useEffect, useState } from 'react';
import {
  MdOutlineBugReport,
  MdRemoveRedEye,
  MdSearch,
  MdOutlineMenuBook,
  MdPhoneIphone
} from 'react-icons/md';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from '@mui/material';
import { toast } from 'react-toastify';
import PuffLoader from 'react-spinners/PuffLoader';
import { DashboardContext } from '../../index';
import * as S from './styled';
import { useModal } from '../../../../hooks/Modal';
import { useMap } from '../../../../hooks/Map';
import { useAuth } from '../../../../hooks/Auth';
import api from '../../../../services/api';
import { appConfig } from '../../../../config';
import Toast from '../../../../components/Toast';
import Button from '../../../../components/Button';
import ModalViewPrague from './components/ModalViewPrague';
import ModalViewWeed from './components/ModalViewWeed';
import ModalViewDisease from './components/ModalViewDisease';

import IconeOutros from '../../../../assets/outros.svg';
import IconePraga from '../../../../assets/pragas.svg';
import IconeErvas from '../../../../assets/ervas.svg';
import IconeDoencas from '../../../../assets/doencas.svg';

const KnowledgeBase = () => {
  const { token, user, getNotifications } = useAuth();
  const { selectedFarmId } = useMap();
  const {
    uploadedFiles: files,
    setUpdateScreen,
    currentModal,
    setCurrentModal,
    setSelectedPrague,
    prague_cropName,
    prague_plantName,
    setSelectedWeed,
    setSelectedDisease,
    setUploadedFiles,
    setImageDiagrammaticScale,
    setDisease_cropName,
    setDisease_plantName
  } = useContext(DashboardContext);
  const { closeModal, openModal } = useModal();

  const carouselPrague = useRef(null);
  const carouselWeed = useRef(null);
  const carouselDisease = useRef(null);

  const [loading, setLoading] = useState(false);
  const [listPragues, setListPragues] = useState([]);
  const [listWeeds, setListWeeds] = useState([]);
  const [listDiseases, setListDiseases] = useState([]);

  const [filter, setFilter] = useState('');
  const [filterIcon, setFilterIcon] = useState('all');

  const getPragueList = async () => {
    setLoading(true);
    setListPragues([]);
    try {
      const params = new URLSearchParams([['filter', filter || '']]);
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Prague/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // const sortResponse = response.data.data.pragues.sort((x, y) => {
      //   return x.status - y.status;
      // });

      const filterActive = response.data.data.pragues.filter(
        (v) => v.active === true
      );

      setListPragues(filterActive);
      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getWeedList = async () => {
    setLoading(true);
    setListWeeds([]);
    try {
      const params = new URLSearchParams([['filter', filter || '']]);
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Weed/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // const sortResponse = response.data.data.pragues.sort((x, y) => {
      //   return x.status - y.status;
      // });

      const filterActive = response.data.data.weeds.filter(
        (v) => v.active === true
      );

      setListWeeds(filterActive);
      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getDiseaseList = async () => {
    setLoading(true);
    setListWeeds([]);
    try {
      const params = new URLSearchParams([['filter', filter || '']]);
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Disease/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // const sortResponse = response.data.data.pragues.sort((x, y) => {
      //   return x.status - y.status;
      // });

      const filterActive = response.data.data.diseases.filter(
        (v) => v.active === true
      );

      setListDiseases(filterActive);
      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getPragueId = async (prague_id) => {
    try {
      const params = new URLSearchParams([['prague_id', prague_id]]);
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Prague/Id`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedPrague(response.data.data.prague);

      response?.data.data.prague?.crop_type.forEach((crop) => {
        prague_cropName.push(crop?.name);
      });
      response?.data.data.prague?.plant.forEach((plant) => {
        prague_plantName.push(plant?.name);
      });
      response?.data.data.prague?.prague_images.forEach((img) => {
        files.push({
          error: false,
          file: {
            path: 'gafanhoto.jpg',
            lastModified: '',
            lastModifiedDate: '',
            name: img?.prague_image_id,
            size: '',
            type: '',
            webkitRelativePath: ''
          },
          id: img?.prague_image_id,
          name: img?.prague_image_id,
          preview: img?.url,
          progress: 0,
          readableSize: '',
          uploaded: true,
          url: img?.url
        });
      });

      setCurrentModal('modalViewPrague');
      openModal();
    } catch (error) {
      console.error(error);
    }
  };

  const getDiseaseId = async (disease_id) => {
    // setCurrentModal('');
    try {
      const params = new URLSearchParams([['disease_id', disease_id]]);
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Disease/Id`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedDisease(response.data.data.disease);

      const cropName = [];
      const plantName = [];
      const fileImage = [];
      setCurrentModal('');

      if (response) {
        setImageDiagrammaticScale(
          response.data.data.disease?.diagrammatic_Scale
        );

        response.data.data.disease.crop_type.forEach((crop) => {
          cropName.push(crop?.name);
        });
        response.data.data.disease.plant.forEach((plant) => {
          plantName.push(plant?.name);
        });

        setDisease_cropName(cropName);
        setDisease_plantName(plantName);

        await response.data.data.disease?.disease_images.forEach((img) => {
          fileImage.push({
            error: false,
            file: {
              path: '',
              lastModified: '',
              lastModifiedDate: '',
              name: img?.disease_id,
              size: '',
              type: '',
              webkitRelativePath: ''
            },
            id: img?.disease_image_id,
            name: img?.disease_id,
            preview: img?.url,
            progress: 0,
            readableSize: '',
            uploaded: true,
            url: img?.url,
            legend: img?.legend
          });
        });

        setUploadedFiles(fileImage);

        setCurrentModal('modalViewDisease');
        openModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getWeedId = async (weed_id) => {
    try {
      const params = new URLSearchParams([['weed_id', weed_id]]);
      const response = await api.get(`${appConfig.apiHosts.manager}/Weed/Id`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSelectedWeed(response.data.data.weed);

      response?.data.data.weed?.weed_images.forEach((img) => {
        files.push({
          error: false,
          file: {
            path: '',
            lastModified: '',
            lastModifiedDate: '',
            name: img?.weed_image_id,
            size: '',
            type: '',
            webkitRelativePath: ''
          },
          id: img?.weed_image_id,
          name: img?.weed_image_id,
          preview: img?.url,
          progress: 0,
          readableSize: '',
          uploaded: true,
          url: img?.url
        });
      });
      setCurrentModal('modalViewWeed');
      openModal();
    } catch (error) {
      console.error(error);
    }
  };

  const handleLeftClick = (e, typePrague) => {
    e.preventDefault();
    if (typePrague === 'prague') {
      carouselPrague.current.scrollLeft -= carouselPrague.current.offsetWidth;
    }
    if (typePrague === 'disease') {
      carouselDisease.current.scrollLeft -= carouselDisease.current.offsetWidth;
    }
    if (typePrague === 'weed') {
      carouselWeed.current.scrollLeft -= carouselWeed.current.offsetWidth;
    }
  };

  const handleRightClick = (e, typePrague) => {
    e.preventDefault();
    if (typePrague === 'prague') {
      carouselPrague.current.scrollLeft += carouselPrague.current.offsetWidth;
    }
    if (typePrague === 'disease') {
      carouselDisease.current.scrollLeft += carouselDisease.current.offsetWidth;
    }
    if (typePrague === 'weed') {
      carouselWeed.current.scrollLeft += carouselWeed.current.offsetWidth;
    }
  };

  const handleClickIcon = (type) => {
    if (type === 'prague') {
      setListWeeds([]);
      setListDiseases([]);
      getPragueList();
    }

    if (type === 'weed') {
      setListPragues([]);
      setListDiseases([]);
      getWeedList();
    }

    if (type === 'disease') {
      setListPragues([]);
      setListWeeds([]);
      getDiseaseList();
    }
  };

  useEffect(() => {
    getPragueList();
    getWeedList();
    getDiseaseList();
  }, []);

  useEffect(() => {
    if (filter === '') {
      getPragueList();
      getWeedList();
      getDiseaseList();
    }
  }, [filter]);

  return (
    <S.Container>
      <S.ContentContainer>
        <S.NavContainer>
          <S.NavButton active>Base de Conhecimento</S.NavButton>
          <S.HelpButtonContainer>
            <S.HelpButton
              type="button"
              onClick={() => {
                window.open(
                  `${window.location.origin}/manualsolos.pdf`,
                  '_blank'
                );
              }}
            >
              <MdOutlineMenuBook />
              Acessar Tutorial da Plataforma
            </S.HelpButton>
            <S.HelpButton type="button">
              <MdPhoneIphone />
              Conheça o APP
            </S.HelpButton>
          </S.HelpButtonContainer>
        </S.NavContainer>
        <hr />
        <S.SearchAreaContainer>
          <S.IconFilterContainer>
            <S.IconButton
              type="button"
              onClick={() => handleClickIcon('prague')}
            >
              <img alt="" src={IconePraga} />
            </S.IconButton>
            <S.IconButton
              type="button"
              onClick={() => handleClickIcon('disease')}
            >
              <img alt="" src={IconeDoencas} />
            </S.IconButton>
            <S.IconButton type="button" onClick={() => handleClickIcon('weed')}>
              <img alt="" src={IconeErvas} />
            </S.IconButton>
            <S.IconButton
              type="button"
              onClick={() => handleClickIcon('others')}
            >
              <img alt="" src={IconeOutros} />
            </S.IconButton>
          </S.IconFilterContainer>
          <S.SearchArea>
            <button
              type="button"
              onClick={() => {
                getPragueList();
                getWeedList();
                getDiseaseList();
              }}
            >
              <MdSearch size={28} />
            </button>
            <S.SearchInput
              type="text"
              placeholder="Pesquisa por pragas, doenças, etc..."
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
                if (e.target.value === '') {
                  getPragueList();
                }
              }}
            />
          </S.SearchArea>
        </S.SearchAreaContainer>
        <S.Content>
          {listPragues.length > 0 && (
            <>
              <S.Title>Pragas</S.Title>
              <S.CardContainer>
                <button
                  type="button"
                  onClick={(e) => handleLeftClick(e, 'prague')}
                >
                  <FaChevronCircleLeft />
                </button>
                {loading ? (
                  <>
                    <PuffLoader color="#79ac34" loading={loading} size={30} />
                  </>
                ) : (
                  <div className="carouselPrague" ref={carouselPrague}>
                    {listPragues?.map((row) => (
                      <S.Card>
                        <S.CardImage>
                          {row?.main_photo && (
                            <img alt="" src={row.main_photo} />
                          )}
                        </S.CardImage>
                        <S.CardTextContainer>
                          <S.CardTitle>{row?.name}</S.CardTitle>
                          <S.CardDescription>
                            {row?.scientific_name}
                          </S.CardDescription>
                        </S.CardTextContainer>
                        <S.CardActionContainer>
                          <S.CardAction>
                            <button
                              type="button"
                              onClick={() => {
                                getPragueId(row.prague_id);
                              }}
                            >
                              <MdRemoveRedEye size={16} />
                              VEJA MAIS
                            </button>
                          </S.CardAction>
                        </S.CardActionContainer>
                      </S.Card>
                    ))}
                  </div>
                )}
                <button
                  type="button"
                  onClick={(e) => handleRightClick(e, 'prague')}
                >
                  <FaChevronCircleRight />
                </button>
              </S.CardContainer>
            </>
          )}

          {listWeeds.length > 0 && (
            <>
              <S.Title>Ervas Daninhas</S.Title>
              <S.CardContainer>
                <button
                  type="button"
                  onClick={(e) => handleLeftClick(e, 'weed')}
                >
                  <FaChevronCircleLeft />
                </button>
                {loading ? (
                  <>
                    <PuffLoader color="#79ac34" loading={loading} size={30} />
                  </>
                ) : (
                  <div className="carouselWeed" ref={carouselWeed}>
                    {listWeeds?.map((row) => (
                      <S.Card>
                        <S.CardImage>
                          {row?.main_photo && (
                            <img alt="" src={row.main_photo} />
                          )}
                        </S.CardImage>
                        <S.CardTextContainer>
                          <S.CardTitle>{row?.name}</S.CardTitle>
                          <S.CardDescription>
                            {row?.scientific_name}
                          </S.CardDescription>
                        </S.CardTextContainer>
                        <S.CardActionContainer>
                          <S.CardAction>
                            <button
                              type="button"
                              onClick={() => {
                                getWeedId(row.weed_id);
                              }}
                            >
                              <MdRemoveRedEye size={16} />
                              VEJA MAIS
                            </button>
                          </S.CardAction>
                        </S.CardActionContainer>
                      </S.Card>
                    ))}
                  </div>
                )}
                <button
                  type="button"
                  onClick={(e) => handleRightClick(e, 'weed')}
                >
                  <FaChevronCircleRight />
                </button>
              </S.CardContainer>
            </>
          )}

          {listDiseases.length > 0 && (
            <>
              <S.Title>Doenças</S.Title>
              <S.CardContainer>
                <button
                  type="button"
                  onClick={(e) => handleLeftClick(e, 'disease')}
                >
                  <FaChevronCircleLeft />
                </button>
                {loading ? (
                  <>
                    <PuffLoader color="#79ac34" loading={loading} size={30} />
                  </>
                ) : (
                  <div className="carouselDisease" ref={carouselDisease}>
                    {listDiseases?.map((row) => (
                      <S.Card>
                        <S.CardImage>
                          {row?.main_photo && (
                            <img alt="" src={row.main_photo} />
                          )}
                        </S.CardImage>
                        <S.CardTextContainer>
                          <S.CardTitle>{row?.name}</S.CardTitle>
                          <S.CardDescription>
                            {row?.scientific_name}
                          </S.CardDescription>
                        </S.CardTextContainer>
                        <S.CardActionContainer>
                          <S.CardAction>
                            <button
                              type="button"
                              onClick={() => {
                                getDiseaseId(row.disease_id);
                              }}
                            >
                              <MdRemoveRedEye size={16} />
                              VEJA MAIS
                            </button>
                          </S.CardAction>
                        </S.CardActionContainer>
                      </S.Card>
                    ))}
                  </div>
                )}
                <button
                  type="button"
                  onClick={(e) => handleRightClick(e, 'disease')}
                >
                  <FaChevronCircleRight />
                </button>
              </S.CardContainer>
            </>
          )}
        </S.Content>
      </S.ContentContainer>
      {currentModal === 'modalViewPrague' && <ModalViewPrague />}
      {currentModal === 'modalViewWeed' && <ModalViewWeed />}
      {currentModal === 'modalViewDisease' && <ModalViewDisease />}
    </S.Container>
  );
};

export default KnowledgeBase;
