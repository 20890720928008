import { appConfig } from '../config';
import api from './api';

const login = async ({ email, password }) => {
  try {
    const response = await api.post(
      `${appConfig.apiHosts.auth}/UserFundacao/Login`,
      {
        email,
        password
      }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

const authenticated = async (token) => {
  try {
    await api.get(`${appConfig.apiHosts.auth}/UserFundacao/Authenticated`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const checkForRegisteredEmail = async (data) => {
  try {
    const params = new URLSearchParams([['email', data.email]]);
    const response = await api.get(
      `${appConfig.apiHosts.auth}/UserFundacao/CheckForRegisteredEmail`,
      { params }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
};

const userFundacao = async (data) => {
  try {
    await api.post(
      `${appConfig.apiHosts.auth}/UserFundacao/UserFundacao`,
      data
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const forgotPassword = async (data) => {
  try {
    await api.post(
      `${appConfig.apiHosts.auth}/UserFundacao/ForgotPassword`,
      data
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {
  login,
  authenticated,
  checkForRegisteredEmail,
  userFundacao,
  forgotPassword
};
