import styled from 'styled-components';
import { shade } from 'polished';
import signinBackgroundImg from '../../assets/sign-in-background.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  @media (min-width: 1000px) {
    padding-left: 50px;
  }

  width: 100%;
  max-width: 700px;
`;

export const Form = styled.form`
  max-width: 450px;
  width: 100%;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */

  > span {
    color: #444444;
    font-size: 1.3rem;
    font-weight: bold;
    margin: 46px 0px 16px 0px;
  }

  p {
    color: #a3a3a3;
    font-size: 1rem;
    margin-bottom: 40px;
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signinBackgroundImg}) no-repeat center;
  background-size: cover;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  > p {
    padding: 15px;
    font-weight: bold;
    font-size: 0.8rem;
    color: #fff;
    @media (max-width: 1000px) {
      display: none;
    }
  }
`;

export const SignIn = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;

  p {
    color: #444444;
  }

  a {
    color: #79ac34;
    margin-left: 5px;
    font-weight: bold;

    &:hover {
      color: ${shade(0.2, '#79ac34')};
    }
  }
`;

export const ErrorContainer = styled.section`
  margin: 3px 15px 5px;
  /* font-weight: bold; */
  font-size: 0.9rem !important;
  color: #d62222 !important;
`;
