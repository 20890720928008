/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { UserFarm } from '../../utils/userFarms.types';
import HitchingUserFarmApi from '../../../../../../../../APi/FarmApi/HitchingUserFarmApi';

export const useUserFarmsListRequest = () => {
  const {
    token,
    user: { organizacao_id }
  } = useAuth();
  const hitchingUserFarmApi = useMemo(() => new HitchingUserFarmApi(token), []);
  const [filter, setFilter] = useState<string>('');

  function mascaraTelefone(tel: string) {
    tel = tel.replace(/\D/g, '');
    tel = tel.replace(/^(\d{2})(\d)/g, '($1) $2');
    tel = tel.replace(/(\d)(\d{4})$/, '$1-$2');
    tel = tel.substring(0, 15);
    return tel;
  }
  function ValidaCPF(cpf: string) {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  function mascaraCnpj(cnjs: string) {
    return cnjs.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5'
    );
  }

  function hitchingUserFarmMap(farm: any): UserFarm {
    return {
      id: farm.farm_id,
      name: farm.fullname,
      email: farm.email,
      farms: farm.farm_name,
      tel: mascaraTelefone(farm.phone_number),
      cnpj:
        farm.cpf_cnpj.length > 11
          ? mascaraCnpj(farm.cpf_cnpj)
          : ValidaCPF(farm.cpf_cnpj)
    };
  }

  const hitchingUserFarmDate = useQuery({
    queryKey: [hitchingUserFarmApi.getUserFarmList, { filter }],
    queryFn: async () =>
      hitchingUserFarmApi.UserFarmList(filter, organizacao_id)
  });

  const userFarmDelete = useMutation({
    mutationFn: async (id: string) => hitchingUserFarmApi.UserFarmDelete(id)
  });

  const onDeleteUserFarm = (id: string) => userFarmDelete.mutate(id);

  return {
    request: {
      items: hitchingUserFarmDate.data?.data.data.map(hitchingUserFarmMap) ?? []
    },
    setFilter,
    onDeleteUserFarm
  };
};
