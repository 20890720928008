/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect
} from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  OutlinedInput,
  Selected,
  TextareaAutosize
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { AddCircle } from '@mui/icons-material';
import { CompactPicker } from 'react-color';
import Checkbox from 'react-custom-checkbox';
import { useDropzone } from 'react-dropzone';
// import { useFiles } from "../../context/files";
import PuffLoader from 'react-spinners/PuffLoader';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  FaChevronLeft,
  FaChevronRight,
  FaUpload,
  FaTrash,
  FaCheckCircle
} from 'react-icons/fa';
import {
  MdOutlineCloudUpload,
  MdCheck,
  MdClose,
  MdError,
  MdLink,
  MdMoodBad
} from 'react-icons/md';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
import filesize from 'filesize';
import { toast } from 'react-toastify';
import { Carousel } from 'react-responsive-carousel';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import storage from '../../../../../../../../services/firebase';
import Button from '../../../../../../../../components/Button';
import { useMap } from '../../../../../../../../hooks/Map';
import { appConfig } from '../../../../../../../../config';
import { useAuth } from '../../../../../../../../hooks/Auth';
import api from '../../../../../../../../services/api';
import Toast from '../../../../../../../../components/Toast';
import * as S from './styled';

const ModalEditWeed = () => {
  const inputFileRef = useRef(null);
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const {
    selectedManagement,
    uploadedFiles: files,
    deleteFile,
    handleUpload,
    setUploadedFiles,
    setUpdateScreen,
    itemTypeSelected,
    selectTypeCultureList,
    setSelectTypeCultureList,
    selectedWeed,
    setSelectedWeed,
    prague_cropName,
    setPrague_CropName,
    prague_plantName,
    setPrague_PlantName,
    setCurrentModal
  } = useContext(DashboardContext);

  const { selectedFarmId } = useMap();
  const theme = useTheme();
  const uploadFile = useRef(null);

  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(selectedWeed?.quantity);
  const [checked, setChecked] = useState(true);

  const [infestationLevel, setInfestationLevel] = useState([]);
  const [titleInfestationLevel, setTitleInfestationLevel] = useState('');
  const [level, setLevel] = useState('');
  const [InfestationValue, setInfestationValue] = useState('');
  const [colorPickerInfestationVisible, setColorPickerInfestationVisible] =
    useState(false);
  const [colorInfestation, setColorInfestation] = useState('');

  const [selectedPhoto, setSelectedPhoto] = useState(0);
  const [mainPhoto, setMainPhoto] = useState('');
  const [weedClassificationList, setWeedClassificationList] = useState([]);
  const [photoList, setPhotoList] = useState([]);

  const [phenologicalStage, setPhenologicalStage] = useState([]);
  const [valuePhenologicalStage, setValuePhenologicalStage] = useState('');
  const [phenologicalStageTitle, setPhenologicalStageTitle] = useState('');

  const ModalAddNewWeedSchema = Yup.object().shape({
    weedClassification: Yup.string().nullable(),
    name: Yup.string().required('Nome é obrigatório'),
    scientific_name: Yup.string().nullable(),
    description: Yup.string().nullable(),
    symptoms: Yup.string().nullable(),
    damage: Yup.string().nullable(),
    unit_measurement: Yup.string().nullable()
  });

  const { handleSubmit, register, errors, control, reset } = useForm({
    resolver: yupResolver(ModalAddNewWeedSchema)
  });

  const getData = () => {
    setInfestationLevel(selectedWeed?.weed_infestation_level);
    setMainPhoto(selectedWeed?.main_photo);
    setTitleInfestationLevel(selectedWeed?.weed_infestation_level_title);
    setPhenologicalStage(selectedWeed?.phenological_stage);
    setPhenologicalStageTitle(selectedWeed?.weed_phenological_stage_title);
  };

  const handleUploadFile = async (e) => {
    try {
      const fileData = await storage.ref(`/images/${e.name}`).put(e.file);
      const imageSrc = { id: e.id, url: await fileData.ref.getDownloadURL() };

      files.forEach((file) => URL.revokeObjectURL(file.preview));

      return imageSrc;
    } catch (error) {
      console.error(error);
    }
  };

  const FileList = () => {
    if (!files.length) {
      return (
        <span>
          <MdMoodBad
            style={{ marginLeft: '45%', marginTop: 10 }}
            size={24}
            color="#d5d2d2"
          />
        </span>
      );
    }
  };

  const onDrop = useCallback(
    (file) => {
      handleUpload(file);
    },
    [handleUpload]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: ['image/jpeg', 'image/pjpeg', 'image/png', 'image/gif'],
      // maxFiles: 1,
      multiple: true,
      onDrop
    });

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return (
        <S.UploadMessage>Clique ou arraste uma imagem aqui</S.UploadMessage>
      );
    }

    if (isDragReject) {
      return (
        <S.UploadMessage type="error">
          Tipo de arquivo não suportado
        </S.UploadMessage>
      );
    }

    return (
      <S.UploadMessage type="success">Solte as imagens aqui</S.UploadMessage>
    );
  }, [isDragActive, isDragReject]);

  const addInfestationInTable = () => {
    if (level !== '' && InfestationValue !== '') {
      infestationLevel.push({
        color: colorInfestation,
        level,
        value: InfestationValue
      });

      setLevel('');
      setInfestationValue('');
      setColorInfestation('');
    } else {
      return toast(
        <Toast title="Preencha os campos para serem adicionados na tabela" />,
        {
          type: 'error'
        }
      );
    }
  };

  const getWeedClassification = async () => {
    const params = new URLSearchParams([
      ['type_id', '850929e9-4192-4314-95ae-a6a1327ce67e']
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterClassByActive = response.data.data.values.filter(
        (v) => v.status === true
      );
      setWeedClassificationList(filterClassByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeBackgroundInfestation = (color) => {
    setColorInfestation(color.hex);
    setColorPickerInfestationVisible(false);
  };

  const addPhenologicalStagelInTable = () => {
    if (valuePhenologicalStage !== '') {
      phenologicalStage.push({
        phenological_stage: valuePhenologicalStage
      });
      setValuePhenologicalStage('');
    } else {
      return toast(
        <Toast title="Preencha os campos para serem adicionados na tabela" />,
        {
          type: 'warning'
        }
      );
    }
  };

  const updateWeed = async (data) => {
    const params = new URLSearchParams([['weed_id', selectedWeed?.weed_id]]);
    try {
      const response = await api.put(
        `${appConfig.apiHosts.manager}/Weed/Update`,
        data,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast(<Toast title="Erva daninha editada com sucesso" />, {
        type: 'success'
      });

      setLoading(false);
      return true;
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast(<Toast title="Não foi possível editar erva daninha" />, {
        type: 'error'
      });
      return false;
    }
  };

  const createWeedImages = async (data) => {
    try {
      await api.post(`${appConfig.apiHosts.manager}/WeedImage/Create`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteWeedImage = async (id) => {
    const params = new URLSearchParams([
      ['weedImageId', id],
      ['status', false]
    ]);
    try {
      const response = await api.delete(
        `${appConfig.apiHosts.manager}/WeedImage/Delete`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const clearFields = () => {
    setUploadedFiles([]);
    setInfestationLevel([]);
    setTitleInfestationLevel('');
    setInfestationValue('');
    setLevel('');
    setColorInfestation('');
    setSelectedWeed([]);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const imageUrl = [];
    const oldPhoto = [];
    const newPhoto = [];

    if (
      (files.length > 0 && mainPhoto == '') ||
      (oldPhoto.length > 0 && mainPhoto == '') ||
      mainPhoto === null
    ) {
      setLoading(false);
      return toast(
        <Toast
          title="Não foi possível editar erva daninha"
          content="Torne uma foto como principal"
        />,
        { type: 'warning' }
      );
    }

    files.forEach((image) => {
      if (image.url === '') {
        newPhoto.push(image);
      } else {
        oldPhoto.push({ id: image.id, url: image.url });
      }
    });

    try {
      let editWeed = {
        name: data.name,
        description: data.description || null,
        measurement_unit: data.unit_measurement || null,
        scientific_name: data.scientific_name || null,
        weed_classification_id: data.weedClassification || null,
        quantity: selectedValue,
        phenological_stage: phenologicalStage,
        weed_phenological_stage_title: phenologicalStageTitle,
        created_by: user.user_id,
        weed_infestation_level: infestationLevel || null,
        weed_infestation_level_title: titleInfestationLevel,
        main_photo: ''
      };

      if (oldPhoto.length !== selectedWeed.weed_images.length) {
        const deleteImagesId = [];
        for (let i = 0; i < selectedWeed.weed_images.length; i++) {
          if (
            oldPhoto.filter(
              (v) => v.id === selectedWeed.weed_images[i].weed_image_id
            ).length <= 0
          ) {
            deleteImagesId.push(selectedWeed.weed_images[i]);
          }
        }
        deleteImagesId.forEach((id) => {
          deleteWeedImage(id.weed_image_id);
        });
      }

      if (newPhoto.length > 0) {
        await newPhoto.forEach(async (file) => {
          const url = handleUploadFile(file);
          url
            .then((value) => {
              imageUrl.push(value.url);

              if (oldPhoto.filter((v) => v.url === mainPhoto).length > 0) {
                const main_photo = oldPhoto.filter(
                  (v) => v.url === mainPhoto
                )[0];
                editWeed = {
                  ...editWeed,
                  main_photo: main_photo?.url
                };
              } else if (value.id === mainPhoto && editWeed.main_photo === '') {
                editWeed = {
                  ...editWeed,
                  main_photo: value?.url
                };
              }
            })
            .finally(async () => {
              if (imageUrl.length === newPhoto.length) {
                const responseUpdateWeed = await updateWeed(editWeed);

                if (responseUpdateWeed) {
                  const weedImage = {
                    url: imageUrl,
                    weed_id: selectedWeed?.weed_id,
                    created_by: user.user_id
                  };

                  await createWeedImages(weedImage);

                  clearFields();
                  reset();
                  setUpdateScreen(true);
                  setPrague_PlantName([]);
                  setPrague_CropName([]);
                  setCurrentModal('');
                  closeModal();
                }
              }
            });
        });
      } else {
        const main_photo = oldPhoto.filter((v) => v.url === mainPhoto)[0];
        await updateWeed(
          (editWeed = {
            ...editWeed,
            main_photo: main_photo?.url
          })
        );

        clearFields();
        reset();
        setUpdateScreen(true);
        setCurrentModal('');
        closeModal();
      }
    } catch (error) {
      console.error(error);
      toast(<Toast title="Não foi possível editar a praga" />, {
        type: 'error'
      });
    }
  };

  useEffect(() => {
    getWeedClassification();
    getData();
  }, []);

  const removeSizeRowByIndex = (index) => {
    setInfestationLevel(infestationLevel.filter((v, i) => i !== index));
  };

  const removePhenologicalStageSizeRowByIndex = (index) => {
    setPhenologicalStage(phenologicalStage.filter((v, i) => i !== index));
  };

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      shouldCloseOnOverlayClick={false}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Editar Erva Daninha</S.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <S.InputWrapper style={{ margin: 0 }}>
              <S.InputContainer>
                <Controller
                  name="weedClassification"
                  control={control}
                  defaultValue={selectedWeed?.weed_classification_id}
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span style={{ marginBottom: '8px' }}>
                        Classificação de Daninha
                      </span>
                      <Select
                        placeholder="Selecione..."
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {weedClassificationList.map((pest) => (
                          <MenuItem value={pest.value_Id} key={pest.value_Id}>
                            {pest.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </S.InputContainer>
              <S.InputContainer>
                <span>Nome Comum</span>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={selectedWeed?.name}
                  render={({ onChange, value }) => (
                    <TextField
                      inputProps={{ maxlength: 42 }}
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.name?.message && (
                  <S.InputError>{errors?.name?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer>
                <span>Nome Científico</span>
                <Controller
                  name="scientific_name"
                  control={control}
                  defaultValue={selectedWeed?.scientific_name}
                  render={({ onChange, value }) => (
                    <TextField
                      inputProps={{ maxlength: 70 }}
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </S.InputContainer>
              <S.InputContainer>
                <span>Unidade de Medida</span>
                <Controller
                  name="unit_measurement"
                  control={control}
                  defaultValue={selectedWeed?.measurement_unit}
                  render={({ onChange, value }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.TextAreaContainer>
                <span className="inputContainer__title">Descrição</span>
                <Controller
                  name="description"
                  control={control}
                  defaultValue={selectedWeed?.description}
                  render={({ onChange, value }) => (
                    <TextareaAutosize
                      value={value}
                      onChange={onChange}
                      className="textArea"
                    />
                  )}
                />
              </S.TextAreaContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer>
                <S.CheckboxContainer>
                  <span style={{ marginTop: '15px' }}>Quantidade</span>
                </S.CheckboxContainer>
                {checked && (
                  <S.CheckboxContainer>
                    <label
                      className="radioContainer"
                      style={{
                        color: selectedValue === true ? '#79AC34' : ''
                      }}
                    >
                      Sim
                      <input
                        type="checkbox"
                        checked={selectedValue === true}
                        onClick={(e) => {
                          e.target.checked
                            ? setSelectedValue(true)
                            : setSelectedValue(false);
                        }}
                      />
                      <span className="checkmark" />
                    </label>

                    <label
                      className="radioContainer"
                      style={{
                        color: selectedValue === true ? '#79AC34' : ''
                      }}
                    >
                      Não
                      <input
                        type="checkbox"
                        checked={selectedValue === false}
                        onClick={(e) => {
                          e.target.checked
                            ? setSelectedValue(false)
                            : setSelectedValue(true);
                        }}
                      />
                      <span className="checkmark" />
                    </label>
                  </S.CheckboxContainer>
                )}
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer style={{ height: 'auto' }}>
                <span style={{ paddingBottom: '10px' }}>Foto</span>
                <S.PhotoContainer>
                  {files.length <= 0 ? (
                    <S.DropContainer {...getRootProps()}>
                      <input accept="image/*" {...getInputProps()} />
                      <div className="inputMessage">
                        <MdOutlineCloudUpload />
                        {renderDragMessage()}
                      </div>
                    </S.DropContainer>
                  ) : (
                    <>
                      <Carousel
                        className="carouselContainer"
                        width={500}
                        // height={280}
                        // swipeScrollTolerance={5}
                        showThumbs={false}
                        selectedItem={selectedPhoto}
                        showStatus={false}
                        onChange={(e) => setSelectedPhoto(e)}
                      >
                        {files.map((uploadedFile) => (
                          <div className="viewPhoto">
                            <img
                              alt={uploadedFile.name}
                              src={uploadedFile.preview}
                              // height="280"
                              style={{ border: '2px solid #79ac34' }}
                            />
                          </div>
                        ))}
                      </Carousel>
                      <S.ThumbsContainer>
                        {files.map((uploadedFile, index) => (
                          <S.ThumbItemContainer>
                            <S.ThumbItem>
                              <S.ThumbButtonImage
                                type="button"
                                className="thumbsContainer__button"
                                onClick={() => setSelectedPhoto(index)}
                                style={{
                                  border:
                                    selectedPhoto === index
                                      ? '5px solid #79ac34'
                                      : '2px solid #47aa12'
                                }}
                              >
                                <img
                                  alt={uploadedFile.name}
                                  src={uploadedFile.preview}
                                  style={{
                                    border:
                                      selectedPhoto === index
                                        ? '2px solid #FFF'
                                        : 'none'
                                  }}
                                />
                              </S.ThumbButtonImage>
                              <S.ThumbIconsContainer>
                                <S.ThumbButtonIcon
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                      uploadedFile.id === mainPhoto ||
                                      uploadedFile.url === mainPhoto
                                    ) {
                                      setMainPhoto('');
                                    }
                                    deleteFile(uploadedFile.id);
                                    // deleteWeedImage(uploadedFile.id);
                                    setSelectedPhoto(0);
                                  }}
                                >
                                  <FaTrash size={14} color="#AF0000" />
                                </S.ThumbButtonIcon>
                                {uploadedFile.id === mainPhoto &&
                                  uploadedFile.preview !== mainPhoto && (
                                    <FaCheckCircle color="#79ac34" size={15} />
                                  )}
                                {uploadedFile.preview === mainPhoto &&
                                  uploadedFile.id !== mainPhoto && (
                                    <FaCheckCircle color="#79ac34" size={15} />
                                  )}
                              </S.ThumbIconsContainer>
                            </S.ThumbItem>
                            {selectedPhoto === index &&
                              uploadedFile.id !== mainPhoto &&
                              uploadedFile.preview !== mainPhoto}
                            {selectedPhoto === index &&
                              uploadedFile.id !== mainPhoto &&
                              uploadedFile.preview !== mainPhoto && (
                                <Button
                                  text="Tornar Principal"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (uploadedFile.url !== '') {
                                      setMainPhoto(uploadedFile.url);
                                    } else {
                                      setMainPhoto(uploadedFile.id);
                                    }
                                  }}
                                  className="thumbsContainer__buttonMain"
                                />
                              )}
                          </S.ThumbItemContainer>
                        ))}
                      </S.ThumbsContainer>
                    </>
                  )}
                </S.PhotoContainer>
                <S.UploadFileContainer>
                  {files.length > 0 && (
                    <S.AddFiles {...getRootProps()}>
                      <input accept="image/*" {...getInputProps()} />
                      <S.UploadFileText>
                        <FaUpload size={13} />
                        <span className="uploadfile__span">
                          Adicionar fotos
                        </span>
                      </S.UploadFileText>
                    </S.AddFiles>
                  )}
                </S.UploadFileContainer>
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper style={{ alignItems: 'start' }}>
              <S.InputContainer style={{ height: 'auto' }}>
                <span style={{ paddingBottom: '10px', marginTop: '10px' }}>
                  Nível de Infestação
                </span>
                <TableContainer
                  style={{
                    width: '100%'
                  }}
                >
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: '#e4e4e4'
                          }}
                          colSpan={4}
                        >
                          <input
                            className="table__input--title"
                            type="text"
                            placeholder="Digite o título da tabela"
                            value={titleInfestationLevel}
                            onChange={(e) => {
                              setTitleInfestationLevel(e.target.value);
                            }}
                            // eslint-disable-next-line react/jsx-closing-bracket-location
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {infestationLevel?.map((value, index) => (
                        <TableRow>
                          <TableCell align="center">
                            <div
                              style={{
                                height: '18px',
                                width: '18px',
                                background: value.color ? value.color : ''
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">{value.level}</TableCell>
                          <TableCell align="center">{value.value}</TableCell>
                          <TableCell align="center">
                            <S.DeleteRowButton
                              type="button"
                              onClick={() => removeSizeRowByIndex(index)}
                            >
                              <FaTrash size={14} color="#AF0000" />
                            </S.DeleteRowButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <S.InputWrapper>
                  <S.ColorBtnContainer background={colorInfestation}>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setColorPickerInfestationVisible(
                          !colorPickerInfestationVisible
                        );
                      }}
                    >
                      Cor
                    </button>
                    {colorPickerInfestationVisible && (
                      <S.ColorPicker>
                        <CompactPicker
                          color={colorInfestation}
                          onChange={(e) => handleChangeBackgroundInfestation(e)}
                        />
                      </S.ColorPicker>
                    )}
                  </S.ColorBtnContainer>
                  <TextField
                    label="Nível"
                    name="cycle"
                    id="cycle"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}
                  />
                  <TextField
                    label="Valor"
                    name="size"
                    id="size"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={InfestationValue}
                    onChange={(e) => setInfestationValue(e.target.value)}
                  />
                  <button
                    type="button"
                    className="table__addButton"
                    onClick={(e) => {
                      e.preventDefault();
                      addInfestationInTable();
                    }}
                  >
                    <AddCircle />
                  </button>
                </S.InputWrapper>
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper style={{ alignItems: 'start' }}>
              <S.InputContainer style={{ height: 'auto' }}>
                <span style={{ paddingBottom: '10px', marginTop: '15px' }}>
                  Estágio Fenológico
                </span>
                <TableContainer
                  style={{
                    overflow: 'auto',
                    width: '100%'
                  }}
                >
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: '#e4e4e4'
                          }}
                          colSpan={2}
                        >
                          <input
                            className="table__input--title"
                            type="text"
                            placeholder="Digite o título da tabela"
                            value={phenologicalStageTitle}
                            onChange={(e) => {
                              setPhenologicalStageTitle(e.target.value);
                            }}
                            // eslint-disable-next-line react/jsx-closing-bracket-location
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {phenologicalStage?.map((value, index) => (
                        <TableRow>
                          <TableCell align="center">
                            {value.phenological_stage}
                          </TableCell>
                          <TableCell align="center">
                            <S.DeleteRowButton
                              type="button"
                              onClick={() => {
                                removePhenologicalStageSizeRowByIndex(index);
                              }}
                            >
                              <FaTrash size={14} color="#AF0000" />
                            </S.DeleteRowButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <S.InputWrapper>
                  <TextField
                    label="Valor"
                    name="size"
                    id="size"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={valuePhenologicalStage}
                    onChange={(e) => setValuePhenologicalStage(e.target.value)}
                  />
                  <button
                    type="button"
                    className="table__addButton"
                    onClick={(e) => {
                      e.preventDefault();
                      addPhenologicalStagelInTable();
                    }}
                  >
                    <AddCircle />
                  </button>
                </S.InputWrapper>
              </S.InputContainer>
            </S.InputWrapper>
            <S.ButtonWrapper>
              <Button
                text="Cancelar"
                className="cancelBtn"
                onClick={() => {
                  clearFields();
                  setSelectedWeed([]);
                  setCurrentModal('');
                  closeModal();
                }}
              />
              <Button
                text={
                  loading ? (
                    <>
                      <PuffLoader color="#79ac34" loading={loading} size={30} />
                    </>
                  ) : (
                    'Salvar'
                  )
                }
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                type="submit"
                disabled={loading}
                className="saveBtn"
              />
            </S.ButtonWrapper>
          </S.FormContainer>
        </form>
      </S.Container>
    </Modal>
  );
};

export default ModalEditWeed;
