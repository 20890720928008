export const MethodOfCollect = (param) => {
  switch (param) {
    case 'Argila':
      return 'Dispersão: NaOH';
    case 'MO':
      return 'Extração: Dicromato de Potássio';
    case 'PH':
      return 'Solução: CaCl²';
    case 'Ca':
      return 'Extração: KCl 1N';
    case 'Mg':
      return 'Extração: KCl 1N';
    case 'K':
      return 'Extração: Mehlich 1';
    case 'AI':
      return 'Extração: KCl 1N';
    case 'CTC':
      return '';
    case 'V%':
      return '';
    case 'Sal. AI':
      return '';
    case 'P':
      return 'Extração: Mehlich 1';
    case 'S':
      return 'Extração: Fosfato de Cálcio';
    case 'MO x Arg':
      return 'Extração: Dicromato de Potássio';
    default:
      return '';
  }
};
