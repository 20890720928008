import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  padding: 20px;
  border: 1px solid #dfdfdf;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  transition: 0.8s ease-in;
  overflow: auto;
  border-radius: 4px;
`;

export const PhotoContainer = styled.div`
  width: 100%;
  height: 150px;
  margin: 16px 0;
  background-color: #ccc;
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 21px;
`;

export const Subtitle = styled.p`
  color: #444;
  font-size: 14px;
  margin-bottom: 3px;
`;

export const ContentRow = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #444;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;

  .btnStyle {
    height: 40px;
  }
`;

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  span {
    //font-weight: bold;
    font-size: 16px;
  }
`;

export const CommitView = styled.div`
  width: 100%;
  height: 132px;
  border-radius: 4px;
  resize: none;
  font-size: 16px;
  border: 1px solid #b4b4b4;
  color: #444;
  padding: 5px;
  font-family: Arial;
  overflow: auto;
  margin-bottom: 10px;
`;

export const CommitArea = styled.textarea`
  flex: 1;
  resize: none;
  font-size: 14px;
  font-family: Arial;
  border: none;
  display: flex;
  padding-top: 10px;
  height: 38px;
  color: #444;
`;

export const CommitContainer = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #b4b4b4;
  color: #444;
  padding: 5px;

  svg {
    height: 20px;
    width: 20px;
    color: #444;
    margin-left: 5px;
  }

  button {
    border: none;
    background-color: transparent;
  }
`;
