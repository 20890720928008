import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Modal from 'react-modal';
import { useModal } from '../../../../../../hooks/Modal';
import { FarmContext } from '../../index';
import * as S from './styled';

const EditFarmModal = ({
  messageDialog = 'Deseja realmente fazer isso ?',
  alert = '',
  farm,
  ...props
}) => {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { handleDeleteFarm, setDeleteFarm } = useContext(FarmContext);
  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      {...props}
    >
      <S.DialogContainer>
        <S.Text>{messageDialog}</S.Text>
        <p>{alert}</p>
        <S.ButtonsContainer>
          <S.ConfirmationButton
            onClick={() => {
              handleDeleteFarm(farm?.features[0]?.properties?.farm_id);
              closeModal();
              setDeleteFarm();
            }}
            style={{ backgroundColor: '#79AC34' }}
          >
            Sim
          </S.ConfirmationButton>
          <S.ConfirmationButton
            onClick={() => {
              closeModal();
              setDeleteFarm();
            }}
            style={{ backgroundColor: '#d62222' }}
          >
            Não
          </S.ConfirmationButton>
        </S.ButtonsContainer>
      </S.DialogContainer>
    </Modal>
  );
};

EditFarmModal.propTypes = {
  messageDialog: PropTypes.string,
  alert: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  farm: PropTypes.object
};

export default EditFarmModal;
