/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef
} from 'react';
import Modal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import { TextField } from '@mui/material';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
import filesize from 'filesize';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import api from '../../../../../../../../services/api';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { appConfig } from '../../../../../../../../config';
import Toast from '../../../../../../../../components/Toast';
import Button from '../../../../../../../../components/Button';
import storage from '../../../../../../../../services/firebase';
import * as S from './styled';

const ModalEditItem = () => {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const {
    selectedManagement,
    uploadedFiles: files,
    handleUpload,
    setUpdateScreen,
    itemTypeSelected,
    selectedDisease,
    imageCultures,
    setImageCultures,
    selectedAdmItem,
    filePhotoScale,
    setFilePhotoScale
  } = useContext(DashboardContext);

  const [itemValue, setItemValue] = useState('');
  const [itemEmail, setItemEmail] = useState('');
  const [selectedPhoto, setSelectedPhoto] = useState(0);
  const [mainPhoto, setMainPhoto] = useState(null);
  const [selectedPhotoScale, setSelectedPhotoScale] = useState(0);

  const inputFiles = useRef(null);

  const onDrop = useCallback(
    (file) => {
      handleUpload(file);
    },
    [handleUpload]
  );

  const { isDragActive, isDragReject } = useDropzone({
    accept: ['image/jpeg', 'image/pjpeg', 'image/png', 'image/gif'],
    onDrop
  });

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return (
        <S.UploadMessage>Clique ou arraste uma imagem aqui</S.UploadMessage>
      );
    }

    if (isDragReject) {
      return (
        <S.UploadMessage type="error">
          Tipo de arquivo não suportado
        </S.UploadMessage>
      );
    }

    return (
      <S.UploadMessage type="success">Solte as imagens aqui</S.UploadMessage>
    );
  }, [isDragActive, isDragReject]);

  const getData = () => {
    setMainPhoto(selectedDisease?.photo || null);
  };

  const handleUploadFile = async (e) => {
    try {
      const fileData = await storage.ref(`/cultures/${e.name}`).put(e.file);
      const imageSrc = { id: e.id, url: await fileData.ref.getDownloadURL() };

      files.forEach((file) => URL.revokeObjectURL(file.preview));

      return imageSrc;
    } catch (error) {
      console.error(error);
    }
  };

  async function updateValueFile(value) {
    const params = new URLSearchParams([['valueId', selectedAdmItem.value_Id]]);
    try {
      const response = await api.put(
        `${appConfig.apiHosts.manager}/Value/Update`,
        value,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      toast(<Toast title="Editado com sucesso" />, {
        type: 'success'
      });
      setUpdateScreen(true);
      closeModal();
    } catch (error) {
      console.error(error);
      toast(<Toast title="Não foi possível editar" />, {
        type: 'error'
      });
    }
  }

  const updateValue = async () => {
    let newValue = {};

    if (selectedManagement === 'Administradores') {
      newValue = {
        name: itemEmail,
        type_id: itemTypeSelected
      };
    } else {
      newValue = {
        name: itemValue,
        type_id: itemTypeSelected
      };
    }

    if (filePhotoScale.length > 0) {
      await filePhotoScale.forEach(async (file) => {
        newValue = {
          ...newValue,
          photo: file.url || null
        };

        await updateValueFile(newValue);
      });
    } else {
      await updateValueFile(newValue);
    }
  };

  const fileReader = async () => {
    const filesInput = [];
    const uploadFiles = [];

    filesInput.push(inputFiles.current.files);
    await Object.keys(filesInput[0]).forEach((v) =>
      uploadFiles.push(filesInput[0][v])
    );

    const newUploadedFiles = [];

    await uploadFiles.forEach((file) => {
      newUploadedFiles.push({
        file,
        id: uuid(),
        name: file.name,
        readableSize: filesize(file.size),
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: true,
        error: false,
        url: URL.createObjectURL(file)
      });
    });

    newUploadedFiles.forEach((file) => {
      imageCultures.push(file);

      const url = handleUploadFile(file);
      url.then((value) => {
        filePhotoScale.push(value);
      });
    });
  };

  const deleteFileScale = async (id) => {
    setImageCultures([]);
    setFilePhotoScale([]);

    const params = new URLSearchParams([
      ['value_id', id],
      ['status', false]
    ]);
    try {
      const response = await api.delete(
        `${appConfig.apiHosts.manager}/Value/DeletePhoto`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const clickInputFile = () => {
    inputFiles.current.click();
  };

  useEffect(() => {
    getData();
    if (files.length > 0) {
      setSelectedPhoto(0);
    }
  }, []);

  useEffect(() => {
    setItemValue(selectedAdmItem?.name);
  }, [selectedAdmItem]);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        {selectedManagement === 'Administradores' ? (
          <>
            <S.TableTitle>Administrador</S.TableTitle>
            <hr />
            <S.InputContainer>
              <span>E-mail</span>
              <TextField
                name="adm-email"
                id="adm-email"
                placeholder="Digite o e-mail do usuário"
                margin="dense"
                fullWidth
                size="small"
                value={itemEmail}
                onChange={(e) => setItemEmail(e.target.value)}
              />
              <S.ButtonWrapper>
                <Button
                  text="Cancelar"
                  className="cancelBtn"
                  onClick={() => {
                    closeModal();
                  }}
                />
                <Button
                  text="Salvar"
                  className="saveBtn"
                  onClick={() => updateValue()}
                />
              </S.ButtonWrapper>
            </S.InputContainer>
          </>
        ) : (
          <>
            <S.TableTitle>{selectedManagement}</S.TableTitle>
            <hr />
            <S.InputContainer>
              <S.InputWrapper>
                <S.InputArea>
                  <span>ID (Gerado Automaticamente)</span>
                  <TextField
                    name="id"
                    id="id"
                    disabled
                    margin="dense"
                    fullWidth
                    className="inputId"
                    size="small"
                    value={selectedAdmItem.value_Id}
                  />
                </S.InputArea>
                <S.InputArea>
                  <span>Valor</span>
                  <TextField
                    name="description"
                    id="description"
                    placeholder="Ex: Pulverizar sobre o plantio"
                    margin="dense"
                    fullWidth
                    size="small"
                    defaultValue={selectedAdmItem?.name}
                    value={itemValue}
                    onChange={(e) => setItemValue(e.target.value)}
                  />
                </S.InputArea>
              </S.InputWrapper>

              {selectedAdmItem.type_Id ===
                'c826052d-3a59-4398-aec1-81193ccd2e42' && (
                <S.InputWrapper style={{ marginBottom: '15px' }}>
                  <S.InputContainer style={{ height: 'auto', width: '100%' }}>
                    <span style={{ paddingBottom: '10px' }}>Photo</span>
                    <S.PhotoContainerScale>
                      <input
                        id="input_file"
                        type="file"
                        accept="image/*"
                        ref={inputFiles}
                        className="photoContainerScale__input"
                        onChange={() => fileReader()}
                      />
                      {imageCultures.length <= 0 ? (
                        <S.DropContainerScale onClick={() => clickInputFile()}>
                          <div className="inputMessage">
                            <MdOutlineCloudUpload />
                            {renderDragMessage()}
                          </div>
                        </S.DropContainerScale>
                      ) : (
                        <>
                          <Carousel
                            width={700}
                            // swipeScrollTolerance={5}
                            showThumbs={false}
                            selectedItem={selectedPhotoScale}
                            showStatus={false}
                            showArrows={false}
                            showIndicators={false}
                            onChange={(e) => setSelectedPhotoScale(e)}
                          >
                            {imageCultures.map((uploadedFileScale) => (
                              <div
                                className="viewPhotoScale"
                                key={uploadedFileScale.value_Id}
                              >
                                <img
                                  alt={uploadedFileScale.name || ''}
                                  src={
                                    uploadedFileScale.url ??
                                    uploadedFileScale.photo
                                  }
                                  height="280"
                                  style={{ border: '2px solid #79ac34' }}
                                />
                                <S.ThumbButtonIconScale
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    deleteFileScale(uploadedFileScale.value_Id);
                                    setSelectedPhotoScale(0);
                                  }}
                                  style={{ marginLeft: '10px' }}
                                >
                                  <FaTrash size={14} color="#AF0000" />
                                </S.ThumbButtonIconScale>
                              </div>
                            ))}
                          </Carousel>
                        </>
                      )}
                    </S.PhotoContainerScale>
                  </S.InputContainer>
                </S.InputWrapper>
              )}

              <S.ButtonWrapper>
                <Button
                  text="Cancelar"
                  className="cancelBtn"
                  onClick={closeModal}
                />
                <Button
                  text="Salvar"
                  className="saveBtn"
                  onClick={() => updateValue()}
                />
              </S.ButtonWrapper>
            </S.InputContainer>
          </>
        )}
      </S.Container>
    </Modal>
  );
};

export default ModalEditItem;
