/* eslint-disable react/destructuring-assignment */
export const FarmShareModel = (object) => ({
  farm_share_control_id: object.farm_share_control_id || null,
  farm_id: object.farm_id || '',
  email: object.email || '',
  share_Field: object.share_Field || false,
  share_Subdivision: object.share_Subdivision || false,
  share_FieldMapping: object.share_FieldMapping || false,
  share_DataCollectionPoint: object.share_DataCollectionPoint || false,
  share_Report: object.share_Report || false,
  created_by: object.created_by || ''
});
