/* eslint-disable prettier/prettier */
import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  width: 220px;
  min-height: 300px;
  position: absolute;
  right: 60px;
  top: ${(props) => props.pageTop ? '10px' : '60px'};
  border-radius: 5px;
  padding: 10px 10px;
`;

export const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    background: transparent;
    border: none;
  }
`;

export const Title = styled.span`
  font-size: 0.9rem;
  font-weight: bold;
  color: #000;
`;

export const SearchInput = styled.div`
  margin-top: 10px;
  position: relative;
  border: 1px solid #c4c4c4;
  border-radius: 5px;

  input {
    width: 100%;
    height: 100%;
    flex: 1;
    padding: 5px 25px 5px 5px;
    font-size: 0.8rem;
    border: 0;
    background: transparent;
    /* padding: 0 25px 0 0 */
  }

  svg {
    position: absolute;
    right: 5px;
    top: 5px;
  }
`;

export const UsersList = styled.div`
  width: 100%;
  height: 220px;
  overflow: auto;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  margin-top: -9px;
`;

export const UserItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 1px;
  padding-top: 3px;
  width: 100%;
  border-bottom: 1px solid #79ac34;
  background-color: ${(props) => props.newMessage ? 'rgba(121, 172, 52, 0.3) ' : '#FFF'};

  > span {
    font-size: 0.6rem;
  }

  svg {
    width: 32px;
    height: 32px;
    color: #a4a4a4;
  }

  .deleteIcon {
    width: 16px;
    height: 16px;
    color: #79ac34;
  }
`;

export const Badge = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #79ac34;
  position: absolute;
`;

export const UserItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background: transparent;
  border: 0;
`;

export const UserAvatar = styled.div`
  img {
    width: 32px;
    height: 32px;
  }
`;
export const UserDetails = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin: 5px;
`;
export const UserName = styled.span`
  font-size: 0.7rem;
`;
export const LastMessage = styled.span`
  font-size: 0.6rem;

  display: flex;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
    color: #6C9A2E;
    margin-right: 2px;
    margin-top: 2px;
  }
`;

export const SwitchBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: none;
  margin: 10px 0;
`;

export const SwitchBtnItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  border: none;
  font-size: 12px;
  padding: 0 4px;
  cursor: pointer;
  transition: 0.5s border-bottom;
  padding-bottom: 5px;

  border-bottom: ${(props) =>
    props.active ? '3px solid #79ac34' : '3px solid transparent'};

  svg {
    font-size: 14px;
    margin-bottom: 2px;
    margin-right: 2px;
  }
`;

export const ContactName = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  margin: 10px 0 5px 0px;
  gap: 5px;
`;

export const AreaChat = styled.div`
  border: 1px solid #b4b4b4;
  background-color: #f5f5f5;
  border-radius: 4px;
  height: 175px;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 5px;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;

  .deleteIconMessage {
    width: 12px;
    height: 12px;
    color: #79ac34;
  }
`;

export const MessageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
`;

export const SendMessageContainer = styled.div`
  display: flex;
`;

export const SendMessage = styled.textarea`
  width: 100%;
  border-radius: 4px;
  resize: none;
  font-size: 11px;
  border: 1px solid #b4b4b4;
  color: #333;
  padding: 5px;
  height: 30px;
`;

export const IconBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;

  svg {
    color: #79ac34;
  }
`;

export const Message = styled.div`
  display: flex;
  align-items: flex-end;
  width: auto;
  font-size: 11px;
  margin: 5px 0px;
  justify-content: ${(props) =>
    props.sideMessage ? 'flex-start' : 'flex-end'};

  >span {
    display: flex;
    flex-direction: column;
    background-color: ${(props) =>
    props.sideMessage ? '#e4e4e4' : 'rgba(121, 172, 52, 0.3)'};
    padding: 3px 5px;
    border-radius: 4px;
  }

  svg {
    color: ${(props) =>
    props.viewer ? '#53BDEB' : '#b4b4b4'};;
  }
`;

export const DateHourInfoMessageContainer = styled.div`
  display: flex;
  gap: 3px;
  justify-content: flex-end;
`;

export const DateHourInfoMessage = styled.div`
  font-size: 8px;
`;
