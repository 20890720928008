import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { DashboardContext } from '../../../..';
import Button from '../../../../../../components/Button';
import Toast from '../../../../../../components/Toast';
import { appConfig } from '../../../../../../config';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import SimpleButton from '../../../../../../components/SimpleButton';
import api from '../../../../../../services/api';
import * as S from './styled';

function FinnalyWalkSubdivision() {
  const { setDashboardState } = useContext(DashboardContext);
  const {
    generatedMappingsSubdivisions,
    removeMappingGeometries,
    selectedFarmId,
    removeAllInterections,
    resetMappings,
    removeRestrictionLayers,
    removeSubdivisionGeometries
  } = useMap();
  const { user, token } = useAuth();

  const registerMappings = async () => {
    try {
      generatedMappingsSubdivisions.map(async (walkOfSubdivision) => {
        walkOfSubdivision.features[0].properties = {
          farm_id: selectedFarmId,
          fieldList: walkOfSubdivision.features[0].properties.fieldList,
          subdivision_id:
            walkOfSubdivision.features[0].properties.subdivision_id,
          created_by: user.user_id
        };
        await api.post(
          `${appConfig.apiHosts.field}/FieldMapping/PostFieldMapping`,
          walkOfSubdivision,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      });
      toast(
        <Toast title="Caminhamentos de Subdivisão cadastrados com sucesso" />,
        { type: 'success' }
      );
      removeAllInterections();
      setDashboardState('dashboard@harvestmap');
    } catch (error) {
      toast(
        <Toast title="Não foi possivel cadastar o caminhamentos de subdivisão" />,
        { type: 'error' }
      );
    }
  };
  return (
    <S.Container>
      <SimpleButton
        text="Voltar"
        onClick={() => {
          removeMappingGeometries();
          removeSubdivisionGeometries();
          removeAllInterections();
          setDashboardState('dashboard@harvestmap');
        }}
      />
      <h3>Cadastro de Caminhamento</h3>
      <S.ButtonsContainer>
        <Button
          type="button"
          text="Cadastrar Caminhamentos"
          onClick={() => {
            registerMappings();
          }}
        />
        <Button
          type="button"
          style={{
            background: '#FFF',
            color: '#79AC34',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 5%)'
          }}
          text="Cancelar"
          onClick={() => {
            resetMappings();
            removeMappingGeometries();
            removeRestrictionLayers();
            removeAllInterections();
            setDashboardState('dashboard@harvestmap');
          }}
        />
      </S.ButtonsContainer>
    </S.Container>
  );
}

export default FinnalyWalkSubdivision;
