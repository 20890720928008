/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { OrderedTable } from '../../../../../../../components/OrderedTable';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import { OrganizacaoProfile } from '../utils/moduleProfile.types';
import { useOrganizacaoProfileListRequest } from './hooks/useModuleProfileListRequest';
import { ResearchField } from '../../../../../../../components/ResearchField';

const OrganizacaoProfileList: FC = () => {
  const { request, setFilter } = useOrganizacaoProfileListRequest();

  const headCells: HeadCell<OrganizacaoProfile>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Perfil'
    },
    {
      id: 'perfil',
      numeric: false,
      disablePadding: false,
      label: 'Organização'
    }
  ];

  return (
    <>
      <ResearchField onChange={setFilter} />
      <OrderedTable
        rows={request.items}
        HeadCell={headCells}
        title="Perfil x Organização"
        routeNameAdd="./new"
        routeNameEdit="./edit"
        checkBox={false}
      />

      <Outlet />
    </>
  );
};
export default OrganizacaoProfileList;
