import styled, { css } from 'styled-components';

export const Container = styled.button`
  background: #e4e4e4;
  border-radius: 5px;
  padding: 5px;
  background: transparent;
  font-size: 0.8rem;
  border: solid 1px #8d8d8d;
  transition: 0.15s all;

  &:hover {
    border-color: #bfd76c;
    color: #bfd76c;
  }

  ${(props) =>
    props.selected &&
    css`
      background: #bfd76c;
      &:hover {
        border-color: #bfd76c;
        color: #fff;
      }
    `}
`;
export const ButtonBox = styled.button`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

export const Text = styled.span`
  color: #000000;
  ${(props) =>
    props.selected &&
    css`
      color: #000000;
    `}
`;
