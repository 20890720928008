/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-lone-blocks */
/* eslint-disable prettier/prettier */
/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable linebreak-style */
/* eslint-disable eqeqeq */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { MdDelete, MdModeEdit, MdKeyboardArrowRight, MdPrint } from 'react-icons/md';
import pdfMake from 'pdfmake/build/pdfmake';
import { DashboardContext } from '../../../..';
import { blobToBase64 } from '../../../../../../helpers/blobToBase64';
import Button from '../../../../../../components/Button';
import SimpleButton from '../../../../../../components/SimpleButton';
import { useMap } from '../../../../../../hooks/Map';
import * as S from './styled';
import api from '../../../../../../services/api';
import { useAuth } from '../../../../../../hooks/Auth';
import { reportDepthParam } from '../../../../../../reports/reportDepthParam';
import { reportDepthParamMultiplePages } from '../../../../../../reports/reportDepthParamMultiplePages';
import RequestOwnerNameModal from '../RequestOwnerNameModal';
import { useModal } from '../../../../../../hooks/Modal';
import { setDepth } from '../../../../../../helpers/parseDephPoint';
import { appConfig } from '../../../../../../config';

export const CreatedReportsContext = createContext({});

const CreatedReports = () => {
  const {
    reports,
    setReports,
    reportEditId,
    setReportEditId,
    removeGeometriesColoredSubdivisions,
    renderSubdivisionsWithColors,
    setLegendResultsValue,
    selectedFarmId,
    selectedFarmGeoJson,
    listSubdivisions,
    disableTileMap,
    croquis,
    fieldsList
  } = useMap();
  const { fields } = useContext(DashboardContext);
  const { dashboardState, setDashboardState } = useContext(DashboardContext);
  const handleDeleteMap = (id) => {
    const filteredReport = reports.filter((value) => value.id !== id);
    setReports(filteredReport);
  };

  const [table, setTable] = useState('');

  const { token, user } = useAuth();
  const { openModal } = useModal();
  const [allParamsRel, setAllParamsRel] = useState([]);

  const handleClickReportList = async (parameter, depth) => {
    try {
      const params = new URLSearchParams([['ParamId', parameter]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/ReportLegend/GetReportLegendByParam`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // const responseParams = response.data.data;
      setLegendResultsValue(response.data.data);
    } catch (error) {
      console.error(error);
    }

    removeGeometriesColoredSubdivisions();
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['parameter', parameter],
        ['depth', setDepth(depth)]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // const responseParams = response.data.data;
      renderSubdivisionsWithColors(listSubdivisions, response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getTable = async () => {
    try {
      const params = new URLSearchParams([['farmId', selectedFarmId]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/Report/GetReportListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setTable(response.data.data);
      // ['Argila', 'MO', 'PH', 'Ca', 'Mg', 'K', 'AI', 'CTC', 'V%', 'P', 'Sal. AI', 'MO x Arg', 'S']
      // CTC
      // V%
      response.data.data.map((value) => {
        if (
          Number(value.pH_CaCl2) > 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'PH')
            .length < 1
        ) {
          return allParamsRel.push({ param: 'PH', depth: value.prof });
        }
        if (
          Number(value.argila) > 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'Argila')
            .length < 1
        ) {
          return allParamsRel.push({ param: 'Argila', depth: value.prof });
        }
        if (
          Number(value.mo) > 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'MO')
            .length < 1
        ) {
          return allParamsRel.push({ param: 'MO', depth: value.prof });
        }
        return null;
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTable();
  }, []);

  useEffect(() => {
    if (reports.length === 0) {
      setDashboardState('dashboard@report');
    }
  }, [reports]);

  const exportReportPages = (ownerName, reportPages) => {
    const report = reportDepthParamMultiplePages(
      reports,
      ownerName,
      table,
      reportPages,
      croquis,
      selectedFarmGeoJson,
      fields,
      listSubdivisions);
    pdfMake.createPdf(report).open();
  };

  return (
    <CreatedReportsContext.Provider value={{ exportReportPages }}>
      <S.Container>
        <SimpleButton
          text="Voltar"
          onClick={() => {
            setDashboardState('dashboard@report');
            setReports([]);
          }}
        />
        <h3>Relatórios</h3>
        <S.ListCardContainer>
          {reports.map((map, index) => (
            <S.FarmCardContainer key={map.id}>
              <S.ViewAction>
                <S.FarmCardHeader>
                  <S.FarmCardText>
                    <h3>Relatório {index + 1}</h3>
                    <span>Parametro: {map.param} </span>
                    <br />
                    <span>Camada: {map.layer}</span>
                  </S.FarmCardText>
                </S.FarmCardHeader>
                <button
                  type="button"
                  onClick={() => {
                    handleClickReportList(map.param, map.layer);
                  }}
                >
                  <MdKeyboardArrowRight size={28} color="#444444" />
                </button>
              </S.ViewAction>
              <S.FarmCardActions>
                {/* <button
                  type="button"
                  onClick={async () => {
                    const canvas = document.getElementsByTagName('canvas')[0];
                    const imgData = canvas.toDataURL('image/png');
                    canvas.toBlob(async (blob) => {
                      const base64 = await blobToBase64(blob);
                      const report = reportDepthParam(
                        { reportTitle: `RESULTADO DE ANÁLISE DE SOLO - ${new Date().getFullYear()}`,
                          farmName: selectedFarmGeoJson.features[0].properties.farm_name,
                          depth: map.layer,
                          param: map.param,
                          mapImage: imgData
                        });
                      pdfMake.createPdf(report).open();
                    });
                  }}
                >
                  <span style={{ marginRight: '2px' }}>Imprimir</span>
                  <MdPrint />
                </button> */}
                <button
                  type="button"
                  onClick={() => {
                    setReportEditId(map.id);
                    setDashboardState('dashboard@report');
                  }}
                >
                  <span>Editar</span>
                  <MdModeEdit />
                </button>
                <button
                  type="button"
                  className="simple-buttons"
                  onClick={() => handleDeleteMap(map.id)}
                >
                  <span>Deletar</span>
                  <MdDelete />
                </button>
              </S.FarmCardActions>
            </S.FarmCardContainer>
          ))}
        </S.ListCardContainer>
        <Button
          text="Imprimir relatório"
          onClick={() => {
          // exportReportPages();
            openModal();
          }}
          className="buttonAddMap"
        />
        <Button
          text="Adicionar outro relatório"
          onClick={() => {
            // handleClick();
            // setListMapsVisible(true);
            setDashboardState('dashboard@report');
          }}
          className="buttonAddMap"
        />
        <Button
          text="Cancelar"
          onClick={() => {
            setReports([]);
            disableTileMap();
            setDashboardState('dashboard@report');
          }}
        />
      </S.Container>
      <RequestOwnerNameModal />
    </CreatedReportsContext.Provider>
  );
};
export default CreatedReports;
