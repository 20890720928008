/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { Controller } from 'react-hook-form';
import { Checkbox, TextField } from '@mui/material';
import * as s from './utils/organizacaoForm.style';
import { useModal } from '../../../../../../../hooks/Modal';
import { useOrganizacaoForm } from './hooks/useOrganizacaoForm';
import { OrganizacaoFormProps } from '../utils/organizacao.types';
import Toast from '../../../../../../../components/Toast';

const OrganizacaoForm: FC<OrganizacaoFormProps> = (props) => {
  const { modalCreatUserStyles } = useModal();
  const navigate = useNavigate();
  const { form, onSelectActivated, title, statusCode, mensageSuccess } =
    useOrganizacaoForm(props);
  const { type } = props;

  useEffect(() => {
    if (statusCode === 200) {
      toast(
        <Toast
          title={mensageSuccess[type].title}
          content={mensageSuccess[type].content}
        />,
        {
          type: 'success'
        }
      );
    }
    if (statusCode === 409) {
      toast(
        <Toast
          title={'Nome da organização já cadastrada'}
          content={'O nome da organização já está cadastrada'}
        />,
        {
          type: 'warning'
        }
      );
    }
  }, [statusCode]);
  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen
      style={modalCreatUserStyles}
    >
      <s.title className="container__title">{title[type]}</s.title>
      <s.container>
        <div className="flex">
          <Controller
            name="name"
            control={form.control}
            render={({ onBlur, onChange, value }) => (
              <TextField
                style={{ flex: 3 }}
                type="text"
                onBlur={({ target }) => {
                  onBlur();
                }}
                onChange={({ target }) => {
                  onChange(target.value);
                }}
                value={value}
                label="Nome"
              />
            )}
          />
          {type !== 'new' && (
            <Controller
              name="active"
              control={form.control}
              render={({ value }) => (
                <div
                  style={{ flex: 1, textAlign: 'left', alignSelf: 'center' }}
                >
                  <label>
                    Ativado
                    <Checkbox onChange={onSelectActivated} checked={value} />
                  </label>
                </div>
              )}
            />
          )}
        </div>
        <s.containerbotton>
          <button
            type="button"
            className="containerbotton__bottoncancel"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </button>
          <button
            type="button"
            className={`containerbotton__bottonregister${
              form.isValid ? '--disabled' : ''
            }`}
            onClick={form.handleSubmit}
            disabled={form.isValid}
          >
            Registrar
          </button>
        </s.containerbotton>
      </s.container>
    </Modal>
  );
};

export default OrganizacaoForm;
