import styled from 'styled-components';

export const Container = styled.div`
  margin: 20px;
  position: absolute;
  top: 0;
  width: 250px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  span {
    font-size: 0.7rem;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  h1 {
    font-size: 1rem;
  }

  > button {
    border: none;
    background: transparent;
  }
`;

export const Form = styled.form`
  width: 100%;
  position: relative;

  label {
    display: flex;
    flex-direction: column;
    margin-top: 5px;

    > div {
      display: flex;
      input {
        margin: 5px;
      }
    }
  }
`;

export const InputDate = styled.input`
  position: relative;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 40px;
  margin-top: 10px;
  padding: 0 5px;
  width: 100%;
  /* width: 260px; */
`;
