import { AxiosResponse } from 'axios';
import api from './configFarmApi';

class FarmApi {
  constructor(token: string) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  get getFarm() {
    return 'get-farm';
  }

  async GetFarms(id: string): Promise<AxiosResponse> {
    return api.get(`/Farm/GetFarmListByOwnerId?ownerId=${id}`);
  }
}

export default FarmApi;
