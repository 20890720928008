/* eslint-disable react/no-array-index-key */
/* eslint-disable import/named */
import Control from 'ol/control/Control';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MdVerticalAlignBottom, MdVerticalAlignTop } from 'react-icons/md';
import { shade, setLightness } from 'polished';
import { useAuth } from '../../hooks/Auth';
import { useMap } from '../../hooks/Map';
import { DashboardContext } from '../../pages/Dashboard';
import api from '../../services/api';
import ImgLegendaP from '../../assets/legenda_p.png';
import ImgLegendaMo from '../../assets/legenda_mo.png';
import {
  Container,
  LegendResultsBody,
  LegendResultsContainer,
  LegendResultsDescription,
  LegendResultsHeader,
  LegendResultsValueColor,
  LegendResultsValueContainer,
  TextExibition,
  TextExibitionContainer,
  ContainerLegend,
  LegendRecommBody,
  LegendRecommContainer,
  LegendRecommValueColor,
  LegendRecommValueContainer
} from './styled';
import { setDepth } from '../../helpers/parseDephPoint';
import { appConfig } from '../../config';

const WebMap4 = ({ ...props }) => {
  const [legendResultsValue, setLegendResultsValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [map, setMap] = useState();
  const mapRef = useRef();
  const controlRef = useRef();
  const legendRef4 = useRef();
  mapRef.current = map;
  const { user, token } = useAuth();
  const {
    newMap,
    setRefMap4,
    refMap4,
    selectedFarmGeoJson,
    raster,
    renderedMaps,
    renderFarm,
    renderFields,
    renderSubdivision,
    renderSubdivisions,
    setListSubdivisions,
    selectedFarmId,
    setListFields,
    renderSubdivisionsWithColorsMapRef4,
    listSubdivisions,
    recommZoneList,
    recommendationMapSubtitle,
    renderRecommendationZoneWithColorsMapRef4,
    setLegendRecommendationValue,
    legendRecommendationValue4,
    setLegendRecommendationValue4,
    recommendationMapSubtitle4,
    setRecommendationMapSubtitle4
  } = useMap();
  const {
    dashboardState,
    showResult,
    setSubdivisions,
    resultVisible,
    setFields
  } = useContext(DashboardContext);

  const mapLayer = raster();
  const [minimizedLegend, setMinimizedLegend] = useState(false);
  const parameterMap = renderedMaps[3].param;
  const depthMap = renderedMaps[3].layer;
  const fertilizeMap = renderedMaps[3].fertilize;
  const colorMap = renderedMaps[3].color;
  const typeMap = renderedMaps[3].mapType;

  useEffect(() => {
    const originMap = newMap(mapRef.current, mapLayer);
    setRefMap4(originMap);
    const myLegend4 = new Control({ element: legendRef4.current });
    originMap.addControl(myLegend4);
  }, []);

  const getSubdivisions = async () => {
    try {
      setLoading(true);
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSubdivisions(response.data.data);
      renderSubdivisions(response.data.data);
      setListSubdivisions(response.data.data);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getFields = async () => {
    try {
      setFields([]);
      setListFields([]);
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Field/GetActiveFieldListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFields(response.data.data);
      setListFields(response.data.data);
      renderFields(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setFields([]);
    }
  };

  const handleGenerateLegend = async (parameter) => {
    try {
      const params = new URLSearchParams([['ParamId', parameter]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/ReportLegend/GetReportLegendByParam`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setLegendResultsValue(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleViewParam = async (parameter, depth) => {
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['parameter', parameter],
        ['depth', setDepth(depth)]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // const responseParams = response.data.data;
      renderSubdivisionsWithColorsMapRef4(listSubdivisions, response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const generateColoredZone = async (color) => {
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/RecommendationZone/GetRecommendationsListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const selectedRecommZoneBytFert = response.data.data.filter(
        (v) => v.fertilize === fertilizeMap
      );

      const selectedRecommZone = [];

      recommZoneList.forEach(async (v) => {
        if (selectedRecommZoneBytFert.length > 0) {
          await selectedRecommZoneBytFert.forEach((zone) => {
            if (
              zone.recommendation_zone_id ===
              v.features[0].properties.recommendation_zone_id
            ) {
              selectedRecommZone.push(v);
            }
          });
        }
      });

      const quantity = selectedRecommZoneBytFert
        .map((value) => value.quantity)
        .filter((value, index, self) => self.indexOf(value) === index);

      const itemFilter = [];

      selectedRecommZoneBytFert.forEach(async (item) => {
        if (itemFilter.length <= 0) {
          itemFilter.push(item);
        } else {
          itemFilter.forEach((value) => {
            if (value?.fertilize !== item.fertilize) {
              itemFilter.push(item);
            }
          });
        }
      });

      const sort = await selectedRecommZoneBytFert.sort(
        (n1, n2) => n2.quantity - n1.quantity
      );

      const cores = selectedRecommZoneBytFert.map((value) => {
        sort.forEach((qt, index) => {
          if (value.quantity === qt.quantity) {
            value = {
              ...value,
              color: `${setLightness((index + 1) / 10, color)}`
            };
          }
        });
        return value;
      });

      cores.forEach((item, index) => {
        recommendationMapSubtitle4.push({
          quantity: item.quantity,
          fertilize: item.fertilize,
          measurement_unit: item.measurement_unit,
          color: `${setLightness((index + 1) / 10, color)}`
        });
      });

      renderRecommendationZoneWithColorsMapRef4(selectedRecommZone, cores);
      setLegendRecommendationValue4(recommendationMapSubtitle4);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    renderFarm(selectedFarmGeoJson);
    getSubdivisions();
    getFields();
    if (typeMap === 'results') {
      handleGenerateLegend(parameterMap);
      handleViewParam(parameterMap, depthMap);
    } else {
      setRecommendationMapSubtitle4([]);
      setLegendRecommendationValue4([]);
      generateColoredZone(colorMap);
    }
  }, [refMap4]);

  return (
    <>
      <ContainerLegend ref={legendRef4} {...props}>
        {legendResultsValue.length > 0 && resultVisible && (
          <>
            {parameterMap !== 'P' && parameterMap !== 'MO x Arg' && (
              <LegendResultsContainer>
                <LegendResultsHeader>
                  <h3>
                    Parâmetro: <b>{parameterMap}</b>
                  </h3>
                  <button
                    type="button"
                    onClick={() => setMinimizedLegend(!minimizedLegend)}
                  >
                    {minimizedLegend ? (
                      <MdVerticalAlignTop />
                    ) : (
                      <MdVerticalAlignBottom />
                    )}
                  </button>
                </LegendResultsHeader>
                <LegendResultsBody
                  minimized={minimizedLegend}
                  className="legendDynamics"
                >
                  {legendResultsValue.map((legendResult, index) => {
                    return (
                      <LegendResultsValueContainer key={index}>
                        <LegendResultsValueColor colored={legendResult.color} />
                        <LegendResultsDescription>
                          {`${legendResult.range} - ${legendResult.description}`}
                        </LegendResultsDescription>
                      </LegendResultsValueContainer>
                    );
                  })}
                </LegendResultsBody>
              </LegendResultsContainer>
            )}

            {parameterMap === 'P' && (
              <LegendResultsContainer className="legendImgP">
                <LegendResultsHeader>
                  <h3>
                    Parâmetro: <b>{parameterMap}</b>
                  </h3>
                  <button
                    type="button"
                    onClick={() => setMinimizedLegend(!minimizedLegend)}
                  >
                    {minimizedLegend ? (
                      <MdVerticalAlignTop />
                    ) : (
                      <MdVerticalAlignBottom />
                    )}
                  </button>
                </LegendResultsHeader>
                <LegendResultsBody minimized={minimizedLegend}>
                  <LegendResultsValueContainer>
                    <img alt="legendaP" src={ImgLegendaP} />
                  </LegendResultsValueContainer>
                </LegendResultsBody>
              </LegendResultsContainer>
            )}

            {parameterMap === 'MO x Arg' && (
              <LegendResultsContainer className="legendImgMo">
                <LegendResultsHeader>
                  <h3>
                    Parâmetro: <b>{parameterMap}</b>
                  </h3>
                  <button
                    type="button"
                    onClick={() => setMinimizedLegend(!minimizedLegend)}
                  >
                    {minimizedLegend ? (
                      <MdVerticalAlignTop />
                    ) : (
                      <MdVerticalAlignBottom />
                    )}
                  </button>
                </LegendResultsHeader>
                <LegendResultsBody minimized={minimizedLegend}>
                  <LegendResultsValueContainer>
                    <img alt="legendaMo" src={ImgLegendaMo} />
                  </LegendResultsValueContainer>
                </LegendResultsBody>
              </LegendResultsContainer>
            )}
          </>
        )}

        <>
          {legendRecommendationValue4.length > 0 && (
            <LegendRecommContainer>
              <LegendResultsHeader>
                <h3>Legenda</h3>
              </LegendResultsHeader>
              <LegendRecommBody minimized={minimizedLegend}>
                {legendRecommendationValue4.map(
                  (legendRecommendation, index) => {
                    return (
                      <LegendRecommValueContainer key={index}>
                        <LegendRecommValueColor
                          colored={legendRecommendation.color}
                        />
                        <LegendResultsDescription>
                          {`${legendRecommendation.quantity} ${legendRecommendation.measurement_unit} - ${legendRecommendation.fertilize}`}
                        </LegendResultsDescription>
                      </LegendRecommValueContainer>
                    );
                  }
                )}
              </LegendRecommBody>
            </LegendRecommContainer>
          )}
        </>
      </ContainerLegend>
      <TextExibitionContainer>
        <TextExibition>Mapa 4</TextExibition>
      </TextExibitionContainer>
      <Container ref={mapRef} {...props} />
    </>
  );
};

export default WebMap4;
