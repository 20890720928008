import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { DashboardContext } from '../../../..';
import Button from '../../../../../../components/Button';
import SimpleButton from '../../../../../../components/SimpleButton';
import Toast from '../../../../../../components/Toast';
import { appConfig } from '../../../../../../config';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import api from '../../../../../../services/api';
import * as S from './styles';

function EditWalkOfSubdivision() {
  const { setDashboardState } = useContext(DashboardContext);
  const {
    removeAllInterections,
    editedWalkOfSubdivision,
    removeSubdivisionGeometries,
    removeMappingGeometries
  } = useMap();
  const { token } = useAuth();

  const EditSubdivision = async () => {
    try {
      await api.put(
        `${appConfig.apiHosts.field}/FieldMapping/UpdateFieldMapping`,
        editedWalkOfSubdivision,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      toast(<Toast title="Caminhamento da subdivisão editado com sucesso" />, {
        type: 'success'
      });
      setDashboardState('dashboard@harvestmap');
    } catch (error) {
      toast(
        <Toast title="Não foi possivel editar caminhamento da subdivisão" />,
        { type: 'error' }
      );
    }
  };
  return (
    <>
      <SimpleButton
        text="Voltar"
        onClick={() => {
          removeMappingGeometries();
          removeSubdivisionGeometries();
          removeAllInterections();
          setDashboardState('dashboard@harvestmap');
        }}
      />
      <S.Container>
        <h1>Editando Caminhamento de Subdivisão</h1>
        <Button
          text="Salvar Caminhamento"
          onClick={() => {
            removeAllInterections();
            EditSubdivision();
          }}
        />
      </S.Container>
    </>
  );
}

export default EditWalkOfSubdivision;
