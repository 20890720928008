import React, { forwardRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styled';

const Input = forwardRef((props, ref) => {
  const { name, style, error, ...rest } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(true);
  }, []);
  return (
    <Container
      isErrored={error}
      isFilled={isFilled}
      isFocused={isFocused}
      style={style}
    >
      <input
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        type="text"
        name={name}
        {...rest}
        ref={ref}
      />
    </Container>
  );
});

Input.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  style: PropTypes.shape()
};

export default Input;
