/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useRef, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
} from '@mui/material';
import { MdAttachFile, MdCheck, MdClose, MdError, MdSend } from 'react-icons/md';
import { useDropzone } from 'react-dropzone';
import PuffLoader from 'react-spinners/PuffLoader';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import { useAuth } from '../../../../../../../../hooks/Auth';
import storage from '../../../../../../../../services/firebase';
import api from '../../../../../../../../services/api';
import { appConfig } from '../../../../../../../../config';
import Button from '../../../../../../../../components/Button';
import * as S from './styled';

const ViewRoutine = () => {
  const { openModal } = useModal();
  const { token, user, getNotifications } = useAuth();
  const {
    setItemProblemVisible,
    setOpendedModal,
    setItemSelectedVisible,
    selectedRoutine,
    setSelectedRoutine,
    listTypeManager,
    selectTypeRoutineList,
    setSelectTypeRoutineList,
    uploadedFiles: files,
    deleteFile,
    handleUpload,
    setUploadedFiles
  } = useContext(DashboardContext);

  const [loading, setLoading] = useState('false');
  const [commits, setCommits] = useState('');
  const [additionalRemarks, setAdditionalRemarks] = useState([]);
  const [formatDate, setFormatDate] = useState();

  const [btnDisabled, setBtnDisabled] = useState(false);

  const getTypeRoutine = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Rotina')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      response.data.data.values.forEach((v) => {
        if (selectedRoutine.routine_type_id === v.value_Id) {
          const routineName = {
            ...selectedRoutine,
            name: v.name
          };
          setSelectedRoutine(routineName);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const formatRoutineDate = (date) => {
    const data = new Date(date);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const onDrop = useCallback((file) => {
    handleUpload(file);
  }, [handleUpload]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: ['image/jpeg', 'image/pjpeg', 'image/png', 'image/gif'],
    maxFiles: 1,
    multiple: null,
    onDrop,
  });

  const handleAddInvoiceFiles = async (e) => {
    try {
      const fileData = await storage.ref(`/images/${e.name}`).put(e.file);
      const imageSrc = await fileData.ref.getDownloadURL();

      files.forEach((file) => URL.revokeObjectURL(file.preview));

      return imageSrc;
    } catch (error) {
      console.error(error);
    }
  };

  const getAdditionalRemarks = async () => {
    additionalRemarks.splice(0, additionalRemarks.length);
    try {
      const params = new URLSearchParams([['routine_id', selectedRoutine.routine_id]]);

      const response = await api.get(
        `${appConfig.apiHosts.prague}/Routine/AdditionalRemarks/ListByRoutineId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      additionalRemarks.push(response.data.data.additionalRemarkss.reverse());
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const createAdditionalRemarks = async () => {
    setLoading(true);
    let imageUrl;
    if (files.length > 0) {
      imageUrl = await handleAddInvoiceFiles(files[files.length - 1]);
    }

    const newRemarks = {
      text: commits,
      image: imageUrl || '',
      routine_id: selectedRoutine.routine_id,
      created_by: user.user_id
    };

    try {
      const response = await api.post(
        `${appConfig.apiHosts.prague}/Routine/AdditionalRemarks/Create`,
        newRemarks,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setCommits('');
      setUploadedFiles([]);
      getAdditionalRemarks();
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTypeRoutine();
    formatRoutineDate();
    getAdditionalRemarks();
  }, []);

  useEffect(() => {
    if (files.length > 0) {
      setBtnDisabled;
    } else {
      setBtnDisabled(false);
    }
  }, [files]);

  return (
    <S.Container>
      <S.CloseButton onClick={() => setItemSelectedVisible('')}>
        <MdClose />
      </S.CloseButton>
      <S.Title>Rotina {selectedRoutine?.title}</S.Title>
      <S.Subtitle>Data Inicial</S.Subtitle>
      <S.ContentRow>{formatRoutineDate(selectedRoutine?.init_date)}</S.ContentRow>
      <S.Subtitle>Data Final</S.Subtitle>
      <S.ContentRow>{selectedRoutine?.end_date ? formatRoutineDate(selectedRoutine?.end_date) : '-'}</S.ContentRow>
      <S.Subtitle>Status</S.Subtitle>
      <S.ContentRow>{selectedRoutine?.status_name}</S.ContentRow>
      <S.Subtitle>Tipo</S.Subtitle>
      <S.ContentRow>{selectedRoutine?.routine_type_name}</S.ContentRow>
      <S.Subtitle>Talhão</S.Subtitle>
      <S.ContentRow>{selectedRoutine?.encompass_name}</S.ContentRow>
      <S.Subtitle>Executor</S.Subtitle>
      <S.ContentRow>{selectedRoutine?.executor_name}</S.ContentRow>
      <S.Subtitle>Monitoramento Instantâneo?</S.Subtitle>
      <S.ContentRow>{selectedRoutine?.instant_monitoring ? 'Sim' : 'Não'}</S.ContentRow>
      <S.InputContainer>
        <span style={{ marginBottom: '10px' }}>Observações Adicionais</span>
        <S.CommitView loading={loading}>
          {loading ? (
            <>
              <PuffLoader
                color="#79ac34"
                loading={loading}
                size={30}
              />
            </>
          ) : (
            <>
              {additionalRemarks.length > 0 && (
                <>
                  {additionalRemarks[0]?.map((v, i) => (
                    <S.Commit key={i}>
                      {v.text && <b>{v.text}</b>}
                      {v.image && <img src={v.image} alt="" />}
                    </S.Commit>
                  ))}
                </>
              )}
            </>
          )}
        </S.CommitView>
        <S.CommitContainer loading={loading}>
          {loading ? (
            <>
              <PuffLoader
                color="#79ac34"
                loading={loading}
                size={30}
              />
            </>
          ) : (
            <>
              <S.CommitArea placeholder="Insira um comentário" value={commits} onChange={(e) => setCommits(e.target.value)} />
              <button onClick={() => createAdditionalRemarks()}>
                <MdSend />
              </button>
              {files.length <= 0 ? (
                <S.DropContainer {...getRootProps()}>
                  <input accept="image/*" maxFiles={1} {...getInputProps()} disabled={btnDisabled} />
                  <div className="inputMessage">
                    <MdAttachFile />
                  </div>
                </S.DropContainer>
              ) : (
                <div className="inputMessage">
                  <MdAttachFile color="#CCC" />
                </div>
              )}
            </>
          )}
        </S.CommitContainer>
        {files.length > 0 && (
          <S.FileInfo>
            {files.map((uploadedFile) => (
              <>
                <div>
                  <strong>{uploadedFile.name}</strong>
                </div>
                <button onClick={() => deleteFile(files.id)}>
                  <MdClose />
                </button>
              </>
            ))}
          </S.FileInfo>
        )}
      </S.InputContainer>

      <S.ButtonContainer>
        <Button
          type="button"
          text="Sugerir Recomendação"
          className="btnStyle"
          onClick={() => {
            setOpendedModal('modalNewRecommendation');
            openModal();
          }}
        />
      </S.ButtonContainer>
    </S.Container>
  );
};

export default ViewRoutine;
