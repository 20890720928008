/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC, createContext, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/Auth';

const Authorization: FC<{ children: ReactNode }> = ({ children }) => {
  const user = useAuth();

  if (!user.user) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default Authorization;
