import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  background: #79ac34;
  height: 50px;
  border-radius: 8px;
  border: 0;
  padding: 0 15px;
  color: #fff;
  width: 100%;
  font-weight: bold;
  margin-top: 15px;
  transition: all 0.2s;
  font-size: 16px;

  &:hover {
    background: ${shade(0.2, '#79ac34')};
  }

  &:disabled {
    background: #dcdcdc;
  }
`;
