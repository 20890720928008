import styled from 'styled-components';

export const containerTable = styled.div`
  display: flex;
  gap: 4%;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
`;

export const containerbotton = styled.div`
  position: absolute;
  display: flex;
  bottom: 11px;
  right: 22px;
  gap: 10px;
  .containerbotton__bottonregister {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid;
    background-color: #608929;
    color: white;
    width: 125px;
  }
  .containerbotton__bottonregister--disabled {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid;
    background-color: #dcdcdc;
    color: white;
    width: 125px;
  }
  .containerbotton__bottoncancel {
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: #dcdcdc;
    width: 125px;
  }
`;

export const container = styled.div`
  display: flex;
  flex-direction: column;
  height: 490px;
  overflow-y: auto;
  gap: 10%;
`;
