/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useRef, useCallback } from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
} from '@mui/material';
import { MdAttachFile, MdCheck, MdClose, MdError } from 'react-icons/md';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import Button from '../../../../../../../../components/Button';
import * as S from './styled';

const ViewRecommendation = ({ typeRecommendation, culture, product, dose, growCrops, area, volume }) => {
  const { openModal } = useModal();

  const {
    setItemSelectedVisible,
    setOpendedModal,
    selectedCustomerRecommendation
  } = useContext(DashboardContext);

  return (
    <S.Container>
      <S.CloseButton onClick={() => setItemSelectedVisible('')}>
        <MdClose />
      </S.CloseButton>
      <S.Title>Recomendação</S.Title>
      <S.Subtitle>Tipo</S.Subtitle>
      <S.ContentRow>{selectedCustomerRecommendation.type_name}</S.ContentRow>
      <S.Subtitle>Cultura</S.Subtitle>
      <S.ContentRow>{selectedCustomerRecommendation.crop_type_name}</S.ContentRow>
      <S.Subtitle>Produto</S.Subtitle>
      <S.ContentRow>{selectedCustomerRecommendation.product_name}</S.ContentRow>
      <S.Subtitle>Dose</S.Subtitle>
      <S.ContentRow>{selectedCustomerRecommendation.dose}</S.ContentRow>
      <S.Subtitle>Cultivar</S.Subtitle>
      <S.ContentRow>{selectedCustomerRecommendation.plant_name}</S.ContentRow>
      <S.Subtitle>Area</S.Subtitle>
      <S.ContentRow>{selectedCustomerRecommendation.area}</S.ContentRow>
      <S.Subtitle>Volume de Calda</S.Subtitle>
      <S.ContentRow>{selectedCustomerRecommendation.syrup_volume_name}</S.ContentRow>

      <S.ButtonContainer>
        <Button
          type="button"
          text="Editar Recomendação"
          className="btnStyle"
          onClick={() => {
            setOpendedModal('modalEditRecommendation');
            setItemSelectedVisible('');
            openModal();
          }}
        />
      </S.ButtonContainer>
    </S.Container>
  );
};

export default ViewRecommendation;
