export const GetParamNameByParamInitials = (param) => {
  switch (param) {
    case 'Argila':
      return 'Argila';
    case 'MO':
      return 'Matéria Orgânica';
    case 'PH':
      return 'pH';
    case 'Ca':
      return 'Cálcio';
    case 'Mg':
      return 'Magnésio';
    case 'K':
      return 'Potássio';
    case 'AI':
      return 'Alumínio';
    case 'CTC':
      return 'CTC';
    case 'V%':
      return 'Saturação por Bases (V%)';
    case 'Sal. AI':
      return 'Saturação por Alumínio (m%)';
    case 'P':
      return 'Fósforo';
    case 'S':
      return 'Enxofre (S)';
    case 'MO x Arg':
      return 'Matéria Orgânica';
    default:
      return '-';
  }
};
