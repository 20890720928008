/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { MdFilterList, MdSearch } from 'react-icons/md';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Pagination
} from '@mui/material';
import PuffLoader from 'react-spinners/PuffLoader';
import { DashboardContext } from '../../../../index';
import * as S from './styled';
import { useModal } from '../../../../../../hooks/Modal';
import Button from '../../../../../../components/Button';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import api from '../../../../../../services/api';
import { appConfig } from '../../../../../../config';

const Alert = () => {
  const {
    openedModal,
    setOpendedModal,
    setUpdateScreen,
    updateScreen,
    problemList,
    setProblemList,
    listTypeManager
  } = useContext(DashboardContext);
  const { closeModal, openModal } = useModal();
  const { selectedFarmId } = useMap();
  const { token, user, getNotifications } = useAuth();

  const [loading, setLoading] = useState(false);
  const [alertList, setAlertList] = useState([]);
  const [pragueList, setPragueList] = useState([]);
  const [totalPagesPrague, setTotalPagesPrague] = useState();
  const [totalPagesAlert, setTotalPagesAlert] = useState();
  const [searchField, setSearchField] = useState('');

  const [reportsPragues, setReportsPragues] = useState([]);

  const getProblemListByPrague = async (pageNumber = 1) => {
    setLoading(true);
    setReportsPragues([]);

    try {
      const type_id = listTypeManager.filter((v) => v.name === 'Problema')[0].type_Id;
      const paramsType = new URLSearchParams([
        ['type_id', type_id]
      ]);
      const responseValue = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params: paramsType,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const statusFilter = responseValue.data.data.values.filter((v) => v.name === 'Praga')[0].value_Id;

      const params = new URLSearchParams([
        ['problemtype_id', statusFilter],
        ['page', pageNumber],
        ['filter', searchField || '']
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Problem/ListByProblemTypeId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setPragueList(response.data.data.problems);
      setTotalPagesPrague(response.data.data.pagination.totalPages);

      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getAlertList = async (pageNumber = 1) => {
    setLoading(true);
    setReportsPragues([]);

    try {
      const params = new URLSearchParams([
        ['farm_id', selectedFarmId],
        ['page', pageNumber],
        ['filter', searchField || '']
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Alert/ListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setAlertList(response.data.data.alerts);
      setTotalPagesAlert(response.data.data.pagination.totalPages);

      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePagePrague = (event, value) => {
    getProblemListByPrague(value);
  };

  const handleChangePageAlerts = (event, value) => {
    getAlertList(value);
  };

  useEffect(() => {
    getProblemListByPrague();
    getAlertList();
  }, []);

  useEffect(() => {
    if (updateScreen) {
      getProblemListByPrague();
      getAlertList();
    }
  }, [updateScreen]);

  useEffect(() => {
    if (searchField === '') {
      getProblemListByPrague();
      getAlertList();
    }
  }, [searchField]);

  return (
    <S.Container>
      <S.SearchAreaContainer>
        <S.SearchArea>
          <MdSearch
            size={28}
            onClick={() => {
              getProblemListByPrague();
              getAlertList();
            }}
          />
          <S.SearchInput
            type="text"
            placeholder="Pesquisa por pragas, talhões, etc..."
            value={searchField}
            onChange={(e) => {
              setSearchField(e.target.value);
            }}
          />
        </S.SearchArea>
        {/* <S.FilterWrapper>
          <MdFilterList />
          <span>Filtrar Por...</span>
        </S.FilterWrapper> */}
      </S.SearchAreaContainer>

      <S.AddBtnContainer>
        <S.SubTitle>Reportes de Pragas</S.SubTitle>
      </S.AddBtnContainer>
      <TableContainer
        style={{
          borderRadius: '8px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <>
            <PuffLoader
              color="#79ac34"
              loading={loading}
              size={30}
            />
          </>
        ) : (
          <Table size="small" cellspacing="0">
            <TableHead>
              <TableRow style={{ backgroundColor: '#EFEFEF', fontWeight: 'bold' }}>
                <TableCell align="center" style={{ fontWeight: 'bold', borderLeft: 'none' }}>Tipo</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Espécie</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Unidade de Medida</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Cultura</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Cultivar Resistente</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pragueList?.map((row) => (
                <TableRow>
                  <TableCell align="center" style={{ borderLeft: 'none' }}>{row.problem_name}</TableCell>
                  <TableCell align="center">
                    {row.kind}
                  </TableCell>
                  <TableCell align="center">
                    {row.measurement_unit}
                  </TableCell>
                  <TableCell align="center">
                    {row.crop_type_id_name}
                  </TableCell>
                  <TableCell align="center">
                    {row.plant}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Stack spacing={1}>
        <Pagination
          count={totalPagesPrague}
          variant="outlined"
          shape="rounded"
          size="small"
          onChange={handleChangePagePrague}
        />
      </Stack>
      <S.AddBtnContainer>
        <S.SubTitle>Alertas</S.SubTitle>
        <Button
          type="button"
          text="Criar Alerta"
          className="addBtn"
          onClick={() => {
            setOpendedModal('modalNewAlert');
            openModal();
          }}
        />
      </S.AddBtnContainer>
      <TableContainer
        style={{
          borderRadius: '8px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <>
            <PuffLoader
              color="#79ac34"
              loading={loading}
              size={30}
            />
          </>
        ) : (
          <Table size="small" cellspacing="0">
            <TableHead>
              <TableRow style={{ backgroundColor: '#EFEFEF', fontWeight: 'bold' }}>
                <TableCell align="center" style={{ fontWeight: 'bold', borderLeft: 'none' }}>Tipo</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Horário</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Região</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Problema</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alertList.map((row) => (
                <TableRow>
                  <TableCell align="center" style={{ borderLeft: 'none' }}>{row.alert_type_name}</TableCell>
                  <TableCell align="center">
                    {row.period_name}
                  </TableCell>
                  <TableCell align="center">
                    {row.region}
                  </TableCell>
                  <TableCell align="center">
                    {row.problem_name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Stack spacing={1}>
        <Pagination
          count={totalPagesAlert}
          variant="outlined"
          shape="rounded"
          size="small"
          onChange={handleChangePageAlerts}
        />
      </Stack>
    </S.Container>

  );
};

export default Alert;
