import { useCallback } from 'react';
import { appConfig } from '../config';
import api from './api';

const sendMessage = async (chat_id, userOrigin_id, text, token) => {
  const params = {
    chat_id,
    userOrigin_id,
    text,
    created_at: new Date().toISOString()
  };
  try {
    const response = await api.post(
      `${appConfig.apiHosts.chat}/Message/Create`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
};

const markAsReadMessage = async (message_id, token) => {
  try {
    await api.put(
      `${appConfig.apiHosts.chat}/Message/MarkAsRead`,
      { message_id },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  } catch (error) {
    console.error(error);
  }
};

const createChat = async (userOrigin_id, userDestiny_id, token) => {
  const params = {
    userOrigin_id,
    userDestiny_id
  };
  try {
    const response = await api.post(
      `${appConfig.apiHosts.chat}/Chat/Create`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteChat = async (loggedUser_id, chat_id, token) => {
  const params = { loggedUser_id, chat_id, status: false };
  try {
    const response = await api.delete(
      `${appConfig.apiHosts.chat}/Chat/Delete`,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteMessage = async (message_id, chat_id, token) => {
  const params = {
    message_id,
    chat_id,
    status: false
  };
  try {
    const response = await api.delete(
      `${appConfig.apiHosts.chat}/Message/Delete`,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
};

const getContacts = async (user_id, token) => {
  try {
    const params = new URLSearchParams([['loggedUser_id', user_id]]);
    const response = await api.get(
      `${appConfig.apiHosts.chat}/Chat/Contacts/List`,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data.data.contacts;
  } catch (error) {
    console.error(error);
  }
};

const getChatMessages = async (chat_id, user_id, token) => {
  try {
    if (!chat_id) return;
    const params = new URLSearchParams([
      ['loggedUser_id', user_id],
      ['chat_id', chat_id]
    ]);
    const response = await api.get(`${appConfig.apiHosts.chat}/Message/List`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data.data.messages;
  } catch (error) {
    console.error(error);
  }
};

const getChats = async (user_id, token) => {
  try {
    const params = new URLSearchParams([['loggedUser_id', user_id]]);
    const response = await api.get(`${appConfig.apiHosts.chat}/Chat/List`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data.data.chats;
  } catch (error) {
    console.error(error);
  }
};

export default {
  createChat,
  deleteChat,
  markAsReadMessage,
  deleteMessage,
  sendMessage,
  getContacts,
  getChatMessages,
  getChats
};
