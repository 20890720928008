/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import React, { useContext, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Modal from 'react-modal';
import PuffLoader from 'react-spinners/PuffLoader';
import { useModal } from '../../../../../../../../../hooks/Modal';
import Button from '../../../../../../../../../components/Button';
import * as S from './styled';
import { DashboardContext } from '../../../../../../..';
import api from '../../../../../../../../../services/api';
import { appConfig } from '../../../../../../../../../config';
import { useAuth } from '../../../../../../../../../hooks/Auth';

const ModalDeleteRoutine = () => {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token } = useAuth();

  const { selectedRoutine, setUpdateScreen } = useContext(DashboardContext);

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('current');

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await api.delete(
        `${appConfig.apiHosts.prague}/Routine/DeleteRoutineWithLote?routine_id=${selectedRoutine.routine_id}&action=${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      closeModal();
      setLoading(false);
      setUpdateScreen(true);
    }
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Excluir rotina recorrente</S.Title>
        <form onSubmit={onSubmit}>
          <S.FormContainer>
            <S.InputWrapper>
              <S.InputContainer>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="current"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    onChange={handleRadioChange}
                    value="current"
                    control={<Radio />}
                    label="Esta rotina"
                  />
                  <FormControlLabel
                    onChange={handleRadioChange}
                    value="next"
                    control={<Radio />}
                    label="Esta e as rotinas seguintes"
                  />
                  <FormControlLabel
                    onChange={handleRadioChange}
                    value="all"
                    control={<Radio />}
                    label="Todas as rotinas"
                  />
                </RadioGroup>
              </S.InputContainer>
            </S.InputWrapper>
            <S.ButtonWrapper>
              <Button
                text="Cancelar"
                className="cancelBtn"
                onClick={() => {
                  closeModal();
                }}
              />
              <Button
                text={loading ? (
                  <>
                    <PuffLoader
                      color="#79ac34"
                      loading={loading}
                      size={30}
                    />
                  </>
                ) : 'Ok'}
                disabled={loading}
                className="saveBtn"
                type="submit"
              />
            </S.ButtonWrapper>
          </S.FormContainer>
        </form>
      </S.Container>
    </Modal>
  );
};

export default ModalDeleteRoutine;
