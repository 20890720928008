/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { OrganizacaoProfile } from '../../utils/moduleProfile.types';
import OrganizacaoApi from '../../../../../../../../APi/FarmApi/OrganizacaoApi';

export const useOrganizacaoProfileListRequest = () => {
  const { token, user } = useAuth();
  const organizacaoApi = useMemo(() => new OrganizacaoApi(token), []);
  const [filter, setFilter] = useState<string>('');

  function profileModuleMap(moduleProfile: any, index): OrganizacaoProfile {
    return {
      idEndity: moduleProfile.id,
      id: index + 1,
      name: moduleProfile.name,
      perfil: moduleProfile.nameaggregate
    };
  }

  const moduleProfileQuery = useQuery({
    queryKey: [organizacaoApi.retomarListaOrganizacaoAtrelamento, { filter }],
    queryFn: async () =>
      organizacaoApi.ListarAtrelarPerfilOrganizacao(
        filter,
        '',
        user.organizacao_id
      )
  });

  return {
    request: {
      items: moduleProfileQuery.data?.data.data.map(profileModuleMap) ?? []
    },
    setFilter
  };
};
