/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useRef, useCallback } from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
} from '@mui/material';
import { MdOutlineCloudUpload, MdCheck, MdClose, MdError, MdLink, MdMoodBad } from 'react-icons/md';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import Button from '../../../../../../../../components/Button';
import * as S from './styled';

const ViewParamRecommendation = () => {
  const { openModal } = useModal();
  const {
    setItemProblemVisible,
    setOpendedModal,
    itemSelectedVisible,
    setItemSelectedVisible,
    selectedRecommendation,
    setCurrentModal
  } = useContext(DashboardContext);

  return (
    <S.Container>
      <S.CloseButton onClick={() => setItemSelectedVisible('')}>
        <MdClose />
      </S.CloseButton>
      <S.Title>Recomendação</S.Title>
      <S.Subtitle>Tipo de Produto</S.Subtitle>
      <S.ContentRow>{selectedRecommendation?.type_name}</S.ContentRow>
      <S.Subtitle>Dose</S.Subtitle>
      <S.ContentRow>{selectedRecommendation?.dose}</S.ContentRow>
      <S.Subtitle>Volume em Calda</S.Subtitle>
      <S.ContentRow>{selectedRecommendation?.syrup_volume}</S.ContentRow>
      <S.Subtitle>Principio Ativo</S.Subtitle>
      <S.ContentRow>{selectedRecommendation?.active_principle}</S.ContentRow>
      <S.Subtitle>Modo de Aplicação</S.Subtitle>
      <S.ContentRow>{selectedRecommendation?.application_mode_name}</S.ContentRow>
      <S.ButtonContainer>
        <Button
          type="button"
          text="Editar Recomendação"
          className="btnStyle"
          onClick={() => {
            setCurrentModal('modalParametersEditRecommendation');
            setItemSelectedVisible('');
            openModal();
          }}
        />
      </S.ButtonContainer>
    </S.Container>
  );
};

export default ViewParamRecommendation;
