/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { MdAddCircle, MdModeEdit, MdDelete, MdCheck } from 'react-icons/md';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from '@mui/material';
import { toast } from 'react-toastify';
import Checkbox from 'react-custom-checkbox';
import PuffLoader from 'react-spinners/PuffLoader';
import { DashboardContext } from '../../../../index';
import * as S from './styled';
import ModalAddNewDisease from './components/ModalAddNewDisease';
import ModalEditDisease from './components/ModalEditDisease';
import ModalViewDisease from './components/ModalViewDisease';
import ModalDeleteConfirm from './components/ModalDeleteConfirm';
import { useModal } from '../../../../../../hooks/Modal';
import { appConfig } from '../../../../../../config';
import api from '../../../../../../services/api';
import Button from '../../../../../../components/Button';
import { useAuth } from '../../../../../../hooks/Auth';
import Toast from '../../../../../../components/Toast';

const Disease = () => {
  const {
    uploadedFiles: files,
    setUploadedFiles,
    setSelectedManagement,
    setItemTypeSelected,
    updateScreen,
    setUpdateScreen,
    currentModal,
    setCurrentModal,
    prague_cropName,
    setPrague_CropName,
    prague_plantName,
    setPrague_PlantName,
    clearFiles,
    selectedDisease,
    setSelectedDisease,
    imageDiagrammaticScale,
    disease_cropName,
    setDisease_cropName,
    disease_plantName,
    setDisease_plantName,
    setImageDiagrammaticScale,
    setFilePhotoScale
  } = useContext(DashboardContext);
  const { closeModal, openedModal, openModal } = useModal();
  const { token, user, getNotifications } = useAuth();

  const [diseaseId, setDiseaseId] = useState('');
  const [listType, setListType] = useState([]);
  const [listDiseases, setListDiseases] = useState([]);
  const [pragueUpdate, setPragueUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const getDiseaseList = async () => {
    setLoading(true);
    setListDiseases([]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Disease/List`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // const sortResponse = response.data.data.pragues.sort((x, y) => {
      //   return x.status - y.status;
      // });

      const filterActive = response.data.data.diseases.filter((v) => v.active === true);

      setListDiseases(filterActive);
      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleChangeItemSelected = async (event) => {
    setItemTypeSelected(event.target.value);
    const filterNameSelected = listType.filter((item) => item.type_Id === event.target.value);
    setSelectedManagement(filterNameSelected[0].name);
  };

  const handleDeleteDisease = async (disease_id) => {
    setDiseaseId(disease_id);
    setCurrentModal('modalDeleteConfirm');
    openModal();
  };

  const getDiseaseId = async (disease_id) => {
    try {
      const params = new URLSearchParams([['disease_id', disease_id]]);
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Disease/Id`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedDisease(response.data.data.disease);
      return response.data.data.disease;
    } catch (error) {
      console.error(error);
    }
  };

  const openViewDisease = async (id) => {
    const getDiseaseById = await getDiseaseId(id);

    if (getDiseaseById) {
      setCurrentModal('modalViewDisease');
      openModal();
    }
  };

  const openEditDisease = async (id) => {
    const cropName = [];
    const plantName = [];
    const fileImage = [];
    setUploadedFiles([]);
    setCurrentModal('');

    const responseGetDiseaseById = await getDiseaseId(id);
    if (responseGetDiseaseById) {
      setImageDiagrammaticScale(responseGetDiseaseById?.diagrammatic_Scale);
      setFilePhotoScale(responseGetDiseaseById?.diagrammatic_Scale);

      responseGetDiseaseById.crop_type.forEach((crop) => {
        cropName.push(crop?.name);
      });
      responseGetDiseaseById.plant.forEach((plant) => {
        plantName.push(plant?.name);
      });

      setDisease_cropName(cropName);
      setDisease_plantName(plantName);

      await responseGetDiseaseById?.disease_images.forEach((img) => {
        fileImage.push({
          error: false,
          file: { path: '', lastModified: '', lastModifiedDate: '', name: img?.disease_id, size: '', type: '', webkitRelativePath: '' },
          id: img?.disease_image_id,
          name: img?.disease_id,
          preview: img?.url,
          progress: 0,
          readableSize: '',
          uploaded: true,
          url: img?.url,
          legend: img?.legend
        });
      });

      setUploadedFiles(fileImage);

      setCurrentModal('modalEditDisease');
      openModal();
    }
    // value?.diagrammatic_Scale.forEach((img) => {
    //   imageDiagrammaticScale.push({
    //     error: false,
    //     file: { path: '', lastModified: '', lastModifiedDate: '', name: img?.diagrammatic_scale_id, size: '', type: '', webkitRelativePath: '' },
    //     id: img?.diagrammatic_scale_id,
    //     name: img?.diagrammatic_scale_id,
    //     preview: img?.image,
    //     progress: 0,
    //     readableSize: '',
    //     uploaded: true,
    //     url: img?.image,
    //   });
    // });
  };

  useEffect(() => {
    getDiseaseList();
  }, []);

  useEffect(() => {
    if (updateScreen) {
      getDiseaseList();
    }
  }, [updateScreen]);

  return (
    <S.Container>
      <S.Content>
        <S.AddBtnContainer>
          <Button
            text="Adicionar Nova Doença"
            style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center'
            }}
            className="content__addButton"
            onClick={() => {
              setCurrentModal('modalAddNewDisease');
              openModal();
            }}
          />
        </S.AddBtnContainer>
        <S.CardContainer>
          {loading ? (
            <>
              <PuffLoader color="#79ac34" loading={loading} size={30} />
            </>
          ) : (
            listDiseases?.map((row) => (
              <S.Card>
                <S.CardImage onClick={() => openViewDisease(row.disease_id)}>
                  {row?.main_photo && <img alt="" src={row.main_photo} />}
                </S.CardImage>
                <S.CardTextContainer onClick={() => openViewDisease(row.disease_id)}>
                  <S.CardTitle>{row?.name}</S.CardTitle>
                  <S.CardDescription>{row?.scientific_name}</S.CardDescription>
                </S.CardTextContainer>
                <S.CardActionContainer>
                  <S.CardAction>
                    <button type="button" onClick={() => openEditDisease(row.disease_id)}>
                      <MdModeEdit />
                      Editar
                    </button>
                  </S.CardAction>
                  <S.CardAction>
                    <button
                      type="button"
                      onClick={() => handleDeleteDisease(row.disease_id)}
                    >
                      <MdDelete />
                      Excluir
                    </button>
                  </S.CardAction>
                </S.CardActionContainer>
              </S.Card>
            ))
          )}
        </S.CardContainer>

      </S.Content>
      {currentModal === 'modalAddNewDisease' && <ModalAddNewDisease />}
      {currentModal === 'modalEditDisease' && <ModalEditDisease />}
      {currentModal === 'modalViewDisease' && <ModalViewDisease />}
      {currentModal === 'modalDeleteConfirm' && (
        <ModalDeleteConfirm
          messageDialog="Deseja realmente excluir esta doença?"
          disease_id={diseaseId}
        />
      )}
    </S.Container>
  );
};

export default Disease;
