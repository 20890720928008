import styled from 'styled-components';

export const Container = styled.div``;
export const Title = styled.span`
  font-size: 0.9em;
  font-weight: bold;
`;
export const Content = styled.p`
  font-size: 0.7em;
`;
