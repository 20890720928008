/* eslint-disable array-callback-return */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect
} from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  OutlinedInput,
  Selected,
  TextareaAutosize
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { AddCircle } from '@mui/icons-material';
import { CompactPicker } from 'react-color';
import Checkbox from 'react-custom-checkbox';
import { useDropzone } from 'react-dropzone';
// import { useFiles } from "../../context/files";
import PuffLoader from 'react-spinners/PuffLoader';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  FaChevronLeft,
  FaChevronRight,
  FaUpload,
  FaTrash,
  FaCheckCircle
} from 'react-icons/fa';
import {
  MdOutlineCloudUpload,
  MdCheck,
  MdClose,
  MdError,
  MdLink,
  MdMoodBad
} from 'react-icons/md';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
import filesize from 'filesize';
import { toast } from 'react-toastify';
import { Carousel } from 'react-responsive-carousel';
import { forEach } from 'jszip';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import storage from '../../../../../../../../services/firebase';
import Button from '../../../../../../../../components/Button';
import { useMap } from '../../../../../../../../hooks/Map';
import { appConfig } from '../../../../../../../../config';
import { useAuth } from '../../../../../../../../hooks/Auth';
import api from '../../../../../../../../services/api';
import Toast from '../../../../../../../../components/Toast';
import * as S from './styled';

const ModalEditPrague = () => {
  const inputFileRef = useRef(null);
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const {
    selectedManagement,
    uploadedFiles: files,
    deleteFile,
    handleUpload,
    setUploadedFiles,
    setUpdateScreen,
    itemTypeSelected,
    selectTypeCultureList,
    setSelectTypeCultureList,
    selectedPrague,
    setSelectedPrague,
    prague_cropName,
    setPrague_CropName,
    prague_plantName,
    setPrague_PlantName,
    setCurrentModal
  } = useContext(DashboardContext);

  const { selectedFarmId } = useMap();
  const theme = useTheme();
  const uploadFile = useRef(null);

  const [loading, setLoading] = useState(false);
  const [pragueName, setPragueName] = useState('');
  const [scientificName, setScientificName] = useState('');
  const [selectedCulture, setSelectedCulture] = useState('');
  const [selectedValue, setSelectedValue] = useState(selectedPrague?.incidence_status);
  const [checked, setChecked] = useState(true);
  const [size, setSize] = useState([]);
  const [controlPrague, setControlPrague] = useState([]);
  const [instar, setInstar] = useState([]);
  const [lifeCycle, setLifeCycle] = useState('');
  const [sizeCycle, setSizeCycle] = useState('');
  const [titleSize, setTitleSize] = useState('');
  const [colorControl, setColorControl] = useState('');
  const [levelControl, setLevelControl] = useState('');
  const [valueControl, setValueControl] = useState('');
  const [titleControl, setTitleControl] = useState('');
  const [cycleInstar, setCycleInstar] = useState('');
  const [sizeInstar, setSizeInstar] = useState('');
  const [titleInstar, setTitleInstar] = useState('');
  const [colorPickerSizeVisible, setColorPickerSizeVisible] = useState(false);
  const [colorPickerControlVisible, setColorPickerControlVisible] =
    useState(false);
  const [colorPickerInstarVisible, setColorPickerInstarVisible] =
    useState(false);
  const [colorSize, setColorSize] = useState('');
  const [colorInstar, setColorInstar] = useState('');
  const [selectedPhoto, setSelectedPhoto] = useState(0);
  const [mainPhoto, setMainPhoto] = useState('');
  const [mainPhotoUrl, setMainPhotoUrl] = useState('');

  const [cropList, setCropList] = useState([]);
  const [plantList, setPlantList] = useState([]);
  const [pestClassificationList, setPestClassificationList] = useState([]);

  const [cropName, setCropName] = useState([]);
  const [plantName, setPlantName] = useState([]);
  const [photoList, setPhotoList] = useState([]);

  const ModalAddNewPragueSchema = Yup.object().shape({
    pestClassification: Yup.string().nullable(),
    name: Yup.string().required('Nome é obrigatório'),
    scientific_name: Yup.string().nullable(),
    description: Yup.string().nullable(),
    symptoms: Yup.string().nullable(),
    damage: Yup.string().nullable(),
    unit_measurement: Yup.string().nullable()
  });

  const { handleSubmit, register, errors, control, reset } = useForm({
    resolver: yupResolver(ModalAddNewPragueSchema)
  });

  const getData = () => {
    setSize(selectedPrague.pragues_size);
    setInstar(selectedPrague.pragues_instar);
    setControlPrague(selectedPrague.pragues_control_level);
    setMainPhoto(selectedPrague.main_photo);
    setTitleSize(selectedPrague.prague_size_title);
    setTitleControl(selectedPrague.prague_control_level_title);
    setTitleInstar(selectedPrague.prague_instar_title);
  };

  const handleUploadFile = async (e) => {
    try {
      const fileData = await storage.ref(`/images/${e.name}`).put(e.file);
      const imageSrc = { id: e.id, url: await fileData.ref.getDownloadURL() };

      files.forEach((file) => URL.revokeObjectURL(file.preview));

      return imageSrc;
    } catch (error) {
      console.error(error);
    }
  };

  const FileList = () => {
    if (!files.length) {
      return (
        <span>
          <MdMoodBad
            style={{ marginLeft: '45%', marginTop: 10 }}
            size={24}
            color="#d5d2d2"
          />
        </span>
      );
    }
  };

  const onDrop = useCallback(
    (file) => {
      handleUpload(file);
    },
    [handleUpload]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: ['image/jpeg', 'image/pjpeg', 'image/png', 'image/gif'],
      // maxFiles: 1,
      multiple: true,
      onDrop
    });

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return (
        <S.UploadMessage>Clique ou arraste uma imagem aqui</S.UploadMessage>
      );
    }

    if (isDragReject) {
      return (
        <S.UploadMessage type="error">
          Tipo de arquivo não suportado
        </S.UploadMessage>
      );
    }

    return (
      <S.UploadMessage type="success">Solte as imagens aqui</S.UploadMessage>
    );
  }, [isDragActive, isDragReject]);

  const addCycleInTable = () => {
    if (lifeCycle !== '' && sizeCycle !== '') {
      size.push({
        color: colorSize,
        description: lifeCycle,
        size: sizeCycle
      });

      setLifeCycle('');
      setSizeCycle('');
      setColorSize('');
    } else {
      return toast(
        <Toast title="Preencha os campos para serem adicionados na tabela" />,
        {
          type: 'error'
        }
      );
    }
  };

  const addControlInTable = () => {
    if (colorControl !== '' && levelControl !== '' && valueControl !== '') {
      controlPrague.push({
        color: colorControl,
        level: levelControl,
        value: valueControl
      });

      setColorControl('');
      setLevelControl('');
      setValueControl('');
    } else {
      return toast(
        <Toast title="Preencha os campos para serem adicionados na tabela" />,
        {
          type: 'error'
        }
      );
    }
  };

  const addInstarInTable = () => {
    if (cycleInstar !== '' && sizeInstar !== '') {
      instar.push({
        color: colorInstar,
        description: cycleInstar,
        size: sizeInstar
      });

      setCycleInstar('');
      setSizeInstar('');
      setColorInstar('');
    } else {
      return toast(
        <Toast title="Preencha os campos para serem adicionados na tabela" />,
        {
          type: 'error'
        }
      );
    }
  };

  const getCropType = async () => {
    const params = new URLSearchParams([
      ['type_id', 'c826052d-3a59-4398-aec1-81193ccd2e42']
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterCropByActive = response.data.data.values.filter(
        (v) => v.status === true
      );
      setCropList(filterCropByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getPlant = async () => {
    const params = new URLSearchParams([
      ['type_id', '4f7bca40-7ffd-4485-9627-e5d2ba90776c']
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterPlantByActive = response.data.data.values.filter(
        (v) => v.status === true
      );

      setPlantList(filterPlantByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getPestClassification = async () => {
    const params = new URLSearchParams([
      ['type_id', '921a83a7-132a-494f-9dac-a8071eb327e6']
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterClassByActive = response.data.data.values.filter(
        (v) => v.status === true
      );
      setPestClassificationList(filterClassByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeBackgroundControl = (color) => {
    setColorControl(color.hex);
    setColorPickerControlVisible(false);
  };

  const handleChangeBackgroundSize = (color) => {
    setColorSize(color.hex);
    setColorPickerSizeVisible(false);
  };

  const handleChangeBackgroundInstar = (color) => {
    setColorInstar(color.hex);
    setColorPickerInstarVisible(false);
  };

  const updatePrague = async (data) => {
    const params = new URLSearchParams([
      ['prague_id', selectedPrague.prague_id]
    ]);
    try {
      const response = await api.put(
        `${appConfig.apiHosts.manager}/Prague/Update`,
        data,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast(<Toast title="Praga editada com sucesso" />, {
        type: 'success'
      });

      setLoading(false);
      return true;
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast(<Toast title="Não foi possível editar praga" />, {
        type: 'error'
      });
      return false;
    }
  };

  const createPragueImages = async (data) => {
    try {
      await api.post(
        `${appConfig.apiHosts.manager}/PragueImage/Create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const clearFields = () => {
    setUploadedFiles([]);
    setSize([]);
    setControlPrague([]);
    setInstar([]);
    setTitleSize('');
    setTitleControl('');
    setTitleInstar('');
    setCropName([]);
    setPlantName([]);
    setPrague_CropName([]);
    setPrague_PlantName([]);
    setCycleInstar('');
    setSizeInstar('');
    setSizeCycle('');
    setLifeCycle('');
    setValueControl('');
    setLevelControl('');
    setColorControl('');
    setColorInstar('');
    setColorSize('');
    setSelectedPrague([]);
  };

  const deletePragueImage = async (id) => {
    const params = new URLSearchParams([
      ['pragueImageId', id],
      ['status', false]
    ]);
    try {
      const response = await api.delete(
        `${appConfig.apiHosts.manager}/PragueImage/Delete`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const imageUrl = [];
    const selectedCropsId = [];
    const selectedPlantsId = [];
    const photoUrl = [];
    const oldPhoto = [];
    const newPhoto = [];

    files.forEach((image) => {
      if (image.url === '') {
        newPhoto.push(image);
      } else {
        oldPhoto.push({ id: image.id, url: image.url });
      }
    });

    cropList.forEach((value) => {
      prague_cropName.forEach((v) => {
        if (value.name === v) {
          selectedCropsId.push({ prague_crop_type_id: null, crop_type_id: value.value_Id, name: null });
        }
      });
    });

    plantList.forEach((value) => {
      prague_plantName.forEach((v) => {
        if (value.name === v) {
          selectedPlantsId.push({ prague_plant_id: null, plant_id: value.value_Id, name: null });
        }
      });
    });

   if (files.length > 0 && mainPhoto == '' || oldPhoto.length > 0 && mainPhoto == '' || mainPhoto == '' || mainPhoto === null) {
     setLoading(false);
     return toast(
       <Toast
         title="Não foi possível editar praga"
         content="Torne uma foto como principal"
       />,
       { type: 'warning' }
     );
   }

   if (files.length > 0 && mainPhoto == null) {
    setLoading(false);
    return toast(
      <Toast
        title="Não foi possível editar praga"
        content="Torne uma foto como principal"
      />,
      { type: 'warning' }
    );
  }
    try {
      let editPrague = {
        prague_id: selectedPrague.prague_id,
        name: data.name,
        description: data.description || null,
        damage: data.damage || null,
        pragues_instar: instar || selectedPrague.pragues_instar,
        pragues_size: size || selectedPrague.pragues_size,
        crop_type: selectedCropsId || null,
        plant: selectedPlantsId || null,
        prague_images: [],
        pragues_control_level: controlPrague || selectedPrague.pragues_control_level,
        pest_classification: data.pestClassification || null,
        pest_classification_name: null,
        prague_control_Level_title: titleControl || null,
        prague_size_title: titleSize || null,
        prague_instar_title: titleInstar || null,
        scientific_name: data.scientific_name || null,
        symptoms: data.symptoms || null,
        measurement_unit: data.unit_measurement || null,
        incidence: true,
        incidence_status: selectedValue,
        created_by: user.user_id,
        main_photo: ''
      };

      if (oldPhoto.length !== selectedPrague.prague_images.length) {
        const deleteImagesId = [];
          for (let i = 0; i < selectedPrague.prague_images.length; i++) {
            if (oldPhoto.filter((v) => v.id === selectedPrague.prague_images[i].prague_image_id).length <= 0) {
              deleteImagesId.push(selectedPrague.prague_images[i]);
            }
          }
        deleteImagesId.forEach((id) => {
         deletePragueImage(id.prague_image_id);
        });
      }

      if (newPhoto.length > 0) {
        await newPhoto.forEach(async (file) => {
          const url = handleUploadFile(file);
          url
            .then((value) => {
              imageUrl.push(value.url);
              if (oldPhoto.filter((v) => v.url === mainPhoto).length > 0) {
                const main_photo = oldPhoto.filter(
                  (v) => v.url === mainPhoto
                )[0];
                editPrague = {
                  ...editPrague,
                  main_photo: main_photo?.url
                };
              } else if (value.id === mainPhoto && editPrague.main_photo === '') {
                editPrague = {
                  ...editPrague,
                  main_photo: value?.url
                };
              }
            })

            .finally(async () => {
              if (imageUrl.length === newPhoto.length) {
                const responseUpdatePrague = await updatePrague(editPrague);

                if (responseUpdatePrague) {
                  const pragueImage = {
                    url: imageUrl,
                    prague_id: selectedPrague.prague_id,
                    created_by: user.user_id
                  };

                  await createPragueImages(pragueImage);

                  clearFields();
                  reset();
                  setUpdateScreen(true);
                  setPrague_PlantName([]);
                  setPrague_CropName([]);
                  setCurrentModal('');
                  closeModal();
                }
              }
            });
        });
      } else {
        const main_photo = oldPhoto.filter((v) => v.url === mainPhoto)[0];
        await updatePrague(editPrague = {
          ...editPrague,
          main_photo: main_photo?.url
        });

        clearFields();
        reset();
        setUpdateScreen(true);
        setPrague_PlantName([]);
        setPrague_CropName([]);
        setCurrentModal('');
        closeModal();
      }
    } catch (error) {
      console.error(error);
      toast(<Toast title="Não foi possível editar a praga" />, {
        type: 'error'
      });
    }
  };

  const handleCropChange = (event) => {
    setPrague_CropName([]);
    const {
      target: { value }
    } = event;
    setPrague_CropName(
      value
    );
    // On autofill we get a stringified value.
    // typeof value === 'string' ? value.split(',') : value
  };

  const handlePlantChange = (event) => {
    const {
      target: { value }
    } = event;
    setPrague_PlantName(
     value
    );
    // On autofill we get a stringified value.
    // typeof value === 'string' ? value.split(',') : value
  };

  function getStyles(name, pName, pTheme) {
    return {
      fontWeight:
        pName.indexOf(name) === -1
          ? pTheme.typography.fontWeightRegular
          : pTheme.typography.fontWeightMedium
    };
  }

  const removeSizeRowByIndex = (index) => {
    setSize(size.filter((v, i) => i !== index));
  };

  const removeInstarRowByIndex = (index) => {
    setInstar(instar.filter((v, i) => i !== index));
  };

  const removeControlPragueRowByIndex = (index) => {
    setControlPrague(controlPrague.filter((v, i) => i !== index));
  };

  useEffect(() => {
    getCropType();
    getPlant();
    getPestClassification();
    getData();
  }, []);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      shouldCloseOnOverlayClick={false}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Editar Praga</S.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <S.InputWrapper style={{ margin: 0 }}>
              <S.InputContainer>
                <Controller
                  name="pestClassification"
                  control={control}
                  defaultValue={selectedPrague?.pest_classification}
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span style={{ marginBottom: '8px' }}>
                        Classificação de Praga
                      </span>
                      <Select
                        placeholder="Selecione..."
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {pestClassificationList.map((pest) => (
                          <MenuItem value={pest.value_Id} key={pest.value_Id}>
                            {pest.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </S.InputContainer>
              <S.InputContainer>
                <span>Nome Comum</span>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={selectedPrague?.name}
                  render={({ onChange, value }) => (
                    <TextField
                      inputProps={{ maxlength: 42 }}
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.name?.message && (
                  <S.InputError>{errors?.name?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer>
                <span>Nome Científico</span>
                <Controller
                  name="scientific_name"
                  control={control}
                  defaultValue={selectedPrague.scientific_name}
                  render={({ onChange, value }) => (
                    <TextField
                      inputProps={{ maxlength: 70 }}
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </S.InputContainer>
              <S.InputContainer>
                <span>Unidade de Medida</span>
                <Controller
                  name="unit_measurement"
                  control={control}
                  defaultValue={selectedPrague.measurement_unit}
                  render={({ onChange, value }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper style={{ margin: 0 }}>
              <S.InputContainer>
                <Controller
                  name="crop_type"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Cultura</span>
                      <Select
                        style={{ width: '351px' }}
                        multiple
                        displayEmpty
                        value={prague_cropName}
                        onChange={handleCropChange}
                        size="small"
                        // input={<OutlinedInput />}
                        renderValue={(selected) => {
                          return selected.join(', ');
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        {cropList.map((v) => (
                          <MenuItem
                            value={v.name}
                            key={v.value_Id}
                            style={getStyles(name, cropName, theme)}
                          >
                            {v.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="plant"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Cultivar Resistente</span>
                      <Select
                        style={{ width: '351px' }}
                        multiple
                        displayEmpty
                        value={prague_plantName}
                        onChange={handlePlantChange}
                        size="small"
                        // input={<OutlinedInput />}
                        renderValue={(selected) => {
                          return selected.join(', ');
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        {plantList.map((v) => (
                          <MenuItem
                            value={v.name}
                            key={v.value_Id}
                            style={getStyles(name, plantName, theme)}
                          >
                            {v.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.TextAreaContainer>
                <span className="inputContainer__title">Descrição</span>
                <Controller
                  name="description"
                  control={control}
                  defaultValue={selectedPrague.description}
                  render={({ onChange, value }) => (
                    <TextareaAutosize
                      value={value}
                      onChange={onChange}
                      className="textArea"
                    />
                  )}
                />
              </S.TextAreaContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.TextAreaContainer>
                <span className="inputContainer__title">Sintomas</span>
                <Controller
                  name="symptoms"
                  control={control}
                  defaultValue={selectedPrague.symptoms}
                  render={({ onChange, value }) => (
                    <TextareaAutosize
                      value={value}
                      onChange={onChange}
                      className="textArea"
                    />
                  )}
                />
                {errors?.symptoms?.message && (
                  <S.InputError>{errors?.symptoms?.message}</S.InputError>
                )}
              </S.TextAreaContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.TextAreaContainer>
                <span className="inputContainer__title">Dano Causado</span>
                <Controller
                  name="damage"
                  control={control}
                  defaultValue={selectedPrague.damage}
                  render={({ onChange, value }) => (
                    <TextareaAutosize
                      value={value}
                      onChange={onChange}
                      className="textArea"
                    />
                  )}
                />
                {errors?.damage?.message && (
                  <S.InputError>{errors?.damage?.message}</S.InputError>
                )}
              </S.TextAreaContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.InputContainer style={{ height: 'auto' }}>
                <span style={{ paddingBottom: '10px' }}>Foto</span>
                <S.PhotoContainer>
                  {files.length <= 0 ? (
                    <S.DropContainer {...getRootProps()}>
                      <input accept="image/*" {...getInputProps()} />
                      <div className="inputMessage">
                        <MdOutlineCloudUpload />
                        {renderDragMessage()}
                      </div>
                    </S.DropContainer>
                  ) : (
                    <>
                      <Carousel
                        className="carouselContainer"
                        width={500}
                        // height={280}
                        // swipeScrollTolerance={5}
                        showThumbs={false}
                        selectedItem={selectedPhoto}
                        showStatus={false}
                        onChange={(e) => setSelectedPhoto(e)}
                      >
                        {files.map((uploadedFile) => (
                          <div className="viewPhoto">
                            <img
                              alt={uploadedFile.name}
                              src={uploadedFile.preview}
                              // height="280"
                              style={{ border: '2px solid #79ac34' }}
                            />
                          </div>
                        ))}
                      </Carousel>
                      <S.ThumbsContainer>
                        {files.map((uploadedFile, index) => {
                          return (
                            <S.ThumbItemContainer>
                              <S.ThumbItem>
                                <S.ThumbButtonImage
                                type="button"
                                className="thumbsContainer__button"
                                onClick={() => setSelectedPhoto(index)}
                                style={{
                                  border:
                                    selectedPhoto === index
                                      ? '5px solid #79ac34'
                                      : '2px solid #47aa12'
                                }}
                                >
                                  <img
                                  alt={uploadedFile.name}
                                  src={uploadedFile.preview}
                                  style={{
                                    border:
                                      selectedPhoto === index
                                        ? '2px solid #FFF'
                                        : 'none'
                                  }}
                                  />
                                </S.ThumbButtonImage>
                                <S.ThumbIconsContainer>
                                  <S.ThumbButtonIcon
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (uploadedFile.id === mainPhoto || uploadedFile.url === mainPhoto) {
                                      setMainPhoto('');
                                    }
                                    // deletePragueImage(uploadedFile.id);
                                    deleteFile(uploadedFile.id);
                                    setSelectedPhoto(0);
                                  }}
                                  >
                                    <FaTrash size={14} color="#AF0000" />
                                  </S.ThumbButtonIcon>
                                  {uploadedFile.id === mainPhoto && uploadedFile.preview !== mainPhoto &&
                                  <FaCheckCircle color="#79ac34" size={15} />}
                                  {uploadedFile.preview === mainPhoto && uploadedFile.id !== mainPhoto &&
                                  <FaCheckCircle color="#79ac34" size={15} />}
                                </S.ThumbIconsContainer>
                              </S.ThumbItem>
                              {selectedPhoto === index &&
                              uploadedFile.id !== mainPhoto && uploadedFile.preview !== mainPhoto && (
                                <Button
                                  text="Tornar Principal"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (uploadedFile.url !== '') {
                                      setMainPhoto(uploadedFile.url);
                                    } else {
                                      setMainPhoto(uploadedFile.id);
                                    }
                                  }}
                                  className="thumbsContainer__buttonMain"
                                />
                              )}

                            </S.ThumbItemContainer>
                        );
})}
                      </S.ThumbsContainer>
                    </>
                  )}
                </S.PhotoContainer>
                <S.UploadFileContainer>
                  {files.length > 0 && (
                    <S.AddFiles {...getRootProps()}>
                      <input accept="image/*" {...getInputProps()} />
                      <S.UploadFileText>
                        <FaUpload size={13} />
                        <span className="uploadfile__span">
                          Adicionar fotos
                        </span>
                      </S.UploadFileText>
                    </S.AddFiles>
                  )}
                </S.UploadFileContainer>
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper style={{ alignItems: 'start' }}>
              <S.InputContainer style={{ height: 'auto' }}>
                <span style={{ paddingBottom: '10px', marginTop: '10px' }}>
                  Tamanho
                </span>
                <TableContainer
                  style={{
                    width: '100%',
                  }}
                >
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: '#e4e4e4'
                          }}
                          colSpan={4}
                        >
                          <input
                            className="table__input--title"
                            type="text"
                            placeholder="Digite o título da tabela"
                            value={titleSize}
                            onChange={(e) => setTitleSize(e.target.value)}
                          // eslint-disable-next-line react/jsx-closing-bracket-location
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {size?.map((value, index) => (
                        <TableRow>
                          <TableCell align="center">
                            <div
                              style={{
                                height: '18px',
                                width: '18px',
                                background: value.color ? value.color : ''
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {value.description}
                          </TableCell>
                          <TableCell align="center">{value.size}</TableCell>
                          <TableCell align="center">
                            <S.DeleteRowButton type="button" onClick={() => removeSizeRowByIndex(index)}>
                              <FaTrash size={14} color="#AF0000" />
                            </S.DeleteRowButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <S.InputWrapper>
                  <S.ColorBtnContainer background={colorSize}>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setColorPickerSizeVisible(!colorPickerSizeVisible);
                      }}
                    >
                      Cor
                    </button>
                    {colorPickerSizeVisible && (
                      <S.ColorPicker>
                        <CompactPicker
                          color={colorSize}
                          onChange={(e) => handleChangeBackgroundSize(e)}
                        />
                      </S.ColorPicker>
                    )}
                  </S.ColorBtnContainer>
                  <TextField
                    label="Ciclo"
                    name="cycle"
                    id="cycle"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={lifeCycle}
                    onChange={(e) => setLifeCycle(e.target.value)}
                  />
                  <TextField
                    label="Tamanho"
                    name="size"
                    id="size"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={sizeCycle}
                    onChange={(e) => setSizeCycle(e.target.value)}
                  />
                  <button
                    type="button"
                    className="table__addButton"
                    onClick={(e) => {
                      e.preventDefault();
                      addCycleInTable();
                    }}
                  >
                    <AddCircle />
                  </button>
                </S.InputWrapper>
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.InputContainer
                style={{ justifyContent: 'flex-start', height: 'auto' }}
              >
                <span style={{ paddingBottom: '10px' }}>Instar</span>
                <TableContainer
                  style={{
                    width: '100%',
                  }}
                >
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: '#e4e4e4'
                          }}
                          colSpan={4}
                        >
                          <input
                            className="table__input--title"
                            type="text"
                            placeholder="Digite o título da tabela"
                            value={titleInstar}
                            onChange={(e) => setTitleInstar(e.target.value)}
                          // eslint-disable-next-line react/jsx-closing-bracket-location
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {instar?.map((value, index) => (
                        <TableRow>
                          <TableCell align="center">
                            <div
                              style={{
                                height: '18px',
                                width: '18px',
                                background: value.color ? value.color : ''
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {value.description}
                          </TableCell>
                          <TableCell align="center">{value.size}</TableCell>
                          <TableCell align="center">
                            <S.DeleteRowButton type="button" onClick={() => removeInstarRowByIndex(index)}>
                              <FaTrash size={14} color="#AF0000" />
                            </S.DeleteRowButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <S.InputWrapper>
                  <S.ColorBtnContainer background={colorInstar}>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setColorPickerInstarVisible(!colorPickerInstarVisible);
                      }}
                    >
                      Cor
                    </button>
                    {colorPickerInstarVisible && (
                      <S.ColorPicker>
                        <CompactPicker
                          color={colorInstar}
                          onChange={(e) => handleChangeBackgroundInstar(e)}
                        />
                      </S.ColorPicker>
                    )}
                  </S.ColorBtnContainer>
                  <TextField
                    label="Ciclo"
                    name="cycle"
                    id="cycle"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={cycleInstar}
                    onChange={(e) => setCycleInstar(e.target.value)}
                  />
                  <TextField
                    label="Tamanho"
                    name="size"
                    id="size"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={sizeInstar}
                    onChange={(e) => setSizeInstar(e.target.value)}
                  />
                  <button
                    className="table__addButton"
                    onClick={(e) => {
                      e.preventDefault();
                      addInstarInTable();
                    }}
                  >
                    <AddCircle />
                  </button>
                </S.InputWrapper>
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.InputContainer>
                <S.CheckboxContainer>
                  <span style={{ marginRight: '10px' }}>Incidência</span>
                </S.CheckboxContainer>
                {checked && (
                  <S.CheckboxContainer>
                    <label
                      className="radioContainer"
                      style={{
                        color: selectedValue === true ? '#79AC34' : ''
                      }}
                    >
                      Sim
                      <input
                        type="checkbox"
                        checked={selectedValue === true}
                        onClick={(e) => {
                          e.target.checked
                            ? setSelectedValue(true)
                            : setSelectedValue(false);
                        }}
                      />
                      <span className="checkmark" />
                    </label>

                    <label
                      className="radioContainer"
                      style={{
                        color: selectedValue === true ? '#79AC34' : ''
                      }}
                    >
                      Não
                      <input
                        type="checkbox"
                        checked={selectedValue === false}
                        onClick={(e) => {
                          e.target.checked
                            ? setSelectedValue(false)
                            : setSelectedValue(true);
                        }}
                      />
                      <span className="checkmark" />
                    </label>
                  </S.CheckboxContainer>
                )}
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.InputContainer
                style={{ justifyContent: 'flex-start', height: 'auto' }}
              >
                <span style={{ paddingBottom: '10px' }}>Nível de Controle</span>
                <TableContainer
                  style={{
                    width: '100%',
                  }}
                >
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: '#e4e4e4'
                          }}
                          colSpan={4}
                        >
                          {' '}
                          <input
                            className="table__input--title"
                            type="text"
                            placeholder="Digite o título da tabela"
                            value={titleControl}
                            onChange={(e) => setTitleControl(e.target.value)}
                          // eslint-disable-next-line react/jsx-closing-bracket-location
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {controlPrague?.map((value, index) => (
                        <TableRow>
                          <TableCell align="center">
                            <div
                              style={{
                                height: '18px',
                                width: '18px',
                                background: value.color ? value.color : ''
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">{value.level}</TableCell>
                          <TableCell align="center">{value.value}</TableCell>
                          <TableCell align="center">
                            <S.DeleteRowButton type="button" onClick={() => removeControlPragueRowByIndex(index)}>
                              <FaTrash size={14} color="#AF0000" />
                            </S.DeleteRowButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <S.InputWrapper>
                  <S.ColorBtnContainer background={colorControl}>
                    <button
                      type="button"
                      onClick={() => {
                        setColorPickerControlVisible(
                          !colorPickerControlVisible
                        );
                      }}
                    >
                      Cor
                    </button>
                    {colorPickerControlVisible && (
                      <S.ColorPicker>
                        <CompactPicker
                          color={colorControl}
                          onChange={(e) => handleChangeBackgroundControl(e)}
                        />
                      </S.ColorPicker>
                    )}
                  </S.ColorBtnContainer>
                  <TextField
                    label="Nível"
                    name="cycle"
                    id="cycle"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={levelControl}
                    onChange={(e) => setLevelControl(e.target.value)}
                  />
                  <TextField
                    label="Valor"
                    name="size"
                    id="size"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={valueControl}
                    onChange={(e) => setValueControl(e.target.value)}
                  />
                  <button
                    className="table__addButton"
                    onClick={(e) => {
                      e.preventDefault();
                      addControlInTable();
                    }}
                  >
                    <AddCircle />
                  </button>
                </S.InputWrapper>
              </S.InputContainer>
            </S.InputWrapper>
            <S.ButtonWrapper>

              <Button
                text="Cancelar"
                className="cancelBtn"
                onClick={() => {
                  clearFields();
                  setSelectedPrague([]);
                  setCurrentModal('');
                  closeModal();
                }}
              />
              <Button
                text={
                  loading ? (
                    <>
                      <PuffLoader color="#79ac34" loading={loading} size={30} />
                    </>
                  ) : (
                    'Salvar'
                  )
                }
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                type="submit"
                disabled={loading}
                className="saveBtn"
              />
            </S.ButtonWrapper>
          </S.FormContainer>
        </form>
      </S.Container>
    </Modal>
  );
};

export default ModalEditPrague;
