import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useModal } from '../../../../../../../../hooks/Modal';
import * as S from './styled';
import { useAuth } from '../../../../../../../../hooks/Auth';
import Toast from '../../../../../../../../components/Toast';
import { appConfig } from '../../../../../../../../config';
import api from '../../../../../../../../services/api';
import { DashboardContext } from '../../../../../../index';

const ModalDeleteConfirm = ({
  messageDialog = 'Deseja realmente fazer isso ?',
  alert = '',
  prague_id,
  ...props
}) => {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const { setUpdateScreen } = useContext(DashboardContext);
  const deletePrague = async (id) => {
    const params = new URLSearchParams([
      ['prague_id', id],
      ['status', false]
    ]);
    try {
      await api.delete(`${appConfig.apiHosts.manager}/Prague/Delete`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUpdateScreen(true);

      toast(<Toast title="Removido com sucesso" />, {
        type: 'success'
      });

      closeModal();
    } catch (error) {
      console.error(error);
      toast(<Toast title="Não foi possível remover" />, {
        type: 'error'
      });
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      shouldCloseOnOverlayClick={false}
      onRequestClose={closeModal}
      style={modalStyles}
      {...props}
    >
      <S.DialogContainer>
        <S.Text>{messageDialog}</S.Text>
        <p>{alert}</p>
        <S.ButtonsContainer>
          <S.ConfirmationButton
            onClick={() => {
              deletePrague(prague_id);
            }}
            style={{ backgroundColor: '#79AC34' }}
          >
            Sim
          </S.ConfirmationButton>
          <S.ConfirmationButton
            onClick={() => {
              closeModal();
            }}
            style={{ backgroundColor: '#d62222' }}
          >
            Não
          </S.ConfirmationButton>
        </S.ButtonsContainer>
      </S.DialogContainer>
    </Modal>
  );
};

ModalDeleteConfirm.propTypes = {
  messageDialog: PropTypes.string,
  alert: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  prague_id: PropTypes.string
};

export default ModalDeleteConfirm;
