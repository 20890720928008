import React, { useContext, useState } from 'react';
import { MdClose } from 'react-icons/md';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { useMap } from '../../hooks/Map';
import { DashboardContext } from '../../pages/Dashboard';
import Button from '../Button';
import * as S from './styled';

const FilterCard = () => {
  const { setControlState, renderWMSTileFieldFilters, removeWMSLayer } =
    useMap();
  const { fields } = useContext(DashboardContext);
  const [selectedFields, setSelectedFields] = useState([]);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  const options = fields.map((value) => {
    return { label: value.features[0].properties.field_name, value };
  });

  const handleFilterFields = () => {
    if (selectedFields.length < 1 || initialDate === '' || finalDate === '') {
      return;
    }
    removeWMSLayer();
    selectedFields.forEach((value) => {
      renderWMSTileFieldFilters(value.value, [initialDate, finalDate]);
    });
  };
  return (
    <S.Container>
      <S.Header>
        <h1>Filtro de Talhões</h1>
        <button
          type="button"
          onClick={() => {
            setControlState(undefined);
          }}
        >
          <MdClose />
        </button>
      </S.Header>
      <S.Form>
        {/* <ReactMultiSelectCheckboxes
          width="300px"
          options={options}
          onChange={(e) => setSelectedFields(e)}
        /> */}
        <h1>Periodo</h1>
        <S.InputDate
          type="date"
          value={initialDate}
          onChange={(e) => setInitialDate(e.target.value)}
        />
        <S.InputDate
          type="date"
          value={finalDate}
          onChange={(e) => setFinalDate(e.target.value)}
        />
        <Button
          type="button"
          text="Filtrar"
          style={{ height: '40px' }}
          onClick={() => handleFilterFields()}
        />
      </S.Form>
    </S.Container>
  );
};

export default FilterCard;
