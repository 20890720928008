/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useRef, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
} from '@mui/material';
import Checkbox from 'react-custom-checkbox';
import { useDropzone } from 'react-dropzone';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
// import { useFiles } from "../../context/files";
import PuffLoader from 'react-spinners/PuffLoader';
import { CircularProgressbar } from 'react-circular-progressbar';
import { MdOutlineCloudUpload, MdCheck, MdClose, MdError, MdLink, MdMoodBad } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import filesize from 'filesize';
import { toast } from 'react-toastify';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import storage from '../../../../../../../../services/firebase';
import Button from '../../../../../../../../components/Button';
import { useMap } from '../../../../../../../../hooks/Map';
import { appConfig } from '../../../../../../../../config';
import { useAuth } from '../../../../../../../../hooks/Auth';
import api from '../../../../../../../../services/api';
import Toast from '../../../../../../../../components/Toast';
import * as S from './styled';
import { FullLoadingProvider } from '../../../../../../../../hooks/FullLoading';

const ModalNewProblem = () => {
  const inputFileRef = useRef(null);
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const {
    selectedManagement,
    uploadedFiles: files,
    deleteFile,
    handleUpload,
    setUploadedFiles,
    setUpdateScreen,
    listTypeManager,
    setListTypeManager,
    selectTypeProblemList,
    setSelectTypeProblemList,
    selectTypeCultureList,
    setSelectTypeCultureList,
    listPragues
  } = useContext(DashboardContext);
  const { selectedFarmId } = useMap();

  const ModalNewProblemSchema = Yup.object().shape({
    type_problem: Yup.string().required('Tipo de problema é obrigatório'),
    unit_measurement: Yup.string().required('Unidade de medida é obrigatório'),
  });

  const { handleSubmit, register, errors, control, reset } = useForm({
    resolver: yupResolver(ModalNewProblemSchema)
  });

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [selectedCulture, setSelectedCulture] = useState('');
  const [unitMeasurement, setUnitMeasurement] = useState('');
  const [especies, setEspecies] = useState('');
  const [cultivar, setCultivar] = useState('');
  const [commits, setCommits] = useState('');

  const clearFields = () => {
    setSelectedType('');
    setSelectedCulture('');
    setUnitMeasurement('');
    setEspecies('');
    setCultivar('');
    setCommits('');
    setUploadedFiles([]);
  };

  const handleAddInvoiceFiles = async (e) => {
    try {
      const fileData = await storage.ref(`/images/${e.name}`).put(e.file);
      const imageSrc = await fileData.ref.getDownloadURL();

      files.forEach((file) => URL.revokeObjectURL(file.preview));

      return imageSrc;
    } catch (error) {
      console.error(error);
    }
  };

  const FileList = () => {
    if (!files.length) {
      return (
        <span>
          <MdMoodBad
            style={{ marginLeft: '45%', marginTop: 10 }}
            size={24}
            color="#d5d2d2"
          />
        </span>
      );
    }
  };

  const onDrop = useCallback((file) => {
    handleUpload(file);
  }, [handleUpload]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: ['image/jpeg', 'image/pjpeg', 'image/png', 'image/gif'],
    maxFiles: 1,
    multiple: null,
    onDrop,
  });

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return <S.UploadMessage>Arraste uma imagem aqui</S.UploadMessage>;
    }

    if (isDragReject) {
      return (
        <S.UploadMessage type="error">
          Tipo de arquivo não suportado
        </S.UploadMessage>
      );
    }

    return <S.UploadMessage type="success">Solte as imagens aqui</S.UploadMessage>;
  }, [isDragActive, isDragReject]);

  const getTypeProblem = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Problema')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const filterProblemByActive = response.data.data.values.filter((v) => v.status === true);
      setSelectTypeProblemList(filterProblemByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeCulture = async () => {
    const type_id = listTypeManager?.filter((v) => v?.name === 'Culturas')[0]?.type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterCultureByActive = response.data.data.values.filter((v) => v.status === true);
      setSelectTypeCultureList(filterCultureByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    let imageUrl;
    if (files.length > 0) {
      imageUrl = await handleAddInvoiceFiles(files[files.length - 1]);
    }

    const newProblem = {
      problemtype_id: data.type_problem,
      measurement_unit: data.unit_measurement,
      kind: data.species,
      plant: cultivar,
      crop_type_id: selectedCulture || null,
      image: imageUrl,
      note: commits,
      farm_id: selectedFarmId,
      created_by: user.user_id
    };

    try {
      await api.post(
        `${appConfig.apiHosts.prague}/Problem/Create`,
        newProblem,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast(<Toast title="Problema criado com sucesso" />, {
        type: 'success'
      });

      setUpdateScreen(true);

      if (checked) {
        reset();
        clearFields();
        setLoading(false);
      } else {
        reset();
        clearFields();
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast(<Toast title="Não foi possível criar o problema" />, {
        type: 'error'
      });
    }
  };

  useEffect(() => {
    getTypeProblem();
    getTypeCulture();
  }, []);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Novo Problema</S.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <S.InputWrapper>
              <S.InputContainer>
                <Controller
                  name="type_problem"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Tipo</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                        error={!!errors?.type_problem?.message}
                      >
                        {selectTypeProblemList?.map((prob) => (
                          <MenuItem value={prob.value_Id} key={prob.value_Id}>{prob.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.type_problem?.message && (
                  <S.InputError>{errors?.type_problem?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <span>ID (Gerado Automaticamente)</span>
                <TextField
                  name="id_problem"
                  id="id_problem-email"
                  margin="dense"
                  fullWidth
                  size="small"
                  value=""
                  disabled
                  style={{ backgroundColor: '#EFEFEF' }}
                />
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer>
                <span>Unidade de Medida</span>
                <Controller
                  name="unit_measurement"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value, field }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                      error={!!errors?.unit_measurement?.message}
                    />
                  )}
                />
                {errors?.unit_measurement?.message && (
                  <S.InputError>{errors?.unit_measurement?.message}</S.InputError>
                )}
              </S.InputContainer>

              <S.InputContainer>
                <Controller
                  name="species"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Espécie</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                        error={!!errors?.species?.message}
                      >
                        {listPragues?.map((prague) => (
                          <MenuItem value={prague.prague_id} key={prague.prague_id}>{prague.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.species?.message && (
                  <S.InputError>{errors?.species?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper style={{ margin: 0 }}>
              <S.InputContainer>
                <span>Cultivar Resistente</span>
                <TextField
                  name="cultivar"
                  id="cultivar"
                  margin="dense"
                  fullWidth
                  size="small"
                  value={cultivar}
                  onChange={(e) => setCultivar(e.target.value)}
                />
              </S.InputContainer>
              <S.InputContainer>
                <FormControl className="selectStyle">
                  <span>Cultura</span>
                  <Select
                    labelId="culture"
                    name="culture"
                    placeholder="Selecione..."
                    value={selectedCulture}
                    onChange={(e) => {
                      setSelectedCulture(e.target.value);
                    }}
                    size="small"
                  >
                    {selectTypeCultureList?.map((cult) => (
                      <MenuItem value={cult.value_Id} key={cult.value_Id}>{cult.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.PhotoContainer>
                <span style={{ marginBottom: '10px' }}>Foto</span>
                <div className="uploadFile">
                  {files.length <= 0 ? (
                    <S.DropContainer {...getRootProps()}>
                      <input accept="image/*" maxFiles={1} {...getInputProps()} />
                      <div className="inputMessage">
                        <MdOutlineCloudUpload />
                        {renderDragMessage()}
                      </div>
                    </S.DropContainer>
                  ) : (
                    <S.DropContainer style={{ cursor: 'default', border: 'none' }}>
                      {files.map((uploadedFile) => (
                        <S.ContainerList
                          style={{
                            backgroundImage: `url(${uploadedFile.preview})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'left top'
                          }}
                          className="uploadedBox"
                        >
                          <li key={uploadedFile.id}>
                            <S.FileInfo>
                              {/* <S.Preview src={uploadedFile.preview} /> */}
                              <div>
                                <strong>{uploadedFile.name}</strong>
                                <span>
                                  {uploadedFile.readableSize}{' '}
                                </span>
                              </div>
                              <button onClick={(e) => deleteFile(files.id)}>
                                <MdClose />
                              </button>
                            </S.FileInfo>

                            <div>
                              {!uploadedFile.uploaded && !uploadedFile.error && (
                                <CircularProgressbar
                                  styles={{
                                    root: { width: 24 },
                                    path: { stroke: '#7159c1' },
                                  }}
                                  strokeWidth={10}
                                  text={String(uploadedFile.progress)}
                                  value={uploadedFile.progress || 0}
                                />
                              )}

                              {uploadedFile.url && (
                                <a
                                  href={uploadedFile.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <MdLink style={{ marginRight: 8 }} size={24} color="#222" />
                                </a>
                              )}
                              {uploadedFile.error && <MdError size={24} color="#e57878" />}
                            </div>
                          </li>
                        </S.ContainerList>
                      ))}
                    </S.DropContainer>
                  )}
                </div>
              </S.PhotoContainer>

              <S.InputContainer style={{ height: 'auto' }}>
                <span style={{ marginBottom: '10px' }}> Observações</span>
                <S.CommitArea
                  value={commits}
                  onChange={(e) => setCommits(e.target.value)}
                />
              </S.InputContainer>
            </S.InputWrapper>
            <S.ButtonWrapper>
              <Button
                text="Cancelar"
                className="cancelBtn"
                onClick={() => {
                  clearFields();
                  closeModal();
                }}
              />
              <S.CheckboxContainer>
                <Checkbox
                  checked={checked}
                  icon={(
                    <MdCheck
                      color="#507919"
                    />
                  )}
                  borderColor="#A0BD39"
                  borderRadius={3}
                  borderWidth={1}
                  size={20}
                  style={{ cursor: 'pointer' }}
                  onChange={() => {
                    setChecked(!checked);
                  }}
                />
                <label htmlFor="register">Continuar Cadastrando</label>
              </S.CheckboxContainer>
              <Button
                text={loading ? (
                  <>
                    <PuffLoader
                      color="#79ac34"
                      loading={loading}
                      size={30}
                    />
                  </>
                ) : 'Criar Problema'}
                style={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}
                disabled={loading}
                className="saveBtn"
                type="submit"
              // onClick={() => createProblem()}
              />
            </S.ButtonWrapper>
          </S.FormContainer>
        </form>
      </S.Container>
    </Modal>
  );
};

export default ModalNewProblem;
