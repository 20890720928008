import React from 'react';
import PropTypes from 'prop-types';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Container } from './styled';

const SimpleButton = ({ text, icon = <MdKeyboardArrowLeft />, ...props }) => {
  return (
    <Container type="button" {...props}>
      {icon}
      {text}
    </Container>
  );
};

SimpleButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node
};

export default SimpleButton;
