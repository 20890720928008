/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect
} from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  OutlinedInput,
  Selected,
  TextareaAutosize
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { AddCircle } from '@mui/icons-material';
import { CompactPicker } from 'react-color';
import Checkbox from 'react-custom-checkbox';
import { useDropzone } from 'react-dropzone';
// import { useFiles } from "../../context/files";
import PuffLoader from 'react-spinners/PuffLoader';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  FaChevronLeft,
  FaChevronRight,
  FaUpload,
  FaTrash,
  FaCheckCircle
} from 'react-icons/fa';
import {
  MdOutlineCloudUpload,
  MdCheck,
  MdClose,
  MdError,
  MdLink,
  MdMoodBad
} from 'react-icons/md';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
import filesize from 'filesize';
import { toast } from 'react-toastify';
import { Carousel } from 'react-responsive-carousel';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import storage from '../../../../../../../../services/firebase';
import Button from '../../../../../../../../components/Button';
import { useMap } from '../../../../../../../../hooks/Map';
import { appConfig } from '../../../../../../../../config';
import { useAuth } from '../../../../../../../../hooks/Auth';
import api from '../../../../../../../../services/api';
import Toast from '../../../../../../../../components/Toast';
import * as S from './styled';

const ModalViewDisease = () => {
  const inputFileRef = useRef(null);
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const {
    uploadedFiles: files,
    selectedDisease,
    setSelectedDisease,
    setCurrentModal
  } = useContext(DashboardContext);

  const { selectedFarmId } = useMap();
  const uploadFile = useRef(null);

  const [checked, setChecked] = useState(true);
  const [selectedPhoto, setSelectedPhoto] = useState(0);
  const [selectedPhotoScale, setSelectedPhotoScale] = useState(0);
  const [photoScale, setPhotoScale] = useState([]);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Dados da Doença</S.Title>
        <S.FormContainer>
          <S.InputWrapper style={{ margin: 0 }}>
            {selectedDisease?.type_disease_name && (
              <S.InputContainer>
                <S.SubTitle>
                  Tipo
                </S.SubTitle>
                <S.TextContainer>{
                  selectedDisease?.type_disease_name
                }
                </S.TextContainer>
              </S.InputContainer>
            )}
            <S.InputContainer>
              <S.SubTitle>Nome Comum</S.SubTitle>
              <S.TextContainer>{selectedDisease?.name}</S.TextContainer>
            </S.InputContainer>
          </S.InputWrapper>
          <S.InputWrapper>
            {selectedDisease?.scientific_name && (
              <S.InputContainer>
                <S.SubTitle>Nome Científico</S.SubTitle>
                <S.TextContainer>{selectedDisease?.scientific_name}</S.TextContainer>
              </S.InputContainer>
            )}
            <S.InputContainer>
              <S.CheckboxContainer>
                <S.SubTitle style={{ marginBottom: '5px' }}>Incidência</S.SubTitle>
              </S.CheckboxContainer>
              {checked && (
                <S.CheckboxContainer>
                  <label
                    className="radioContainer"
                    style={{
                      color: selectedDisease.incidence === true ? '#79AC34' : ''
                    }}
                  >
                    Sim
                    <input
                      disabled
                      type="checkbox"
                      checked={selectedDisease.incidence === true}
                    />
                    <span className="checkmark" />
                  </label>

                  <label
                    className="radioContainer"
                    style={{
                      color: selectedDisease.incidence === false ? '#79AC34' : ''
                    }}
                  >
                    Não
                    <input
                      disabled
                      type="checkbox"
                      checked={selectedDisease.incidence === false}
                    />
                    <span className="checkmark" />
                  </label>
                </S.CheckboxContainer>
              )}
            </S.InputContainer>
          </S.InputWrapper>
          <S.InputWrapper>
            {selectedDisease?.crop_type.length > 0 && (
              <S.InputContainer style={{ height: 'auto' }}>
                <S.SubTitle>Cultura</S.SubTitle>
                {selectedDisease?.crop_type?.map((crop) => (
                  <S.TextContainer>{crop.name}</S.TextContainer>
                ))}
              </S.InputContainer>
            )}
            {selectedDisease?.plant.length > 0 && (
              <S.InputContainer style={{ height: 'auto' }}>
                <S.SubTitle>Cultivar Resistente</S.SubTitle>
                {selectedDisease?.plant?.map((plant) => (
                  <S.TextContainer>{plant.name}</S.TextContainer>
                ))}
              </S.InputContainer>
            )}
          </S.InputWrapper>
          <S.InputWrapper>
            {selectedDisease?.description && (
              <S.TextAreaContainer>
                <S.SubTitle>Descrição</S.SubTitle>
                <TextareaAutosize
                  value={selectedDisease?.description}
                  disabled
                  className="textArea"
                />
              </S.TextAreaContainer>
            )}
          </S.InputWrapper>
          <S.InputWrapper>
            {selectedDisease?.symptoms && (
              <S.TextAreaContainer>
                <S.SubTitle>Sintomas</S.SubTitle>
                <TextareaAutosize
                  value={selectedDisease?.symptoms}
                  disabled
                  className="textArea"
                />
              </S.TextAreaContainer>
            )}
          </S.InputWrapper>
          <S.InputWrapper>
            {selectedDisease?.disease_images?.length > 0 && (
              <S.InputContainer style={{ height: 'auto' }}>
                <S.SubTitle>Foto</S.SubTitle>
                <S.PhotoContainer>
                  <Carousel
                    width={500}
                    // swipeScrollTolerance={5}
                    showThumbs={false}
                    selectedItem={selectedPhoto}
                    showStatus={false}
                    showArrows={false}
                    showIndicators={false}
                    onChange={(e) => setSelectedPhoto(e)}
                  >
                    {selectedDisease?.disease_images?.map((uploadedFile, index) => (
                      <div className="viewPhoto">
                        <img
                          alt={uploadedFile.weed_image_id}
                          src={uploadedFile.url}
                          // height="280"
                          style={{ border: '2px solid #79ac34' }}
                        />
                        <S.SubtitlePhotoContainer>
                          <span>{uploadedFile?.legend}</span>
                        </S.SubtitlePhotoContainer>
                      </div>
                    ))}

                  </Carousel>
                  <S.ThumbsContainer>
                    {selectedDisease?.disease_images?.map((uploadedFile, index) => (
                      <S.ThumbItemContainer>
                        <S.ThumbItem>
                          <S.ThumbButtonImage
                            type="button"
                            className="thumbsContainer__button"
                            onClick={() => {
                              setSelectedPhoto(index);
                            }}
                            style={{
                              border:
                                selectedPhoto === index
                                  ? '5px solid #79ac34'
                                  : '2px solid #47aa12'
                            }}
                          >
                            <img
                              alt={uploadedFile.disease_image_id}
                              src={uploadedFile.url}
                              style={{
                                border:
                                  selectedPhoto === index
                                    ? '2px solid #FFF'
                                    : 'none'
                              }}
                            />
                          </S.ThumbButtonImage>
                          <S.ThumbIconsContainer>
                            {uploadedFile.url === selectedDisease.main_photo && (
                              <FaCheckCircle color="#79ac34" size={15} />
                            )}
                          </S.ThumbIconsContainer>
                        </S.ThumbItem>
                      </S.ThumbItemContainer>
                    ))}
                  </S.ThumbsContainer>
                </S.PhotoContainer>
              </S.InputContainer>
            )}
          </S.InputWrapper>

          <S.InputWrapper>
            {selectedDisease?.diagrammatic_Scale?.length > 0 && (
              <S.InputContainer style={{ height: 'auto' }}>
                <S.SubTitle>Escala Diagramática</S.SubTitle>
                <S.PhotoContainer>
                  <Carousel
                    width={500}
                    // swipeScrollTolerance={5}
                    showThumbs={false}
                    selectedItem={selectedPhotoScale}
                    showStatus={false}
                    showArrows={false}
                    showIndicators={false}
                    onChange={(e) => setSelectedPhotoScale(e)}
                  >
                    {selectedDisease?.diagrammatic_Scale?.map((uploadedFile) => (
                      <div className="viewPhotoScale">
                        <img
                          alt={uploadedFile.diagrammatic_scale_image_id}
                          src={uploadedFile.url}
                          // height="280"
                          style={{ border: '2px solid #79ac34' }}
                        />
                      </div>
                    ))}
                  </Carousel>
                </S.PhotoContainer>
              </S.InputContainer>
            )}
          </S.InputWrapper>

          <S.InputWrapper style={{ alignItems: 'start', marginBottom: '15px' }}>

            <S.InputContainer style={{ height: 'auto' }}>
              <S.SubTitle style={{ paddingBottom: '10px', marginTop: '10px' }}>
                Nível de Severidade
              </S.SubTitle>
              <TableContainer
                  style={{
                    overflow: 'auto',
                    width: '100%'
                  }}
              >
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                          align="center"
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: '#e4e4e4'
                          }}
                          colSpan={4}
                      >
                        <span>{selectedDisease?.disease_severity_level_title}</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedDisease?.disease_severity_level?.map((value, index) => (
                      <TableRow>
                        <TableCell align="center">
                          <div
                              style={{
                                height: '18px',
                                width: '18px',
                                background: value.color ? value.color : ''
                              }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {value.level}
                        </TableCell>
                        <TableCell align="center">{value.value}</TableCell>
                        <TableCell align="center">{value.comments}</TableCell>
                      </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </S.InputContainer>

          </S.InputWrapper>
          <S.InputWrapper style={{ alignItems: 'start', marginBottom: '15px' }}>
            {selectedDisease?.canopy_plant.length > 0 && (
              <S.InputContainer style={{ height: 'auto' }}>
                <S.SubTitle style={{ paddingBottom: '10px', marginTop: '10px' }}>
                  Dossel de Planta
                </S.SubTitle>
                <TableContainer
                  style={{
                    overflow: 'auto',
                    width: '100%'
                  }}
                >
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: '#e4e4e4'
                          }}
                          colSpan={1}
                        >
                          <span>{selectedDisease?.disease_canopy_plant_title}</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedDisease?.canopy_plant?.map((value, index) => (
                        <TableRow>
                          <TableCell align="center">{value.canopy_plant}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </S.InputContainer>
            )}
          </S.InputWrapper>
          <S.ButtonWrapper>
            <Button
              text="Fechar"
              className="cancelBtn"
              onClick={() => {
                setCurrentModal('');
                setSelectedDisease([]);
                closeModal();
              }}
            />
          </S.ButtonWrapper>
        </S.FormContainer>
      </S.Container>
    </Modal>
  );
};

export default ModalViewDisease;
