import styled from 'styled-components';
import {
  Table,
  Select as MUISelect,
  TextField,
  Button as MUIButton
} from '@mui/material';
import { shade } from 'polished';

export const Container = styled.div`
  height: 100%;
  hr {
    border: 1px solid #a0bd39;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  height: 100%;
`;

export const HelpButtonContainer = styled.div`
  display: flex;
  gap: 1px;
`;

export const HelpButton = styled.button`
  background: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: none;
  color: #79ac34;
  width: 100%;
  font-weight: bold;
  transition: all 0.2s;
  border: solid 2px #a0bd39;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: auto;
  margin: 0px;
  margin-bottom: -2px;
  cursor: pointer;

  &:hover {
    background: #a0bd39;
    color: #fff;
  }

  &:disabled {
    background: #dcdcdc;
  }
`;

export const SearchAreaContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
  margin-bottom: 16px;
  //justify-content: flex-end;

  span {
    margin-left: 10px;
    width: 100px;
    color: #4d4d4d;
  }

  button {
    border: none;
    background: transparent;
    cursor: pointer;
  }
`;

export const SearchArea = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  box-shadow: 1px 3px 6px #0000001a;
  height: 40px;
  width: 100%;

  svg {
    color: #bdbdbd;
    margin: 0 5px;
    cursor: pointer;
  }
`;

export const SearchInput = styled.input`
  height: 30px;
  flex: 1;
  color: #4d4d4d;
  border: none;
`;

export const Title = styled.h1`
  width: 100%;
  font-size: 18px;
  padding-left: 20px;
  color: #444;
  margin-top: 5px;
`;

export const SubTitle = styled.span`
  font-size: 16px;
  color: #4d4d4d;
  font-weight: bold;
`;

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  /* overflow-y: auto; */
  width: 100%;

  margin-bottom: 100px;

  /* @media (min-height: 1800px) {
    height: 100%;
  } */
`;

export const NavButton = styled.button`
  border: ${(props) => (props.active ? '2px solid #a0bd39' : 'none')};
  border-top-left-radius: ${(props) => (props.active ? '6px' : '0')};
  border-top-right-radius: ${(props) => (props.active ? '6px' : '0')};
  border-bottom: none;
  background-color: ${(props) => (props.active ? '#a0bd39' : '#FFF')};
  cursor: pointer;
  font-weight: bold;
  padding: 5px 10px;
  color: ${(props) => (props.active ? '#FFF' : '#4d4d4d')};
  //margin-bottom: -2px;

  svg {
    color: #a0bd39;
    width: 30px;
    height: 30px;
  }
`;

export const IconFilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const IconButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    width: 38px;
    height: 38px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 20px;
  width: 78vw;
  //gap: 15px;
  padding: 0 10px;

  svg {
    color: #79ac34;
    font-size: 32px;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .carouselPrague,
  .carouselWeed,
  .carouselDisease {
    display: flex;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    scroll-behavior: smooth;
    margin: 0 10px;
    gap: 10px;
  }

  .carouselPrague::-webkit-scrollbar,
  .carouselWeed::-webkit-scrollbar,
  .carouselDisease::-webkit-scrollbar {
    display: none;
  }
`;

export const Card = styled.div`
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  background-color: #f5f5f5;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  width: 210px;
  height: 265px;
  display: flex;
  flex-direction: column;
  cursor: default;
`;

export const CardImage = styled.div`
  width: 207px;
  height: 180px;
  background-color: #d4d4d4;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;
export const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0;
  margin-bottom: 15px;
  flex: 1;
`;
export const CardTitle = styled.h3`
  font-size: 14px;
`;
export const CardDescription = styled.span`
  font-size: 12px;
`;

export const CardActionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: end;
  width: 100%;
`;
export const CardAction = styled.div`
  margin: 5px 0;

  button {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    font-size: 12px;
    color: #79ac34;
    font-weight: bold;

    svg {
      margin-right: 3px;
      font-size: 12px;
      padding-bottom: 1px;
    }
  }
`;
