import { AxiosResponse } from 'axios';
import api from './configFarmApi';

class SystemModuleApi {
  constructor(token: string) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  get getSystemModule() {
    return 'get-farm';
  }

  get RetornarModulo() {
    return 'retornarModulos';
  }

  async ListarModulos(
    filtro: string,
    organizacao_id?: string
  ): Promise<AxiosResponse> {
    return api.get(
      `/Farm/ListarModulos?filtro=${filtro}&organizacao_id=${
        organizacao_id || ''
      }`
    );
  }

  async RetornarModulos(id: string): Promise<AxiosResponse> {
    return api.get(`/Farm/RetornarModulo?id=${id}`);
  }

  async ModuleCreate<T>(data: T): Promise<AxiosResponse> {
    return api.post('/Farm/Modulo', data);
  }

  async ModuleUpdate<T>(data: T): Promise<AxiosResponse> {
    return api.put('/Farm/Modulo', data);
  }

  async ListarAtrelarModuloSubmodulo(
    filtro: string,
    ModuleId?: string
  ): Promise<AxiosResponse> {
    return api.get(
      `/Farm/ListarAtrelarModuloSubmodulo?module_id=${
        ModuleId || ''
      }&filtro=${filtro}`
    );
  }

  async AtrelarModuloSubmodulo(data: any): Promise<AxiosResponse> {
    return api.put(
      `/Farm/AtrelarModuloSubmodulo?module_id=${data.module_id}&sub_module_id=${data.sub_module_id}`
    );
  }

  async DeletarModuloSubmodulo(data: any): Promise<AxiosResponse> {
    return api.delete(
      `/Farm/DeletarModuloSubmodulo?module_id=${data.module_id}&sub_module_id=${data.sub_module_id}`
    );
  }
}

export default SystemModuleApi;
