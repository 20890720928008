import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as Yup from 'yup';
import { useAuth } from '../../../../../../../../hooks/Auth';
import HitchingUserFarmApi from '../../../../../../../../APi/FarmApi/HitchingUserFarmApi';
import UserAPI from '../../../../../../../../APi/FarmApi/UserAPI';
import { UserProfileFormProps } from '../UserProfileForm';

interface UserProfileForm {
  user_id: string;
  user_profile_id: string;
  organizacao_id: string;
}

const useUserProfileForm = ({ type }: UserProfileFormProps) => {
  const { token, user } = useAuth();
  const hitchingUserFarmApi = useMemo(() => new HitchingUserFarmApi(token), []);
  const useApi = useMemo(() => new UserAPI(token), []);
  const [formValid, setFormValid] = useState<boolean>(false);
  const navigate = useNavigate();
  const Queries = useQueryClient();
  const [mensage, setMensage] = useState<boolean>(false);
  const { id } = useParams();
  const [dataUserProfile, setDataUserProfile] = useState<any>({});
  const FormValidationSchema = Yup.object().shape({
    user_id: Yup.string().required(),
    user_profile_id: Yup.string().required()
  });

  const methods = useForm({
    defaultValues: {
      user_id: [],
      user_profile_id: [],
      organizacao_id: ''
    },
    resolver: yupResolver(FormValidationSchema)
  });
  const onSuccessUserData = (data) => {
    setDataUserProfile(data.data?.data[0]);
  };
  const UserData = useQuery({
    queryKey: [useApi.listaAtrelamentoUsuarioPerfil, { id }],
    queryFn: async () => hitchingUserFarmApi.UserFarmGet(id),
    onSuccess: onSuccessUserData
  });

  const userFarmsCreate = useMutation({
    mutationFn: async (form: UserProfileForm) => {
      form.organizacao_id = user.organizacao_id;
      hitchingUserFarmApi.UserFarmCreate(form);
    },
    onSuccess: async () => {
      setMensage(true);
      navigate(-1);
      await Queries.invalidateQueries(useApi.listaAtrelamentoUsuarioPerfil);
    }
  });

  useEffect(() => {
    setFormValid(
      !methods.control.getValues('user_id') ||
        !methods.control.getValues('user_profile_id')
    );
  }, [methods.control.watchInternal()]);

  const submit = methods.handleSubmit((form: UserProfileForm) => {
    userFarmsCreate.mutate(form);
  });

  return {
    form: {
      methods,
      submit,
      isValid: formValid,
      isSuccess: userFarmsCreate.isSuccess,
      isRedy: UserData.isLoading || UserData.isFetching,
      profile: dataUserProfile?.idAggregate,
      user: dataUserProfile?.id
    },
    mensage
  };
};

export default useUserProfileForm;
