import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useModal } from '../../../../../../hooks/Modal';
import { useMap } from '../../../../../../hooks/Map';
import { InformationContext } from '../../index';

import * as S from './styled';

const EditFarmModal = ({
  messageDialog = 'Deseja realmente fazer isso ?',
  ...props
}) => {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { removeMappingGeometries } = useMap();
  const { handleDeleteMapping } = useContext(InformationContext);
  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      {...props}
    >
      <S.DialogContainer>
        <S.Text>{messageDialog}</S.Text>
        <S.ButtonsContainer>
          <S.ConfirmationButton
            onClick={() => {
              handleDeleteMapping();
              closeModal();
              removeMappingGeometries();
            }}
            style={{ backgroundColor: '#79AC34' }}
          >
            Sim
          </S.ConfirmationButton>
          <S.ConfirmationButton
            onClick={() => closeModal()}
            style={{ backgroundColor: '#d62222' }}
          >
            Não
          </S.ConfirmationButton>
        </S.ButtonsContainer>
      </S.DialogContainer>
    </Modal>
  );
};

EditFarmModal.propTypes = {
  messageDialog: PropTypes.string
};

export default EditFarmModal;
