import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 0px 10px;
  background: #e4e4e4;
  border-radius: 3px;
  display: flex;
  align-items: center;
  gap: 15px;
  border: 2px solid #e4e4e4;
  ${(props) =>
    props.selected &&
    css`
      border-color: #bfd76c;
    `}

  & + div {
    margin-top: 5px;
  }
`;

export const CheckBox = styled.input`
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 13px;
    height: 13px;
    /* border-radius: 50%; */
    top: 0px;
    left: 0px;
    background-color: #fff;
    border: 1px solid #fff;
  }

  &:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 13px;
    height: 13px;
    /* border-radius: 50%; */
    top: 0px;
    left: 0px;
    background-color: #aed329;
    border: 3px solid #fff;
  }
`;

export const Text = styled.span`
  font-size: 0.8rem;
  color: #000000;
  ${(props) =>
    props.selected &&
    css`
      color: #000000;
    `}
`;
