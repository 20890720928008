import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FarmList } from './FarmList';

import Field from '../Farm/components/Field';
import Farm from '../Farm';
import RegisterFarm from '../Farm/components/RegisterFarm';
import EditingFarm from '../Farm/components/EditingFarm';
import RegisterFarmFromShapefile from '../Farm/components/RegisterFarmFromShapefile';

const FarmRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<FarmList />}>
        <Route path="/" element={<Navigate to="./farmList" />} />
        <Route path="/farmList" element={<Farm />} />
        <Route path="/new" element={<RegisterFarm />} />
        <Route path="/edit/:id" element={<EditingFarm />} />
        <Route path="/selectFarm" element={<Field />} />
        <Route
          path="/RegisterFarmFromShapefile"
          element={<RegisterFarmFromShapefile />}
        />
      </Route>
    </Routes>
  );
};

export default FarmRouter;
