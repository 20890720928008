/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-lone-blocks */
/* eslint-disable prettier/prettier */
/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable linebreak-style */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect } from 'react';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import { DashboardContext } from '../../../..';
import Button from '../../../../../../components/Button';
import SimpleButton from '../../../../../../components/SimpleButton';
import { useMap } from '../../../../../../hooks/Map';
import * as S from './styled';

const SelectedMaps = () => {
  const { renderedMaps, setRenderedMaps, setRenderedMapEditId } = useMap();
  const { setDashboardState } = useContext(DashboardContext);
  const handleDeleteMap = (id) => {
    const fielteredMaps = renderedMaps.filter((value) => value.id !== id);
    setRenderedMaps(fielteredMaps);
  };

  useEffect(() => {
    if (renderedMaps.length === 0) {
      setDashboardState('dashboard@results');
    }
  }, [renderedMaps]);

  return (
    <S.Container>
      <SimpleButton
        text="Voltar"
        onClick={() => {
          setDashboardState('dashboard@results');
          setRenderedMaps([]);
        }}
      />
      <h3>Mapas selecionados</h3>
      {renderedMaps.map((map, index) => (
        <S.FarmCardContainer key={map.id}>
          <S.FarmCardHeader>
            {map.mapType === 'results' ? (
              <S.FarmCardText>
                <h3>Mapa {index + 1}</h3>
                <span>Parametro: {map.param} </span>
                <br />
                <span>Camada: {map.layer}</span>
              </S.FarmCardText>
            ) : (
              <S.FarmCardText>
                <h3>Mapa {index + 1}</h3>
                <span>{map.recommendationType === 'fertilizer' ? 'Fertilizante' : 'Corretivo'}: {map.fertilize} </span>
              </S.FarmCardText>
            )}
          </S.FarmCardHeader>
          <S.FarmCardActions>
            <button
              type="button"
              onClick={() => {
                setRenderedMapEditId(map.id);
                setDashboardState('dashboard@results');
              }}
            >
              <span>Editar</span>
              <MdModeEdit />
            </button>
            <button
              type="button"
              className="simple-buttons"
              onClick={() => handleDeleteMap(map.id)}
            >
              <span>Deletar</span>
              <MdDelete />
            </button>
          </S.FarmCardActions>
        </S.FarmCardContainer>
      ))}
      <Button
        text="Adicionar outro mapa"
        onClick={() => {
          // handleClick();
          // setListMapsVisible(true);
          setDashboardState('dashboard@results');
        }}
        className="buttonAddMap"
        disabled={renderedMaps.length === 4}
      />
    </S.Container>
  );
};
export default SelectedMaps;
