import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
  width: 300px;
  height: 100%;
  padding: 20px;
  border: 1px solid #dfdfdf;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  transition: 0.8s ease-in;
  border-radius: 4px;
`;

export const PhotoContainer = styled.div`
  width: 100%;
  height: 150px;
  margin: 16px 0;
  background-color: #ccc;
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 21px;
`;

export const Subtitle = styled.p`
  color: #444;
  font-size: 14px;
  margin-bottom: 3px;
`;

export const ContentRow = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #444;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;

  .btnStyle {
    height: 40px;
  }
`;

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
`;
