import styled, { css } from 'styled-components';

export const title = styled.h2`
  font-weight: bold;
  padding-left: 11px;
`;

export const container = styled.div`
  position: relative;
  padding: 23px 10px;
  height: 248px;
  overflow-y: auto;
  width: 31vw;
  display: flex;
  flex-direction: column;
  gap: 2%;
  justify-content: left;

  .container__input {
    flex: 1;

    height: 50px;
    background: transparent;
    border-radius: 8px;
    border: 1px solid !important;
    color: #444444 !important;
    padding: 12px;

    border: 0;
    color: #444444;
    padding: 12px;

    &::placeholder {
      color: #a3a3a3;
    }
  }
  .container__input--invalid {
    flex: 1;

    height: 50px;
    background: transparent;
    border-radius: 8px;
    border: 1px solid red !important;
    color: #444444 !important;
    padding: 12px;

    border: 0;
    color: #444444;
    padding: 12px;

    &::placeholder {
      color: #a3a3a3;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .flex {
    display: flex;
    gap: 11px;
    flex-wrap: wrap;
  }
`;

export const containerbotton = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  gap: 10px;
  .containerbotton__bottonregister {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid;
    background-color: #608929;
    color: white;
    width: 125px;
  }
  .containerbotton__bottonregister--disabled {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid;
    background-color: #dcdcdc;
    color: white;
    width: 125px;
  }
  .containerbotton__bottoncancel {
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: #dcdcdc;
    width: 125px;
  }
`;
