import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content, Title } from './styled';

const Toast = ({ title, content = '' }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Container>
  );
};
Toast.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string
};

export default Toast;
