/* eslint-disable no-irregular-whitespace */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import Checkbox from 'react-custom-checkbox';
import { MdCheck } from 'react-icons/md';
import { toast } from 'react-toastify';
import PuffLoader from 'react-spinners/PuffLoader';
import { useModal } from '../../../../../../../../hooks/Modal';
import { useAuth } from '../../../../../../../../hooks/Auth';
import api from '../../../../../../../../services/api';
import { useMap } from '../../../../../../../../hooks/Map';
import Toast from '../../../../../../../../components/Toast';
import { appConfig } from '../../../../../../../../config';
import { DashboardContext } from '../../../../../../index';
import Button from '../../../../../../../../components/Button';
import * as S from './styled';

const ModalEditRecommendation = () => {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const { selectedFarmId } = useMap();
  const {
    selectedManagement,
    listTypeManager,
    selectTypeCustomerRecommendationList,
    setSelectTypeCustomerRecommendationList,
    selectedTypeAppZoneList,
    setSelectedTypeAppZoneList,
    selectedTypeProductList,
    setSelectedTypeProductList,
    selectedTypeGrowCropList,
    setSelectedTypeGrowCropList,
    selectedTypeVolumeList,
    setSelectedTypeVolumeList,
    selectedTypeAppTimeList,
    setSelectedTypeAppTimeList,
    selectedTypeAppModeList,
    setSelectedTypeAppModeList,
    selectTypeCultureList,
    setSelectTypeCultureList,
    setUpdateScreen,
    selectedCustomerRecommendation
  } = useContext(DashboardContext);

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [selectedAppZone, setSelectedAppZone] = useState('');
  const [selectedCulture, setSelectedCulture] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedGrowCrops, setSelectedGrowCrops] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedDose, setSelectedDose] = useState('');
  const [selectedVolume, setSelectedVolume] = useState('');
  const [selectedAmount, setSelectedAmount] = useState('');
  const [selectedAppTime, setSelectedAppTime] = useState('');
  const [technical, setTechnical] = useState('');
  const [selectedAppMode, setSelectedAppMode] = useState('');
  const [commits, setCommits] = useState('');
  const [customerId, setCustomerId] = useState('');

  const ModalNewRecommendationSchema = Yup.object().shape({
    type_recomm: Yup.string().required('Tipo de recomendação é obrigatório'),
    app_zone: Yup.string().required('Zona de aplicação é obrigatório'),
    culture: Yup.string().required('Cultura é obrigatório'),
    product: Yup.string().required('Produto é obrigatório'),
    status: Yup.string(),
    plant: Yup.string().required('Cultivar é obrigatório'),
    area: Yup.string().required('Área é obrigatório'),
    dose: Yup.string().required('Dose é obrigatório'),
    volume: Yup.string().required('Volume é obrigatório'),
    quantity: Yup.string().required('Quantidade é obrigatório'),
    app_time: Yup.string().required('Horário de aplicação é obrigatório'),
    technical: Yup.string().required('Responsável técnico é obrigatório'),
    app_mode: Yup.string().required('Modo de aplicação é obrigatório'),
    commits: Yup.string()
  });

  const { handleSubmit, register, errors, control, reset } = useForm({
    resolver: yupResolver(ModalNewRecommendationSchema)
  });

  const onSubmit = async (data) => {
    setLoading(true);

    const editedCustomerRecommendation = {
      type_id: data.type_recomm,
      application_zone_id: data.app_zone,
      crop_type_id: data.culture,
      product: data.product,
      status: selectedCustomerRecommendation.status,
      plant: data.plant,
      area: data.area,
      dose: data.dose,
      syrup_volume_id: data.volume,
      quantity: data.quantity,
      time_application: data.app_time,
      technical_manager: data.technical,
      application_mode: data.app_mode,
      note: data.commits,
      farm_id: selectedFarmId,
      created_by: user.user_id
    };

    const params = new URLSearchParams([
      ['farm_id', selectedFarmId],
      ['customer_recommendation_id', selectedCustomerRecommendation.customer_recommendation_id]
    ]);

    try {
      await api.put(
        `${appConfig.apiHosts.prague}/CustomerRecommendation/Update`,
        editedCustomerRecommendation,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast(<Toast title="Recomendação editada com sucesso" />, {
        type: 'success'
      });

      setUpdateScreen(true);

      if (checked) {
        reset();
        setLoading(false);
      } else {
        reset();
        closeModal();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast(<Toast title="Não foi possível editar a recomendação" />, {
        type: 'error'
      });
    }
  };

  const getTypeCustomerRecommendation = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Recomendação (Cliente)')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterRecommendationByActive = response.data.data.values.filter((v) => v.status === true);
      setSelectTypeCustomerRecommendationList(filterRecommendationByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeCulture = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Culturas')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterCultureByActive = response.data.data.values.filter((v) => v.status === true);
      setSelectTypeCultureList(filterCultureByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeProduct = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Produto')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterProductByActive = response.data.data.values.filter((v) => v.status === true);
      setSelectedTypeProductList(filterProductByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeVolume = async () => {
    // const type_id = listTypeManager.filter((v) => v.name === 'Volume')[0].type_Id;

    const params = new URLSearchParams([['type_id', 'ceaef185-a56c-46b9-bc3c-ee50faabe78d']]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterVolumetByActive = response.data.data.values.filter((v) => v.status === true);
      setSelectedTypeVolumeList(filterVolumetByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeAppTime = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Horário')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterAppTimeByActive = response.data.data.values.filter((v) => v.status === true);
      setSelectedTypeAppTimeList(filterAppTimeByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeAppMode = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Modo de Aplicação')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterAppModeByActive = response.data.data.values.filter((v) => v.status === true);
      setSelectedTypeAppModeList(filterAppModeByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeAppZone = async () => {
    const params = new URLSearchParams([
      ['farmId', selectedFarmId],
      ['userId', user.user_id]
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.field}/Field/GetActiveFieldListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedTypeAppZoneList(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeCrop = async () => {
    try {
      const response = await api.get(
        `${appConfig.apiHosts.field}/Crop_Subtype/GetAllActiveCrop_SubtypeList`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedTypeGrowCropList(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteRecommendation = async () => {
    setLoading(true);

    try {
      const params = new URLSearchParams([
        ['farm_id', selectedFarmId],
        ['customer_recommendation_id', selectedCustomerRecommendation.customer_recommendation_id],
        ['status', 'false']
      ]);
      await api.delete(
        `${appConfig.apiHosts.prague}/CustomerRecommendation/Delete`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast(<Toast title="Recomendação excluida com sucesso" />, {
        type: 'success'
      });

      reset();
      setUpdateScreen(true);
      setLoading(false);
      closeModal();
      setLoading(false);
    } catch (error) {
      toast(<Toast title="Não foi possivel excluir a recomendação" />, {
        type: 'error'
      });
      console.error(error);
    }
  };

  useEffect(() => {
    getTypeAppZone();
    getTypeCrop();
    getTypeCulture();
    getTypeCustomerRecommendation();
    getTypeProduct();
    getTypeVolume();
    getTypeAppTime();
    getTypeAppMode();
  }, []);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Editar Recomendação</S.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <S.InputWrapper>
              <S.InputContainer>
                <Controller
                  name="type_recomm"
                  control={control}
                  defaultValue={selectedCustomerRecommendation.type_id}
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Tipo</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {selectTypeCustomerRecommendationList.map((recomm) => (
                          <MenuItem value={recomm.value_Id} key={recomm.value_Id}>{recomm.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.type_recomm?.message && (
                  <S.InputError>{errors?.type_recomm?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="app_zone"
                  control={control}
                  defaultValue={selectedCustomerRecommendation.application_zone_id}
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Zona de Aplicação</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {selectedTypeAppZoneList.map((appZone) => (
                          <MenuItem
                            value={appZone.features[0].properties.field_id}
                            key={appZone.features[0].properties.field_id}
                          >
                            Talhão {appZone.features[0].properties.field_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.app_zone?.message && (
                  <S.InputError>{errors?.app_zone?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer>
                <Controller
                  name="culture"
                  control={control}
                  defaultValue={selectedCustomerRecommendation.crop_type_id}
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Cultura (Previamente Selecionado)</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {selectTypeCultureList.map((culture) => (
                          <MenuItem value={culture.value_Id} key={culture.value_Id}>{culture.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.culture?.message && (
                  <S.InputError>{errors?.culture?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <span>ID (Gerado Automaticamente)</span>
                <Controller
                  name="id"
                  control={control}
                  defaultValue={selectedCustomerRecommendation.customer_recommendation_id}
                  render={({ onChange, value }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={selectedCustomerRecommendation.customer_recommendation_id}
                      disabled
                      style={{ backgroundColor: '#EFEFEF', marginBottom: '7px' }}
                    />
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer>
                <Controller
                  name="product"
                  control={control}
                  defaultValue={selectedCustomerRecommendation.product}
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Produto</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {selectedTypeProductList.map((prod) => (
                          <MenuItem value={prod.value_Id} key={prod.value_Id}>{prod.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.product?.message && (
                  <S.InputError>{errors?.product?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <span>Status</span>
                <Controller
                  name="status"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value="Não Aplicado"
                      disabled
                      style={{ backgroundColor: '#EFEFEF', marginBottom: '7px' }}
                    />
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer>
                <Controller
                  name="plant"
                  control={control}
                  defaultValue={selectedCustomerRecommendation.plant}
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Cultivar</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {selectedTypeGrowCropList.map((plant) => (
                          <MenuItem value={plant.crop_subtype_id} key={plant.crop_subtype_id}>{plant.description}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.plant?.message && (
                  <S.InputError>{errors?.plant?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <span>Área</span>
                <Controller
                  name="area"
                  control={control}
                  defaultValue={selectedCustomerRecommendation.area}
                  render={({ onChange, value }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                      style={{ marginBottom: '7px' }}
                    />
                  )}
                />
                {errors?.area?.message && (
                  <S.InputError>{errors?.area?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer>
                <span>Dose</span>
                <Controller
                  name="dose"
                  control={control}
                  defaultValue={selectedCustomerRecommendation.dose}
                  render={({ onChange, value }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                      style={{ marginBottom: '7px' }}
                    />
                  )}
                />
                {errors?.dose?.message && (
                  <S.InputError>{errors?.dose?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="volume"
                  control={control}
                  defaultValue={selectedCustomerRecommendation.syrup_volume_id}
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Volume de Calda</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {selectedTypeVolumeList.map((v) => (
                          <MenuItem value={v.value_Id} key={v.value_Id}>{v.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.volume?.message && (
                  <S.InputError>{errors?.volume?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer>
                <span>Quantidade</span>
                <Controller
                  name="quantity"
                  control={control}
                  defaultValue={selectedCustomerRecommendation.quantity}
                  render={({ onChange, value }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                      style={{ marginBottom: '7px' }}
                    />
                  )}
                />
                {errors?.quantity?.message && (
                  <S.InputError>{errors?.quantity?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="app_time"
                  control={control}
                  defaultValue={selectedCustomerRecommendation.time_application}
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Horário de Aplicação</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {selectedTypeAppTimeList.map((appTime) => (
                          <MenuItem value={appTime.value_Id} key={appTime.value_Id}>{appTime.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.app_time?.message && (
                  <S.InputError>{errors?.app_time?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer>
                <span>Responsável Técnico</span>
                <Controller
                  name="technical"
                  control={control}
                  defaultValue={selectedCustomerRecommendation.technical_manager}
                  render={({ onChange, value }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                      style={{ marginBottom: '7px' }}
                    />
                  )}
                />
                {errors?.technical?.message && (
                  <S.InputError>{errors?.technical?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="app_mode"
                  control={control}
                  defaultValue={selectedCustomerRecommendation.application_mode}
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Modo de Aplicação</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {selectedTypeAppModeList.map((appMode) => (
                          <MenuItem value={appMode.value_Id} key={appMode.value_Id}>{appMode.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.app_mode?.message && (
                  <S.InputError>{errors?.app_mode?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer style={{ height: '200px' }}>
                <span style={{ marginBottom: '10px' }}>Observações</span>
                <Controller
                  name="commits"
                  control={control}
                  defaultValue={selectedCustomerRecommendation.note}
                  render={({ onChange, value }) => (
                    <S.CommitArea
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>
            <S.ButtonWrapper>
              <Button
                text="Adicionar Outro Produto"
                className="addNewProductBtn"
              />
            </S.ButtonWrapper>

            <S.ButtonWrapper>
              <Button
                text="Cancelar"
                className="cancelBtn"
                onClick={() => {
                  reset();
                  closeModal();
                }}
              />
              <S.CheckboxContainer>
                <Button
                  text={loading ? (
                    <>
                      <PuffLoader
                        color="#79ac34"
                        loading={loading}
                        size={30}
                      />
                    </>
                  ) : 'Salvar'}
                  disabled={loading}
                  className="saveBtn"
                  otype="submit"
                />
              </S.CheckboxContainer>
              <Button
                text={loading ? (
                  <>
                    <PuffLoader
                      color="#79ac34"
                      loading={loading}
                      size={30}
                    />
                  </>
                ) : 'Excluir'}
                disabled={loading}
                className="delBtn"
                onClick={deleteRecommendation}
              />
            </S.ButtonWrapper>
          </S.FormContainer>
        </form>
      </S.Container>
    </Modal>
  );
};

export default ModalEditRecommendation;
