import React, { FC, useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import WebMap from '../../../../../components/WebMap';

const FarmList: FC = () => {
  return (
    <div style={{ display: 'flex', height: '100%', gap: 1 }}>
      <div style={{ flex: 1 }}>
        <Outlet />
      </div>
    </div>
  );
};

export default FarmList;
