/**
 * Converts a date to a string in the format 'YYYY-MM-DDTHH:MM:SSZ'
 * @param date
 * @returns string
 */
export const localDateTime = (date: Date | undefined): string => {
  if (!date) {
    date = new Date();
  }
  const padZero = (num: number) => (num < 10 ? `0${num}` : num);
  const yyyy = date.getFullYear();
  const mm = padZero(date.getMonth() + 1);
  const dd = padZero(date.getDate());
  const hh = padZero(date.getHours());
  const mn = padZero(date.getMinutes());
  const ss = padZero(date.getSeconds());
  return `${yyyy}-${mm}-${dd}T${hh}:${mn}:${ss}Z`;
};
