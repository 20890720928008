import React, { useEffect, useState, useCallback, useContext } from 'react';
import { MdClose, MdDelete } from 'react-icons/md';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import {
  BiUserCircle,
  BiUser,
  BiChat,
  BiSend,
  BiCheckDouble,
  BiMessageDetail
} from 'react-icons/bi';
import SimpleButton from '../SimpleButton';
import { useMap } from '../../hooks/Map';
import { useAuth } from '../../hooks/Auth';
import api from '../../services/api';
import { appConfig } from '../../config';
import * as S from './styled';
import { DashboardContext } from '../../pages/Dashboard';
import chatService from '../../services/chat';

const Chat = () => {
  const {
    showChat,
    setShowChat,
    contactList,
    chatList,
    setChatList,
    setContactList
  } = useMap();
  const {
    dashboardState,
    chatNotifications,
    setNotifications,
    setChatNotifications
  } = useContext(DashboardContext);
  const { user, token, getNotifications } = useAuth();
  const [chatActived, setChatActived] = useState(false);
  const [contactOrChat, setContactOrChat] = useState('ChatList');
  const [chatState, setChatState] = useState('Main');
  const [contactName, setContactName] = useState('');
  const [messageList, setMessageList] = useState([]);
  const [selectedChat, setSelectedChat] = useState('');
  const [message, setMessage] = useState('');
  const [verifyTimeMessage, setVerifyTimeMessage] = useState(
    appConfig.verifyTimeMessageChat
  );

  const handleSendMessage = useCallback(async () => {
    if (!message) return;
    try {
      const sendedMessage = await chatService.sendMessage(
        selectedChat,
        user.user_id,
        message,
        token
      );
      setMessage('');
      setMessageList((oldMessages) =>
        [...oldMessages.reverse(), sendedMessage].reverse()
      );
    } catch (error) {
      console.error('Não foi possivel enviar a mensagem.', error);
    }
  }, [selectedChat, message]);

  const handleEnterKeyPress = (e) => {
    const keyPressed = e.which || e.keyCode;
    if (keyPressed === 13) {
      e.preventDefault();
      if (e.shiftKey) {
        setMessage(`${message}\n`);
        return;
      }
      handleSendMessage();
    }
  };

  const getMessageList = useCallback(async (selectedChatId) => {
    try {
      const messages = await chatService.getChatMessages(
        selectedChatId,
        user.user_id,
        token
      );
      const msgFalse = messages.filter(
        (v) => v.read === false && v.userOrigin_id !== user.user_id
      );
      if (msgFalse.length > 0) {
        msgFalse.forEach((v) => {
          chatService.markAsReadMessage(v.message_Id, token);
        });
      }
      setMessageList(messages.reverse());
    } catch (error) {
      console.error('Erro ao recuperar mensagens.', error);
    }
  }, []);

  const queryClientInterval = useQueryClient();
  const { status, data, isFetching } = useQuery(
    'syncMessage',
    async () => {
      await getMessageList(selectedChat);
    },
    {
      refetchInterval: appConfig.verifyTimeMessageChat
    }
  );

  const addMutation = useMutation(
    (selectedChatId) => getMessageList(selectedChatId),
    {
      onSuccess: () => queryClientInterval.invalidateQueries('syncMessage')
    }
  );

  const clearMutation = useMutation(
    (selectedChatId) => getMessageList(selectedChatId),
    {
      onSuccess: () => queryClientInterval.invalidateQueries('syncMessage')
    }
  );

  const getChatList = useCallback(async () => {
    try {
      const chats = await chatService.getChats(user.user_id, token);
      setChatList(chats);
    } catch (error) {
      console.error('Erro ao recuperar chats.', error);
    }
  }, []);

  const handleCreateChat = async (userDestinyId) => {
    try {
      const createdChat = await chatService.createChat(
        user.user_id,
        userDestinyId,
        token
      );
      getMessageList(createdChat.chat_Id);
      setSelectedChat(createdChat.chat_Id);

      addMutation.mutate(createdChat.chat_Id, {
        onSuccess: () => {
          setSelectedChat(createdChat.chat_Id);
        }
      });
      setChatActived(true);
      getChatList();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleDeleteChat = async (chatId) => {
    try {
      await chatService.deleteChat(user.user_id, chatId, token);
      await getChatList();
    } catch (error) {
      console.error('Não foi possivel excluir chat.', error);
    }
  };

  const handleDeleteMessage = async (message_id, chatId) => {
    try {
      await chatService.deleteMessage(message_id, chatId, token);
      await getMessageList(chatId);
    } catch (error) {
      console.error('Não foi possivel excluir mensagem.', error);
    }
  };

  const getContactList = useCallback(async () => {
    try {
      const contacts = await chatService.getContacts(user.user_id, token);
      setContactList(contacts);
    } catch (error) {
      console.error('Não foi possivel recuperar a lista de contatos', error);
    }
  }, []);

  useEffect(() => {
    getChatList();
    getContactList();
  }, []);

  useEffect(() => {
    getNotifications(user.email, token).then((response) => {
      setNotifications(response.notifications);
      setChatNotifications(response.chatNotifications);
    });
  }, [chatActived, selectedChat]);

  return (
    <>
      {showChat === true ? (
        <S.Container pageTop={dashboardState === 'dashboard@resultsMap'}>
          <S.ChatHeader>
            {!chatActived ? (
              <S.Title>Chat</S.Title>
            ) : (
              <SimpleButton
                text="Voltar"
                onClick={() => {
                  setChatActived(false);
                  setMessageList([]);
                  setSelectedChat('');
                  clearMutation.mutate();
                }}
              />
            )}
            <button
              type="button"
              onClick={() => {
                setShowChat(false);
                setMessageList([]);
                setSelectedChat('');
                clearMutation.mutate();
              }}
            >
              <MdClose color="#000" />
            </button>
          </S.ChatHeader>
          {!chatActived ? (
            <>
              <S.SwitchBtn>
                <S.SwitchBtnItem
                  onClick={() => setContactOrChat('Contacts')}
                  active={contactOrChat === 'Contacts'}
                >
                  <BiUser />
                  Contatos
                </S.SwitchBtnItem>
                <S.SwitchBtnItem
                  onClick={() => setContactOrChat('ChatList')}
                  active={contactOrChat === 'ChatList'}
                >
                  <BiChat />
                  Conversas
                </S.SwitchBtnItem>
              </S.SwitchBtn>
              {contactOrChat === 'Contacts' ? (
                <S.UsersList>
                  {contactList.map((contact) => (
                    <S.UserItemContainer>
                      <S.UserItem
                        key={contact.user_Id}
                        onClick={() => {
                          setMessageList([]);
                          clearMutation.mutate();
                          setContactName(contact.fullname);
                          handleCreateChat(contact.user_Id);
                        }}
                      >
                        <S.UserAvatar>
                          <BiUserCircle size={28} />
                        </S.UserAvatar>
                        <S.UserDetails>
                          <S.UserName>{contact.fullname}</S.UserName>
                        </S.UserDetails>
                      </S.UserItem>
                    </S.UserItemContainer>
                  ))}
                </S.UsersList>
              ) : (
                <S.UsersList>
                  {chatList.map((chat) => (
                    <S.UserItemContainer
                      newMessage={
                        chatNotifications.filter(
                          (v) => v.chat_id === chat.chat_Id
                        ).length > 0
                      }
                    >
                      <S.UserItem
                        key={chat.chat_Id}
                        onClick={() => {
                          setMessageList([]);
                          setSelectedChat(chat.chat_Id);
                          getMessageList(chat.chat_Id);
                          setContactName(
                            contactList.filter(
                              (contact) =>
                                contact.user_Id === chat.userDestiny_id ||
                                contact.user_Id === chat.userOrigin_id
                            )[0]?.fullname
                          );
                          addMutation.mutate(chat.chat_Id, {
                            onSuccess: () => {
                              setSelectedChat(chat.chat_Id);
                            }
                          });
                          setChatActived(true);
                        }}
                      >
                        {chatNotifications.filter(
                          (v) => v.chat_id === chat.chat_Id
                        ).length > 0 && <S.Badge />}
                        <S.UserAvatar>
                          <BiUserCircle size={26} />
                        </S.UserAvatar>
                        <S.UserDetails>
                          <S.UserName>
                            {
                              contactList.filter(
                                (contact) =>
                                  contact.user_Id === chat.userDestiny_id ||
                                  contact.user_Id === chat.userOrigin_id
                              )[0]?.fullname
                            }
                          </S.UserName>
                          {chatNotifications.filter(
                            (v) => v.chat_id === chat.chat_Id
                          ).length > 0 && (
                            <S.LastMessage>
                              <BiMessageDetail />
                              Novas mensagens...
                            </S.LastMessage>
                          )}
                          {/* <S.LastMessage>Gostaria de tirar uma...</S.LastMessage> */}
                        </S.UserDetails>
                      </S.UserItem>
                      <S.IconBtn onClick={() => handleDeleteChat(chat.chat_Id)}>
                        <MdDelete className="deleteIcon" />
                      </S.IconBtn>
                    </S.UserItemContainer>
                  ))}
                </S.UsersList>
              )}
            </>
          ) : (
            <>
              <S.ContactName>
                <BiUserCircle size={26} />
                {contactName}
              </S.ContactName>
              <S.AreaChat>
                {messageList?.map((msg) => (
                  <S.Message
                    sideMessage={msg.userOrigin_id !== user.user_id}
                    viewer={msg.read}
                  >
                    <span>
                      <S.MessageWrapper>
                        {msg.text}
                        {user.user_id === msg.userOrigin_id && (
                          <S.IconBtn
                            onClick={() => {
                              handleDeleteMessage(
                                msg.message_Id,
                                selectedChat,
                                msg.userOrigin_id,
                                msg.chat_id
                              );
                            }}
                          >
                            <MdDelete className="deleteIconMessage" />
                          </S.IconBtn>
                        )}
                      </S.MessageWrapper>
                      <S.DateHourInfoMessageContainer>
                        <S.DateHourInfoMessage>
                          {new Date(msg.created_at).toLocaleDateString()}
                        </S.DateHourInfoMessage>
                        <S.DateHourInfoMessage>
                          {new Date(msg.created_at).toLocaleTimeString()}
                        </S.DateHourInfoMessage>
                        {user.user_id === msg.userOrigin_id && (
                          <BiCheckDouble />
                        )}
                      </S.DateHourInfoMessageContainer>
                    </span>
                  </S.Message>
                ))}
              </S.AreaChat>
              <S.SendMessageContainer>
                <S.SendMessage
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  onKeyPress={handleEnterKeyPress}
                />
                <S.IconBtn onClick={handleSendMessage}>
                  <BiSend size={26} />
                </S.IconBtn>
              </S.SendMessageContainer>
            </>
          )}
        </S.Container>
      ) : null}
    </>
  );
};

export default Chat;
