import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 550px;
  padding: 10px 10px;

  hr {
    border: 1px solid #444;
    margin-bottom: 10px;
  }

  .selectStyle {
    flex: 1;

    span {
      margin-bottom: 8px;
    }
  }

  .uploadFile {
    border: 1px solid #b8b8b8;
    padding: 5px;
    border-radius: 4px;
    background-color: #efefef;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  width: 100%;
  display: flex;
  font-size: 18px;
  margin-bottom: 21px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 140px;

  span {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const InputRecorrenceContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 85px;

  span {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -10px;

  .cancelBtn {
    background-color: #dcdcdc;
    color: #444;
    width: 120px;
    padding: 10px;
    height: 40px;
  }

  .saveBtn {
    padding: 10px;
    height: 40px;
    width: auto;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-bottom: 5px;

  span {
    font-weight: bold;
    font-size: 16px;
    color: #4d4d4d;
  }
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .inputId {
    background-color: #efefef;
  }
`;

export const LabelFileInput = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 5px;
  color: #a8a8a8;
  border-radius: 4px;
  /* box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); */
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  transition: background-color 0.2s;

  background-color: #efefef;
  &:hover {
    background-color: #efefef;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }
`;

export const FileInput = styled.input`
  display: none;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  border: 1px dashed #a8a8a8;
  border-radius: 4px;
  width: 100%;
  height: 100%;

  svg {
    height: 30px;
    width: 30px;
  }
`;

export const ContainerDocument = styled.div`
  width: 100%;
  display: flex;
  flex-direction: Column;
  align-items: center;
  justify-content: center;
`;

export const RemoveBtn = styled.button`
  display: flex;
  background-color: transparent;
  align-items: flex-end;
  border: none;
  margin-top: 10px;
  cursor: pointer;

  span {
    font-size: 12px;
    color: #79ac34;
  }

  svg {
    height: 16px;
    width: 16px;
    color: #79ac34;
  }
`;

const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;

export const DropContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #b8b8b8;
  border-radius: 4px;
  cursor: pointer;
  height: 120px;
  background-color: #efefef;

  transition: height 0.2s ease;

  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};

  .inputMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      height: 40px;
      width: 40px;
      color: #999;
    }
  }
`;

const messageColors = {
  default: '#999',
  error: '#e57878',
  success: '#78e5d5'
};

export const UploadMessage = styled.p`
  display: flex;
  color: ${(props) => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
`;

export const ContainerList = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;

  button {
    position: absolute;
    right: 0;
    border: none;
    background: transparent;
    padding-right: 3px;
    padding-top: 2px;
    cursor: pointer;

    svg {
      color: #fff;
    }
  }

  li {
    display: flex;

    & + li {
      margin-top: 15px;
    }
  }
`;

export const FileInfo = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 5px;

  background-color: RGBA(121, 172, 52, 0.7);

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    strong {
      font-size: 12px;
      color: #111;
    }

    span {
      font-size: 10px;
      color: #111;

      button {
        border: none;
        background: transparent;
        color: #e57878;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
`;

export const Preview = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-bottom: 5px;
`;

export const CommitArea = styled.textarea`
  width: 100%;
  height: 132px;
  border-radius: 4px;
  resize: none;
  font-size: 16px;
  border: 1px solid #b4b4b4;
  color: #444;
  padding: 5px;
  font-family: Arial;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  padding-bottom: 8px;
  padding-right: 15px;
  flex: 1;

  label {
    color: #444;
  }

  input {
    height: 18px;
    width: 18px;
    border: 1px solid #f00;
  }
`;

export const InputError = styled.span`
  color: red !important;
  font-size: 0.6rem !important;
  font-weight: normal !important;
`;
