/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { Controller } from 'react-hook-form';
import { Checkbox, TextField } from '@mui/material';
import * as s from './utils/systemModuleForm.style';
import { useModal } from '../../../../../../../hooks/Modal';
import useSystemModuleForm from './hooks/useSystemModuleForm';
import Toast from '../../../../../../../components/Toast';

interface ProfileFormProps {
  type: 'new' | 'edit';
}

const SystemModuleForm: FC<ProfileFormProps> = (props) => {
  const { modalCreatUserStyles } = useModal();
  const navigate = useNavigate();
  const { type } = props;
  const { form, onSelectActivated, title, mensage, mensageSuccess } =
    useSystemModuleForm(props);

  useEffect(() => {
    if (mensage) {
      toast(
        <Toast
          title={mensageSuccess[type].title}
          content={mensageSuccess[type].content}
        />,
        {
          type: 'success'
        }
      );
    }
  }, [mensage]);
  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen
      style={modalCreatUserStyles}
    >
      <>
        <s.title>{title[type]}</s.title>
        <s.container>
          <div className="flex">
            <Controller
              name="name"
              control={form.control}
              render={({ onBlur, onChange, value }) => (
                <TextField
                  style={{ flex: 3 }}
                  type="text"
                  onBlur={({ target }) => {
                    onBlur();
                  }}
                  onChange={({ target }) => {
                    onChange(target.value);
                  }}
                  value={value}
                  label="Nome"
                />
              )}
            />
            {type !== 'new' && (
              <Controller
                name="active"
                control={form.control}
                render={({ value }) => (
                  <div
                    style={{ flex: 1, textAlign: 'left', alignSelf: 'center' }}
                  >
                    <label>
                      Ativado
                      <Checkbox onChange={onSelectActivated} checked={value} />
                    </label>
                  </div>
                )}
              />
            )}
          </div>
          <s.containerbotton>
            <button
              type="button"
              className="containerbotton__bottoncancel"
              onClick={() => navigate(-1)}
            >
              Cancelar
            </button>
            <button
              type="button"
              className={`containerbotton__bottonregister${
                form.isValid ? '--disabled' : ''
              }`}
              onClick={form.submit}
              disabled={form.isValid}
            >
              Registrar
            </button>
          </s.containerbotton>
        </s.container>
      </>
    </Modal>
  );
};

export default SystemModuleForm;
