import styled from 'styled-components';

export const Container = styled.div``;

export const ContainerClose = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;

  button {
    background: transparent;
    border: none;
  }
`;

export const PagesChoice = styled.div`
  padding: 10px;
`;

export const PaddingChild = styled.div`
  padding-left: 15px;
  margin-bottom: 10px;
`;

export const TitleSelectReport = styled.div`
  padding-left: 10px;
  font-weight: bold;
  margin-top: 10px;
`;
