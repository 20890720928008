/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
} from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import Checkbox from 'react-custom-checkbox';
import { MdCheck } from 'react-icons/md';
import { toast } from 'react-toastify';
import PuffLoader from 'react-spinners/PuffLoader';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import Button from '../../../../../../../../components/Button';
import { useAuth } from '../../../../../../../../hooks/Auth';
import api from '../../../../../../../../services/api';
import { appConfig } from '../../../../../../../../config';
import Toast from '../../../../../../../../components/Toast';
import { useMap } from '../../../../../../../../hooks/Map';
import * as S from './styled';

const ModalNewAlert = () => {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const {
    selectedManagement,
    uploadedFiles: files,
    deleteFile,
    handleUpload,
    setUploadedFiles,
    setUpdateScreen,
    problemList,
    setProblemList,
    selectTypeAlertList,
    setSelectTypeAlertList,
    listTypeManager,
    selectTypeProblemList,
    setSelectTypeProblemList,
    selectTypePeriodList,
    setSelectTypePeriodList
  } = useContext(DashboardContext);
  const { selectedFarmId } = useMap();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [selectedTimeCourse, setSelectedTimeCourse] = useState('');
  const [selectedAppMode, setSelectedAppMode] = useState('');
  const [selectedProblem, setSelectedProblem] = useState('');
  const [region, setRegion] = useState('');
  const [alertDetails, setAlertDetails] = useState('');
  const [problemFilter, setProblemFilter] = useState([]);

  const ModalNewAlertSchema = Yup.object().shape({
    type_alert: Yup.string().required('Tipo é obrigatório'),
    time_course: Yup.string().required('Horário é obrigatório'),
    region: Yup.string().required('Região é obrigatório'),
    problem: Yup.string().required('Problema é obrigatório'),
    commit: Yup.string()

  });

  const { handleSubmit, register, errors, control, reset } = useForm({
    resolver: yupResolver(ModalNewAlertSchema)
  });

  const getProblemFilterList = async () => {
    const filterProblem = [];

    problemList.forEach((v) => {
      if (filterProblem.length <= 0) {
        filterProblem.push(v);
      } else {
        filterProblem.forEach((value) => {
          if (value?.problem_id !== value.problem_id) {
            filterProblem.push(v);
          }
        });
      }
    });

    await setProblemFilter(filterProblem);
  };

  const getTypeAlert = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Alerta')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterAlertByActive = response.data.data.values.filter((v) => v.status === true);
      setSelectTypeAlertList(filterAlertByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeProblem = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Problema')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterProblemByActive = response.data.data.values.filter((v) => v.status === true);
      setSelectTypeProblemList(filterProblemByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypePeriod = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Horário')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterPeriodByActive = response.data.data.values.filter((v) => v.status === true);
      setSelectTypePeriodList(filterPeriodByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const newAlert = {
      alert_type_id: data.type_alert,
      period_id: data.time_course,
      region: data.region,
      problem_id: data.problem,
      note: data.commit,
      farm_id: selectedFarmId,
      created_by: user.user_id
    };

    try {
      await api.post(
        `${appConfig.apiHosts.prague}/Alert/Create`,
        newAlert,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast(<Toast title="Alerta criado com sucesso" />, {
        type: 'success'
      });
      setUpdateScreen(true);

      if (checked) {
        reset();
        setLoading(false);
      } else {
        reset();
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast(<Toast title="Não foi possível criar o alerta" />, {
        type: 'error'
      });
    }
  };

  useEffect(() => {
    getProblemFilterList();
    getTypeAlert();
    getTypeProblem();
    getTypePeriod();
  }, []);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Novo Alerta</S.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <S.InputWrapper>
              <S.InputContainer>
                <Controller
                  name="type_alert"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Tipo</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {selectTypeAlertList.map((alert) => (
                          <MenuItem value={alert.value_Id} key={alert.value_Id}>{alert.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.type_alert?.message && (
                  <S.InputError>{errors?.type_alert?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="time_course"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Horário</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {selectTypePeriodList.map((period) => (
                          <MenuItem value={period.value_Id} key={period.value_Id}>{period.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.time_course?.message && (
                  <S.InputError>{errors?.time_course?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.InputContainer>
                <span>Região</span>
                <Controller
                  name="region"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value, field }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                    // style={{ marginBottom: '7px' }}
                    />
                  )}
                />
                {errors?.region?.message && (
                  <S.InputError>{errors?.region?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="problem"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Problema</span>
                      <Select
                        placeholder="Selecione..."
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {selectTypeProblemList.map((prob) => (
                          <MenuItem value={prob.value_Id} key={prob.value_Id}>{prob.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.problem?.message && (
                  <S.InputError>{errors?.problem?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer style={{ height: '150px' }}>
                <span style={{ marginBottom: '10px' }}>Detalhes do Alertas</span>
                <Controller
                  name="commit"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <S.CommitArea
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>
            <S.ButtonWrapper>
              <Button
                text="Cancelar"
                className="cancelBtn"
                onClick={() => {
                  reset();
                  closeModal();
                }}
              />
              <S.CheckboxContainer>
                <Checkbox
                  checked={checked}
                  onChange={() => {
                    setChecked(!checked);
                  }}
                  icon={(
                    <MdCheck
                      color="#507919"
                    />
                  )}
                  borderColor="#A0BD39"
                  borderRadius={3}
                  borderWidth={1}
                  size={20}
                  style={{ cursor: 'pointer' }}
                />
                <label htmlFor="register">Continuar Cadastrando</label>
              </S.CheckboxContainer>
              <Button
                text={loading ? (
                  <>
                    <PuffLoader
                      color="#79ac34"
                      loading={loading}
                      size={30}
                    />
                  </>
                ) : 'Criar Recomendação'}
                disabled={loading}
                className="saveBtn"
                type="submit"
              />
            </S.ButtonWrapper>
          </S.FormContainer>
        </form>
      </S.Container>
    </Modal>
  );
};

export default ModalNewAlert;
