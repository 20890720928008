/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useRef, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
} from '@mui/material';
import { MdAttachFile, MdCheck, MdClose, MdError } from 'react-icons/md';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import Button from '../../../../../../../../components/Button';
import * as S from './styled';

const ViewRecommendationMade = ({ typeRecommendation, culture, product, dose, growCrops, area, volume }) => {
  const { openModal } = useModal();
  const {
    setItemSelectedVisible,
    setOpendedModal,
    selectedCustomerRecommendation
  } = useContext(DashboardContext);

  const [commits, setCommits] = useState('');
  const [additionalRemarks, setAdditionalRemarks] = useState('');

  return (
    <S.Container>
      <S.CloseButton onClick={() => setItemSelectedVisible('')}>
        <MdClose />
      </S.CloseButton>
      <S.Title>Recomendação</S.Title>
      <S.Content>
        <S.ContentOver>
          <S.ContentLeft>
            <S.Subtitle>Tipo</S.Subtitle>
            <S.ContentRow>{selectedCustomerRecommendation.type_name}</S.ContentRow>
            <S.Subtitle>Cultura</S.Subtitle>
            <S.ContentRow>{selectedCustomerRecommendation.crop_type_name}</S.ContentRow>
            <S.Subtitle>Produto</S.Subtitle>
            <S.ContentRow>{selectedCustomerRecommendation.product_name}</S.ContentRow>
            <S.Subtitle>Dose</S.Subtitle>
            <S.ContentRow>{selectedCustomerRecommendation.dose}</S.ContentRow>
            <S.Subtitle>Cultivar</S.Subtitle>
            <S.ContentRow>{selectedCustomerRecommendation.plant_name}</S.ContentRow>
            <S.Subtitle>Area</S.Subtitle>
            <S.ContentRow>{selectedCustomerRecommendation.area}</S.ContentRow>
            <S.Subtitle>Dose</S.Subtitle>
            <S.ContentRow>{selectedCustomerRecommendation.dose}</S.ContentRow>
            <S.Subtitle>Volume de Calda</S.Subtitle>
            <S.ContentRow>{selectedCustomerRecommendation.syrup_volume_name}</S.ContentRow>
            <S.Subtitle>Quantidade</S.Subtitle>
            <S.ContentRow>{selectedCustomerRecommendation.quantity}</S.ContentRow>
          </S.ContentLeft>
          <S.ContentRight>
            {/* <S.Subtitle>Zona de Aplicação</S.Subtitle>
            <S.PhotoContainer /> */}
            <S.Subtitle>Status</S.Subtitle>
            <S.ContentRow style={{ color: '#95c652' }}>Aplicado</S.ContentRow>
            <S.Subtitle>Horário de Aplicação</S.Subtitle>
            <S.ContentRow>{selectedCustomerRecommendation.time_application_name}</S.ContentRow>
            <S.Subtitle>Responsável Técnico</S.Subtitle>
            <S.ContentRow>{selectedCustomerRecommendation.technical_manager}</S.ContentRow>
            <S.Subtitle>Modo de Aplicação</S.Subtitle>
            <S.ContentRow>{selectedCustomerRecommendation.application_mode_name}</S.ContentRow>
          </S.ContentRight>
        </S.ContentOver>
        <S.CommitContainer>
          <S.Subtitle>Observações</S.Subtitle>
          <p className="commitContent">{selectedCustomerRecommendation.note}</p>
        </S.CommitContainer>
      </S.Content>

      <S.ButtonContainer>
        <Button
          type="button"
          text="Reavaliar Recomendação"
          className="btnStyle"
          onClick={() => {
            setOpendedModal('modalRecommendationRevaluation');
            setItemSelectedVisible('');
            openModal();
          }}
        />
      </S.ButtonContainer>
    </S.Container>
  );
};

export default ViewRecommendationMade;
