/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { OrderedTable } from '../../../../../../../components/OrderedTable';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import { UserFarm } from '../utils/userFarms.types';
import { useUserFarmsListRequest } from './hooks/useUserFarmsListRequest';
import { ResearchField } from '../../../../../../../components/ResearchField';

const UserFarmsList: FC = () => {
  const { request, setFilter, onDeleteUserFarm } = useUserFarmsListRequest();

  const headCells: HeadCell<UserFarm>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Nome'
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email'
    },
    {
      id: 'farms',
      numeric: false,
      disablePadding: false,
      label: 'Fazenda'
    },
    {
      id: 'tel',
      numeric: false,
      disablePadding: false,
      label: 'Telefone'
    },
    {
      id: 'cnpj',
      numeric: false,
      disablePadding: false,
      label: 'CPF/CNPJ'
    }
  ];

  return (
    <>
      <ResearchField onChange={setFilter} />
      <OrderedTable
        rows={request.items}
        HeadCell={headCells}
        // IconCreate={AddIcon}
        title="Usuários x Fazendas"
        routeNameAdd="./new"
        onDelete={onDeleteUserFarm}
        checkBox={false}
        selectInLine
      />
      <Outlet />
    </>
  );
};
export default UserFarmsList;
