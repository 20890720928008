/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { UserProfileList } from './UserProfileList';
import UserProfileForm from './UserProfileForm/UserProfileForm';

const UserProfileRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<UserProfileList />}>
        <Route path="/new" element={<UserProfileForm type="New" />} />
        <Route path="/edit/:id" element={<UserProfileForm type="Edit" />} />
      </Route>
    </Routes>
  );
};

export default UserProfileRouter;
