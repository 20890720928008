import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 20px;
  position: absolute;
  top: 0;
  width: 250px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;

  span {
    font-size: 0.7rem;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  h1 {
    font-size: 1rem;
  }

  > button {
    border: none;
    background: transparent;
  }
`;

export const UploadButton = styled.div`
  margin-top: 10px;
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;

    &:hover {
      background: ${shade(0.2, '#9fbc38')};
    }
    svg {
      margin-right: 10px;
    }
  }
  display: flex;
  width: 100%;
  height: 40px;
  border: none;
  background: #9fbc38;
  border-radius: 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
  align-items: center;
  justify-content: space-around;

  span {
    color: #fff;
    font-size: 0.8rem;
    font-weight: bold;
  }

  input[type='file'] {
    display: none;
  }
`;
