import styled from 'styled-components';

export const Container = styled.div`
  max-width: 300px;
  width: 100%;
  height: 65%;

  h1 {
    margin-left: 5px;
    font-size: 1.1rem;
    margin-bottom: 15px;
  }

  hr {
    border: solid 1px #dcdcdc;
  }

  p {
    margin-top: 25px;
    text-align: left;
    font-size: 12px;
    color: #8d8d8d;
    opacity: 1;
  }

  .backButton {
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    margin-bottom: 25px;
    padding: 0px;
    margin-left: -7px;

    button {
      display: flex;
      align-items: center;
      font-weight: bold;

      > span {
        margin-left: 5px;
      }

      > svg {
        height: 20px;
        width: 20px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .cardContainer {
    height: 90%;
    overflow: auto;
    scrollbar-width: thin;
  }

  .positionButton {
    height: 100%;
  }
`;

export const FarmCardActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;

  button {
    display: flex;
    background: transparent;
    border: none;

    & + button {
      margin-left: 10px;
    }
    > span {
      color: #79ac34;
      font-size: 0.8rem;
    }
    > svg {
      width: 16px;
      height: 16px;
      color: #79ac34;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  height: 100%;
`;

export const Button = styled.button`
  width: 100%;
  height: 45px;
  background-color: #ffffff;
  color: #79ac34;
  font-size: 13px;
  font-weight: bold;
  margin-top: 10px;
  border: 1px solid #79ac34;
  border-radius: 8px;
  opacity: 1;
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  border: 1px solid #efefef;
  border-radius: 6px;
  padding: 15px 10px;
  margin: 16px 0;
`;

export const ColorArea = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  cursor: pointer;
  border: ${(props) =>
    props.selected === props.color ? props.color : '3px solid #FFF'};
`;
