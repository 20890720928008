import React, { useState } from 'react';

import * as S from './styled';

export const Chip = ({
  text,
  selected = true,
  disabled = false,
  onChange,
  onClick
}) => {
  return (
    <S.Container selected={selected}>
      <S.CheckBox
        type="checkbox"
        checked={selected}
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
      />
      <S.Text selected={selected}>{text}</S.Text>
    </S.Container>
  );
};
