import styled from 'styled-components';

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  width: 300px;
`;

export const Text = styled.h1`
  font-size: 1rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const ConfirmationButton = styled.button`
  margin: 20px;
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
`;
