import styled from 'styled-components';

export const Container = styled.div`
  margin: 20px;
  position: absolute;
  top: 0;
  width: 250px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  h1 {
    font-size: 1rem;
  }

  > button {
    border: 0;
    background: transparent;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    justify-content: space-between;
  }
`;
