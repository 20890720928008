/* eslint-disable eqeqeq */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useRef, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import Checkbox from 'react-custom-checkbox';
import { MdCheck } from 'react-icons/md';
import PuffLoader from 'react-spinners/PuffLoader';
import { toast } from 'react-toastify';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { useMap } from '../../../../../../../../hooks/Map';
import api from '../../../../../../../../services/api';
import { appConfig } from '../../../../../../../../config';
import Button from '../../../../../../../../components/Button';
import Toast from '../../../../../../../../components/Toast';
import * as S from './styled';

const ModalNewTask = () => {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { selectedFarmId } = useMap();
  const { token, user, getNotifications } = useAuth();
  const {
    selectedManagement,
    updateScreen,
    setUpdateScreen,
    listTypeManager,
    selectedTypeTaskList,
    setSelectedTypeTaskList,
    selectedTechnician
  } = useContext(DashboardContext);

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const [name, setName] = useState('');
  const [selectedTasks, setSelectedTasks] = useState('');
  const [commits, setCommits] = useState('');
  const [technicianList, setTechnicianList] = useState([]);
  const [routine, setRoutine] = useState([]);
  const [routineList, setRoutineList] = useState([]);

  const ModalTaskSchema = Yup.object().shape({
    tasks: Yup.string().required('Tarefa é obrigatório'),
    commits: Yup.string()
  });

  const { handleSubmit, register, errors, control, reset } = useForm({
    resolver: yupResolver(ModalTaskSchema)
  });

  const clearFields = () => {
    setName('');
    setCommits('');
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const newTask = {
      routine_type_id: routine.routine_type_id,
      encompass_id: routine.encompass_id,
      status: routine.status,
      title: routine.title,
      date: routine.date,
      period: routine.period,
      tecnician_id: selectedTechnician.technician_id,
      instant_monitoring: true,
      note: commits
    };

    const params = new URLSearchParams([
      ['farm_id', selectedFarmId],
      ['routine_id', routine.routine_id]
    ]);

    try {
      await api.put(
        `${appConfig.apiHosts.prague}/Routine/Update`,
        newTask,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast(<Toast title="Tarefa delegada com sucesso" />, {
        type: 'success'
      });

      setUpdateScreen(true);

      if (checked) {
        reset();
        clearFields();
        setLoading(false);
      } else {
        reset();
        clearFields();
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast(<Toast title="Não foi possível delegar tarefa" />, {
        type: 'error'
      });
    }
  };

  const getTypeTask = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Tarefa')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedTypeTaskList(response.data.data.values);
    } catch (error) {
      console.error(error);
    }
  };

  const getRoutineList = async () => {
    try {
      const type_id = listTypeManager.filter((v) => v.name === 'Status')[0].type_Id;
      const paramsType = new URLSearchParams([
        ['type_id', type_id]
      ]);
      const responseValue = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params: paramsType,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const statusFilter = responseValue.data.data.values.filter((v) => v.name === 'Ativo')[0].value_Id;

      const params = new URLSearchParams([
        ['status', statusFilter],
        ['page', 1],
        ['filter', '']
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Routine/ListByStatus`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const routineListFilter = response.data.data.routines.filter((v) => v.routine_type_name !== 'Monitoramento Instantâneo');
      setRoutineList(routineListFilter);
    } catch (error) {
      console.error(error);
    }
  };

  const getRoutineById = async (id) => {
    const params = new URLSearchParams([['routine_id', id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Routine/ListByRoutineId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterNote = response?.data.data.note;

      if (filterNote) {
        setCommits(filterNote);
      }

      setRoutine(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTypeTask();
    // getTechnicianList();
    getRoutineList();
  }, []);

  useEffect(() => {
    if (updateScreen) {
      getTypeTask();
      // getTechnicianList();
      getRoutineList();
    }
  }, [updateScreen]);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Nova Tarefa</S.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <S.InputWrapper>
              <S.InputContainer>
                <FormControl className="selectStyle">
                  <span>Técnico</span>
                  <TextField
                    name="tech"
                    id="tech"
                    fullWidth
                    size="small"
                    disabled
                    value={selectedTechnician.name}
                  />
                </FormControl>
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="tasks"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Tarefas</span>
                      <Select
                        placeholder="Selecione..."
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                          getRoutineById(e.target.value);
                        }}
                        size="small"
                      >
                        {routineList.map((rout) => (
                          <MenuItem value={rout.routine_id} key={rout.routine_id}>{rout.title}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.tasks?.message && (
                  <S.InputError>{errors?.tasks?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.InputContainer style={{ height: '200px' }}>
                <span style={{ marginBottom: '10px' }}>Observações</span>
                <Controller
                  name="commits"
                  control={control}
                  defaultValue={commits}
                  render={({ onChange, value }) => (
                    <S.CommitArea
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>
            <S.ButtonWrapper>
              <Button
                text="Cancelar"
                className="cancelBtn"
                onClick={() => {
                  clearFields();
                  closeModal();
                }}
              />
              <S.CheckboxContainer>
                <Checkbox
                  checked={checked}
                  icon={(
                    <MdCheck
                      color="#507919"
                    />
                  )}
                  borderColor="#A0BD39"
                  borderRadius={3}
                  borderWidth={1}
                  size={20}
                  style={{ cursor: 'pointer' }}
                  onChange={() => {
                    setChecked(!checked);
                  }}
                />
                <label htmlFor="register">Continuar Cadastrando</label>
              </S.CheckboxContainer>
              <Button
                text={loading ? (
                  <>
                    <PuffLoader
                      color="#79ac34"
                      loading={loading}
                      size={30}
                    />
                  </>
                ) : 'Delegar Tarefa'}
                disabled={loading}
                className="saveBtn"
                type="submit"
              />
            </S.ButtonWrapper>
          </S.FormContainer>
        </form>
      </S.Container>
    </Modal>
  );
};

export default ModalNewTask;
