import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  background: rgba(255, 0, 0, 0.5);
  padding: 0px 10px;
  top: 0;
  left: 0;

  span {
    color: #fff;
    font-weight: bold;
    font-size: 0.7rem;
  }
`;
