/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';
import { useModal } from '../../../../../../hooks/Modal';
import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';
import * as S from './styled';
import { CreatedReportsContext } from '../CreatedReports';
import { SelectIndex } from '../../../../../../components/SelectIndex';
import { useMap } from '../../../../../../hooks/Map';
import { useAuth } from '../../../../../../hooks/Auth';
import { ReportContext } from '../..';

const RequestOwnerNameModal = ({ ...props }) => {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { setCroquis, selectedFarmGeoJson } = useMap();
  const { user } = useAuth();
  const [ownerName, setOwnerName] = useState(user.fullname);
  const [farmName, setFarmName] = useState<string>(
    selectedFarmGeoJson?.features[0]?.properties.farm_name
  );
  const [data, hour] = new Date().toISOString().split('T');
  const [harvestName, setHarvestName] = useState<string>('');
  const [localName, setLocalName] = useState<string>('');
  const [date, setDate] = useState(data);
  const [responsible, setResponsible] = useState<string>();
  const [pages, setPages] = useState({
    all: true,
    frontCover: true,
    backCover: true,
    summary: true,
    propertyPresentation: true,
    propertyLocation: true,
    propertySketch: true,
    subdivisionsPerformedInTheFields: true,
    distributionOfSamplingPointsInEachSubdivision: true,
    graphicalPresentationOfResults: true,
    bibliographicReference: true,
    numericalPresentationOfResults: true
    // finalCover: true
  });
  const { exportReportPages } = useContext<any>(ReportContext);
  useEffect(() => {
    if (
      !pages.propertyLocation &&
      !pages.propertySketch &&
      !pages.subdivisionsPerformedInTheFields &&
      !pages.distributionOfSamplingPointsInEachSubdivision
    ) {
      setPages({ ...pages, propertyPresentation: false });
    }
  }, [
    pages.propertyLocation,
    pages.propertySketch,
    pages.subdivisionsPerformedInTheFields,
    pages.distributionOfSamplingPointsInEachSubdivision
  ]);
  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      {...props}
    >
      <S.Container>
        <S.ContainerClose>
          <button type="button" onClick={() => closeModal()}>
            <MdClose />
          </button>
        </S.ContainerClose>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
          <div style={{ flex: 1 }}>
            <S.TitleSelectReport>Nome do Proprietario:</S.TitleSelectReport>
            <Input
              placeholder="Nome do Proprietario"
              type="text"
              value={ownerName}
              onChange={(e) => setOwnerName(e.target.value)}
            />
          </div>
          <div style={{ flex: 1 }}>
            <S.TitleSelectReport>Nome daFazenda:</S.TitleSelectReport>
            <Input
              placeholder="Nome da Fazenda"
              type="text"
              value={farmName}
              onChange={(e) => setFarmName(e.target.value)}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
          <div style={{ flex: 1 }}>
            <S.TitleSelectReport>Nome da Safra:</S.TitleSelectReport>
            <Input
              placeholder="Nome da Safra"
              type="text"
              value={harvestName}
              onChange={(e) => setHarvestName(e.target.value)}
            />
          </div>
          <div style={{ flex: 1 }}>
            <S.TitleSelectReport>Local:</S.TitleSelectReport>
            <Input
              placeholder="Local"
              type="text"
              value={localName}
              onChange={(e) => setLocalName(e.target.value)}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
          <div style={{ flex: 1 }}>
            <S.TitleSelectReport>Data:</S.TitleSelectReport>
            <Input
              placeholder="Data"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div style={{ flex: 1 }}>
            <S.TitleSelectReport>Responsavel:</S.TitleSelectReport>
            <Input
              placeholder="Responsavel"
              type="text"
              value={responsible}
              onChange={(e) => setResponsible(e.target.value)}
            />
          </div>
        </div>
        <S.TitleSelectReport>
          Escolha o que deseja que seja impresso no relatório:
        </S.TitleSelectReport>
        <S.PagesChoice>
          <SelectIndex
            text="Tudo"
            selected={pages.all}
            onClick={() => {
              setPages({
                all: !pages.all,
                frontCover: !pages.all,
                backCover: !pages.all,
                summary: !pages.all,
                propertyPresentation: !pages.all,
                propertyLocation: !pages.all,
                propertySketch: !pages.all,
                subdivisionsPerformedInTheFields: !pages.all,
                distributionOfSamplingPointsInEachSubdivision: !pages.all,
                graphicalPresentationOfResults: !pages.all,
                bibliographicReference: !pages.all,
                numericalPresentationOfResults: !pages.all
                // finalCover: !pages.all
              });
            }}
          />
          <S.PaddingChild>
            <SelectIndex
              text="Capa"
              selected={pages.frontCover}
              onClick={() => {
                setPages({ ...pages, frontCover: !pages.frontCover });
              }}
            />
            <SelectIndex
              text="Contracapa"
              selected={pages.backCover}
              onClick={() => {
                setPages({ ...pages, backCover: !pages.backCover });
              }}
            />
            <SelectIndex
              text="Sumário"
              selected={pages.summary}
              onClick={() => {
                setPages({ ...pages, summary: !pages.summary });
              }}
            />
          </S.PaddingChild>
          <S.PaddingChild>
            <SelectIndex
              text="Apresentação da Propriedade"
              selected={pages.propertyPresentation}
              onClick={() => {
                setPages({
                  ...pages,
                  propertyPresentation: !pages.propertyPresentation,
                  propertyLocation: !pages.propertyPresentation,
                  propertySketch: !pages.propertyPresentation,
                  subdivisionsPerformedInTheFields: !pages.propertyPresentation,
                  distributionOfSamplingPointsInEachSubdivision:
                    !pages.propertyPresentation
                });
              }}
            />
          </S.PaddingChild>
          <S.PaddingChild>
            <S.PaddingChild>
              <SelectIndex
                text="Localização da Propriedade"
                selected={pages.propertyLocation}
                onClick={() => {
                  setPages({
                    ...pages,
                    propertyLocation: !pages.propertyLocation,
                    propertyPresentation: true
                  });
                }}
              />
              <SelectIndex
                text="Croqui da Propriedade"
                selected={pages.propertySketch}
                onClick={() => {
                  setPages({
                    ...pages,
                    propertySketch: !pages.propertySketch,
                    propertyPresentation: true
                  });
                }}
              />
              <SelectIndex
                text="Subdivisões realizadas nos campos"
                selected={pages.subdivisionsPerformedInTheFields}
                onClick={() => {
                  setPages({
                    ...pages,
                    subdivisionsPerformedInTheFields:
                      !pages.subdivisionsPerformedInTheFields,
                    propertyPresentation: true
                  });
                }}
              />
              <SelectIndex
                text="Distribuição dos pontos de  amostragem em cada subdivisão"
                selected={pages.distributionOfSamplingPointsInEachSubdivision}
                onClick={() => {
                  setPages({
                    ...pages,
                    distributionOfSamplingPointsInEachSubdivision:
                      !pages.distributionOfSamplingPointsInEachSubdivision,
                    propertyPresentation: true
                  });
                }}
              />
            </S.PaddingChild>
          </S.PaddingChild>
          <S.PaddingChild>
            <SelectIndex
              text="Apresentação gráfica dos resultados das análises de solo quanto aos principais parâmetros avaliados"
              selected={pages.graphicalPresentationOfResults}
              onClick={() => {
                setPages({
                  ...pages,
                  graphicalPresentationOfResults:
                    !pages.graphicalPresentationOfResults
                });
              }}
            />
            <SelectIndex
              text="Referência Bibliográfica"
              selected={pages.bibliographicReference}
              onClick={() => {
                setPages({
                  ...pages,
                  bibliographicReference: !pages.bibliographicReference
                });
              }}
            />
            <SelectIndex
              text="Apresentação Numérica dos Resultados"
              selected={pages.numericalPresentationOfResults}
              onClick={() => {
                setPages({
                  ...pages,
                  numericalPresentationOfResults:
                    !pages.numericalPresentationOfResults
                });
              }}
            />
          </S.PaddingChild>
          {/* <S.PaddingChild>
            <SelectIndex
              text="Final"
              selected={pages.finalCover}
              onClick={() => {
                setPages({ ...pages, finalCover: !pages.finalCover });
              }}
            />
          </S.PaddingChild> */}
        </S.PagesChoice>
        <Button
          text="Imprimir"
          onClick={() => {
            setCroquis([]);
            closeModal();
            exportReportPages(
              ownerName,
              pages,
              farmName,
              harvestName,
              localName,
              data,
              responsible
            );
          }}
        />
      </S.Container>
    </Modal>
  );
};

export default RequestOwnerNameModal;
