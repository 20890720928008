/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { SubModulo } from '../../utils/moduleProfile.types';
import ProfileApi from '../../../../../../../../APi/FarmApi/ProfileApi';

export const useProfileSubModuleRequest = () => {
  const { token, user } = useAuth();
  const systemPerfilApi = useMemo(() => new ProfileApi(token), []);
  const [filter, setFilter] = useState<string>('');

  function ProfileSubModuleMap(moduleProfile: any, index): SubModulo {
    return {
      idEndity: moduleProfile.id,
      id: index + 1,
      name: moduleProfile.name,
      submodulo: moduleProfile.nameaggregate,
      idaggregate: moduleProfile.idaggregate
    };
  }

  const ProfileSubModuleQuery = useQuery({
    queryKey: [systemPerfilApi.ListaAtrelarPerfilSubModulo, { filter }],
    queryFn: async () =>
      systemPerfilApi.ListarAtrelarPerfilSubmodulo(
        filter,
        '',
        user.organizacao_id
      )
  });

  return {
    request: {
      items:
        ProfileSubModuleQuery.data?.data.data.map(ProfileSubModuleMap) ?? []
    },
    setFilter
  };
};
