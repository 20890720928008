/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
  createRef
} from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  OutlinedInput,
  Selected,
  TextareaAutosize
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { AddCircle } from '@mui/icons-material';
import { CompactPicker } from 'react-color';
import Checkbox from 'react-custom-checkbox';
import { useDropzone } from 'react-dropzone';
// import { useFiles } from "../../context/files";
import PuffLoader from 'react-spinners/PuffLoader';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  FaUpload,
  FaTrash,
  FaCheckCircle
} from 'react-icons/fa';
import {
  MdOutlineCloudUpload,
  MdMoodBad
} from 'react-icons/md';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
import filesize from 'filesize';
import { toast } from 'react-toastify';
import { Carousel } from 'react-responsive-carousel';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import storage from '../../../../../../../../services/firebase';
import Button from '../../../../../../../../components/Button';
import { useMap } from '../../../../../../../../hooks/Map';
import { appConfig } from '../../../../../../../../config';
import { useAuth } from '../../../../../../../../hooks/Auth';
import api from '../../../../../../../../services/api';
import Toast from '../../../../../../../../components/Toast';
import * as S from './styled';

const ModalAddNewWeed = () => {
  const inputFileRef = useRef(null);
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const {
    selectedManagement,
    uploadedFiles: files,
    deleteFile,
    handleUpload,
    setUploadedFiles,
    setUpdateScreen,
    itemTypeSelected,
    selectTypeCultureList,
    setSelectTypeCultureList
  } = useContext(DashboardContext);

  const { selectedFarmId } = useMap();
  const theme = useTheme();

  const uploadFile = useRef(null);

  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('nao');
  const [checked, setChecked] = useState(true);

  const [infestationLevel, setInfestationLevel] = useState([]);
  const [titleInfestationLevel, setTitleInfestationLevel] = useState('');
  const [level, setLevel] = useState('');
  const [valueInfestation, setValueInfestation] = useState('');
  const [colorPickerInfestationLevelVisible, setColorPickerInfestationLevelVisible] = useState(false);
  const [colorInfestationLevel, setColorInfestationLevel] = useState('');

  const [phenologicalStage, setPhenologicalStage] = useState([]);
  const [valuePhenologicalStage, setValuePhenologicalStage] = useState('');
  const [phenologicalStageTitle, setPhenologicalStageTitle] = useState('');

  const [selectedPhoto, setSelectedPhoto] = useState(0);
  const [mainPhoto, setMainPhoto] = useState('');
  const [weedClassificationList, setWeedClassificationList] = useState([]);

  const ModalAddNewWeedSchema = Yup.object().shape({
    weedClassification: Yup.string().nullable(),
    name: Yup.string().required('Nome é obrigatório'),
    scientific_name: Yup.string().nullable(),
    description: Yup.string().nullable(),
    symptoms: Yup.string().nullable(),
    damage: Yup.string().nullable(),
    unit_measurement: Yup.string().nullable()
  });

  const { handleSubmit, register, errors, control, reset } = useForm({
    resolver: yupResolver(ModalAddNewWeedSchema)
  });

  const handleUploadFile = async (e) => {
    try {
      const fileData = await storage.ref(`/images/${e.name}`).put(e.file);
      const imageSrc = { id: e.id, url: await fileData.ref.getDownloadURL() };

      files.forEach((file) => URL.revokeObjectURL(file.preview));

      return imageSrc;
    } catch (error) {
      console.error(error);
    }
  };

  const FileList = () => {
    if (!files.length) {
      return (
        <span>
          <MdMoodBad
            style={{ marginLeft: '45%', marginTop: 10 }}
            size={24}
            color="#d5d2d2"
          />
        </span>
      );
    }
  };

  const onDrop = useCallback(
    (file) => {
      handleUpload(file);
    },
    [handleUpload]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: ['image/jpeg', 'image/pjpeg', 'image/png', 'image/gif'],
      // maxFiles: 1,
      multiple: true,
      onDrop
    });

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return (
        <S.UploadMessage>Clique ou arraste uma imagem aqui</S.UploadMessage>
      );
    }

    if (isDragReject) {
      return (
        <S.UploadMessage type="error">
          Tipo de arquivo não suportado
        </S.UploadMessage>
      );
    }

    return (
      <S.UploadMessage type="success">Solte as imagens aqui</S.UploadMessage>
    );
  }, [isDragActive, isDragReject]);

  const addInfestationLevelInTable = () => {
    if (level !== '' && valueInfestation !== '' && colorInfestationLevel !== '') {
      infestationLevel.push({
        color: colorInfestationLevel,
        level,
        value: valueInfestation
      });

      setLevel('');
      setValueInfestation('');
      setColorInfestationLevel('');
    } else {
      return toast(
        <Toast title="Preencha os campos para serem adicionados na tabela" />,
        {
          type: 'warning'
        }
      );
    }
  };

  const addPhenologicalStagelInTable = () => {
    if (valuePhenologicalStage !== '') {
      phenologicalStage.push({
        phenological_stage: valuePhenologicalStage
      });
      setValuePhenologicalStage('');
    } else {
      return toast(
        <Toast title="Preencha os campos para serem adicionados na tabela" />,
        {
          type: 'warning'
        }
      );
    }
  };

  const removePhenologicalStageSizeRowByIndex = (index) => {
    setPhenologicalStage(phenologicalStage.filter((v, i) => i !== index));
  };

  const getWeedClassification = async () => {
    const params = new URLSearchParams([
      ['type_id', '850929e9-4192-4314-95ae-a6a1327ce67e']
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterClassByActive = response.data.data.values.filter(
        (v) => v.status === true
      );
      setWeedClassificationList(filterClassByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeBackgroundInfestationLevel = (color) => {
    setColorInfestationLevel(color.hex);
    setColorPickerInfestationLevelVisible(false);
  };

  const createWeed = async (data) => {
    try {
      const response = await api.post(
        `${appConfig.apiHosts.manager}/Weed/Create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast(<Toast title="Erva daninha criada com sucesso" />, {
        type: 'success'
      });

      return response.data.data;
    } catch (error) {
      console.error(error);
      toast(<Toast title="Não foi possível criar uma nova erva daninha" />, {
        type: 'error'
      });
      return false;
    }
  };

  const createWeedImages = async (data) => {
    try {
      await api.post(
        `${appConfig.apiHosts.manager}/WeedImage/Create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const clearFields = () => {
    setUploadedFiles([]);
    setInfestationLevel([]);
    setTitleInfestationLevel('');
    setLevel([]);
    setValueInfestation('');
    setColorInfestationLevel('');
    setPhenologicalStageTitle('');
    setValuePhenologicalStage('');
    setPhenologicalStage([]);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const imageUrl = [];
    const selectedCropsId = [];
    const selectedPlantsId = [];
    const photoUrl = [];

    if (files.length > 0 && mainPhoto == '') {
      setLoading(false);
      return toast(
        <Toast
          title="Não foi possível criar uma nova erva daninha"
          content="Torne uma foto como principal"
        />,
        { type: 'warning' }
      );
    }

    try {
      let newWeed = {
        name: data.name,
        description: data.description || null,
        measurement_unit: data.unit_measurement || null,
        scientific_name: data.scientific_name || null,
        weed_classification_id: data.weedClassification || null,
        quantity: selectedValue === 'sim',
        phenological_stage: phenologicalStage,
        weed_phenological_stage_title: phenologicalStageTitle,
        created_by: user.user_id,
        weed_infestation_level: infestationLevel || null,
        weed_infestation_level_title: titleInfestationLevel || null
      };

      if (files.length > 0) {
        await files.forEach(async (file) => {
          const url = handleUploadFile(file);
          url
            .then((value) => {
              imageUrl.push(value);
            })
            .finally(async () => {
              if (imageUrl.length === files.length) {
                const mainPhot = imageUrl.filter((v) => v.id === mainPhoto);

                newWeed = {
                  ...newWeed,
                  main_photo: mainPhot[0].url || null
                };

                if (imageUrl.length === files.length) {
                  const responseCreateWeed = await createWeed(newWeed);

                  imageUrl.forEach((value) => {
                    photoUrl.push(value.url);
                  });

                  if (responseCreateWeed) {
                    const weedImage = {
                      url: photoUrl,
                      weed_id: responseCreateWeed.weed.weed_id,
                      created_by: user.user_id
                    };

                    await createWeedImages(weedImage);
                  }

                  setLoading(false);
                  clearFields();
                  reset();
                  setUpdateScreen(true);
                  closeModal();
                }
              }
            });
        });
      } else {
        const responseCreatePrague = await createWeed(newWeed);

        if (responseCreatePrague) {
          setLoading(false);
          clearFields();
          reset();
          setUpdateScreen(true);
          closeModal();
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast(<Toast title="Não foi possível criar uma nova erva daninha" />, {
        type: 'error'
      });
    }
  };

  function getStyles(name, pName, pTheme) {
    return {
      fontWeight:
        pName.indexOf(name) === -1
          ? pTheme.typography.fontWeightRegular
          : pTheme.typography.fontWeightMedium
    };
  }

  const removeSizeRowByIndex = (index) => {
    setInfestationLevel(infestationLevel.filter((v, i) => i !== index));
  };

  useEffect(() => {
    getWeedClassification();
  }, []);

  useEffect(() => {
    setUploadedFiles([]);
  }, [closeModal]);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      shouldCloseOnOverlayClick={false}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Adicionar Erva Daninha</S.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <S.InputWrapper style={{ margin: 0 }}>
              <S.InputContainer>
                <Controller
                  name="weedClassification"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span style={{ marginBottom: '8px' }}>
                        Classificação de Daninha
                      </span>
                      <Select
                        placeholder="Selecione..."
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {weedClassificationList.map((pest) => (
                          <MenuItem value={pest.value_Id} key={pest.value_Id}>
                            {pest.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </S.InputContainer>
              <S.InputContainer>
                <span>Nome Comum</span>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextField
                      inputProps={{ maxlength: 42 }}
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.name?.message && (
                  <S.InputError>{errors?.name?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer>
                <span>Nome Científico</span>
                <Controller
                  name="scientific_name"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextField
                      inputProps={{ maxlength: 70 }}
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </S.InputContainer>
              <S.InputContainer>
                <span>Unidade de Medida</span>
                <Controller
                  name="unit_measurement"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.TextAreaContainer>
                <span className="inputContainer__title">Descrição</span>
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextareaAutosize
                      value={value}
                      onChange={onChange}
                      className="textArea"
                    />
                  )}
                />
                {errors?.description?.message && (
                  <S.InputError>{errors?.description?.message}</S.InputError>
                )}
              </S.TextAreaContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.InputContainer>
                <S.CheckboxContainer>
                  <span style={{ marginTop: '15px' }}>Quantidade</span>
                </S.CheckboxContainer>
                {checked && (
                  <S.CheckboxContainer>
                    <label
                      className="radioContainer"
                      style={{
                        color: selectedValue === 'sim' ? '#79AC34' : ''
                      }}
                    >
                      Sim
                      <input
                        type="checkbox"
                        checked={selectedValue === 'sim'}
                        onClick={(e) => {
                          e.target.checked
                            ? setSelectedValue('sim')
                            : setSelectedValue('nao');
                        }}
                      />
                      <span className="checkmark" />
                    </label>

                    <label
                      className="radioContainer"
                      style={{
                        color: selectedValue === 'nao' ? '#79AC34' : ''
                      }}
                    >
                      Não
                      <input
                        type="checkbox"
                        checked={selectedValue === 'nao'}
                        onClick={(e) => {
                          e.target.checked
                            ? setSelectedValue('nao')
                            : setSelectedValue('sim');
                        }}
                      />
                      <span className="checkmark" />
                    </label>
                  </S.CheckboxContainer>
                )}
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.InputContainer style={{ height: 'auto' }}>
                <span style={{ paddingBottom: '10px' }}>Foto</span>
                <S.PhotoContainer>
                  {files.length <= 0 ? (
                    <S.DropContainer {...getRootProps()}>
                      <input accept="image/*" {...getInputProps()} />
                      <div className="inputMessage">
                        <MdOutlineCloudUpload />
                        {renderDragMessage()}
                      </div>
                    </S.DropContainer>
                  ) : (
                    <>
                      <Carousel
                        width={500}
                        // swipeScrollTolerance={5}
                        showThumbs={false}
                        selectedItem={selectedPhoto}
                        showStatus={false}
                        onChange={(e) => setSelectedPhoto(e)}
                      >
                        {files.map((uploadedFile) => (
                          <div className="viewPhoto">
                            <img
                              alt={uploadedFile.name}
                              src={uploadedFile.preview}
                              height="280"
                              style={{ border: '2px solid #79ac34' }}
                            />
                          </div>
                        ))}
                      </Carousel>
                      <S.ThumbsContainer>
                        {files.map((uploadedFile, index) => (
                          <S.ThumbItemContainer>
                            <S.ThumbItem>
                              <S.ThumbButtonImage
                                type="button"
                                className="thumbsContainer__button"
                                onClick={() => setSelectedPhoto(index)}
                                style={{
                                  border:
                                    selectedPhoto === index
                                      ? '5px solid #79ac34'
                                      : '2px solid #47aa12'
                                }}
                              >
                                <img
                                  alt={uploadedFile.name}
                                  src={uploadedFile.preview}
                                  style={{
                                    border:
                                      selectedPhoto === index
                                        ? '2px solid #FFF'
                                        : 'none'
                                  }}
                                />
                              </S.ThumbButtonImage>
                              <S.ThumbIconsContainer>
                                <S.ThumbButtonIcon
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (uploadedFile.id === mainPhoto) {
                                      setMainPhoto('');
                                    }
                                    deleteFile(uploadedFile.id);
                                    setSelectedPhoto(0);
                                  }}
                                >
                                  <FaTrash size={14} color="#AF0000" />
                                </S.ThumbButtonIcon>
                                {uploadedFile.id === mainPhoto && (
                                  <FaCheckCircle color="#79ac34" size={15} />
                                )}
                              </S.ThumbIconsContainer>
                            </S.ThumbItem>
                            {selectedPhoto === index &&
                              uploadedFile.id !== mainPhoto && (
                                <Button
                                  text="Tornar Principal"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setMainPhoto(uploadedFile.id);
                                  }}
                                  className="thumbsContainer__buttonMain"
                                />
                              )}
                          </S.ThumbItemContainer>
                        ))}
                      </S.ThumbsContainer>
                    </>
                  )}
                </S.PhotoContainer>
                <S.UploadFileContainer>
                  {files.length > 0 && (
                    <S.AddFiles {...getRootProps()}>
                      <input accept="image/*" {...getInputProps()} />
                      <S.UploadFileText>
                        <FaUpload size={13} />
                        <span className="uploadfile__span">
                          Adicionar fotos
                        </span>
                      </S.UploadFileText>
                    </S.AddFiles>
                  )}
                </S.UploadFileContainer>
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper style={{ alignItems: 'start' }}>
              <S.InputContainer style={{ height: 'auto' }}>
                <span style={{ paddingBottom: '10px', marginTop: '15px' }}>
                  Nível de Infestação
                </span>
                <TableContainer
                  style={{
                    overflow: 'auto',
                    width: '100%'
                  }}
                >
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: '#e4e4e4'
                          }}
                          colSpan={4}
                        >
                          <input
                            className="table__input--title"
                            type="text"
                            placeholder="Digite o título da tabela"
                            value={titleInfestationLevel}
                            onChange={(e) => setTitleInfestationLevel(e.target.value)}
                          // eslint-disable-next-line react/jsx-closing-bracket-location
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {infestationLevel?.map((value, index) => (
                        <TableRow>
                          <TableCell align="center">
                            <div
                              style={{
                                height: '18px',
                                width: '18px',
                                background: value.color ? value.color : ''
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {value.level}
                          </TableCell>
                          <TableCell align="center">{value.value}</TableCell>
                          <TableCell align="center">
                            <S.DeleteRowButton type="button" onClick={() => removeSizeRowByIndex(index)}>
                              <FaTrash size={14} color="#AF0000" />
                            </S.DeleteRowButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <S.InputWrapper>
                  <S.ColorBtnContainer background={colorInfestationLevel}>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setColorPickerInfestationLevelVisible(!colorPickerInfestationLevelVisible);
                      }}
                    >
                      Cor
                    </button>
                    {colorPickerInfestationLevelVisible && (
                      <S.ColorPicker>
                        <CompactPicker
                          color={colorInfestationLevel}
                          onChange={(e) => handleChangeBackgroundInfestationLevel(e)}
                        />
                      </S.ColorPicker>
                    )}
                  </S.ColorBtnContainer>
                  <TextField
                    label="Nível"
                    name="level"
                    id="level"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}
                  />
                  <TextField
                    label="Valor"
                    name="size"
                    id="size"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={valueInfestation}
                    onChange={(e) => setValueInfestation(e.target.value)}
                  />
                  <button
                    type="button"
                    className="table__addButton"
                    onClick={(e) => {
                      e.preventDefault();
                      addInfestationLevelInTable();
                    }}
                  >
                    <AddCircle />
                  </button>
                </S.InputWrapper>
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper style={{ alignItems: 'start' }}>
              <S.InputContainer style={{ height: 'auto' }}>
                <span style={{ paddingBottom: '10px', marginTop: '15px' }}>
                  Estágio Fenológico
                </span>
                <TableContainer
                  style={{
                    overflow: 'auto',
                    width: '100%'
                  }}
                >
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: '#e4e4e4'
                          }}
                          colSpan={2}
                        >
                          <input
                            className="table__input--title"
                            type="text"
                            placeholder="Digite o título da tabela"
                            value={phenologicalStageTitle}
                            onChange={(e) => setPhenologicalStageTitle(e.target.value)}
                          // eslint-disable-next-line react/jsx-closing-bracket-location
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {phenologicalStage?.map((value, index) => (
                        <TableRow>
                          <TableCell align="center">{value.phenological_stage}</TableCell>
                          <TableCell align="center">
                            <S.DeleteRowButton type="button" onClick={() => removePhenologicalStageSizeRowByIndex(index)}>
                              <FaTrash size={14} color="#AF0000" />
                            </S.DeleteRowButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <S.InputWrapper>
                  <TextField
                    label="Valor"
                    name="size"
                    id="size"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={valuePhenologicalStage}
                    onChange={(e) => setValuePhenologicalStage(e.target.value)}
                  />
                  <button
                    type="button"
                    className="table__addButton"
                    onClick={(e) => {
                      e.preventDefault();
                      addPhenologicalStagelInTable();
                    }}
                  >
                    <AddCircle />
                  </button>
                </S.InputWrapper>
              </S.InputContainer>
            </S.InputWrapper>
            <S.ButtonWrapper>
              <Button
                text="Cancelar"
                className="cancelBtn"
                onClick={() => {
                  clearFields();
                  closeModal();
                }}
              />
              <Button
                text={
                  loading ? (
                    <>
                      <PuffLoader color="#79ac34" loading={loading} size={30} />
                    </>
                  ) : (
                    'Salvar'
                  )
                }
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                type="submit"
                disabled={loading}
                className="saveBtn"
              />
            </S.ButtonWrapper>
          </S.FormContainer>
        </form>
      </S.Container>
    </Modal>
  );
};

export default ModalAddNewWeed;
