import React from 'react';
import * as S from './styled';

const CardSelect = ({
  text,
  selected = false,
  disabled = false,
  onChange,
  onClick
}) => {
  return (
    <S.Container
      selected={selected}
      onClick={onClick}
      disabled={disabled}
      onChange={onChange}
    >
      {text}
    </S.Container>
  );
};

export default CardSelect;
