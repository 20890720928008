export const GetVerticalPositionLegendByParam = (param) => {
  switch (param) {
    case 'Argila':
      return 650;
    case 'MO':
      return 580;
    case 'MO x Arg':
      return 660;
    case 'PH':
      return 650;
    case 'Ca':
      return 610;
    case 'Mg':
      return 670;
    case 'K':
      return 650;
    case 'AI':
      return 690;
    case 'CTC':
      return 650;
    case 'V%':
      return 610;
    case 'Sal. AI':
      return 650;
    case 'P':
      return 660;
    case 'S':
      return 610;
    default:
      return 610;
  }
};

export const GetVerticalPositionLegendByParamLandscape = (param) => {
  switch (param) {
    case 'Argila':
      return 480;
    case 'MO':
      return 435;
    case 'MO x Arg':
      return 490;
    case 'PH':
      return 485;
    case 'Ca':
      return 455;
    case 'Mg':
      return 495;
    case 'K':
      return 480;
    case 'AI':
      return 505;
    case 'CTC':
      return 480;
    case 'V%':
      return 460;
    case 'Sal. AI':
      return 485;
    case 'P':
      return 510;
    case 'S':
      return 460;
    default:
      return 400;
  }
};
