import styled, { css } from 'styled-components';

export const Container = styled.div`
  input[type='date'] {
    width: 100%;
    height: 50px;
    border: solid 1px #dcdcdc;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
  }

  > span {
    font-weight: bold;
  }
`;

export const SubdivisionsContainer = styled.div`
  overflow: auto;
  max-height: 180px;
  margin-bottom: 10px;
  padding: 5px;
`;
export const Subdivision = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px #e0e0e0;
  margin-bottom: 10px;
  border-radius: 8px;
`;
export const SubdivisionTitle = styled.span`
  font-size: 0.9rem;
`;
export const SubdivisionBody = styled.div``;
export const SubdivisionInformations = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SubdivisionInformation = styled.span`
  font-size: 0.7rem;
`;
export const SubdivisionCheckbox = styled.input``;

// export const SubdivisionHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   button {
//     background: transparent;
//     border: 0;
//   }
// `;
// export const SubdivisionInfo = styled.div`
//   display: flex;
//   flex-direction: column;

//   span {
//     font-size: 0.8rem;
//   }
// `;
// export const SubdivisionState = styled.div`
//   background: #a8a8a8;
//   ${(props) =>
//     props.status === 'Coletado' &&
//     css`
//       background: #62904e;
//     `}
//   ${(props) =>
//     props.status === 'Em Coleta' &&
//     css`
//       background: #d8c267;
//     `}
//   ${(props) =>
//     props.status === 'Divergente' &&
//     css`
//       background: #d04040;
//     `}
//   padding: 2px;
//   border-radius: 4px;
//   display: flex;
//   justify-content: center;
//   margin: 3px;
//   color: #fff;
//   font-weight: bold;
//   font-size: 0.8rem;
//   max-width: 160px;
//   margin: 10px auto;
// `;

export const QrCodeContainer = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const QrCodeImage = styled.div``;

export const PrintQrCodeButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #62904e;
`;
