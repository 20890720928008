/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { MdFilterList, MdSearch } from 'react-icons/md';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Pagination
} from '@mui/material';
import PuffLoader from 'react-spinners/PuffLoader';
import { DashboardContext } from '../../../../index';
import * as S from './styled';
import { useModal } from '../../../../../../hooks/Modal';
import Button from '../../../../../../components/Button';
import { useAuth } from '../../../../../../hooks/Auth';
import api from '../../../../../../services/api';
import { appConfig } from '../../../../../../config';
import { useMap } from '../../../../../../hooks/Map';
import ViewRecommendation from './components/ViewRecommendation';
import ViewRecommendationMade from './components/ViewRecommendationMade';

const Recommendations = () => {
  const { token, user, getNotifications, role } = useAuth();
  const { selectedFarmId } = useMap();

  const [perfil, modulo, subModulo] = role.split('|');
  const {
    setOpendedModal,
    setItemSelectedVisible,
    setUpdateScreen,
    setSelectedCustomerRecommendation,
    updateScreen,
    listTypeManager
  } = useContext(DashboardContext);
  const { closeModal, openModal } = useModal();

  const [loading, setLoading] = useState(false);
  const [nextRecommendationList, setNextRecommendationList] = useState([]);
  const [doneRecommendationList, setDoneRecommendationList] = useState([]);
  const [totalPagesNextRecommendation, setTotalPagesNextRecommendation] = useState();
  const [totalPagesDoneRecommendation, setTotalPagesDoneRecommendation] = useState();
  const [searchField, setSearchField] = useState('');

  const getCustomerNextRecommendationList = async (pageNumber = 1) => {
    setLoading(true);

    const type_id = listTypeManager.filter((v) => v.name === 'Status')[0].type_Id;
    const paramsType = new URLSearchParams([
      ['type_id', type_id]
    ]);
    const responseValue = await api.get(
      `${appConfig.apiHosts.manager}/Value/List`,
      {
        params: paramsType,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    const statusFilter = responseValue.data.data.values.filter((v) => v.name === 'Em andamento')[0].value_Id;

    const params = new URLSearchParams([
      ['status', statusFilter],
      ['page', pageNumber],
      ['filter', searchField || '']
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/CustomerRecommendation/ListByStatusId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setNextRecommendationList(response.data.data.customerRecommendations);
      setTotalPagesNextRecommendation(response.data.data.pagination.totalPages);

      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getCustomerDoneRecommendationList = async (pageNumber = 1) => {
    setLoading(true);

    const type_id = listTypeManager.filter((v) => v.name === 'Status')[0].type_Id;
    const paramsType = new URLSearchParams([
      ['type_id', type_id]
    ]);
    const responseValue = await api.get(
      `${appConfig.apiHosts.manager}/Value/List`,
      {
        params: paramsType,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    const statusFilter = responseValue.data.data.values.filter((v) => v.name === 'Concluído')[0].value_Id;

    const params = new URLSearchParams([
      ['status', statusFilter],
      ['page', pageNumber],
      ['filter', searchField || '']
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/CustomerRecommendation/ListByStatusId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setDoneRecommendationList(response.data.data.customerRecommendations);
      setTotalPagesDoneRecommendation(response.data.data.pagination.totalPages);

      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getCustomerRecommendationById = async (id, modal) => {
    const params = new URLSearchParams([['customer_recommendation_id', id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/CustomerRecommendation/ListByCustomerRecommendationId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setSelectedCustomerRecommendation(response.data.data);

      if (modal === 'concluido') {
        setItemSelectedVisible('viewRecommendationMade');
      } else {
        setItemSelectedVisible('viewRecommendation');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatDateExibition = (date) => {
    const data = new Date(date);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const handleChangePageNextRecommendation = (event, value) => {
    getCustomerNextRecommendationList(value);
  };

  const handleChangePageDoneRecommendation = (event, value) => {
    getCustomerDoneRecommendationList(value);
  };

  useEffect(() => {
    getCustomerNextRecommendationList();
    getCustomerDoneRecommendationList();
    setSelectedCustomerRecommendation([]);
  }, []);

  useEffect(() => {
    if (updateScreen) {
      getCustomerNextRecommendationList();
      getCustomerDoneRecommendationList();
    }
  }, [updateScreen]);

  useEffect(() => {
    if (searchField === '') {
      getCustomerNextRecommendationList();
      getCustomerDoneRecommendationList();
    }
  }, [searchField]);

  return (
    <S.Container>
      <S.SearchAreaContainer>
        <S.SearchArea>
          <MdSearch
            size={28}
            onClick={() => {
              getCustomerNextRecommendationList();
              getCustomerDoneRecommendationList();
            }}
          />
          <S.SearchInput
            type="text"
            placeholder="Pesquisa por pragas, talhões, etc..."
            value={searchField}
            onChange={(e) => {
              setSearchField(e.target.value);
            }}
          />
        </S.SearchArea>
        {/* <S.FilterWrapper>
          <MdFilterList />
          <span>Filtrar Por...</span>
        </S.FilterWrapper> */}
        {subModulo.includes('NovaRecomendacao') && (
          <S.AddBtnContainer>
            <Button
              type="button"
              text="Nova Recomendação"
              className="addBtn"
              onClick={() => {
                setOpendedModal('modalNewRecommendation');
                openModal();
              }}
            />
          </S.AddBtnContainer>
        )}
      </S.SearchAreaContainer>
      <S.AddBtnContainer>
        <S.SubTitle>Recomendações Em andamento / Próximas</S.SubTitle>
      </S.AddBtnContainer>
      <TableContainer
        className="tabela"
        style={{
          borderRadius: '8px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <>
            <PuffLoader
              color="#79ac34"
              loading={loading}
              size={30}
            />
          </>
        ) : (
          <Table size="small" cellspacing="0">
            <TableHead>
              <TableRow style={{ backgroundColor: '#EFEFEF', fontWeight: 'bold' }}>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Data</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Status</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Local</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Responsável Técnico</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nextRecommendationList.map((row) => (
                <TableRow
                  onClick={() => {
                    getCustomerRecommendationById(row.customer_recommendation_id);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell align="center">
                    {formatDateExibition(row.created_at)}
                  </TableCell>
                  <TableCell align="center">
                    {row.status_name}
                  </TableCell>
                  <TableCell align="center">
                    {row.application_zone_name}
                  </TableCell>
                  <TableCell align="center">
                    {row.technical_manager}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Stack spacing={1} style={{ marginBottom: '10px' }}>
        <Pagination
          count={totalPagesNextRecommendation}
          variant="outlined"
          shape="rounded"
          size="small"
          onChange={handleChangePageNextRecommendation}
        />
      </Stack>
      <S.AddBtnContainer>
        <S.SubTitle>Recomendações Realizadas</S.SubTitle>
      </S.AddBtnContainer>
      <TableContainer
        className="tabela"
        style={{
          borderRadius: '8px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <>
            <PuffLoader
              color="#79ac34"
              loading={loading}
              size={30}
            />
          </>
        ) : (
          <Table size="small" cellspacing="0">
            <TableHead>
              <TableRow style={{ backgroundColor: '#EFEFEF', fontWeight: 'bold' }}>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Data</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Status</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Local</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Responsável Técnico</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {doneRecommendationList.map((row) => (
                <TableRow
                  onClick={() => {
                    getCustomerRecommendationById(row.customer_recommendation_id, 'concluido');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell align="center">
                    {formatDateExibition(row.created_at)}
                  </TableCell>
                  <TableCell align="center">
                    {row.status_name}
                  </TableCell>
                  <TableCell align="center">
                    {row.application_zone_name}
                  </TableCell>
                  <TableCell align="center">
                    {row.technical_manager}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Stack spacing={1}>
        <Pagination
          count={totalPagesDoneRecommendation}
          variant="outlined"
          shape="rounded"
          size="small"
          onChange={handleChangePageDoneRecommendation}
        />
      </Stack>
    </S.Container>

  );
};

export default Recommendations;
