import axios from 'axios';

const token = localStorage.getItem('@FundacaoMT:token');

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_MS_FARM
});

api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export default api;
