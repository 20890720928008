/* eslint-disable indent */
/* eslint-disable no-multi-assign */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
import { kml } from '@tmcw/togeojson';
import GeoJSON from 'ol/format/GeoJSON';
import * as turf from '@turf/turf';
import JSZip from 'jszip';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { IoFlask } from 'react-icons/io5';
import { MdCloudUpload, MdDelete, MdKeyboardArrowRight, MdModeEdit, MdCheck, MdGetApp } from 'react-icons/md';
import { saveAs } from 'file-saver';
import PuffLoader from 'react-spinners/PuffLoader';
import { toast } from 'react-toastify';
import * as shp from 'shpjs';
import Checkbox from 'react-custom-checkbox';
import * as shpwrite from 'shp-write';
import Button from '../../../../components/Button';
import Toast from '../../../../components/Toast';
import InstructionsToast from '../../../../components/Toast/Instructions';
import { calculateArea } from '../../../../helpers/calculateArea';
import { parseCoordinatesMultiPolygonMultiFeaturesTo3857, parseMultiPolygonToPolygon, parseMultiPolygonToPolygonShapefile } from '../../../../helpers/parseCoordinates';
import { useAuth } from '../../../../hooks/Auth';
import { useMap } from '../../../../hooks/Map';
import { useModal } from '../../../../hooks/Modal';
import api from '../../../../services/api';
import { DashboardContext } from '../../index';
import ConfirmDeleteModal from './components/ConfirmDialog';
import * as S from './styled';
import { appConfig } from '../../../../config';

export const HarvestMapContext = createContext({});

const HarvestMap = () => {
  const [deleteSubdivision, setDeleteSubdivision] = useState();
  const [farmNotSelected, setFarmNotSelected] = useState(true);
  const {
    setDashboardState,
    subdivisions,
    setSubdivisions,
    setSubdivisionsMappings,
    setSelectedSubdivisions,
    setSelectedHarvestMap,
    setResultVisible,
    setShowResult,
    fields,
    setFields
  } = useContext(DashboardContext);
  const { token, user } = useAuth();
  const {
    farm,
    selectedFarmId,
    setListSubdivisions,
    createSubdivision,
    removeSubdivisionGeometries,
    renderSubdivision,
    modifySubdivision,
    renderSubdivisions,
    renderFieldMappings,
    removeMappingGeometries,
    renderDepthPoints,
    setSubdivision,
    createWalkSubdivisions,
    renderCollectionPoints,
    removePointGeometries,
    removeRecommendationZone,
    setEditSub,
    removeFieldGeometries,
    renderFieldsNoLabel,
    setSelectedFields,
    removeEvent,
    removeColoredRecommendationZone,
    renderSubdivisionFromShapefile,
    selectedFields,
    setListFields,
    listFields,
    selectedFarmGeoJson,
    centerDepthPointSubdivision,
    updateSizeMap,
    ownerName,
    farmName,
    setTableResults,
    tableResults
  } = useMap();
  const [finnalyReq, setFinnalyReq] = useState(false);
  const { openModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [depthPoints, setDepthPoints] = useState([]);
  const [fieldListArr, setFieldListArr] = useState([]);
  const [generatedSubdivisions, setGeneratedSubdivisions] = useState([]);
  const [selectedSubdivisionsId, setSelectedSubdivisionsId] = useState([]);
  const [chekedAll, setCheckedAll] = useState(false);

  const getPoints = async () => {
    const params = new URLSearchParams([['farmId', selectedFarmId],
    ['userId', user.user_id]]);
    try {
      const response = await api.get(`${appConfig.apiHosts.field}/DataCollectionPoint/GetActiveDataCollectionPointListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      const cPoints = await response.data.data.filter((value) =>
        value.features[0].properties.type === 'REFERENCIA_COLETA');
      const dPoints = await response.data.data.filter((value) =>
        value.features[0].properties.type === 'PROFUNDIDADE');
      setDepthPoints(dPoints);
      renderDepthPoints(dPoints);
      renderCollectionPoints(cPoints);
    } catch (error) {
      console.error(error);
    }
  };

  const getSubdivisions = async () => {
    try {
      if (!selectedFarmId) {
        return toast(
          <Toast
            title="Por favor selecione uma fazenda"
            content="Para que as subdivisões sejam carregadas é necessário selecionar uma fazenda"
          />,
          { type: 'warning' }
        );
      }
      setLoading(true);
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSubdivisions(response?.data.data);
      renderSubdivisions(response.data.data);
      setListSubdivisions(response.data.data);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getSubdivisionsWithDepthPoints = async () => {
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSubdivisions(response.data.data);
      renderSubdivisions(response.data.data);
      setListSubdivisions(response.data.data);
      getPoints();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getSubdivisionsMappings = async () => {
    try {
      if (!selectedFarmId) {
        return setFarmNotSelected(true);
      }
      setFarmNotSelected(false);
      const params = new URLSearchParams([['farmId', selectedFarmId], ['userId', user.user_id]]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/FieldMapping/GetActiveFieldMappingListByFarmIdWhereSubdivisionIdIsNotNull`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // eslint-disable-next-line no-console
      setSubdivisionsMappings(response.data.data);
      renderFieldMappings(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleDeleteSubdivision = async (id) => {
    const params = new URLSearchParams([
      ['subdivisionId', id],
      ['status', false]
    ]);
    try {
      await api.delete(`${appConfig.apiHosts.field}/Subdivision/DeleteSubdivison`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      // const newSubdivisions = subdivisions.filter(
      //   (value) => value.features[0]?.properties.farm_id !== id
      // );
      // setSubdivisions(newSubdivisions);
      removeSubdivisionGeometries();
      removeMappingGeometries();
      removePointGeometries();
      getSubdivisions();
      getPoints();
      toast(<Toast title="Subdivisão removida com sucesso" />, {
        type: 'success'
      });
    } catch (error) {
      toast(
        <Toast
          title="Não foi possível remover a subdivisão."
          content="Tente novamente"
        />,
        { type: 'error' }
      );
    }
  };

  const getFields = async () => {
    try {
      setFields([]);
      setListFields([]);
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Field/GetActiveFieldListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFields(response.data.data);
      setListFields(response.data.data);
      setSelectedFields(response.data.data);
      renderFieldsNoLabel(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setFields([]);
    }
  };

  const sendRelatory = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const params = new URLSearchParams([['farmId', selectedFarmId]]);
      const response = await api.post(`${appConfig.apiHosts.report}/Report/Import`,
        formData,
        {
          params,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
          }
        });
      toast(<Toast title="Relátorio importado com sucesso" />, { type: 'success' });
    } catch (error) {
      toast(
        <Toast
          title="Relátorio não foi importado"
          content="Verifique se o arquivo está no padrão correto"
        />,
        { type: 'error' }
      );
    }
  };

  const onMultiRegisterSubdivision = async (geojson) => {
    const fieldsIntersect = listFields
      .map((f) => {
        if (
          turf.booleanIntersects(
            f.features[0].geometry,
            geojson.features[0].geometry
          )
        ) {
          return f.features[0].properties.field_id;
        }
        return '';
      })
      .filter((value) => value !== '' ?? value);
    geojson.features[0].properties = {
      ...geojson.features[0].properties,
      farm_id: selectedFarmId,
      status_id: 'f85e20f6-3ab4-481e-9a0e-e53c581f2bdf',
      fieldList: fieldsIntersect,
      description: geojson.features[0].properties.Sub_Talh,
      created_by: user.user_id
    };

    if (geojson.features[0].geometry.type !== 'MultiPolygon') {
      geojson.features[0].geometry.type = 'MultiPolygon';
      geojson.features[0].geometry.coordinates[0] = [
        ...geojson.features[0].geometry.coordinates
      ];
    }

    try {
      const response = await api.post(
        `${appConfig.apiHosts.field}/Subdivision/PostSubdivision`,
        geojson,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const depthP = geojson;
      if (
        geojson.features[0].properties.Profundida === null ||
        geojson.features[0].properties.Profundida === '' ||
        !geojson.features[0].properties.Profundida
      ) {
        depthP.features[0].properties.Profundida = '0-10-20';
      }
      // if (geojson.features[0].properties.Profundida.split('-').length > 2) {
      //   const indexOf = geojson.features[0].properties.Profundida.lastIndexOf('-');
      //   const result = geojson.features[0].properties.Profundida.substring(indexOf - 2);
      //   geojson.features[0].properties.Profundida = result;
      // }
      if (
        geojson.features[0].properties.Profundida !== '0-10' &&
        geojson.features[0].properties.Profundida !== '0-20' &&
        geojson.features[0].properties.Profundida !== '0-20-40' &&
        geojson.features[0].properties.Profundida !== '0-10-20' &&
        geojson.features[0].properties.Profundida !== '0-10-20-40' &&
        geojson.features[0].properties.Profundida !== '0-10-20-30-40' &&
        geojson.features[0].properties.Profundida !== '0-10-20-30-40-60'
      ) {
        depthP.features[0].properties.Profundida = '0-10-20';
      }

      depthP.features[0].geometry = {
        type: 'Point',
        coordinates: centerDepthPointSubdivision(geojson)
      };

      depthP.features[0].properties = {
        farm_id: selectedFarmId,
        subdivision_id:
          response.data.data.features[0].properties.subdivision_id,
        depth: geojson.features[0].properties.Profundida,
        type: 'PROFUNDIDADE',
        description: '',
        automatically_generated: false,
        created_by: user.user_id
      };

      await api.post(
        `${appConfig.apiHosts.field}/DataCollectionPoint/PostDataCollectionPoint`,
        depthP,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSubdivisions((oldSubdivisions) => [...oldSubdivisions, response.data.data]);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const registerShapefile = (geojson) => {
    if (listFields.length < 1) {
      return toast(
        <Toast
          title="Arquivo não importado"
          content="Não há talhões cadastrados"
        />,
        {
          type: 'error'
        }
      );
    } if (geojson.features.length > 1 && listFields.length > 0) {
      toast(
        <Toast
          title="O shapefile contém multiplas geometrias "
          content={`as geometrias importadas serão registradas de forma numerada e sequencial, quantidade de geometrias: ${geojson.features.length}`}
        />,
        {
          type: 'default'
        }
      );
      geojson.features.forEach((value, index) => {
        const geoJson = {
          type: 'FeatureCollection',
          features: [value]
        };
        generatedSubdivisions.push(
          parseCoordinatesMultiPolygonMultiFeaturesTo3857(geoJson)
        );
      });

      generatedSubdivisions?.map(async (geojsonSub, index) => {
        if (
          !turf.booleanIntersects(
            selectedFarmGeoJson.features[0].geometry,
            geojsonSub.features[0].geometry
          )
        ) {
          return toast(
            <Toast
              title="Subdivisão não importada"
              content="A geometria não está dentro dos limites da fazenda"
            />,
            {
              type: 'error'
            }
          );
        }
        await onMultiRegisterSubdivision(geojsonSub);
        getSubdivisions();
      });
      getSubdivisionsWithDepthPoints();
    }
    if (geojson.features[0].geometry.type !== 'Polygon') {
      return toast(
        <Toast
          title="O shapefile contém uma geometria diferente do tipo aceito"
          content={`Tipo Aceito: 'Polygon' - Tipo Enviado: '${geojson.features[0].geometry.type}'`}
        />,
        {
          type: 'error'
        }
      );
    }
    getSubdivisionsWithDepthPoints();
  };

  function convertToLayerZip(buffer) {
    shp(buffer).then((geojson) => registerShapefile(geojson));
  }

  const convertToLayerKml = (buffer) => {
    const blob = new Blob([buffer], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    fetch(url)
      .then((response) => response.text())
      .then((xml) => {
        const kmlReadResult = kml(
          new DOMParser().parseFromString(xml, 'text/xml')
        );
        registerShapefile(kmlReadResult);
      });
  };

  const convertToLayerKmz = async (buffer) => {
    const blob = new Blob([buffer], { type: 'application/zip' });
    const zip = new JSZip();
    await zip.loadAsync(blob).then(async (zipped) => {
      const file = await zipped.file('doc.kml').async('blob');
      const url = URL.createObjectURL(file);
      fetch(url)
        .then((response) => response.text())
        .then((xml) => {
          const kmlReadResult = kml(
            new DOMParser().parseFromString(xml, 'text/xml')
          );
          registerShapefile(kmlReadResult);
        });
    });
  };

  const handleUploadFile = (file, type) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState !== 2 || reader.error) {
        return null;
      }
      if (
        type === 'application/zip' ||
        type === 'zip' ||
        type === 'application/x-zip-compressed'
      ) {
        return convertToLayerZip(reader.result);
      }
      if (type === 'application/vnd.google-earth.kml+xml' || type === 'kml') {
        return convertToLayerKml(reader.result);
      }
      if (type === 'application/vnd.google-earth.kmz' || type === 'kmz') {
        return convertToLayerKmz(reader.result);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    removeFieldGeometries();
    renderFieldsNoLabel(fields);
    getSubdivisionsMappings();
    setResultVisible(false);
    setShowResult(false);
    removeRecommendationZone();
    setEditSub('');
    removeColoredRecommendationZone();
    setSelectedSubdivisionsId([]);
  }, []);

  const insertAllSelected = () => {
    setSelectedSubdivisionsId(subdivisions.map((value) =>
      value.features[0].properties.subdivision_id
    )
    );
  };

  const deleteMultipleSubdivisions = async () => {
    try {
      selectedSubdivisionsId.map(async (v) => {
        const params = new URLSearchParams([
          ['subdivisionId', v],
          ['status', false]
        ]);
        await api.delete(`${appConfig.apiHosts.field}/Subdivision/DeleteSubdivison`, {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        removeSubdivisionGeometries();
        removeMappingGeometries();
        removePointGeometries();
        getSubdivisions();
        getPoints();
        setCheckedAll(false);
        setSelectedSubdivisionsId([]);
      });
      toast(<Toast title="Remoção feita com sucesso" />, {
        type: 'success'
      });
    } catch {
      toast(
        <Toast
          title="Não foi possível remover a subdivisão."
          content="Tente novamente"
        />,
        { type: 'error' }
      );
    }
  };

  const getParamsFarm = async () => {
    try {
      const params = new URLSearchParams([['farmId', selectedFarmId]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/Report/GetReportListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setTableResults(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const exportSubdivisionsShapefile = async () => {
    const selectedSubddivisions = subdivisions.filter((s) => selectedSubdivisionsId.includes(s.features[0].properties.subdivision_id));
    // const convertedSubdivisions = selectedSubddivisions.map((sub) => parseMultiPolygonToPolygonShapefile(sub));
    const geoJsonSubdivisions = {
      type: 'FeatureCollection',
      name: farmName.replaceAll(' ', '_').replace('-', ''), // nome do arquivo
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:OGC:1.3:CRS84'
        }
      },
      features: selectedSubddivisions.map((ss) => ss.features[0])
    };
    geoJsonSubdivisions.features.map((value, i) => {
      const index = value.properties.description.indexOf('-');
      const field = value.properties.description.substring(index, -10);

      let paramsFarm = '';

      tableResults.forEach((v) => {
        if (v.original === value.properties.description) {
          paramsFarm = v;
        }
      });
      const coordFinder = /\(\s?(\S+)\s+(\S+)\s?\)/g;
      const matches = coordFinder.exec(value.properties.geometry_center);
      const latitude = matches[1];
      const longitude = matches[2];

      return value.properties = {
        FID_1: i + 1,
        Cliente: ownerName,
        Fazenda: farmName,
        Talhao: paramsFarm.campo ? paramsFarm.campo : null,
        Sub_Talhao: value.properties.description,
        pH_Cacl2_1: paramsFarm.pH_Cacl2 ?? null,
        pH_Cacl2_2: null,
        P_1: paramsFarm.p ?? null,
        P_2: null,
        K_1: paramsFarm.k ?? null,
        K_2: null,
        Ca_1: paramsFarm.ca ?? null,
        Ca_2: null,
        Mg_1: paramsFarm.mg ?? null,
        Mg_2: null,
        Al_1: paramsFarm.al ?? null,
        Al_2: null,
        MO_1: paramsFarm.mo ?? null,
        MO_2: null,
        MO_NIVEIS: null,
        Argila_1: paramsFarm.argila ?? null,
        Argila_2: null,
        CTC_1: paramsFarm.ctc ?? null,
        CTC_2: null,
        V_1: paramsFarm.v ?? null,
        V_2: null,
        M_1: paramsFarm.mo ?? null,
        M_2: null,
        S_1: paramsFarm.s ? paramsFarm.s : null,
        S_2: null,
        Hectares: value.properties.geometry_ha,
        COD: null,
        FID_2: null,
        INDICE: null,
        NOME: value.properties.description,
        COMENTARIO: paramsFarm.observacao ? paramsFarm.observacao : null,
        ICONE: null,
        ALTITUDE: null,
        DATA: paramsFarm.data ? paramsFarm.data : null,
        ANGULO: null,
        DISPLAY: null,
        ZONA: null,
        LATITUDE: latitude,
        LONGITUDE: longitude,
        Distance: null,
        Prof: paramsFarm.prof ? paramsFarm.prof : null
      };
    });

    const options = {
      folder: 'data',
      types: {
        polygon: 'subdivisions',
        multipolygon: 'multi'
      }
    };

    const content = await shpwrite.zip(geoJsonSubdivisions, options);
    saveAs(`data:application/zip;base64,${content}`, `${farmName}_subdivisions.zip`);
    // location.href = `data:application/zip;base64,${content}`;
  };

  useEffect(() => {
    getFields();
    getSubdivisions();
    getPoints();
    updateSizeMap();
    getParamsFarm();
  }, []);

  return (
    <HarvestMapContext.Provider value={{ handleDeleteSubdivision, getSubdivisions }}>
      <S.Container>
        <h1>Mapa de Coleta</h1>
        <hr />
        <S.Content>
          <S.Title>Subdivisão</S.Title>
          <S.SubTitle>
            Desenhe as subdivisões de cada talhão no mapa ao lado.
          </S.SubTitle>
          <S.ExportSubdivisionShapeFileButton
            onClick={() => exportSubdivisionsShapefile()}
            disabled={selectedSubdivisionsId
              .length === 0}
          >
            <MdGetApp
              size={20}
              color={selectedSubdivisionsId
                .length === 0 ? '#b4b4b4' : '#A0BD39'}
            />
            Exportar Shapefile de Subdivisões Selecionadas
          </S.ExportSubdivisionShapeFileButton>
          <S.DelContainer>
            {subdivisions?.length > 0 && (
              <S.DeleteSubdivisionSelectedButton
                onClick={() => {
                  if (selectedSubdivisionsId.length > 0) {
                    return setSelectedSubdivisionsId([]),
                      setCheckedAll(false);
                  }
                  insertAllSelected();
                  setCheckedAll(true);
                }}
              >
                <Checkbox
                  checked={chekedAll}
                  icon={(
                    <MdCheck
                      color="#507919"
                      size={16}
                      style={{ margin: 0 }}
                    />
                  )}
                  borderColor="#A0BD39"
                  borderRadius={2}
                  borderWidth={1}
                  size={13}
                  style={{ cursor: 'pointer' }}
                />
                <span className="selectAll">Selecionar todas</span>
              </S.DeleteSubdivisionSelectedButton>
            )}
            {subdivisions?.length > 0 && (
              <S.DeleteSubdivisionSelectedButton
                onClick={() => {
                  deleteMultipleSubdivisions();
                }}
                type="button"
              >
                <MdDelete size={18} style={{ paddingBottom: 3 }} />
                <span>Deletar</span>
              </S.DeleteSubdivisionSelectedButton>
            )}
          </S.DelContainer>
          <S.SubdivionsContainer>
            {loading ? (
              <>
                <PuffLoader
                  color="#79ac34"
                  loading={loading}
                  size={30}
                  style={{ margin: 25 }}
                />
              </>
            ) : subdivisions?.length === 0 ? (
              selectedFarmId ? (
                'Sem Subdivisões cadastradas'
              ) : (
                'Por favor selecione uma fazenda'
              )
            ) : (
              subdivisions.map((value) => (
                <S.Subdivision key={value.features[0].properties.subdivision_id}>
                  <S.SubdivisionHeader>
                    <Checkbox
                      checked={selectedSubdivisionsId.includes(value.features[0].properties.subdivision_id)}
                      icon={(
                        <MdCheck
                          color="#507919"
                          size={16}
                          style={{ margin: 0 }}
                        />
                      )}
                      borderColor="#A0BD39"
                      borderRadius={2}
                      borderWidth={1}
                      onChange={() => {
                        if (selectedSubdivisionsId.includes(value.features[0].properties.subdivision_id)) {
                          return selectedSubdivisionsId.splice(selectedSubdivisionsId.indexOf(value.features[0].properties.subdivision_id), 1);
                        }
                        selectedSubdivisionsId.push(value.features[0].properties.subdivision_id);
                      }}
                      size={12}
                      style={{ cursor: 'pointer' }}
                    />
                    <h4>{value.features[0].properties.description}</h4>
                    <button
                      type="button"
                      onClick={() => {
                        removeSubdivisionGeometries();
                        renderSubdivision(value);
                        setSelectedSubdivisions(value);
                        setSubdivision(value);
                        setDashboardState('dashboard@register_walk_subdivision');
                      }}
                    >
                      <MdKeyboardArrowRight size={26} color="#444444" style={{ margin: 0, paddingTop: 5 }} />
                    </button>
                  </S.SubdivisionHeader>
                  <S.SubdivisionInfo>
                    <span>
                      {`Camada: ${value.features[0].properties.depthDataCollectionPointList} - ${calculateArea(value)}`.replace(',', '')}
                    </span>
                  </S.SubdivisionInfo>
                  <S.SubdivisionState
                    status={value.features[0].properties.status_Description}
                  >
                    {value.features[0].properties.status_Description}
                  </S.SubdivisionState>
                  <S.SubdivisionActions>
                    <button
                      type="button"
                      onClick={() => {
                        modifySubdivision(value);
                        setDashboardState('dashboard@edit_subdivision');
                      }}
                    >
                      Editar
                      <MdModeEdit />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setDeleteSubdivision(value);
                        openModal();
                      }}
                    >
                      Deletar
                      <MdDelete />
                    </button>

                  </S.SubdivisionActions>
                </S.Subdivision>
              ))
            )}
          </S.SubdivionsContainer>
          <S.SendToLaboratoryButton
            onClick={() => {
              setDashboardState('dashboard@send_laboratory');
            }}
            type="button"
            style={{ fontSize: 13 }}
          >Enviar para o laboratório <IoFlask />
          </S.SendToLaboratoryButton>
          <S.AddSubdivisionButton
            type="button"
            disabled={farmNotSelected}
            onClick={() => {
              setSelectedFields([]);
              setDashboardState('dashboard@register_subdivision');
              createSubdivision('selectSub');
              // removeEvent()
              // setSelectedFields([]);
              toast(
                <InstructionsToast title="Instruções para Desenho da Subdivisão">
                  <p>
                    1. Selecione 1 ou mais talhões nos quais você deseja desenhar uma nova subdivisão.
                    <br />
                    2. Após selecionar os talhões, clique no botão "Iniciar Desenho". Sua subidivisão será desenhada apenas nos limites selecionados.
                    <br />
                    3. Desenhe a sudivisão. Para finalizar, clique duas vezes com o mouse.
                    <br />
                    4. Após finalizar o polígono selecione a camada do seu ponto de coleta no menu a esquerda.
                  </p>
                </InstructionsToast>,
                {
                  type: 'default',
                  autoClose: 10000,
                  hideProgressBar: true,
                  style: { marginTop: '25%' }
                }
              );
            }}
          >
            Adicionar subdivisão
          </S.AddSubdivisionButton>
          <S.ActionCard>
            <label htmlFor="upload-file-subdivision" className="label-upload">
              <MdCloudUpload size={28} color="#9FBC38" />
              <span>Fazer upload da subdivisão</span>
              <p>Formatos Aceitos: Shapefile.zip, .kml, .kmz</p>
              <input
                type="file"
                accept=".zip, .kmz, .kml"
                id="upload-file-subdivision"
                multiple={false}
                onChange={(e) => {
                  const qtdArray = e.target.files.length;
                  const ext = e.target.files[qtdArray - 1].name.split('.');
                  if (
                    e.target.files[qtdArray - 1].type !== 'application/zip' &&
                    e.target.files[qtdArray - 1].type !==
                    'application/vnd.google-earth.kml+xml' &&
                    e.target.files[qtdArray - 1].type !==
                    'application/vnd.google-earth.kmz' &&
                    e.target.files[qtdArray - 1].type !==
                    'application/x-zip-compressed' &&
                    ext[ext.length - 1] !== 'kmz' &&
                    ext[ext.length - 1] !== 'kml' &&
                    ext[ext.length - 1] !== 'zip'
                  ) {
                    return (
                      null,
                      toast(
                        <Toast
                          title="Shapefile no formato incorreto"
                          content="Formatos aceitos: .zip, .kml, .kmz "
                        />,
                        {
                          type: 'error'
                        }
                      )
                    );
                  }
                  handleUploadFile(
                    e.target.files[qtdArray - 1],
                    e.target.files[qtdArray - 1].type === ''
                      ? ext[ext.length - 1]
                      : e.target.files[qtdArray - 1].type
                  );
                }}
              />
            </label>
          </S.ActionCard>
          <Button
            text="Gerar pontos de coleta"
            onClick={() => {
              setDashboardState('dashboard@generateCollectionPoints');
            }}
            className="button"
          />
          <Button
            text="Adicionar Caminhamento"
            onClick={() => {
              createWalkSubdivisions(subdivisions);
              setDashboardState('dashboard@finnalyMappingSubdivision');
            }}
            className="button"
          />
        </S.Content>
      </S.Container>
      <ConfirmDeleteModal
        messageDialog="Ao deletar a subdivisão os caminhamentos e pontos relacionados a essa subdivisão serão excluidos. Deseja realmente deletar a subdivisão ?"
        subdivision={deleteSubdivision}
      />
    </HarvestMapContext.Provider>
  );
};
export default HarvestMap;
