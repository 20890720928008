import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 220px;
  margin: 20px;
  background: #fff;
  bottom: 4%;
  right: 0;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 5%);
  padding: 15px;
`;

export const DynamicLegendHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
  }
`;

export const Title = styled.span`
  font-weight: bold;
`;

export const LegendData = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const LegendItemText = styled.div`
  width: 100%;
  font-size: 0.8rem;
`;

export const LegendIconItem = styled.div`
  width: 24px;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FarmLimits = styled.hr`
  border: 2px solid #57a8d0;
  width: 20px;
  border-radius: 2px;
`;

export const FieldLimits = styled.hr`
  border: 2px solid #e6231f;
  width: 20px;
  border-radius: 2px;
`;

export const SubdivisionLimits = styled.hr`
  border: 2px dashed #fff;
  width: 20px;
  border-radius: 2px;
  box-shadow: 1px 1px 5px #000;
`;

export const WalkLimits = styled.hr`
  border: 2px solid #dcf318;
  width: 20px;
  border-radius: 2px;
`;

export const Point = styled.div`
  border: 1px solid #dcdcdc;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 3px 3px 6px #00000029;
`;

export const Imported = styled.div`
  border: 2px solid #d43aaa;
  width: 20px;
  border-radius: 2px;
`;

export const RecommZone = styled.div`
  border: 2px solid #85be37;
  width: 20px;
  border-radius: 2px;
`;
