/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import Modal from 'react-modal';
import { types } from 'util';
import * as s from './utils/userProfile.style';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import { useModal } from '../../../../../../../hooks/Modal';
import { ResearchField } from '../../../../../../../components/ResearchField';
import Toast from '../../../../../../../components/Toast';
import { Profile } from '../../Profile/ProfileList/utils/profile.types';
import { User } from '../../Users/utils/users.types';
import { useOrganizacaoProfileFormRequest } from './hooks/useOrganizacaoProfileFormRequest';
import useProfileOrganizacaoForm from './hooks/useModuleProfileForm';
import { ModuleProfileFormProps } from '../utils/moduleProfile.types';
import TableCreate from '../../../../../../../components/TableCreate/TableCreate';

const OrganizacaoProfileForm: FC<ModuleProfileFormProps> = (props) => {
  const { type } = props;
  const { request, filter, setProfileId } = useOrganizacaoProfileFormRequest();
  const { form, mensage, mensageSuscess, title } = useProfileOrganizacaoForm({
    ...props,
    listaAtrelados: request.listIdaggregate
  });
  const { modalCreatUserStyles } = useModal();
  const navigate = useNavigate();

  useEffect(() => {
    if (mensage) {
      toast(<Toast title={mensageSuscess[type]} content="" />, {
        type: 'success'
      });
    }
  }, [mensage]);

  const organizacaoColumn: HeadCell<User>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'nome da Organização'
    }
  ];

  const profileColumn: HeadCell<Profile>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Perfil'
    }
  ];

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen
      style={{
        ...modalCreatUserStyles,
        ...{
          content: {
            width: '66%',
            height: '79% ',
            transform: 'translate(15vw, 4vw)'
          }
        }
      }}
    >
      <s.container>
        <h1>{title[type]}</h1>
        <s.containerTable>
          <Controller
            name="user_profile_id"
            control={form.methods.control}
            render={({ onChange }) => (
              <div>
                <div style={{ paddingLeft: '2%' }}>
                  <ResearchField
                    onChange={filter.setFilterSubModule}
                    placeholder="Filtro de Perfil"
                  />
                </div>

                <TableCreate
                  rows={request.itemsProfile}
                  HeadCell={profileColumn}
                  IconCreate={AddIcon}
                  title="Perfil"
                  onChange={(selected) => {
                    onChange(selected[0]);
                    setProfileId(selected[0]);
                  }}
                  checkBox
                  checkboxDisabled={type === 'edit'}
                  idStartSelect={[request.idOrganizacao]}
                />
              </div>
            )}
          />
          <Controller
            name="organizacao_id"
            control={form.methods.control}
            render={({ onChange }) => (
              <div>
                <div style={{ paddingLeft: '2%' }}>
                  <ResearchField
                    onChange={filter.setSystemModuleFilter}
                    placeholder="Filtro de Organização"
                  />
                </div>
                <TableCreate
                  rows={request.itemsOrganizacao}
                  HeadCell={organizacaoColumn}
                  // IconCreate={AddIcon}
                  title="Organização"
                  onChange={(selected) => {
                    onChange(selected);
                  }}
                  checkBox
                  multSelec={false}
                  idStartSelect={
                    request.idOrganizacao ? request.listIdaggregate : []
                  }
                  idStartSelectRedy={request.isRedy}
                />
              </div>
            )}
          />
        </s.containerTable>
        <s.containerbotton>
          <button
            type="button"
            className="containerbotton__bottoncancel"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </button>
          <button
            type="button"
            className={`containerbotton__bottonregister${
              form.isValid ? '--disabled' : ''
            }`}
            onClick={form.submit}
            disabled={form.isValid}
          >
            Registrar
          </button>
        </s.containerbotton>
      </s.container>
    </Modal>
  );
};

export default OrganizacaoProfileForm;
