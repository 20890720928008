import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PuffLoader from 'react-spinners/PuffLoader';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';
import SimpleButton from '../../../../../../components/SimpleButton';
import Toast from '../../../../../../components/Toast';
import InstructionsToast from '../../../../../../components/Toast/Instructions';
import { appConfig } from '../../../../../../config';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import api from '../../../../../../services/api';
import { DashboardContext } from '../../../../index';
import * as S from './styled';

export const RegisterFarmFromShapefileContext = createContext({});

export const RegisterFarmFromShapefileProvider = ({ children }) => {
  const [buttonDisable, setButtonDisable] = useState(true);

  return (
    <RegisterFarmFromShapefileContext.Provider
      value={{ buttonDisable, setButtonDisable }}
    >
      {children}
    </RegisterFarmFromShapefileContext.Provider>
  );
};

RegisterFarmFromShapefileProvider.propTypes = {
  children: PropTypes.node
};

const RegisterFarmFromShapefile = () => {
  const { farms, setFarms, setDashboardState } = useContext(DashboardContext);
  const { token, user } = useAuth();
  const navigate = useNavigate();
  const {
    removeGeometries,
    removeAllInterections,
    farm,

    setSelectedFarmId
  } = useMap();
  const [loading, setLoading] = useState(false);
  const [laboratory, setLaboratory] = useState();
  const FarmRegisterSchema = Yup.object().shape({
    farm_name: Yup.string().required('Nome da fazenda é obrigatório'),
    contact_name: Yup.string(),
    contact_number: Yup.string()
  });

  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(FarmRegisterSchema)
  });

  const onSubmit = async (data) => {
    farm.features[0].properties = data;
    farm.features[0].properties.owner_id = `${user.user_id}`;
    farm.features[0].properties.created_by = `${user.user_id}`;
    try {
      setLoading(true);
      const newFarm = await api.post(
        `${appConfig.apiHosts.farm}/Farm/PostFarm`,
        farm,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const { message, data: registeredFarm } = newFarm.data;
      setFarms([...farms, registeredFarm]);
      setSelectedFarmId(newFarm.data.data.features[0].properties.farm_id);
      toast(
        <Toast
          title={message}
          content="Você foi redirecionado para a tela de Fazendas"
        />,
        {
          type: 'success'
        }
      );
      setLoading(false);
      // setDashboardState('dashboard@farm');
      navigate('../farmList');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      toast(
        <Toast
          title="Não foi possível cadastrar a fazenda."
          content="Verifique os dados inseridos no formulário"
        />,
        { type: 'error' }
      );
    }
  };

  useEffect(() => {
    toast(
      <InstructionsToast title="Shapefile importado com sucesso">
        <p>Preencha os dados cadastrais da fazenda</p>
      </InstructionsToast>,
      {
        type: 'success',
        autoClose: 5000
      }
    );
  }, []);
  return (
    <RegisterFarmFromShapefileProvider>
      <S.Container loading={loading}>
        {loading ? (
          <>
            <PuffLoader
              color="#79ac34"
              loading={loading}
              size={30}
              style={{ margin: 25 }}
            />
            <span>Salvando Fazenda...</span>
          </>
        ) : (
          <>
            <S.ContainerBackButton>
              <SimpleButton
                text="Voltar"
                onClick={() => {
                  // setDashboardState('dashboard@farm');
                  removeGeometries();
                  removeAllInterections();
                  navigate(-1);
                }}
              />
            </S.ContainerBackButton>
            <S.RegisterFarmDialog>
              <h3>Adicionar Fazenda</h3>
              <span>
                Cadastre sua fazenda e comece a usar as funcionalidades da
                plataforma.
              </span>
            </S.RegisterFarmDialog>
            <S.Form onSubmit={handleSubmit(onSubmit)}>
              <Input
                type="text"
                name="farm_name"
                placeholder="Nome da fazenda"
                ref={register}
                defaultValue=""
                style={{ borderColor: errors?.farm_name && 'red' }}
              />
              <S.ErrorMessage>{errors?.farm_name?.message}</S.ErrorMessage>
              <Input
                type="text"
                name="contact_number"
                id="contact_number"
                placeholder="Telefone de contato"
                ref={register}
                defaultValue=""
              />
              <Input
                type="text"
                name="contact_name"
                placeholder="Contato da fazenda"
                ref={register}
                defaultValue=""
              />
              <Button text="Cadastrar Fazenda" />
            </S.Form>
          </>
        )}
      </S.Container>
    </RegisterFarmFromShapefileProvider>
  );
};

export default RegisterFarmFromShapefile;
