import styled from 'styled-components';
import {
  Table,
  Select as MUISelect,
  TextField,
  Button as MUIButton
} from '@mui/material';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content__addButton {
    width: 200px;
    padding: 10px;
    height: 40px;
  }
`;

export const Title = styled.h1`
  font-size: 21px;
  margin-bottom: 16px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding: 0 60px;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 630px;
`;

export const AddBtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const AddButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    color: #a0bd39;
    width: 30px;
    height: 30px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;

  .actionBtn {
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: #79ac34;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: auto;
  margin-top: 10px;
  max-height: 560px;
  gap: 15px;
  overflow-y: auto;
  overflow-x: auto;
  padding-bottom: 10px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  background-color: #f5f5f5;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  width: 210px;
  height: 265px;
`;

export const CardImage = styled.div`
  width: 100%;
  height: 180px;
  background-color: #d4d4d4;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;
export const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
  align-items: center;
  margin: 5px 0;
  margin-bottom: 6px;
  flex: 1;
  cursor: pointer;
`;
export const CardTitle = styled.h3`
  font-size: 14px;
  text-align: center;
`;
export const CardDescription = styled.span`
  font-size: 12px;
  text-align: center;
`;

export const CardActionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: end;
  width: 100%;
`;
export const CardAction = styled.div`
  margin: 5px 0;

  button {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    font-size: 12px;
    color: #79ac34;
    font-weight: bold;

    svg {
      margin-right: 3px;
      font-size: 12px;
      padding-bottom: 1px;
    }
  }
`;
