import styled from 'styled-components';
import signinBackgroundImg from '../../assets/sign-in-background.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;

  img {
    height: auto;
    width: 250px;
  }

  h1 {
    font-size: 15em;
    color: #79ac34;
  }

  h3 {
    color: #79ac34;
    font-size: 2em;
  }

  a {
    color: #79ac34;
    font-weight: bold;
    font-size: 1.2em;
    margin-top: 40px;
  }
`;
export const Background = styled.div`
  flex: 1;
  background: url(${signinBackgroundImg}) no-repeat center;
  background-size: cover;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  > p {
    padding: 15px;
    font-weight: bold;
    font-size: 0.8rem;
    color: #fff;
    @media (max-width: 1000px) {
      display: none;
    }
  }
`;
