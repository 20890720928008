/* eslint-disable eqeqeq */
/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { MdCheck, MdSearch, MdDelete } from 'react-icons/md';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Pagination
} from '@mui/material';
import PuffLoader from 'react-spinners/PuffLoader';
import { toast } from 'react-toastify';
import Checkbox from 'react-custom-checkbox';
import Toast from '../../../../../../components/Toast';
import { DashboardContext } from '../../../../index';
import * as S from './styled';
import { useModal } from '../../../../../../hooks/Modal';
import Button from '../../../../../../components/Button';
import { useMap } from '../../../../../../hooks/Map';
import { useAuth } from '../../../../../../hooks/Auth';
import api from '../../../../../../services/api';
import { appConfig } from '../../../../../../config';
import ModalNewProblem from './components/ModalNewProblem';
import ModalEditProblem from './components/ModalEditProblem';
import ModalParametersNewRecommendation from './components/ModalParametersNewRecommendation';
import ModalParametersEditRecommendation from './components/ModalParametersEditRecommendation';
import ViewProblem from './components/ViewProblem';
import ViewParamRecommendation from './components/ViewParamRecommendation';

const Parameters = () => {
  const {
    currentModal,
    setCurrentModal,
    itemSelectedVisible,
    setItemSelectedVisible,
    updateScreen,
    setUpdateScreen,
    selectedProblem,
    setSelectedProblem,
    setUploadedFiles,
    selectedRecommendation,
    setSelectedRecommendation,
    problemList,
    setProblemList,
    listTypeManager,
    setListTypeManager,
    selectTypeProblemList,
    setSelectTypeProblemList,
    selectTypeCultureList,
    setSelectTypeCultureList,
    listPragues,
    setListPragues
  } = useContext(DashboardContext);
  const { selectedFarmId } = useMap();
  const { closeModal, openModal } = useModal();
  const { token, user, getNotifications } = useAuth();

  const [loading, setLoading] = useState(false);
  const [loadingRecommendation, setLoadingRecommendation] = useState(false);
  const [recommendationList, setRecommendationList] = useState([]);
  const [totalPagesProblem, setTotalPagesProblem] = useState();
  const [totalPagesRecommendation, setTotalPagesRecommendation] = useState();
  const [searchField, setSearchField] = useState('');
  const [selectedProblemId, setSelectedProblemId] = useState([]);
  const [chekedAllProblem, setCheckedAllProblem] = useState(false);
  const [selectedRecommendationId, setSelectedRecommendationId] = useState([]);
  const [chekedAllRecommendation, setCheckedAllRecommendation] = useState(false);

  const getProblemList = async (pageNumber = 1) => {
    setLoading(true);
    const params = new URLSearchParams([
      ['farm_id', selectedFarmId],
      ['page', pageNumber],
      ['filter', searchField || '']
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Problem/ListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedProblemId([]);
      if (response.data.data) {
        setProblemList(response?.data.data.problems);
        setTotalPagesProblem(response?.data.data.pagination.totalPages);
      }

      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getRecommendationList = async (pageNumber = 1) => {
    setLoadingRecommendation(true);
    setRecommendationList([]);
    const params = new URLSearchParams([
      ['farm_id', selectedFarmId],
      ['page', pageNumber],
      ['filter', searchField || '']

    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Recomendation/ListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedRecommendationId([]);
      setRecommendationList(response?.data?.data?.recommendations);
      setTotalPagesRecommendation(response?.data?.data?.pagination?.totalPages);
      setLoadingRecommendation(false);
      setUpdateScreen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getProblemById = async (id) => {
    const params = new URLSearchParams([['problem_id', id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Problem/ListByProblemId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedProblem(response.data.data);
      setItemSelectedVisible('viewProblem');
    } catch (error) {
      console.error(error);
    }
  };

  const getRecommendationById = async (id) => {
    const params = new URLSearchParams([['recommendation_id', id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Recomendation/ListByRecommendationId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedRecommendation(response.data.data);
      setUpdateScreen(true);

      setItemSelectedVisible('viewParamRecommendation');
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeList = async () => {
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Type/List`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setListTypeManager(response.data.data.types);
    } catch (error) {
      console.error(error);
    }
  };

  const getPragueList = async () => {
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Prague/List`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // const sortResponse = response.data.data.pragues.sort((x, y) => {
      //   return x.status - y.status;
      // });

      const filterActive = response.data.data.pragues.filter((v) => v.active === true);

      setListPragues(filterActive);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleChangePageProblem = (event, value) => {
    getProblemList(value);
  };

  const handleChangePageRecommendation = (event, value) => {
    getRecommendationList(value);
  };

  const deleteSelectedProblem = async () => {
    setLoading(true);
    try {
      selectedProblemId.map(async (v) => {
        const params = new URLSearchParams([
          ['farm_id', selectedFarmId],
          ['problem_id', v],
          ['status', 'false']
        ]);
        const response = await api.delete(
          `${appConfig.apiHosts.prague}/Problem/Delete`,
          {
            params,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        if (response.data.status == 200) {
          toast(<Toast title="Problema excluido com sucesso" />, {
            type: 'success'
          });
          await setUpdateScreen(true);
        }
      });

      if (chekedAllProblem) {
        setCheckedAllProblem(false);
      }
    } catch (error) {
      setLoading(false);
      toast(<Toast title="Não foi possível excluir" />, {
        type: 'error'
      });
      console.error(error);
    }
  };

  const deleteSelectedRecommendation = async () => {
    setLoadingRecommendation(true);
    try {
      selectedRecommendationId.map(async (v) => {
        const params = new URLSearchParams([
          ['farm_id', selectedFarmId],
          ['recommendation_id', v],
          ['status', 'false']
        ]);
        const response = await api.delete(
          `${appConfig.apiHosts.prague}/Recomendation/Delete`,
          {
            params,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        if (response.data.status == 200) {
          toast(<Toast title="Recomendação excluida com sucesso" />, {
            type: 'success'
          });
          await setUpdateScreen(true);
        }
      });

      if (chekedAllRecommendation) {
        setCheckedAllRecommendation(false);
      }
    } catch (error) {
      setLoading(false);
      toast(<Toast title="Não foi possível excluir" />, {
        type: 'error'
      });
      console.error(error);
    }
  };

  const insertAllSelectedProblems = () => {
    setSelectedProblemId(problemList.map((value) =>
      value.problem_id
    ));
  };

  const insertAllSelectedRecommendations = () => {
    setSelectedRecommendationId(recommendationList.map((value) =>
      value.recommendation_id
    ));
  };

  useEffect(() => {
    getTypeList();
    getProblemList();
    getRecommendationList();
    getPragueList();

    if (!selectedFarmId) {
      return toast(
        <Toast
          title="Por favor selecione uma fazenda"
          content="Para que os problemas e recomendações sejam carregados é necessário selecionar uma fazenda"
        />,
        { type: 'warning' }
      );
    }
  }, []);

  useEffect(() => {
    if (updateScreen) {
      getProblemList();
      getRecommendationList();
    }
  }, [updateScreen]);

  useEffect(() => {
    if (searchField === '') {
      getProblemList();
      getRecommendationList();
    }
  }, [searchField]);

  return (
    <S.Container>
      <S.SearchAreaContainer>
        <S.SearchArea>
          <MdSearch
            size={28}
            onClick={() => {
              getProblemList();
              getRecommendationList();
            }}
          />
          <S.SearchInput
            type="text"
            placeholder="Pesquise por pragas, tipos de produtos, etc..."
            value={searchField}
            onChange={(e) => {
              setSearchField(e.target.value);
            }}
          />
        </S.SearchArea>
        {/* <S.FilterWrapper>
          <MdFilterList />
          <span>Filtrar Por...</span>
        </S.FilterWrapper> */}
      </S.SearchAreaContainer>
      <S.AddBtnContainer>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '16px' }}>
          <S.SubTitle>Problemas</S.SubTitle>
          <S.DelContainer>
            <S.DeleteActionButton
              onClick={() => {
                deleteSelectedProblem();
              }}
              type="button"
              className="deleteButton"
            >
              <MdDelete size={20} style={{ paddingBottom: 3 }} />
              <span>Deletar</span>
            </S.DeleteActionButton>
          </S.DelContainer>
        </div>
        <Button
          type="button"
          text="Novo Problema"
          className="addBtn"
          onClick={() => {
            setCurrentModal('modalNewProblem');
            openModal();
          }}
        />
      </S.AddBtnContainer>
      <TableContainer
        className="tabela"
        style={{
          borderRadius: '8px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Table size="small" cellspacing="0">
          <TableHead>
            <TableRow style={{ backgroundColor: '#EFEFEF', fontWeight: 'bold' }}>
              <TableCell align="center" style={{ fontWeight: 'bold', borderLeft: 'none' }}>
                <S.DeletePointSelectedButton
                  onClick={() => {
                    if (selectedProblemId.length > 0) {
                      return setSelectedProblemId([]),
                        setCheckedAllProblem(false);
                    }
                    insertAllSelectedProblems();
                    setCheckedAllProblem(true);
                  }}
                >
                  <Checkbox
                    checked={chekedAllProblem}
                    icon={(
                      <MdCheck
                        color="#507919"
                        size={20}
                        style={{ margin: 0 }}
                      />
                    )}
                    borderColor="#A0BD39"
                    borderRadius={2}
                    borderWidth={1}
                    size={14}
                    style={{ backgroundColor: '#FFF' }}
                  />
                </S.DeletePointSelectedButton>
              </TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Tipo</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Espécie</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Unidade de Medida</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Cultura</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Cultivar Resistente</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <>
              <PuffLoader
                color="#79ac34"
                loading={loading}
                size={30}
              />
            </>
          ) : (
            <TableBody>
              {problemList?.map((row) => (
                <TableRow>
                  <TableCell align="center" style={{ borderLeft: 'none' }}>
                    <S.DeletePointSelectedButton>
                      <Checkbox
                        checked={selectedProblemId.includes(row.problem_id)}
                        icon={(
                          <MdCheck
                            color="#507919"
                            size={20}
                            style={{ margin: 0 }}
                          />
                        )}
                        borderColor="#A0BD39"
                        borderRadius={2}
                        borderWidth={1}
                        onChange={() => {
                          if (
                            selectedProblemId.includes(
                              row.problem_id
                            )
                          ) {
                            return (
                              selectedProblemId.splice(
                                selectedProblemId.indexOf(
                                  row.problem_id
                                ), 1));
                          }
                          selectedProblemId.push(
                            row.problem_id
                          );
                        }}
                        size={14}
                      />
                    </S.DeletePointSelectedButton>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getProblemById(row.problem_id);
                    }}
                    style={{ cursor: 'pointer' }}
                  >{row.problem_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getProblemById(row.problem_id);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.prague_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getProblemById(row.problem_id);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.measurement_unit}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getProblemById(row.problem_id);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.crop_type_id_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getProblemById(row.problem_id);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.plant}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Stack spacing={1}>
        <Pagination
          count={totalPagesProblem}
          variant="outlined"
          shape="rounded"
          size="small"
          onChange={handleChangePageProblem}
        />
      </Stack>
      <S.AddBtnContainer>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '16px' }}>
          <S.SubTitle>Recomendações</S.SubTitle>
          <S.DelContainer>
            <S.DeleteActionButton
              onClick={() => {
                deleteSelectedRecommendation();
              }}
              type="button"
              className="deleteButton"
            >
              <MdDelete size={20} style={{ paddingBottom: 3 }} />
              <span>Deletar</span>
            </S.DeleteActionButton>
          </S.DelContainer>
        </div>
        <Button
          type="button"
          text="Nova Recomendação"
          className="addBtn"
          style={{ width: '220px' }}
          onClick={() => {
            setCurrentModal('modalParametersNewRecommendation');
            openModal();
          }}
        />
      </S.AddBtnContainer>
      <TableContainer
        className="tabela"
        style={{
          borderRadius: '8px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Table size="small" cellspacing="0">
          <TableHead>
            <TableRow style={{ backgroundColor: '#EFEFEF', fontWeight: 'bold' }}>
              <TableCell align="center" style={{ fontWeight: 'bold', borderLeft: 'none' }}>
                <S.DeletePointSelectedButton
                  onClick={() => {
                    if (selectedRecommendationId.length > 0) {
                      return setSelectedRecommendationId([]),
                        setCheckedAllRecommendation(false);
                    }
                    insertAllSelectedRecommendations();
                    setCheckedAllRecommendation(true);
                  }}
                >
                  <Checkbox
                    checked={chekedAllRecommendation}
                    icon={(
                      <MdCheck
                        color="#507919"
                        size={20}
                        style={{ margin: 0 }}
                      />
                    )}
                    borderColor="#A0BD39"
                    borderRadius={2}
                    borderWidth={1}
                    size={14}
                    style={{ backgroundColor: '#FFF' }}
                  />
                </S.DeletePointSelectedButton>
              </TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Tipo</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Dose</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Volume em Calda</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Princípio Ativo</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Modo de Aplicação</TableCell>
            </TableRow>
          </TableHead>
          {loadingRecommendation ? (
            <>
              <PuffLoader
                color="#79ac34"
                loading={loading}
                size={30}
              />
            </>
          ) : (
            <TableBody>
              {recommendationList?.map((row) => (
                <TableRow>
                  <TableCell align="center" style={{ borderLeft: 'none' }}>
                    <S.DeletePointSelectedButton>
                      <Checkbox
                        checked={selectedRecommendationId.includes(row.recommendation_id)}
                        icon={(
                          <MdCheck
                            color="#507919"
                            size={20}
                            style={{ margin: 0 }}
                          />
                        )}
                        borderColor="#A0BD39"
                        borderRadius={2}
                        borderWidth={1}
                        onChange={() => {
                          if (
                            selectedRecommendationId.includes(
                              row.recommendation_id
                            )
                          ) {
                            return (
                              selectedRecommendationId.splice(
                                selectedRecommendationId.indexOf(
                                  row.recommendation_id
                                ), 1));
                          }
                          selectedRecommendationId.push(
                            row.recommendation_id
                          );
                        }}
                        size={14}
                      />
                    </S.DeletePointSelectedButton>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getRecommendationById(row.recommendation_id);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.type_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getRecommendationById(row.recommendation_id);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.dose}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getRecommendationById(row.recommendation_id);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.syrup_volume}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getRecommendationById(row.recommendation_id);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.active_principle}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getRecommendationById(row.recommendation_id);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.application_mode_name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Stack spacing={1}>
        <Pagination
          count={totalPagesRecommendation}
          variant="outlined"
          shape="rounded"
          size="small"
          onChange={handleChangePageRecommendation}
        />
      </Stack>
      {currentModal === 'modalNewProblem' && <ModalNewProblem />}
      {currentModal === 'modalEditProblem' && <ModalEditProblem />}
      {currentModal === 'modalParametersNewRecommendation' && <ModalParametersNewRecommendation />}
      {currentModal === 'modalParametersEditRecommendation' && <ModalParametersEditRecommendation />}
      {itemSelectedVisible === 'viewProblem' && (
        <ViewProblem />
      )}
      {itemSelectedVisible === 'viewParamRecommendation' && (
        <ViewParamRecommendation />
      )}
    </S.Container>
  );
};

export default Parameters;
