/* eslint-disable prettier/prettier */
/* eslint-disable no-buffer-constructor */
import JSZip, { forEach } from 'jszip';
import React, { useContext, useEffect, useState } from 'react';
import { shade, setLightness } from 'polished';
import { saveAs } from 'file-saver';
import { MdChevronLeft } from 'react-icons/md';
import * as shpwrite from 'shp-write';
import Button from '../../../../../../components/Button';
import { appConfig } from '../../../../../../config';
import { parseCoordinatesTo4326 } from '../../../../../../helpers/parseCoordinates';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import {
  generateFilenameMetadataFileJhonDeere,
  generateFilenameNoExtFileJhonDeere,
  generateFilenameZipFileJhonDeere,
  metadataFileJhonDeere
} from '../../../../../../Models/MetadataFileJhonDeere';
import api from '../../../../../../services/api';
import { DashboardContext } from '../../../../index';
import * as S from './styled';

const RecommendationMap = () => {
  const {
    setDashboardState,
    dashboardState,
    setRecommendationVisible,
    setSubdivisions,
    subdivisions
  } = useContext(DashboardContext);
  const {
    removeRecommendationZone,
    selectedFarmId,
    renderRecommendationZoneWithColors,
    recommZoneList,
    setLegendRecommendationValue,
    legendRecommendationValue,
    selectedFarmGeoJson,
    selectedRecommZone,
    removeColoredRecommendationZone,
    recommendationMapSubtitle,
    setRecommendationMapSubtitle,
    farmName,
    tableResults,
    selectedSubdivisionsId,
    ownerName,
    recommendationsZoneList,
    setRecommendationsZoneList
  } = useMap();
  const { token, user } = useAuth();
  const [background, setBackground] = useState();
  const [colorMap, setColorMap] = useState();

  const getRecommendationsZoneList = async () => {
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/RecommendationZone/GetRecommendationsListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setRecommendationsZoneList(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const generateColoredZone = async (color) => {
    setRecommendationMapSubtitle([]);
    setLegendRecommendationValue([]);
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/RecommendationZone/GetRecommendationsListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setColorMap(response.data.data);

      // const quantity = response.data.data
      //   .map((value) => value.quantity)
      //   .filter((value, index, self) => self.indexOf(value) === index);

      const sort = await response.data.data.sort((n1, n2) => n2.quantity - n1.quantity);

      sort.forEach((item, index) => {
        recommendationMapSubtitle.push({
          quantity: item.quantity,
          fertilize: item.fertilize,
          measurement_unit: item.measurement_unit,
          color: `${setLightness((index + 1) / 10, color)}`
        });
      });

      const cores = response.data.data.map((value) => {
        sort.forEach((qt, index) => {
          if (value.quantity === qt.quantity) {
            const hexaColor = `${setLightness((index + 1) / 10, color)}`;
            value = {
              ...value,
              color: hexaColor.substr(0, 7)
            };
          }
        });
        return value;
      });

      renderRecommendationZoneWithColors(recommZoneList, cores);
      setLegendRecommendationValue(recommendationMapSubtitle);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  // const handleGenerateLegend = async (parameter) => {
  //   try {
  //     const params = new URLSearchParams([['RecommendationId', parameter]]);
  //     const response = await api.get(
  //       `${appConfig.apiHosts.report} / RecommendationZoneColors / GetRecommendationMapLegendByRecommendationId`,
  //       {
  //         params,
  //         headers: {
  //           Authorization: `Bearer ${token} `
  //         }
  //       }
  //     );
  //     // const responseParams = response.data.data;
  //     setLegendRecommendationValue(response.data.data);
  //   } catch (error) {
  //     // eslint-disable-next-line no-console
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    setRecommendationVisible(true);
    getRecommendationsZoneList();
    setLegendRecommendationValue('');
    setRecommendationMapSubtitle([]);
    // generateColoredZone();
    // handleGenerateLegend('95cae6ad-479a-44de-b1af-02a1d5690f86');
  }, []);

  const handleExportShapefile = async () => {
    const recommendationMade = recommZoneList.filter(
      (s) => s.features[0].properties.status_recommendation === 'RECOMENDACAO_REALIZADA');

    const subdivisionRecommMade = [];

    recommendationMade.forEach(async (s) => {
      s.features[0].properties.subdivisions_list.forEach((sId) => {
        subdivisions.forEach((sub) => {
          if (sub.features[0].properties.subdivision_id === sId) {
            const subdivision = sub;

            subdivision.features[0].properties = {
              ...subdivision.features[0].properties,
              recommendation_zone_id: s.features[0].properties.recommendation_zone_id
            };

            subdivisionRecommMade.push(subdivision);
          }
        });
      });
    });

    recommendationsZoneList.forEach(async (recommZone) => {
      subdivisionRecommMade.forEach((s) => {
        if (s.features[0].properties.recommendation_zone_id === recommZone.recommendation_zone_id) {
          s.features[0].properties = {
            ...s.features[0].properties,
            fertilize: recommZone.fertilize,
            measurement_unit: recommZone.measurement_unit,
            quantity: recommZone.quantity,
            recommendation_type: recommZone.recommendation_type
          };
        }
      });
    });

    const geoJsonSubdivisions = {
      type: 'FeatureCollection',
      name: farmName.replaceAll(' ', '_').replace('-', ''), // nome do arquivo
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:OGC:1.3:CRS84'
        }
      },
      features: subdivisionRecommMade.map((ss) => ss.features[0])
    };
    geoJsonSubdivisions.features.map((value, i) => {
      let paramsFarm = '';

      tableResults.forEach((v) => {
        if (v.original === value.properties.description) {
          paramsFarm = v;
        }
      });
      const coordFinder = /\(\s?(\S+)\s+(\S+)\s?\)/g;
      const matches = value.properties.geometry_center ? coordFinder.exec(value.properties?.geometry_center) : null;

      const latitude = matches !== null ? matches[1] : '';
      const longitude = matches !== null ? matches[2] : '';

      return value.properties = {
        FID_1: i + 1,
        Cliente: ownerName,
        Fazenda: farmName,
        Talhao: paramsFarm.campo ? paramsFarm.campo : null,
        Sub_Talhao: value.properties.description,
        pH_Cacl2_1: paramsFarm.pH_Cacl2 ?? null,
        pH_Cacl2_2: null,
        P_1: paramsFarm.p ?? null,
        P_2: null,
        K_1: paramsFarm.k ?? null,
        K_2: null,
        Ca_1: paramsFarm.ca ?? null,
        Ca_2: null,
        Mg_1: paramsFarm.mg ?? null,
        Mg_2: null,
        Al_1: paramsFarm.al ?? null,
        Al_2: null,
        MO_1: paramsFarm.mo ?? null,
        MO_2: null,
        MO_NIVEIS: null,
        Argila_1: paramsFarm.argila ?? null,
        Argila_2: null,
        CTC_1: paramsFarm.ctc ?? null,
        CTC_2: null,
        V_1: paramsFarm.v ?? null,
        V_2: null,
        M_1: paramsFarm.mo ?? null,
        M_2: null,
        S_1: paramsFarm.s ? paramsFarm.s : null,
        S_2: null,
        Hectares: value.properties.geometry_ha,
        COD: null,
        FID_2: null,
        INDICE: null,
        NOME: value.properties.description,
        COMENTARIO: paramsFarm.observacao ? paramsFarm.observacao : null,
        ICONE: null,
        ALTITUDE: null,
        DATA: paramsFarm.data ? paramsFarm.data : null,
        ANGULO: null,
        DISPLAY: null,
        ZONA: null,
        LATITUDE: latitude,
        LONGITUDE: longitude,
        Distance: null,
        Prof: paramsFarm.prof ? paramsFarm.prof : null,
        Fertilizante: value.properties.fertilize,
        Unidade_medida: value.properties.measurement_unit,
        Quantidade: value.properties.quantity,
        Tipo_recomendacao: value.properties.recommendation_type
      };
    });
    // const featTest = new GeoJSON().readFeatures(geoJsonSubdivisions);
    // const test = JSON.parse(new GeoJSON().writeFeatures(featTest));

    const options = {
      folder: 'data',
      types: {
        polygon: 'subdivisions',
        multipolygon: 'multi'
      }
    };

    const content = await shpwrite.zip(geoJsonSubdivisions, options);
    saveAs(`data:application/zip;base64,${content}`, `${farmName}_mapa_de_recomendacao.zip`);
  };

  const handleChangeComplete = (color) => {
    setBackground(color);
    generateColoredZone(color);
  };

  return (
    <S.Container>
      <S.FarmCardActions className="backButton">
        <button
          type="button"
          onClick={() => setDashboardState('dashboard@resultsMap')}
        >
          <MdChevronLeft />
          <span>Voltar</span>
        </button>
      </S.FarmCardActions>
      <h4>Mapa de recomendação</h4>
      <p>
        Mapa de recomendação gerado a partir dos dados selecionados
        anteriormente.
      </p>
      <S.Content>
        <p>Selecione uma cor para visualizar o mapa de recomendação:</p>
        <S.ColorPickerContainer>
          <S.ColorArea
            color="#800000"
            selected={background}
            onClick={() => {
              handleChangeComplete('#800000');
            }}
          />
          <S.ColorArea
            color="#000040"
            selected={background}
            onClick={() => handleChangeComplete('#000040')}
          />
          <S.ColorArea
            color="#004000"
            selected={background}
            onClick={() => handleChangeComplete('#004000')}
          />
          <S.ColorArea
            color="#BE3C08"
            selected={background}
            onClick={() => handleChangeComplete('#BE3C08')}
          />
          <S.ColorArea
            color="#400040"
            selected={background}
            onClick={() => handleChangeComplete('#400040')}
          />
          <S.ColorArea
            color="#BA9910"
            selected={background}
            onClick={() => handleChangeComplete('#BA9910')}
          />
        </S.ColorPickerContainer>
        <S.ButtonContainer class="positionButton">
          <Button
            text="Exportar Shapefile"
            onClick={() => {
              handleExportShapefile();
            }}
          />
        </S.ButtonContainer>
      </S.Content>
    </S.Container>
  );
};

export default RecommendationMap;
