import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useModal } from '../../../../../../hooks/Modal';
import { HarvestMapContext } from '../../index';

import * as S from './styled';

const ConfirmDeleteModal = ({
  messageDialog = 'Deseja realmente fazer isso ?',
  subdivision,
  ...props
}) => {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { handleDeleteSubdivision } = useContext(HarvestMapContext);
  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      {...props}
    >
      <S.DialogContainer>
        <S.Text>{messageDialog}</S.Text>
        <S.ButtonsContainer>
          <S.ConfirmationButton
            onClick={() => {
              handleDeleteSubdivision(
                subdivision?.features[0]?.properties?.subdivision_id
              );
              closeModal();
            }}
            style={{ backgroundColor: '#79AC34' }}
          >
            Sim
          </S.ConfirmationButton>
          <S.ConfirmationButton
            onClick={() => closeModal()}
            style={{ backgroundColor: '#d62222' }}
          >
            Não
          </S.ConfirmationButton>
        </S.ButtonsContainer>
      </S.DialogContainer>
    </Modal>
  );
};

ConfirmDeleteModal.propTypes = {
  messageDialog: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  subdivision: PropTypes.object
};

export default ConfirmDeleteModal;
