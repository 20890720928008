/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { Button, ButtonProps, TextField, styled } from '@mui/material';
import { FC, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { green } from '@mui/material/colors';
import { ResearchFieldProps } from './utils/researchField.types';

const ResearchField: FC<ResearchFieldProps> = ({
  onChange,
  placeholder = 'Filtro'
}) => {
  const [filterValue, setFilterValue] = useState<string>('');

  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(green[50]),
    backgroundColor: '#A0BD39',
    '&:hover': {
      backgroundColor: '#A0BD39'
    }
  }));

  return (
    <div style={{ display: 'flex', gap: 10, marginTop: '1%' }}>
      <TextField
        hiddenLabel
        placeholder={placeholder}
        variant="filled"
        size="small"
        color="success"
        onChange={({ target }) => setFilterValue(target.value)}
      />
      <ColorButton variant="contained" onClick={() => onChange(filterValue)}>
        <SearchIcon htmlColor="#fff" />
      </ColorButton>
    </div>
  );
};
export default ResearchField;
