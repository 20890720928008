/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect
} from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  OutlinedInput,
  Selected,
  TextareaAutosize
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { AddCircle } from '@mui/icons-material';
import { CompactPicker } from 'react-color';
import Checkbox from 'react-custom-checkbox';
import { useDropzone } from 'react-dropzone';
// import { useFiles } from "../../context/files";
import PuffLoader from 'react-spinners/PuffLoader';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  FaChevronLeft,
  FaChevronRight,
  FaUpload,
  FaTrash,
  FaCheckCircle
} from 'react-icons/fa';
import {
  MdOutlineCloudUpload,
  MdCheck,
  MdClose,
  MdError,
  MdLink,
  MdMoodBad
} from 'react-icons/md';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
import filesize from 'filesize';
import { toast } from 'react-toastify';
import { Carousel } from 'react-responsive-carousel';
import { useModal } from '../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../index';
import storage from '../../../../../../services/firebase';
import Button from '../../../../../../components/Button';
import { useMap } from '../../../../../../hooks/Map';
import { appConfig } from '../../../../../../config';
import { useAuth } from '../../../../../../hooks/Auth';
import api from '../../../../../../services/api';
import Toast from '../../../../../../components/Toast';
import * as S from './styled';

const ModalViewPrague = () => {
  const inputFileRef = useRef(null);
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const {
    selectedManagement,
    uploadedFiles: files,
    deleteFile,
    handleUpload,
    setUploadedFiles,
    setUpdateScreen,
    itemTypeSelected,
    selectTypeCultureList,
    setSelectTypeCultureList,
    selectedPrague,
    setSelectedPrague,
    setCurrentModal
  } = useContext(DashboardContext);

  const { selectedFarmId } = useMap();
  const uploadFile = useRef(null);

  const [checked, setChecked] = useState(true);
  const [selectedPhoto, setSelectedPhoto] = useState(0);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Dados da Praga</S.Title>
        <S.FormContainer>
          <S.InputWrapper style={{ margin: 0 }}>
            {selectedPrague?.pest_classification_name && (
              <S.InputContainer>
                <S.SubTitle>
                  Classificação de Praga
                </S.SubTitle>
                <S.TextContainer>{selectedPrague?.pest_classification_name}
                </S.TextContainer>
              </S.InputContainer>
            )}
            <S.InputContainer>
              <S.SubTitle>Nome Comum</S.SubTitle>
              <S.TextContainer>{selectedPrague?.name}</S.TextContainer>
            </S.InputContainer>
          </S.InputWrapper>
          <S.InputWrapper>
            {selectedPrague?.scientific_name && (
              <S.InputContainer>
                <S.SubTitle>Nome Científico</S.SubTitle>
                <S.TextContainer>{selectedPrague?.scientific_name}</S.TextContainer>
              </S.InputContainer>
            )}
            {selectedPrague?.measurement_unit && (
              <S.InputContainer>
                <S.SubTitle>Unidade de Medida</S.SubTitle>
                <S.TextContainer>{selectedPrague?.measurement_unit}</S.TextContainer>
              </S.InputContainer>
            )}
          </S.InputWrapper>
          <S.InputWrapper>
            {selectedPrague?.crop_type.length > 0 && (
              <S.InputContainer style={{ height: 'auto' }}>
                <S.SubTitle>Cultura</S.SubTitle>
                {selectedPrague?.crop_type?.map((crop) => (
                  <S.TextContainer>{crop.name}</S.TextContainer>
                ))}
              </S.InputContainer>
            )}
            {selectedPrague?.plant.length > 0 && (
              <S.InputContainer style={{ height: 'auto' }}>
                <S.SubTitle>Cultivar Resistente</S.SubTitle>
                {selectedPrague?.plant?.map((plant) => (
                  <S.TextContainer>{plant.name}</S.TextContainer>
                ))}
              </S.InputContainer>
            )}
          </S.InputWrapper>
          <S.InputWrapper>
            {selectedPrague?.description && (
              <S.TextAreaContainer>
                <S.SubTitle>Descrição</S.SubTitle>
                <TextareaAutosize
                  value={selectedPrague?.description}
                  disabled
                  className="textArea"
                />
              </S.TextAreaContainer>
            )}
          </S.InputWrapper>
          <S.InputWrapper>
            {selectedPrague?.symptoms && (
              <S.TextAreaContainer>
                <S.SubTitle>Sintomas</S.SubTitle>
                <TextareaAutosize
                  value={selectedPrague?.symptoms}
                  disabled
                  className="textArea"
                />
              </S.TextAreaContainer>
            )}
          </S.InputWrapper>
          <S.InputWrapper>
            {selectedPrague?.damage && (
              <S.TextAreaContainer>
                <S.SubTitle>Dano Causado</S.SubTitle>
                <TextareaAutosize
                  value={selectedPrague?.damage}
                  disabled
                  className="textArea"
                />
              </S.TextAreaContainer>
            )}
          </S.InputWrapper>
          <S.InputWrapper>
            {selectedPrague?.prague_images?.length > 0 && (
              <S.InputContainer style={{ height: 'auto' }}>
                <S.SubTitle>Foto</S.SubTitle>
                <S.PhotoContainer>
                  <Carousel
                    width={500}
                    // swipeScrollTolerance={5}
                    showThumbs={false}
                    selectedItem={selectedPhoto}
                    showStatus={false}
                    onChange={(e) => setSelectedPhoto(e)}
                  >
                    {selectedPrague?.prague_images?.map((uploadedFile) => (
                      <div className="viewPhoto">
                        <img
                          alt={uploadedFile.prague_image_id}
                          src={uploadedFile.url}
                          // height="280"
                          style={{ border: '2px solid #79ac34' }}
                        />
                      </div>
                    ))}
                  </Carousel>
                  <S.ThumbsContainer>
                    {selectedPrague?.prague_images?.map((uploadedFile, index) => (
                      <S.ThumbItemContainer>
                        <S.ThumbItem>
                          <S.ThumbButtonImage
                            type="button"
                            className="thumbsContainer__button"
                            onClick={() => setSelectedPhoto(index)}
                            style={{
                              border:
                                selectedPhoto === index
                                  ? '5px solid #79ac34'
                                  : '2px solid #47aa12'
                            }}
                          >
                            <img
                              alt={uploadedFile.prague_image_id}
                              src={uploadedFile.url}
                              style={{
                                border:
                                  selectedPhoto === index
                                    ? '2px solid #FFF'
                                    : 'none'
                              }}
                            />
                          </S.ThumbButtonImage>
                          <S.ThumbIconsContainer>
                            {uploadedFile.url === selectedPrague.main_photo && (
                              <FaCheckCircle color="#79ac34" size={15} />
                            )}
                          </S.ThumbIconsContainer>
                        </S.ThumbItem>
                      </S.ThumbItemContainer>
                    ))}
                  </S.ThumbsContainer>
                </S.PhotoContainer>
              </S.InputContainer>
            )}
          </S.InputWrapper>

          <S.InputWrapper style={{ alignItems: 'start' }}>
            {selectedPrague?.pragues_size.length > 0 && (
              <S.InputContainer style={{ height: 'auto' }}>
                <S.SubTitle>
                  Tamanho
                </S.SubTitle>
                <TableContainer
                  style={{
                    width: '100%',
                  }}
                >
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: '#e4e4e4'
                          }}
                          colSpan={3}
                        >{selectedPrague?.prague_size_title}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedPrague?.pragues_size?.map((value) => (
                        <TableRow>
                          <TableCell align="center">
                            <div
                              style={{
                                height: '18px',
                                width: '18px',
                                background: value.color ? value.color : ''
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {value.description}
                          </TableCell>
                          <TableCell align="center">{value?.size}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </S.InputContainer>
            )}
          </S.InputWrapper>

          <S.InputWrapper>
            {selectedPrague?.pragues_instar.length > 0 && (
              <S.InputContainer
                style={{ justifyContent: 'flex-start', height: 'auto' }}
              >
                <S.SubTitle>Instar</S.SubTitle>
                <TableContainer
                  style={{
                    width: '100%',
                  }}
                >
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: '#e4e4e4'
                          }}
                          colSpan={3}
                        >{selectedPrague?.prague_instar_title}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedPrague?.pragues_instar?.map((value) => (
                        <TableRow>
                          <TableCell align="center">
                            <div
                              style={{
                                height: '18px',
                                width: '18px',
                                background: value?.color ? value.color : ''
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {value?.description}
                          </TableCell>
                          <TableCell align="center">{value?.size}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </S.InputContainer>
            )}
          </S.InputWrapper>

          <S.InputWrapper>
            <S.InputContainer style={{ height: 'auto' }}>
              <S.CheckboxContainer>
                <S.SubTitle style={{ marginBottom: '5px' }}>Incidência</S.SubTitle>
              </S.CheckboxContainer>
              {checked && (
                <S.CheckboxContainer>
                  <label
                    className="radioContainer"
                    style={{
                      color: selectedPrague?.incidence_status === true ? '#79AC34' : ''
                    }}
                  >
                    Sim
                    <input
                      disabled
                      type="checkbox"
                      checked={selectedPrague?.incidence_status === true}
                    />
                    <span className="checkmark" />
                  </label>

                  <label
                    className="radioContainer"
                    style={{
                      color: selectedPrague?.incidence_status === false ? '#79AC34' : ''
                    }}
                  >
                    Não
                    <input
                      disabled
                      type="checkbox"
                      checked={selectedPrague?.incidence_status === false}
                    />
                    <span className="checkmark" />
                  </label>
                </S.CheckboxContainer>
              )}
            </S.InputContainer>
          </S.InputWrapper>

          <S.InputWrapper>
            {selectedPrague?.pragues_control_level.length > 0 && (
              <S.InputContainer
                style={{ justifyContent: 'flex-start', height: 'auto' }}
              >
                <S.SubTitle>Nível de Controle</S.SubTitle>
                <TableContainer
                  style={{
                    width: '100%',
                  }}
                >
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: '#e4e4e4'
                          }}
                          colSpan={3}
                        >{selectedPrague?.prague_control_Level_title}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedPrague?.pragues_control_level?.map((value) => (
                        <TableRow>
                          <TableCell align="center">
                            <div
                              style={{
                                height: '18px',
                                width: '18px',
                                background: value.color ? value.color : ''
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">{value?.level}</TableCell>
                          <TableCell align="center">{value?.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </S.InputContainer>
            )}
          </S.InputWrapper>
          <S.ButtonWrapper>
            <Button
              text="Fechar"
              className="cancelBtn"
              onClick={() => {
                setCurrentModal('');
                closeModal();
              }}
            />
          </S.ButtonWrapper>
        </S.FormContainer>
      </S.Container>
    </Modal>
  );
};

export default ModalViewPrague;
