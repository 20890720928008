import React, { useContext, useEffect, useRef, useState } from 'react';
import { MdPrint } from 'react-icons/md';
import Modal from 'react-modal';
import { QRCode } from 'react-qrcode-logo';
import { useReactToPrint } from 'react-to-print';
import logo from '../../../../../../assets/icon.png';
import { useModal } from '../../../../../../hooks/Modal';
import { SendLaboratoryContext } from '../SendLaboratory';
import * as S from './styled';

const ModalPrint = ({ ...props }) => {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { sendSubdivisions, subdivisions } = useContext(SendLaboratoryContext);
  const [subdivisionsToSend, setSubdivisionsToSend] = useState([]);
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });
  useEffect(() => {
    const Send = sendSubdivisions.map((value) => {
      const subdivision = subdivisions.filter(
        (v) => v.features[0].properties.subdivision_id === value
      )[0];
      return subdivision;
    });
    setSubdivisionsToSend(Send);
  }, [sendSubdivisions]);
  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      {...props}
    >
      <S.Container>
        <S.PrintQrCodeButton
          type="button"
          onClick={() => {
            handlePrint();
          }}
        >
          Imprimir <MdPrint size={20} />
        </S.PrintQrCodeButton>
        <S.PrintContainer ref={printRef}>
          {subdivisionsToSend.map((value) => {
            return (
              <S.TagContainer>
                <QRCode
                  value={value.features[0].properties.description}
                  fgColor="#62904e"
                  logoImage={logo}
                  logoWidth={40}
                  logoHeight={40}
                  logoOpacity={0.8}
                />
                <S.TagBody>
                  <span>{value.features[0].properties.description}</span>
                </S.TagBody>
              </S.TagContainer>
            );
          })}
        </S.PrintContainer>
      </S.Container>
    </Modal>
  );
};

export default ModalPrint;
