/* eslint-disable prettier/prettier */
/* eslint-disable indent */
import { eachDayOfInterval, format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import {
  MdChevronLeft,
  MdChevronRight,
  MdSatellite,
  MdSpa
} from 'react-icons/md';
import { appConfig } from '../../config';
import { useMap } from '../../hooks/Map';
import {
  Container,
  DataContainer,
  MonthsContainer,
  NextButton,
  ProgressBar,
  ProgressBarContainer,
  FilterNDVI
} from './styled';

const TimeLine = ({ ...props }) => {
  const [ndviActived, setNDVIActived] = useState(false);
  const {
    setDateCarrousel,
    selectedFarmHa,
    setTileType,
    setTilePreset,
    tileType,
    tilePreset
  } = useMap();
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ];

  const generateMonths = (month, year) => {
    if (month < 0) {
      year--;
      month += 12;
    }
    if (month > 12) {
      year++;
      month -= 12;
    }
    if (month === 0) {
      month = 12;
      year--;
    }
    return `${month - 1}/${year}`;
  };
  const generateTimelineData = (month, year) => {
    const gMonths = [];
    for (let i = -1; i < 8; i++) {
      gMonths.push(generateMonths(month - i, year));
    }
    return gMonths.reverse();
  };
  const [selectedMonth] = useState(new Date().getMonth());
  const [selectedYear] = useState(new Date().getFullYear());
  const [timelineData, setTimelineData] = useState(
    generateTimelineData(selectedMonth, selectedYear)
  );

  const [days, setDays] = useState([]);
  useEffect(() => {
    const generatedDays = eachDayOfInterval({
      start: new Date(
        timelineData[0].split('/')[1],
        Number(timelineData[0].split('/')[0]),
        1
      ),
      end: new Date(
        timelineData[7].split('/')[1],
        Number(timelineData[7].split('/')[0]) + 2,
        0
      )
    });
    setDays(generatedDays);
  }, [timelineData]);
  const [progress, setProgress] = useState(days.length);

  useEffect(() => {
    const utcDate = new Date().toISOString();
    setDateCarrousel(format(parseISO(utcDate), 'dd/MM/yyyy'));
  }, []);

  return (
    <Container {...props}>
      <MonthsContainer>
        <NextButton
          type="button"
          onClick={() => {
            setTimelineData(
              generateTimelineData(
                Number(timelineData[0].split('/')[0]) - 1,
                Number(timelineData[0].split('/')[1])
              )
            );
          }}
        >
          <MdChevronLeft />
        </NextButton>
        <div>
          {timelineData.map((value) => (
            <p key={value}>
              {Number(value.split('/')[0]) ===
                Number(
                  new Date(
                    Date.parse(
                      JSON.stringify(days[progress])
                        ?.replace('"', '')
                        ?.replace('"', '')
                    )
                  ).getMonth()
                )
                ? `${new Date(
                  Date.parse(
                    JSON.stringify(days[progress])
                      ?.replace('"', '')
                      ?.replace('"', '')
                  )
                ).getDate()}/`
                : null}
              {`${months[Number(value.split('/')[0])]}/${value.split('/')[1]}`}
            </p>
          ))}
        </div>
        {Number(timelineData[0].split('/')[1]) < new Date().getFullYear() ? (
          <NextButton
            type="button"
            onClick={() => {
              setTimelineData(
                generateTimelineData(
                  Number(timelineData[7].split('/')[0]) + 10,
                  Number(timelineData[7].split('/')[1])
                )
              );
            }}
          >
            <MdChevronRight />
          </NextButton>
        ) : null}
      </MonthsContainer>
      <ProgressBarContainer>
        <ProgressBar
          type="range"
          onChange={(e) => {
            if (selectedFarmHa > appConfig.limitHaFarm) return;
            setProgress(e.target.value);
            const utcDate = new Date(
              Date.parse(
                JSON.stringify(days[e.target.value])
                  ?.replace('"', '')
                  ?.replace('"', '')
              )
            ).toISOString();
            setDateCarrousel(format(parseISO(utcDate), 'dd/MM/yyyy'));
          }}
          min={0}
          max={days.length - 1}
          defaultValue={progress}
        />
      </ProgressBarContainer>

      <DataContainer>
        <div>
          <MdSatellite />
          <MdSatellite />
          <MdSatellite />
        </div>
        <div
          style={{
            width: '4%',
            display: 'flex',
            // backgroundColor: '#000',
            justifyContent: 'space-between',
            marginLeft: '50px'
          }}
        >
          <FilterNDVI
            ndvi={ndviActived}
            type="button"
            onClick={() => {
              if (
                tileType === 'NATURAL-COLOR' &&
                tilePreset === 'NATURAL-COLOR'
              ) {
                setTileType('NDVI');
                setTilePreset('NDVI');
                setNDVIActived(true);
                return;
              }
              setTileType('NATURAL-COLOR');
              setTilePreset('NATURAL-COLOR');
              setNDVIActived(false);
            }}
          >
            <MdSpa />
          </FilterNDVI>
        </div>
      </DataContainer>
    </Container>
  );
};

export default TimeLine;
