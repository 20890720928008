import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { DashboardContext } from '../../../..';
import Button from '../../../../../../components/Button';
import SimpleButton from '../../../../../../components/SimpleButton';
import Toast from '../../../../../../components/Toast';
import { appConfig } from '../../../../../../config';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import api from '../../../../../../services/api';

import * as S from './styled';

function RegisterMapping() {
  const {
    removeAllInterections,
    removeGeometries,
    renderFarm,
    selectedFarmId,
    renderFields,
    farmMapping
  } = useMap();
  const { user, token } = useAuth();
  const { setDashboardState, farms, fields } = useContext(DashboardContext);
  const farm = farms.filter(
    (fFarm) => fFarm.features[0].properties.farm_id === selectedFarmId ?? fFarm
  )[0];
  const registerMapping = async () => {
    farmMapping.features[0].properties = {
      farm_id: selectedFarmId,
      description: 'Caminhamento',
      fieldList: farmMapping.features[0].properties.fieldList,
      created_by: user.user_id
    };
    try {
      await api.post(
        `${appConfig.apiHosts.field}/FieldMapping/PostFieldMapping`,
        farmMapping,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      toast(<Toast title="Caminhamento cadastrado com sucesso." />, {
        type: 'success'
      });
      setDashboardState('dashboard@information');
    } catch (error) {
      toast(<Toast title="Não foi possivel cadastra Caminhamento." />, {
        type: 'error'
      });
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  return (
    <S.Container>
      <SimpleButton
        text="Voltar"
        onClick={() => {
          removeGeometries();
          removeAllInterections();
          setDashboardState('dashboard@information');
          renderFarm(farm);
          renderFields(fields);
        }}
      />

      <S.ButtonsContainer>
        <Button
          text="Salvar Caminhamento"
          // disabled={drawendSubdivisionAndPoint}
          onClick={() => {
            registerMapping();
            // setDrawendSubdivisionAndPoint(true);
            // removeAllInterections();
          }}
        />
        <Button
          text="Cancelar"
          style={{
            background: '#FFF',
            color: '#79AC34',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 5%)'
          }}
          onClick={() => {
            removeGeometries();
            removeAllInterections();
            setDashboardState('dashboard@information');
            renderFarm(farm);
            renderFields(fields);
          }}
        />
      </S.ButtonsContainer>
    </S.Container>
  );
}

export default RegisterMapping;
