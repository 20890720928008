/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
import {
  ApiType, BBox,
  CRS_EPSG4326, DEMLayer, Landsat8AWSLOTL1Layer, MimeTypes, MODISLayer,
  S1GRDAWSEULayer, S2L1CLayer,
  S2L2ALayer
} from '@sentinel-hub/sentinelhub-js';
import { eachDayOfInterval } from 'date-fns';
import proj4 from 'proj4';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { appConfig } from '../../config';
import { useMap } from '../../hooks/Map';
import Toast from '../Toast';
import { Container, PhotoCard } from './styled';

const Carousel = ({ hide }) => {
  const {
    dateCarrousel,
    selectedFieldToTimeline,
    renderWMSTile,
    getBboxOfGeometry,
    selectedFarmHa,
    maxClouds,
    satellite,
    removeWMSLayer,
    tilePreset,
    selectedFieldsToNDVI,
    renderWMSTileMultipleFields
  } = useMap();
  const [checked, setChecked] = useState();
  const [carrouselData, setCarrouselData] = useState();
  const splitedDate = dateCarrousel.split('/');
  useEffect(() => {
    if (checked) {
      renderWMSTile(selectedFieldToTimeline, checked);
    }
  }, [tilePreset]);
  useEffect(async () => {
    if (!selectedFieldToTimeline) {
      return toast(
        <Toast title="Para utilizar o carrossel de imagens, favor selecionar um talhão" />,
        { type: 'warning' }
      );
    }
    if (selectedFieldToTimeline && selectedFarmHa > appConfig.limitHaFarm) {
      return toast(
        <Toast title="Fazenda Maior que 100ha" content="Para acessar o recurso de renderização de imagens, assine a plataforma" />,
        { type: 'warning' }
      );
    }
    let layer;
    if (satellite === 'sentinel-2-l1c') {
      layer = new S2L1CLayer({
        instanceId: `${appConfig.servicesKey.sentinel}`,
        layerId: 'NATURAL-COLOR',
        maxCloudCoverPercent: maxClouds
      });
    } else if (satellite === 'sentinel-2-l2a') {
      layer = new S2L2ALayer({
        instanceId: `${appConfig.servicesKey.sentinel}`,
        layerId: 'NATURAL-COLOR',
        maxCloudCoverPercent: maxClouds
      });
    } else if (satellite === 'landsat-ot-l1') {
      layer = new Landsat8AWSLOTL1Layer({
        instanceId: `${appConfig.servicesKey.sentinel}`,
        layerId: 'NATURAL-COLOR',
        maxCloudCoverPercent: maxClouds
      });
    } else if (satellite === 'dem') {
      layer = new DEMLayer({
        instanceId: `${appConfig.servicesKey.sentinel}`,
        layerId: 'NATURAL-COLOR',
        maxCloudCoverPercent: maxClouds
      });
    } else if (satellite === 'modis') {
      layer = new MODISLayer({
        instanceId: `${appConfig.servicesKey.sentinel}`,
        layerId: 'NATURAL-COLOR',
        maxCloudCoverPercent: maxClouds
      });
    } else if (satellite === 'sentinel-1-grd') {
      layer = new S1GRDAWSEULayer({
        instanceId: `${appConfig.servicesKey.sentinel}`,
        layerId: 'NATURAL-COLOR',
        maxCloudCoverPercent: maxClouds
      });
    } else {
      layer = new S2L1CLayer({
        instanceId: `${appConfig.servicesKey.sentinel}`,
        layerId: 'NATURAL-COLOR',
        maxCloudCoverPercent: maxClouds
      });
    }
    const geometryBbox = await getBboxOfGeometry(selectedFieldToTimeline);
    const par1 = proj4('EPSG:3857', 'EPSG:4326', [geometryBbox[0], geometryBbox[1]]);
    const par2 = proj4('EPSG:3857', 'EPSG:4326', [geometryBbox[2], geometryBbox[3]]);
    const bbox = new BBox(CRS_EPSG4326, ...par1, ...par2);
    const startDate = new Date(splitedDate[2], splitedDate[1] - 1);
    let endDate = new Date(splitedDate[2], splitedDate[1]);
    const actualDate = new Date();
    // if (endDate < actualDate) {
    //   startDate = new Date(splitedDate[2], splitedDate[1] - 2);
    //   endDate = new Date(splitedDate[2], splitedDate[1] - 1);
    // }
    if (Number(splitedDate[1]) - 1 === new Date().getMonth()) {
      endDate = new Date();
    }
    const interval = eachDayOfInterval({
      start: startDate,
      end: endDate
    });
    interval.pop();
    const data = [];
    interval.map((value) => {
      const mapProps = {
        bbox,
        fromTime: new Date(value.getFullYear(), value.getMonth(), value.getDay(), 0, 0, 0).toISOString(),
        toTime: new Date(value.getFullYear(), value.getMonth(), value.getDay(), 23, 59, 59).toISOString(),
        width: 64,
        height: 64,
        format: MimeTypes.JPEG,
        showlogo: false
      };
      const image = layer.getMapUrl(mapProps, ApiType.WMS);
      return data.push({
        date: value.toLocaleString('pt-br', { dateStyle: 'short' }),
        urlImage: image
      });
    });
    setCarrouselData(data.reverse());
  }, [selectedFieldToTimeline, dateCarrousel, maxClouds, satellite]);

  return (
    <Container hide={hide}>
      {carrouselData?.map((item) => (
        <PhotoCard
          onClick={() => {
            if (checked === item.date) {
              setChecked('');
              removeWMSLayer();
              return;
            }
            setChecked(item.date);
            if (selectedFieldsToNDVI.length > 0) {
              selectedFieldsToNDVI.forEach((f) => renderWMSTileMultipleFields(f, item.date));
              return;
            }
            renderWMSTile(selectedFieldToTimeline, item.date);
          }}
          key={item.date}
          selected={checked === item.date}
        >
          <img
            src={item.urlImage}
            style={{ opacity: checked === item.date ? '1' : '' }}
            alt="mapa"
          />
          <div>{item.date}</div>
        </PhotoCard>
      ))}
    </Container>
  );
};

Carousel.propTypes = {
  hide: PropTypes.bool
};

export default Carousel;
