/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../../../../../../hooks/Auth';
import ProfileApi from '../../../../../../../../APi/FarmApi/ProfileApi';
import OrganizacaoApi from '../../../../../../../../APi/FarmApi/OrganizacaoApi';
import UserAPI from '../../../../../../../../APi/FarmApi/UserAPI';
import { ProfileFormProps } from '../../ProfileList/utils/profile.types';

interface ProfileForm {
  userprofile_id: string;
  description: string;
  active: boolean;
  organizacao_id: string;
  isFarmOwner: boolean;
  nomeOrganizacao: string;
}

const useProfileForm = ({ type }: ProfileFormProps) => {
  const {
    token,
    user: { organizacao_id },
    role
  } = useAuth();
  const [sistema, modulo, subModulo] = role.split('|');
  const isMaster: boolean = sistema.includes('MASTER');
  const isAdmin: boolean = sistema.includes('Admin');
  const profileApi = useMemo(() => new ProfileApi(token), []);
  const organizacaoApi = useMemo(() => new OrganizacaoApi(token), []);
  const userApi = useMemo(() => new UserAPI(token), []);
  const [formValid, setFormValid] = useState<boolean>(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [mensage, setMensage] = useState<boolean>(false);
  const [userStatus, setUserStatus] = useState<{
    codeStatus: number;
    message?: string;
  }>({});
  const FormValidationSchema = Yup.object().shape({
    description: Yup.string().required()
  });

  const methods = useForm<ProfileForm>({
    defaultValues: {
      userprofile_id: '',
      description: '',
      active: true,
      isFarmOwner: false,
      organizacao_id: '',
      nomeOrganizacao: ''
    },
    resolver: yupResolver(FormValidationSchema)
  });

  const profileSucess = (form: ProfileForm) => {
    methods.reset({
      ...methods.getValues(),
      ...form
    });
  };

  const profileDate = useQuery({
    queryKey: [profileApi.ProfileId],
    queryFn: async () => profileApi.GetProfile(id.toString(), organizacao_id),
    onSuccess: (data) => {
      profileSucess(data.data.data);
    }
  });

  useEffect(() => {
    const owner =
      !!methods.control.getValues('nomeOrganizacao') ||
      !!methods.control.getValues('organizacao_id') ||
      type === 'edit' ||
      (!isAdmin && !isMaster);
    setFormValid(!methods.control.getValues('description') || !owner);
  }, [methods.control.watchInternal()]);

  const onSelectActivated = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    methods.setValue('active', checked);
  };

  const profileCreate = useMutation({
    mutationFn: async (form: ProfileForm) =>
      profileApi.ProfileCreate(form, organizacao_id),
    onSuccess: async (data) => {
      if (data.data.status === 409) {
        setUserStatus({
          codeStatus: data.data.status,
          message: data.data.message
        });
      }
      setUserStatus({
        codeStatus: 200
      });
      setMensage(true);
      navigate(-1);
      await queryClient.invalidateQueries(profileApi.getProfiles);
    }
  });

  const organizacaoData = useQuery({
    queryKey: [organizacaoApi.getOrganizacao, { organizacao_id }],
    queryFn: async () => organizacaoApi.GetOrganizacao('', organizacao_id)
  });

  const profileUpdate = useMutation({
    mutationFn: async (form: ProfileForm) =>
      await profileApi.ProfileUpdate<ProfileForm>(form),
    onSuccess: async (data) => {
      if (data.data.status === 409) {
        setUserStatus({
          codeStatus: data.data.status,
          message: data.data.message
        });
      }
      setUserStatus({
        codeStatus: 200
      });
      setMensage(true);
      navigate(-1);
      await queryClient.invalidateQueries(profileApi.getProfiles);
    }
  });

  const handleSubmit = methods.handleSubmit((form: ProfileForm) => {
    if (type === 'new') {
      if (!isAdmin && !isMaster) {
        form.organizacao_id = organizacao_id;
      }
      profileCreate.mutate(form);
    } else if (type === 'edit') {
      form.userprofile_id = id.toString();
      profileUpdate.mutate(form);
    }
  });

  const title: Record<string, string> = {
    new: 'Adicionar perfil de usuário',
    edit: 'Atualizar perfil de usuário'
  };

  const mensageSuccess: Record<string, { title: string; content: string }> = {
    new: {
      title: 'Perfil criado com sucesso',
      content: 'Perfil foi criado com sucesso'
    },
    edit: {
      title: 'Perfil atualizado com sucesso',
      content: 'Perfil foi atualizado com sucesso'
    }
  };

  function moduleDataMap(User: any) {
    return {
      id: User.id,
      name: User.name
    };
  }
  const verificarOrganizacao = useMutation({
    mutationFn: async (organizacao: string) =>
      await userApi.VerificarOrganizacao(organizacao),
    onSuccess: async (data) => {
      if (data.data.status === 409) {
        setUserStatus({
          codeStatus: data.data.status,
          message: data.data.message
        });
      }
    }
  });
  const onSelectFarmOwner = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    methods.setValue('isFarmOwner', checked);
  };
  return {
    form: {
      methods,
      handleSubmit,
      control: methods.control,
      isValid: formValid,
      onSelectFarmOwner,
      statusCode: userStatus
    },
    request: {
      itemsOrganizacao: organizacaoData.data?.data.data.map(moduleDataMap) ?? []
    },
    verific: {
      verificarOrganizacao
    },
    onSelectActivated,
    title,
    mensageSuccess,
    mensage,
    profileDate:
      (profileDate.data?.data?.data as ProfileForm) || ({} as ProfileForm)
  };
};

export default useProfileForm;
