import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;

  h1 {
    margin-left: 6px;
    font-size: 16px;
    margin: 16px 0;
  }

  hr {
    border: solid 1px #dcdcdc;
    margin-bottom: 16px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  button.SubmitButton {
    bottom: 60px;
  }

  button.CancelButton {
    bottom: 0;
    background: #fff;
    color: #79ac34;
  }

  button.GeneratePointsButton {
    bottom: 20px;
  }

  span {
    font-size: 14px;
  }
`;

export const WalksSUbdivisionContainer = styled.div`
  padding: 0px;
  max-height: 175px;
  overflow: auto;
  width: 100%;
  margin-bottom: 16px;

  h1 {
    font-size: 16px;
    font-weight: bold;
  }

  span {
    font-size: 14px;
  }
`;

export const WalksSUbdivisionCard = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  padding: 0px 5px;
  margin-bottom: 5px;
`;

export const WalkSubdivisionTitle = styled.span`
  font-size: 12px;
  margin-left: 4px;
`;
export const WalkSubdivisionInformations = styled.span``;
export const WalkSubdivisionActions = styled.div`
  display: flex;
  justify-content: space-around;
  > button {
    background: transparent;
    border: 0;
    color: #79ac34;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 3px;
    margin-top: 5px;
  }
`;

export const CollectionPointsContainer = styled.div`
  padding: 0px;
  max-height: 200px;
  overflow: auto;
  width: 100%;
`;

export const CollectionPoint = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  padding: 0 5px;
  margin-bottom: 5px;
`;

export const CollectionPointHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background: transparent;
    border: 0;
  }

  h4 {
    flex: 1;
    margin-left: 4px;
    font-size: 0.8rem;
    font-weight: normal;
  }
`;

export const CollectionPointActions = styled.div`
  display: flex;
  justify-content: space-around;

  button {
    background: transparent;
    border: 0;
    color: #79ac34;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 3px;
  }
`;

export const Title = styled.h3`
  margin-bottom: 10px;
  font-size: 16px;
`;

export const DeletePointSelectedButton = styled.button`
  display: flex;
  align-items: center;
  margin: 10px 10px 0px 0px;
  width: 100%;
  background: transparent;
  border: 0;
  text-align: left;
  color: #79ac34;
  padding: 0;

  span {
    font-size: 12px;
  }

  .selectAll {
    margin-left: 4px;
    padding-top: 2px;
  }
`;

export const DelContainer = styled.div`
  /* display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; */
  margin-bottom: 5px;
  margin-left: 5px;
`;

export const WalksSUbdivisionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
