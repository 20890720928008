export type selectMenuType =
  | 'users'
  | 'farms'
  | 'userFarms'
  | 'Profile'
  | 'modulesSystem'
  | 'userProfile'
  | 'profileModule'
  | 'organizacao'
  | 'ProfileSubModule'
  | 'subModule'
  | 'organizacaoProfile'
  | 'adm'
  | 'parameters'
  | 'pragues'
  | 'disease'
  | 'weeds'
  | 'Farm';

export enum SelectMenuEnum {
  Users = 'users',
  Farms = 'farms',
  UserFarms = 'userFarms',
  Profile = 'Profile',
  ModulesSystem = 'modulesSystem',
  UserProfile = 'userProfile',
  ProfileModule = 'profileModule',
  Organizacao = 'organizacao',
  ProfileSubModule = 'ProfileSubModule',
  SubModule = 'subModule',
  OrganizacaoProfile = 'organizacaoProfile',
  Adm = 'adm',
  Parameters = 'parameters',
  Pragues = 'pragues',
  Disease = 'disease',
  Weeds = 'weeds',
  Farm = 'Farm'
}

export const subModules: Record<string, any> = {
  Usuarios: 'users',
  Farms: 'farms',
  UsuarioFazendas: 'userFarms',
  Perfis: 'Profile',
  Modulos: 'modulesSystem',
  UsuarioPerfil: 'userProfile',
  PerfilModulo: 'profileModule',
  Organizacao: 'organizacao',
  PerfilSubModulo: 'ProfileSubModule',
  SubModulo: 'subModule',
  PerfilOrganizacao: 'organizacaoProfile',
  ADM: 'adm',
  Parametros: 'parameters',
  Pragas: 'pragues',
  Doencas: 'disease',
  ErvasDaninhas: 'weeds',
  Farm: 'Farm'
};
