import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  margin: 16px 0 16px 0;
  font-size: 1rem;
`;

export const SubTitle = styled.span`
  color: #a3a3a3;
  font-size: 0.8rem;
  text-align: justify;
`;

export const SelectContainer = styled.div`
  margin: 20px 0;
`;

export const ButtonsContainer = styled.div``;
export const SimpleButtonContainer = styled.div``;
