import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import * as Yup from 'yup';
import { useAuth } from '../../../../../../../../hooks/Auth';
import ProfileApi from '../../../../../../../../APi/FarmApi/ProfileApi';
import { useProfileSubModuleFormProps } from '../utils/profileSubModule.types';

const useProfileSubModuleForm = ({
  type,
  listIdaggregate = [],
  subModuleList
}: useProfileSubModuleFormProps) => {
  const { token } = useAuth();
  const profileApi = useMemo(() => new ProfileApi(token), []);
  const [formValid, setFormValid] = useState<boolean>(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [mensage, setMensage] = useState<boolean>(false);

  interface ProfileSubMoluloForm {
    user_profile_id: string;
    sub_module_id: string[];
    acronym: string;
  }
  const FormValidationSchema = Yup.object().shape({
    user_profile_id: Yup.string().required(),
    sub_module_id: Yup.array().required()
  });

  const methods = useForm({
    defaultValues: {
      user_profile_id: [],
      sub_module_id: []
    },
    resolver: yupResolver(FormValidationSchema)
  });

  const profileSubModuleFormCreate = useMutation({
    mutationFn: async (form: ProfileSubMoluloForm) => {
      form.acronym = subModuleList.find(
        (module: any) => module.id === form.sub_module_id[0] // na criação só tera um
      )?.acronym;
      profileApi.AtrelarPerfilSubmodulo(form);
    },
    onSuccess: async () => {
      setMensage(true);
      await queryClient.invalidateQueries(
        profileApi.ListaAtrelarPerfilSubModulo
      );
      navigate(-1);
    }
  });
  const profileSubModuleFormUpdate = useMutation({
    mutationFn: async (form: ProfileSubMoluloForm) => {
      const subModuleListIds = form.sub_module_id.filter(
        (x: any) => !listIdaggregate.includes(x)
      );
      const subModuleListOld = form.sub_module_id.filter((x: any) =>
        listIdaggregate.includes(x)
      );
      const desSelect = listIdaggregate.filter(
        (x) => !subModuleListIds.concat(subModuleListOld).includes(x)
      );

      desSelect.forEach(async (item) => {
        form.acronym = subModuleList.find(
          (module: any) => module.id === item
        )?.acronym;
        await profileApi.DeletarPerfilSubmodulo({
          user_profile_id: form.user_profile_id,
          sub_module_id: item,
          acronym: form.acronym
        });
      });

      subModuleListIds.forEach(async (item) => {
        form.acronym = subModuleList.find(
          (module: any) => module.id === item
        )?.acronym;
        await profileApi.AtrelarPerfilSubmodulo({
          user_profile_id: form.user_profile_id,
          sub_module_id: item,
          acronym: form.acronym
        });
      });
      await queryClient.invalidateQueries(
        profileApi.ListaAtrelarPerfilSubModulo
      );
    },
    onSuccess: async () => {
      setMensage(true);
      await queryClient.invalidateQueries(
        profileApi.ListaAtrelarPerfilSubModulo
      );
      navigate(-1);
    }
  });

  useEffect(() => {
    if (type === 'edit') return;
    setFormValid(
      !methods.control.getValues('user_profile_id') ||
        methods.control.getValues('sub_module_id').length === 0
    );
  }, [methods.control.watchInternal()]);

  const submit = methods.handleSubmit(async (form: ProfileSubMoluloForm) => {
    if (type === 'new') {
      await profileSubModuleFormCreate.mutateAsync(form);
    } else {
      await profileSubModuleFormUpdate.mutateAsync(form);
    }
  });

  const title: Record<string, string> = {
    new: 'Atrelamento de Perfil com Sub-Módulo',
    edit: 'Editar atrelamento de Perfil com Sub-Módulo'
  };

  const mensageSuscess: Record<string, string> = {
    new: 'Atrelamento feito com sucesso',
    edit: 'Edição do atrelamento feito com sucesso'
  };

  return {
    form: {
      methods,
      submit,
      isValid: formValid,
      isSuccess: profileSubModuleFormCreate.isSuccess
    },
    mensage,
    title,
    mensageSuscess
  };
};

export default useProfileSubModuleForm;
