import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 720px;
  padding: 10px;

  hr {
    border: 1px solid #444;
    margin-bottom: 10px;
  }

  .viewPhotoScale {
    max-width: 730px;
    height: 280px;

    img {
      width: auto;
      max-width: 670px;
      height: 280px;
    }
  }
`;

export const TableTitle = styled.h2`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  margin-bottom: 5px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -10px;

  .cancelBtn {
    background-color: #dcdcdc;
    color: #444;
    width: 120px;
    padding: 10px;
    height: 40px;
  }

  .saveBtn {
    padding: 10px;
    height: 40px;
    width: 20%;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .inputId {
    background-color: #efefef;
  }
`;

const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;

export const DropContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #666;
  border-radius: 4px;
  cursor: pointer;
  height: 280px;
  background-color: #efefef;
  width: 661px;

  transition: height 0.2s ease;

  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};

  .inputMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      height: 40px;
      width: 40px;
      color: #999;
    }
  }
`;

const messageColors = {
  default: '#999',
  error: '#e57878',
  success: '#78e5d5'
};

export const UploadMessage = styled.p`
  display: flex;
  color: ${(props) => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
`;

export const ThumbsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 5px;
  overflow-y: auto;
  padding: 0 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  img {
    height: 100%;
    width: 100%;
  }

  button {
    border: none;
    background-color: transparent;
  }
`;

export const ThumbItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  //height: 82px;

  &:hover {
    img {
      border: 2px solid #fff;
    }
  }

  .thumbsContainer__button {
    background-color: transparente;
    cursor: default;
  }

  .thumbsContainer__buttonMain {
    color: #79ac34;
    background-color: #fff;
    border: 1px solid #79ac34;
    height: 25px;
    font-size: 13px;
    width: 109px;
    padding: 0px;
    margin-top: 2px;
    border-radius: 2px;
  }

  .thumbsContainer__buttonMain:active {
    color: #608929;
    border: 1px solid #608929;
    transform: translateY(2px);
  }

  img {
    height: 100%;
  }
`;

export const ThumbItem = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ThumbImage = styled.div`
  background-color: transparente;
  cursor: default;
  width: 100%;
  height: 100%;

  img {
    max-height: 300px;
    object-fit: contain;
  }

  &:hover {
    border: 5px solid #79ac34;
  }
`;

export const ThumbIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  svg {
    margin-left: 5px;
  }
`;

export const ThumbButtonIcon = styled.button`
  border: none;
  background-color: transparent;
  margin-top: 3px;
  margin-bottom: 10px;
`;

export const PhotoContainerScale = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .photoContainerScale__input {
    display: none;
  }
`;

export const DropContainerScale = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #666;
  border-radius: 4px;
  cursor: pointer;
  height: 280px;
  background-color: #efefef;
  width: 100%;

  transition: height 0.2s ease;

  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};

  .inputMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      height: 40px;
      width: 40px;
      color: #999;
    }
  }
`;

export const ThumbButtonIconScale = styled.button`
  border: none;
  background-color: transparent;
  margin-bottom: 10px;
`;

export const UploadFileContainer = styled.div`
  display: flex;
  padding-left: 22px;
`;

export const PhotoCarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubtitlePhotoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  font-size: 14px;
  color: #79ac34;
  margin: 10px 0;

  button {
    border: none;
    background-color: #79ac34;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 13px;
    height: 40px;
    cursor: pointer;

    &:hover {
      background-color: #608929;
    }
  }
`;

export const AddFiles = styled.div`
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  margin: 5px 0;

  transition: height 0.2s ease;

  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};

  svg {
    color: #79ac34;
    font-size: 14px;
  }
`;
