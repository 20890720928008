/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProfileSubModuleList } from './ProfileSubModuleList';
import ProfileSubModuleForm from './ProfileSubModuleForm/SubModuleModuleForm';

const ProfileSubModuleRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ProfileSubModuleList />}>
        <Route path="/new" element={<ProfileSubModuleForm type="new" />} />
        <Route
          path="/edit/:id/:idAggregate"
          element={<ProfileSubModuleForm type="edit" />}
        />
      </Route>
    </Routes>
  );
};

export default ProfileSubModuleRouter;
