import styled from 'styled-components';

export const Container = styled.div`
  max-width: 300px;
  width: 100%;
  height: 65%;

  h1 {
    margin-left: 5px;
    font-size: 21px;
    margin-bottom: 15px;
  }

  hr {
    border: solid 1px #dcdcdc;
  }

  p {
    margin-top: 25px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 12px;
    color: #8d8d8d;
    opacity: 1;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;

  .cardContainer {
    height: 100%;
    max-height: 365px;
    overflow: auto;
    scrollbar-width: thin;
  }
`;

export const ZoneCardContainer = styled.div`
  display: flex;
  height: 115px;
  flex-direction: column;
  border: 1px solid #a3a3a3;
  padding: 10px 12px;
  border-radius: 8px;
  box-shadow: 1px 3px 6px #0000001a;
  border: 1px solid #dcdcdc;

  & + div {
    margin-top: 10px;
  }

  .active {
    border: 1px solid #79ac34;
  }

  .recommMade {
    border: none;
    color: #62904e;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
`;

export const ZoneCardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ZoneCardText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;

  h3 {
    flex: 1;
    font-size: 0.9rem;
  }

  span {
    font-size: 0.7rem;
  }
`;

export const ViewAction = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    height: 100%;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ZoneCardActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 10px;

  button {
    display: flex;
    background: transparent;
    border: none;

    & + button {
      margin-left: 10px;
    }
    > span {
      color: #79ac34;
      font-size: 0.8rem;
    }
    > svg {
      width: 16px;
      height: 16px;
      color: #79ac34;
    }
  }
`;

export const RecommendationButton = styled.button`
  width: 100%;
  height: 45px;
  background-color: #ffffff;
  color: #79ac34;
  font-size: 13px;
  font-weight: bold;
  margin-top: 10px;
  border: 1px solid #79ac34;
  border-radius: 8px;
  opacity: 1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;
