import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../../../../../../hooks/Auth';
import ProfileApi from '../../../../../../../../APi/FarmApi/ProfileApi';
import {
  ModuleProfileForm,
  UserProfile,
  useModuleProfileFormProps
} from '../../utils/moduleProfile.types';
import ModuleProfileApi from '../../../../../../../../APi/FarmApi/ModuleProfileApi';

const useModuleProfileForm = (props: useModuleProfileFormProps) => {
  const { token, user } = useAuth();
  const moduleProfileApi = useMemo(() => new ModuleProfileApi(token), []);
  const profileApi = useMemo(() => new ProfileApi(token), []);
  const [formValid, setFormValid] = useState<boolean>(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { type, listIdaggregate = [], moduleList } = props;
  const { id } = useParams();
  const [filter, setFilter] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  function moduleProfileMap(moduleProfile: any): UserProfile {
    return {
      id: moduleProfile.id,
      name: moduleProfile.name,
      nameAggregate: moduleProfile.nameaggregate,
      acronym: moduleProfile.acronym
    };
  }

  const moduleProfileQuery = useQuery({
    queryKey: [profileApi.ListaAtrelarPerfilModulo, { filter, id }],
    queryFn: async () =>
      profileApi.ListarAtrelarPerfilModulo(filter, id, user.organizacao_id)
  });

  const FormValidationSchema = Yup.object().shape({
    user_profile_id: Yup.string().required(),
    module_id: Yup.array().required()
  });

  const methods = useForm({
    defaultValues: {
      user_profile_id: undefined,
      module_id: [],
      acronym: ''
    },
    resolver: yupResolver(FormValidationSchema)
  });

  const moduleProfileFormCreate = useMutation({
    mutationFn: async (form: ModuleProfileForm) => {
      form.acronym = moduleList.find(
        (module: any) => module.id === form.module_id[0] // na criação só tera um
      )?.acronym;
      await moduleProfileApi.AtrelarPerfilModulo(form);
    },

    onSuccess: async () => {
      setIsSuccess(true);
      navigate(-1);
      await queryClient.invalidateQueries(
        profileApi.ListaAtrelarPerfilModuloParaTabela
      );
    }
  });
  const moduleProfileFormUpdate = useMutation({
    mutationFn: async (form: ModuleProfileForm) => {
      const moduleListId = form.module_id.filter(
        (x: any) => !listIdaggregate.includes(x)
      );
      const moduleListOld = form.module_id.filter((x: any) =>
        listIdaggregate.includes(x)
      );
      const desSelect = listIdaggregate.filter(
        (x) => !moduleListId.concat(moduleListOld).includes(x)
      );

      desSelect.forEach(async (item) => {
        form.acronym = moduleList.find(
          (module: any) => {
            return module.id === desSelect[0];
          } // na criação só tera um
        )?.acronym;
        await moduleProfileApi.DeletarPerfilModulo({
          user_profile_id: form.user_profile_id,
          module_id: item,
          acronym: form.acronym
        });
      });

      moduleListId.forEach(async (item) => {
        form.acronym = moduleList.find(
          (module: any) => module.id === form.module_id[0] // na criação só tera um
        )?.acronym;
        await moduleProfileApi.AtrelarPerfilModulo({
          user_profile_id: form.user_profile_id,
          module_id: item,
          acronym: form.acronym
        });
      });
      await queryClient.invalidateQueries(
        profileApi.ListaAtrelarPerfilModuloParaTabela
      );
    },
    onSuccess: async () => {
      setIsSuccess(true);
      await queryClient.invalidateQueries(
        profileApi.ListaAtrelarPerfilModuloParaTabela
      );
      navigate(-1);
    }
  });
  useEffect(() => {
    if (type === 'edit') return;
    setFormValid(
      !methods.control.getValues('user_profile_id') ||
        methods.control.getValues('module_id').length === 0
    );
  }, [methods.control.watchInternal()]);

  const submit = methods.handleSubmit(async (form: ModuleProfileForm) => {
    if (type === 'new') {
      await moduleProfileFormCreate.mutateAsync(form);
    } else {
      await moduleProfileFormUpdate.mutateAsync(form);
    }
  });

  const title: Record<string, string> = {
    new: 'Atrelamento de Perfil com Módulo',
    edit: 'Edição de Atrelamento de Perfil com Módulo '
  };
  const mensageSuccess: Record<string, string> = {
    new: 'Atrelamento feito com sucesso',
    edit: 'Edição do atrelamento feito com sucesso'
  };

  return {
    form: {
      methods,
      submit,
      isValid: formValid,
      isSuccess
    },
    title,
    mensageSuccess,
    setIsSuccess
  };
};

export default useModuleProfileForm;
