import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { OrderedTable } from '../../../../../../../components/OrderedTable';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import { useUserProfileListRequest } from './hooks/useUserProfileListRequest';
import { ResearchField } from '../../../../../../../components/ResearchField';
import { UserProfile } from './utils/userProfileList.types';

const UserProfileList: FC = () => {
  const { request, onChangeFilter } = useUserProfileListRequest();

  const headCells: HeadCell<UserProfile>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Nome'
    },
    {
      id: 'file',
      numeric: false,
      disablePadding: false,
      label: 'Perfil'
    }
  ];

  return (
    <>
      <ResearchField onChange={onChangeFilter} />
      <OrderedTable
        rows={request.items}
        HeadCell={headCells}
        // IconCreate={EditIcon}
        title="Usuários x Perfil"
        routeNameAdd="./new"
        routeNameEdit="./edit"
        selectInLine
        checkBox={false}
      />
      <Outlet />
    </>
  );
};
export default UserProfileList;
