import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  h1 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    margin-top: 25px;
    color: #444444;
  }

  h2 {
    font-weight: normal;
    font-size: 1rem;
    margin-bottom: 15px;
    color: #444444;
  }

  hr {
    border: solid 1px #dcdcdc;
  }

  .selectSample {
    width: 100%;
  }

  ${(props) =>
    props.show &&
    css`
      height: 50%;
    `}
  transition: 0.5s;
`;
export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  .selectContainer {
    width: 100%;
    max-width: 300px;
    min-width: 200px;
    margin-right: 20px;
  }
`;
export const GraphicContent = styled.div`
  min-width: 450px;
  margin-left: 20px;
  right: 0;
`;

export const AddButton = styled.button`
  background-color: #fff;
  color: #79ac34;
  border: 1px solid #79ac34;
  height: 55px;
  border-radius: 8px;
  padding: 0 16px;
  width: 100%;
  font-weight: bold;
  margin-top: 16px;
  transition: all 0.2s;
`;

export const ChartContainer = styled.div`
  width: 30%;
`;
