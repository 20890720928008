/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MdChevronRight, MdDelete, MdModeEdit } from 'react-icons/md';
import Button from '../../../../../../components/Button';
import Toast from '../../../../../../components/Toast';
import CardSelect from '../../../../../../components/CardSelect';
import InstructionsToast from '../../../../../../components/Toast/Instructions';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import api from '../../../../../../services/api';
import { DashboardContext } from '../../../../index';
import { getDepth, setDepth } from '../../../../../../helpers/parseDephPoint';
import * as S from './styled';

const SelectParametersRecommendation = () => {
  const {
    dashboardState,
    setDashboardState,
    setSubdivisions,
    resultVisible,
    setResultVisible,
    setFields
  } = useContext(DashboardContext);
  const {
    refMap,
    recommZone,
    setRecommZone,
    selectedFarmId,
    setListSubdivisions,
    setLoading,
    renderSubdivisions,
    createRecommendationZone,
    removeRecommendationZone,
    setListFields,
    renderFields,
    recommZoneDrawed,
    removeDrawer,
    recommZoneList,
    renderRecommendationZones,
    createRecommendationZoneSelect,
    finalizeCreateRecommendationZoneSelect,
    tableResults,
    setRecommDepth,
    recommName,
    recommType,
    setRecommType,
    selectedRecommZone,
    listSubdivisions
  } = useMap();
  const { token, user } = useAuth();

  const DepthOptions = [
    '0-10',
    '0-20',
    '0-20-40',
    '0-10-20',
    '0-10-20-40',
    '0-10-20-30-40',
    '0-10-20-30-40-60'
  ];

  const [layer, setLayer] = useState('');
  const [newTableResult, setNewTableResult] = useState([]);

  const getSubdivisions = async () => {
    try {
      if (!selectedFarmId) {
        return toast(
          <Toast
            title="Por favor selecione uma fazenda"
            content="Para que as subdivisões sejam carregadas é necessário selecionar uma fazenda"
          />,
          { type: 'warning' }
        );
      }
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSubdivisions(response.data.data);
      renderSubdivisions(response.data.data);
      setListSubdivisions(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getFields = async () => {
    try {
      setFields([]);
      setListFields([]);
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Field/GetActiveFieldListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFields(response.data.data);
      setListFields(response.data.data);
      renderFields(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setFields([]);
    }
  };

  const cancelRecommZone = () => {
    setDashboardState('dashboard@resultsMap');
  };

  const selectedDephtRecommZone = (depth) => {
    const subdivisionsRecommZone = listSubdivisions.filter((v) =>
      selectedRecommZone.features[0].properties.subdivisions_list.includes(
        v.features[0].properties.subdivision_id
      )
    );
    const descriptions = subdivisionsRecommZone.map(
      (srz) => srz.features[0].properties.description
    );
    const shortTableResults = tableResults.filter(
      (result) =>
        descriptions.includes(result.original) &&
        result.prof === getDepth(depth)
    );

    setNewTableResult(shortTableResults);
  };

  const averageResults = (param) => {
    const valuesParam = newTableResult.map((result) => result[param]);
    const avarage = valuesParam.reduce((pv, cv) => pv + Number(cv), 0);

    if (isNaN(avarage)) {
      return '---';
    }
    return (avarage / valuesParam.filter((vp) => !isNaN(vp)).length).toFixed(2);
  };

  return (
    <S.Container>
      <h1>Mapa de resultados</h1>

      <S.ZoneCardContainer>
        <S.ZoneCardHeader />
        <S.ZoneCardText>
          <h3>{recommName}</h3>
        </S.ZoneCardText>
        {/* <S.ZoneCardActions>
          <button
            type="button"
            className="actionBtn"
          >
            <span>Editar</span>
            <MdModeEdit />
          </button>
          <button
            type="button"
            className="actionBtn"
          >
            <span>Deletar</span>
            <MdDelete />
          </button>
        </S.ZoneCardActions> */}
        <S.ZoneCardActions>
          <S.ActionButtonCard
            status={recommType === 'corrective'}
            onClick={() => setRecommType('corrective')}
          >
            Corretivo
          </S.ActionButtonCard>
          <S.ActionButtonCard
            status={recommType === 'fertilizer'}
            onClick={() => setRecommType('fertilizer')}
          >
            Fertilizante
          </S.ActionButtonCard>
        </S.ZoneCardActions>
      </S.ZoneCardContainer>
      <S.TitleBox>Escolha a camada</S.TitleBox>
      <S.DepthContainer>
        <CardSelect
          text={getDepth(DepthOptions[0])}
          onClick={() => {
            setLayer(getDepth(DepthOptions[0]));
            setRecommDepth(getDepth(DepthOptions[0]));
            selectedDephtRecommZone(getDepth(DepthOptions[0]));
          }}
          selected={layer === getDepth(DepthOptions[0])}
        />
        <CardSelect
          text={getDepth(DepthOptions[3])}
          onClick={() => {
            setLayer(getDepth(DepthOptions[3]));
            setRecommDepth(getDepth(DepthOptions[3]));
            selectedDephtRecommZone(getDepth(DepthOptions[3]));
          }}
          selected={layer === getDepth(DepthOptions[3])}
        />
        <CardSelect
          text={getDepth(DepthOptions[2])}
          onClick={() => {
            setLayer(getDepth(DepthOptions[2]));
            setRecommDepth(getDepth(DepthOptions[2]));
            selectedDephtRecommZone(getDepth(DepthOptions[2]));
          }}
          selected={layer === getDepth(DepthOptions[2])}
        />
        <br />
      </S.DepthContainer>
      <S.DepthContainer>
        <CardSelect
          text={getDepth(DepthOptions[1])}
          onClick={() => {
            setLayer(getDepth(DepthOptions[1]));
            setRecommDepth(getDepth(DepthOptions[1]));
            selectedDephtRecommZone(getDepth(DepthOptions[1]));
          }}
          selected={layer === getDepth(DepthOptions[1])}
        />
        <CardSelect
          text={getDepth(DepthOptions[5])}
          onClick={() => {
            setLayer(getDepth(DepthOptions[5]));
            setRecommDepth(getDepth(DepthOptions[5]));
            selectedDephtRecommZone(getDepth(DepthOptions[5]));
          }}
          selected={layer === getDepth(DepthOptions[5])}
        />
        <CardSelect
          text={getDepth(DepthOptions[6])}
          onClick={() => {
            setLayer(getDepth(DepthOptions[6]));
            setRecommDepth(getDepth(DepthOptions[6]));
            selectedDephtRecommZone(getDepth(DepthOptions[6]));
          }}
          selected={layer === getDepth(DepthOptions[6])}
        />
      </S.DepthContainer>
      {newTableResult.length > 0 && (
        <>
          {recommType === 'corrective' ? (
            <S.tableContainer>
              <S.TableRecomm>
                <tr className="titleTable">
                  <td>Original</td>
                  <td>pH_CaCl2</td>
                  <td>Al</td>
                  <td>MO</td>
                  <td>Argila</td>
                  <td>CTC</td>
                  <td>V%</td>
                  <td>CaMg</td>
                </tr>
                {newTableResult.lenght <= 0 ? null : (
                  <>
                    {newTableResult.map((item) => (
                      <tr className="content">
                        <td>{item.original}</td>
                        <td>
                          {isNaN(Number(item.pH_CaCl2).toFixed(2))
                            ? '---'
                            : Number(item.pH_CaCl2).toFixed(2)}
                        </td>
                        <td>
                          {isNaN(Number(item.al).toFixed(2))
                            ? '---'
                            : Number(item.al).toFixed(2)}
                        </td>
                        <td>
                          {isNaN(Number(item.mo).toFixed(2))
                            ? '---'
                            : Number(item.mo).toFixed(2)}
                        </td>
                        <td>
                          {isNaN(Number(item.argila).toFixed(2))
                            ? '---'
                            : Number(item.argila).toFixed(2)}
                        </td>
                        <td>
                          {isNaN(Number(item.ctc).toFixed(2))
                            ? '---'
                            : Number(item.ctc).toFixed(2)}
                        </td>
                        <td>
                          {isNaN(Number(item.v).toFixed(2))
                            ? '---'
                            : Number(item.v).toFixed(2)}
                        </td>
                        <td>
                          {isNaN(Number(item.caMg).toFixed(2))
                            ? '---'
                            : Number(item.caMg).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
                <tr>
                  <td>
                    <b>Média</b>
                  </td>
                  <td>{averageResults('pH_CaCl2')}</td>
                  <td>{averageResults('al')}</td>
                  <td>{averageResults('mo')}</td>
                  <td>{averageResults('argila')}</td>
                  <td>{averageResults('ctc')}</td>
                  <td>{averageResults('v')}</td>
                  <td>{averageResults('caMg')}</td>
                </tr>
              </S.TableRecomm>
            </S.tableContainer>
          ) : (
            <S.tableContainer>
              <S.TableRecomm>
                <tr className="titleTable">
                  <td>Original</td>
                  <td>P</td>
                  <td>K</td>
                  <td>MO</td>
                  <td>Argila</td>
                  <td>V%</td>
                  <td>Zn</td>
                  <td>S</td>
                  <td>B</td>
                </tr>
                {newTableResult.lenght <= 0 ? null : (
                  <>
                    {newTableResult.map((item) => (
                      <tr className="content">
                        <td>{item.original}</td>
                        <td>
                          {isNaN(Number(item.p).toFixed(2))
                            ? '---'
                            : Number(item.p).toFixed(2)}
                        </td>
                        <td>
                          {isNaN(Number(item.k).toFixed(2))
                            ? '---'
                            : Number(item.k).toFixed(2)}
                        </td>
                        <td>
                          {isNaN(Number(item.mo).toFixed(2))
                            ? '---'
                            : Number(item.mo).toFixed(2)}
                        </td>
                        <td>
                          {isNaN(Number(item.argila).toFixed(2))
                            ? '---'
                            : Number(item.argila).toFixed(2)}
                        </td>
                        <td>
                          {isNaN(Number(item.v).toFixed(2))
                            ? '---'
                            : Number(item.v).toFixed(2)}
                        </td>
                        <td>
                          {isNaN(Number(item.zn).toFixed(2))
                            ? '---'
                            : Number(item.zn).toFixed(2)}
                        </td>
                        <td>
                          {isNaN(Number(item.s).toFixed(2))
                            ? '---'
                            : Number(item.s).toFixed(2)}
                        </td>
                        <td>
                          {isNaN(Number(item.b).toFixed(2))
                            ? '---'
                            : Number(item.b).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
                <tr>
                  <td>
                    <b>Média</b>
                  </td>
                  <td>{averageResults('p')}</td>
                  <td>{averageResults('k')}</td>
                  <td>{averageResults('mo')}</td>
                  <td>{averageResults('argila')}</td>
                  <td>{averageResults('v')}</td>
                  <td>{averageResults('zn')}</td>
                  <td>{averageResults('s')}</td>
                  <td>{averageResults('b')}</td>
                </tr>
              </S.TableRecomm>
            </S.tableContainer>
          )}
        </>
      )}
      <S.Content className="positionButton">
        <S.ButtonContainer>
          <Button
            text="Realizar Recomendação"
            onClick={() => setDashboardState('dashboard@makeRecommendation')}
          />
        </S.ButtonContainer>
        <S.ButtonContainer>
          <Button text="Gerar mapa de recomendação" disabled />
        </S.ButtonContainer>
        <S.ButtonContainer>
          <Button text="Cancelar" outlined onClick={() => cancelRecommZone()} />
        </S.ButtonContainer>
      </S.Content>
    </S.Container>
  );
};

export default SelectParametersRecommendation;
