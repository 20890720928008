/* eslint-disable indent */
import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: #fff;
  border-radius: 4px;
  border: 1px solid #a3a3a3;
  color: #a3a3a3;
  width: 100%;
  display: flex;
  align-items: center;

  & + div {
    /* margin-top: 16px; */
  }
  ${(props) =>
    props.isErrored &&
    css`
      border-color: #d62222 !important;
    `}
  ${(props) =>
    props.isFocused &&
    css`
      color: #79ac34;
      border-color: #79ac34;
    `}
    ${(props) =>
    props.isFilled &&
    css`
      border-color: #79ac34;
    `}

  input {
    flex: 1;
    width: 100%;
    height: 50px;
    background: transparent;
    border-radius: 8px;

    border: 0;
    color: #444444;
    padding: 12px;

    &::placeholder {
      color: #a3a3a3;
    }
  }

  svg {
    margin-right: 16px;
  }
`;
