import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { UserFarmsList } from './UserFarmsList';
import { UserFarmsForm } from './UserFarmsForm';

const UserFarmsRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<UserFarmsList />}>
        <Route path="/new" element={<UserFarmsForm />} />
      </Route>
    </Routes>
  );
};

export default UserFarmsRouter;
