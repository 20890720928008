import styled from 'styled-components';

export const Container = styled.div`
  max-width: 300px;
  width: 100%;
  height: 65%;
  h1 {
    margin-left: 5px;
    font-size: 1.1rem;
    margin-bottom: 15px;
  }

  hr {
    border: solid 1px #dcdcdc;
  }

  p {
    margin-top: 25px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 12px;
    color: #8d8d8d;
    opacity: 1;
  }

  .backButton {
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    margin-bottom: 25px;
    padding: 0px;
    margin-left: -7px;

    button {
      display: flex;
      align-items: center;
      font-weight: bold;

      > span {
        margin-left: 5px;
      }

      > svg {
        height: 20px;
        width: 20px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .cardContainer {
    height: 90%;
    overflow: auto;
    scrollbar-width: thin;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
  height: 100%;
`;

export const FarmCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #a3a3a3;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);

  & + div {
    margin-top: 10px;
  }

  .active {
    border: 1px solid #79ac34;
  }
`;

export const FarmCardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FarmCardText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h3 {
    font-size: 0.9rem;
  }

  span {
    font-size: 0.7rem;
  }
`;

export const ViewAction = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    height: 100%;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FarmCardActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;

  button {
    display: flex;
    background: transparent;
    border: none;

    & + button {
      margin-left: 10px;
    }
    > span {
      color: #79ac34;
      font-size: 0.8rem;
    }
    > svg {
      width: 16px;
      height: 16px;
      color: #79ac34;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 45px;
  background-color: #ffffff;
  color: #79ac34;
  font-size: 13px;
  font-weight: bold;
  margin-top: 10px;
  border: 1px solid #79ac34;
  border-radius: 8px;
  opacity: 1;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;

  .inputDose {
    border-color: #fff;
    border: none;
  }
`;

export const TitleBox = styled.span`
  text-align: left;
  letter-spacing: 0px;
  color: #444444;
  opacity: 1;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const MeasurementContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const UnitOfMeasurement = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const ActionButtonCard = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.status ? '#79ac34' : 'transparent')};
  border 1px solid #79ac34;
  color: ${(props) => (props.status ? '#FFF' : '#79ac34')};
  padding: 5px;
  width: 100px;
  border-radius: 5px;
`;
