/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import ProfileApi from '../../../../../../../../APi/FarmApi/ProfileApi';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { Profile } from '../../../Profile/ProfileList/utils/profile.types';
import SystemModuleApi from '../../../../../../../../APi/FarmApi/SystemModuleApi';
import OrganizacaoApi from '../../../../../../../../APi/FarmApi/OrganizacaoApi';

export const useOrganizacaoProfileFormRequest = () => {
  const { token, user } = useAuth();
  const profileApi = useMemo(() => new ProfileApi(token), []);
  const organizacaoApi = useMemo(() => new OrganizacaoApi(token), []);
  const [filterSubModule, setFilterSubModule] = useState<string>('');
  const [systemModuleFilter, setSystemModuleFilter] = useState<string>('');
  const [profileId, setProfileId] = useState<string>('');
  const { id } = useParams();
  function moduleProfileMap(moduleProfile: any) {
    return moduleProfile.idaggregate;
  }

  const moduleProfileQuery = useQuery({
    queryKey: [
      organizacaoApi.retomarListaOrganizacaoAtrelamento,
      { profileId }
    ],
    queryFn: async () =>
      organizacaoApi.ListarAtrelarPerfilOrganizacao(
        '',
        id,
        user.organizacao_id ?? profileId
      )
  });

  const profileDate = useQuery({
    queryKey: [profileApi.getProfile, { filterSubModule }],
    queryFn: () => profileApi.ProfileList(filterSubModule)
  });

  function subModuleMap(date: any): Profile {
    return {
      id: date.userprofile_id,
      name: date.description
    };
  }

  const organizacaoData = useQuery({
    queryKey: [organizacaoApi.getOrganizacao, { systemModuleFilter }],
    queryFn: async () => organizacaoApi.GetOrganizacao(systemModuleFilter)
  });

  function moduleDataMap(User: any) {
    return {
      id: User.id,
      name: User.name
    };
  }

  const listaAtrelados: any[] =
    moduleProfileQuery.data?.data.data.map(moduleProfileMap) ?? [];

  return {
    request: {
      itemsProfile: id
        ? profileDate.data?.data.data
            .filter((x: any) => x.userprofile_id === id)
            .map(subModuleMap) ?? []
        : profileDate.data?.data.data.map(subModuleMap) ?? [],
      itemsOrganizacao: id
        ? organizacaoData.data?.data.data.map(moduleDataMap) ?? []
        : profileId
        ? organizacaoData.data?.data.data
            .filter((x: any) => !listaAtrelados.includes(x.id))
            .map(moduleDataMap) ?? []
        : organizacaoData.data?.data.data.map(moduleDataMap) ?? [],

      listIdaggregate: listaAtrelados,
      idOrganizacao: id?.toString(),
      isRedy: moduleProfileQuery.isLoading || moduleProfileQuery.isFetching
    },
    filter: {
      setFilterSubModule,
      setSystemModuleFilter
    },
    setProfileId
  };
};
