/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { yupResolver } from '@hookform/resolvers/yup';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import 'jquery-mask-plugin';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Toast from '../../../components/Toast';
import { appConfig } from '../../../config';
import { parsePhone, parseCpfCnpj } from '../../../helpers/parseValues';
import api from '../../../services/api';
import {
  Background,
  Container,
  Content,
  ErrorContainer,
  Form,
  SignIn
} from './styled';
import authService from '../../../services/auth';

const SignUpOtherData = ({ userEmail }: { userEmail: any }) => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    setEmail(userEmail);

    const cpfCnpjOptions = {
      onKeyPress(cpf, _ev, _el, op) {
        const masks = ['000.000.000-000', '00.000.000/0000-00'];
        $('#cpf_cnpj').mask(cpf.length > 14 ? masks[1] : masks[0], op);
      }
    };
    // eslint-disable-next-line no-unused-expressions
    $('#cpf_cnpj').length > 11
      ? $('#cpf_cnpj').mask('00.000.000/0000-00', cpfCnpjOptions)
      : $('#cpf_cnpj').mask('000.000.000-00#', cpfCnpjOptions);
    // eslint-disable-next-line no-unused-expressions
    $('#phone').mask('(00) 0000-00009');
    // eslint-disable-next-line
    $('#phone').blur(function (event) {
      if ($(this).val().length === 15) {
        // Celular com 9 dígitos + 2 dígitos DDD e 4 da máscara
        $(this).mask('(00) 00000-0009');
      } else {
        $(this).mask('(00) 0000-00009');
      }
    });
  }, []);
  const signInValidationSchema = Yup.object().shape({
    cpf_cnpj: Yup.string(),
    // .required('CPF ou CNPJ é obrigatório')
    // .matches(
    //   '([0-9]{2}[-./]?[0-9]{3}[-./]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[-./]?[0-9]{3}[-./]?[0-9]{3}[-./]?[0-9]{2})',
    //   'CPF ou CNPJ Invalido'
    // ),
    phone: Yup.string()
      .required('Telefone é obrigatório')
      .min(14, 'Numero de telefone invalido'),
    email: Yup.string()
      .email('E-mail é inválido')
      .required('E-mail é obrigatório'),
    fullname: Yup.string()
      .required('Nome completo ou Razão social é obrigatório')
      .min(10),
    password: Yup.string()
      .required('Senha é obrigatória')
      .min(6, 'Senha deve conter no minimo 6 caracteres'),
    passwordConfirmation: Yup.string()
      .required('Confirmação de senha é obrigatória')
      .oneOf([Yup.ref('password'), null], 'As senhas deve ser iguais')
  });

  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(signInValidationSchema)
  });

  const onSubmit = async (data: any) => {
    const parsedCpfCnpj = parseCpfCnpj(data.cpf_cnpj);
    const parsedPhone = parsePhone(data.phone);
    // if (isValidCpfCnpj(parsedCpfCnpj) === true) {
    data.cpf_cnpj = parsedCpfCnpj;
    data.phone = parsedPhone;
    delete data.passwordConfirmation;
    try {
      await authService.userFundacao(data);

      toast(
        <Toast
          title="Usuário cadastrado com sucesso."
          content="Você foi redirecionado para a tela de login."
        />,
        {
          type: 'success'
        }
      );
      navigate('/');
    } catch (error) {
      toast(
        <Toast
          title="Não foi possível cadastrar o usuário."
          content="Verifique os dados inseridos no formulário"
        />,
        { type: 'error' }
      );
    }
    // } else {
    //   setError('cpf_cnpj', { message: 'CPF ou CNPJ inválido.' });
    // }
  };

  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <span>Cadastre-se</span>
          <p>Para se cadastrar precisamos de algumas informações suas:</p>
          <br />
          <ErrorContainer>
            <span>{errors.cpf_cnpj?.message}</span>
            {errors.cpf_cnpj?.message && <br />}
            <span>{errors.phone?.message}</span>
            {errors.phone?.message && <br />}
            <span>{errors.fullname?.message}</span>
            {errors.fullname?.message && <br />}
            <span>{errors.email?.message}</span>
            {errors.email?.message && <br />}
            <span>{errors.password?.message}</span>
            {errors.password?.message && <br />}
            <span>{errors.passwordConfirmation?.message}</span>
          </ErrorContainer>
          <span>Usuário</span>
          <table>
            <thead>
              <tr>
                <td>
                  <Input
                    type="text"
                    name="cpf_cnpj"
                    id="cpf_cnpj"
                    placeholder="CPF ou CNPJ"
                    ref={register}
                    error={!!errors.cpf_cnpj?.message}
                    defaultValue=""
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Telefone"
                    ref={register}
                    error={!!errors.phone?.message}
                    defaultValue=""
                  />
                </td>
              </tr>
            </thead>
          </table>
          <table>
            <thead>
              <tr>
                <td>
                  <Input
                    type="text"
                    name="fullname"
                    placeholder="Nome"
                    ref={register}
                    error={!!errors.fullname?.message}
                    defaultValue=""
                  />
                </td>
              </tr>
            </thead>
          </table>

          <span>Dados de login</span>
          <table>
            <thead>
              <tr>
                <td>
                  <Input
                    style={{ borderColor: '#79ac34' }}
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    ref={register}
                    defaultValue={email}
                    error={!!errors.email?.message}
                  />
                </td>
              </tr>
            </thead>
          </table>
          <table>
            <thead>
              <tr>
                <td>
                  <Input
                    type="password"
                    name="password"
                    placeholder="Senha"
                    ref={register}
                    defaultValue=""
                    error={!!errors.password?.message}
                  />
                </td>
                <td>
                  <Input
                    type="password"
                    name="passwordConfirmation"
                    placeholder="Confirmar senha"
                    ref={register}
                    defaultValue=""
                    error={!!errors.passwordConfirmation?.message}
                  />
                </td>
              </tr>
            </thead>
          </table>
          <Button text="Cadastrar" />
          <SignIn>
            <p>Já tem cadastro?</p>
            <Link to="/">Faça o login</Link>
          </SignIn>
        </Form>
      </Content>
      <Background>
        <p>© 2020 Fundação MT - Todos os direitos estão reservados.</p>
      </Background>
    </Container>
  );
};

SignUpOtherData.propTypes = {
  userEmail: PropTypes.string.isRequired
};

export default SignUpOtherData;
