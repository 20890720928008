/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import GlobalStyle from './styles/global';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from './hooks/Auth';
import 'ol/ol.css';
import './services/firebase';
import { appConfig } from './config';

const App: React.FC = () => {
  const { verifyToken } = useAuth();
  useEffect(() => {
    const token = localStorage.getItem('@FundacaoMT:token');
    if (token) {
      verifyToken(token);
    }
  }, []);
  return (
    <BrowserRouter>
      <Routes />
      <GlobalStyle />
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
