import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { UserFilter } from '../../utils/users.types';
import UserAPI from '../../../../../../../../APi/FarmApi/UserAPI';
import { useAuth } from '../../../../../../../../hooks/Auth';

export const useUsersRequest = () => {
  const {
    token,
    user: { organizacao_id }
  } = useAuth();
  const useApi = useMemo(() => new UserAPI(token), []);
  const [useFilter, setUserFilter] = useState<string>('');

  const onChangeFilter = (search: string) => setUserFilter(search);

  const UserData = useQuery({
    queryKey: [useApi.getUser, { useFilter }],
    queryFn: async () => useApi.UserList(useFilter, organizacao_id)
  });
  function mascaraTelefone(tel: string) {
    if (!tel) return;
    tel = tel.replace(/\D/g, '');
    tel = tel.replace(/^(\d{2})(\d)/g, '($1) $2');
    tel = tel.replace(/(\d)(\d{4})$/, '$1-$2');
    tel = tel.substring(0, 15);
    return tel;
  }
  function ValidaCPF(cpf: string) {
    if (!cpf) return;
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  function mascaraCnpj(cnjs: string) {
    return cnjs.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5'
    );
  }

  function userMap(User: any, index) {
    return {
      idEndity: User.user_id,
      id: index + 1,
      name: User.fullname,
      email: User.email,
      tel: mascaraTelefone(User.phone_number),
      cpfCnpj:
        User.cpf_cnpj.length > 11
          ? mascaraCnpj(User.cpf_cnpj)
          : ValidaCPF(User.cpf_cnpj),
      active: User.active ? 'Ativado' : 'Desativado'
    };
  }

  return {
    request: {
      items: UserData.data?.data.data.map(userMap) ?? []
    },
    onChangeFilter
  };
};
