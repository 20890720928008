/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { Controller } from 'react-hook-form';
import { Checkbox, TextField } from '@mui/material';
import * as s from './utils/profileForm.style';
import useProfileForm from './hooks/useProfileForm';
import { useModal } from '../../../../../../../hooks/Modal';
import { ProfileFormProps } from '../ProfileList/utils/profile.types';
import Toast from '../../../../../../../components/Toast';
import { useAuth } from '../../../../../../../hooks/Auth';

const ProfileForm: FC<ProfileFormProps> = (props) => {
  const { modalCreatUserStyles } = useModal();
  const { role } = useAuth();
  const [sistema, modulo, subModulo] = role.split('|');
  const isMaster: boolean = sistema.includes('MASTER');
  const isAdmin: boolean = sistema.includes('Admin');
  const navigate = useNavigate();
  const {
    form,
    onSelectActivated,
    title,
    mensageSuccess,
    mensage,
    request,
    verific: { verificarOrganizacao },
    profileDate: { nomeOrganizacao: oldNomeOrganizacao }
  } = useProfileForm(props);
  const { type } = props;
  const showSelect: boolean = type === 'new' && (isMaster || isAdmin);
  const isOwner = form.methods.watch('isFarmOwner');
  useEffect(() => {
    if (form.statusCode.codeStatus === 200) {
      toast(
        <Toast
          title={mensageSuccess[type].title}
          content={mensageSuccess[type].content}
        />,
        {
          type: 'success'
        }
      );
    }
    if (form.statusCode.codeStatus === 409) {
      toast(
        <Toast
          title={form.statusCode.message}
          content={form.statusCode.message}
        />,
        {
          type: 'warning'
        }
      );
    }
  }, [form.statusCode]);
  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen
      style={{
        ...modalCreatUserStyles
      }}
    >
      <s.title className="container__title">{title[type]}</s.title>
      <s.container>
        <div>
          <div className="flex">
            <Controller
              name="description"
              control={form.control}
              render={({ onBlur, onChange, value }) => (
                <TextField
                  style={{ flex: 3 }}
                  type="text"
                  onBlur={({ target }) => {
                    onBlur();
                  }}
                  onChange={({ target }) => {
                    onChange(target.value);
                  }}
                  value={value}
                  label="Nome"
                />
              )}
            />
            {type !== 'new' && (
              <Controller
                name="active"
                control={form.control}
                render={({ value }) => (
                  <div
                    style={{ flex: 1, textAlign: 'left', alignSelf: 'center' }}
                  >
                    <label>
                      Ativado
                      <Checkbox onChange={onSelectActivated} checked={value} />
                    </label>
                  </div>
                )}
              />
            )}
          </div>
          {isOwner && (isMaster || isAdmin) && (
            <div className="flex">
              <Controller
                name="nomeOrganizacao"
                control={form.methods.control}
                render={(field) => (
                  <TextField
                    onChange={field.onChange}
                    onBlur={({ target }) => {
                      field.onBlur(target.value);
                      const { nomeOrganizacao } = form.methods.getValues();
                      if (
                        !nomeOrganizacao ||
                        nomeOrganizacao?.trim() !== oldNomeOrganizacao?.trim()
                      ) {
                        verificarOrganizacao.mutate(target.value);
                      }
                    }}
                    sx={{ mt: 5 }}
                    value={field.value}
                    name={field.name}
                    type="text"
                    label="Nome do Organização"
                    disabled={type === 'datails'}
                    style={{
                      backgroundColor: type === 'datails' ? '#DCDCDC' : '',
                      flex: 1
                    }}
                    variant="outlined"
                  />
                )}
              />
            </div>
          )}
          {((!isOwner && (isMaster || isAdmin)) || type === 'edit') && (
            <Controller
              name="organizacao_id"
              control={form.methods.control}
              render={({ onChange, value }) => (
                <div>
                  <FormControl sx={{ m: 0, width: '100%', mt: 5 }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Organização
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={value}
                      label="Organização"
                      disabled={type === 'edit'}
                      onChange={(data) => onChange(data.target.value)}
                    >
                      {request.itemsOrganizacao.map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            />
          )}
          {(isMaster || isAdmin) && type === 'new' && (
            <Controller
              name="isFarmOwner"
              control={form.methods.control}
              render={({ value }) => (
                <div
                  style={{
                    flex: 1,
                    textAlign: 'left'
                  }}
                >
                  <label>
                    <Checkbox
                      checked={value}
                      onChange={form.onSelectFarmOwner}
                    />
                    Proprietário
                  </label>
                </div>
              )}
            />
          )}
        </div>
        <s.containerbotton>
          <button
            type="button"
            className="containerbotton__bottoncancel"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </button>
          <button
            type="button"
            className={`containerbotton__bottonregister${
              form.isValid ? '--disabled' : ''
            }`}
            onClick={form.handleSubmit}
            disabled={form.isValid}
          >
            Registrar
          </button>
        </s.containerbotton>
      </s.container>
    </Modal>
  );
};

export default ProfileForm;
