import styled from 'styled-components';
import Input from '../../../../../../components/Input';

export const DialogContainer = styled.div`
  align-items: center;
  max-width: 600px;
  width: 600px;
  height: 650px;
  max-height: 650px;
`;

export const Text = styled.h1`
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const InputEmail = styled(Input)``;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const ConfirmationButton = styled.button`
  margin: 20px;
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
`;

export const PermissionTags = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const Container = styled.div`
  width: 100%;
  height: 220px;
  overflow: auto;
`;

export const ListCardContainer = styled.div`
  background-color: #fff;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  border-bottom: 1px solid #a3a3a3;
  padding: 5px 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin: 5px 10px;
`;

export const ListCardContainerText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 10px;

  h6 {
    font-size: 0.7rem;
    margin-right: 5px;
  }

  span {
    margin-right: 5px;
    font-size: 0.7rem;
    margin-bottom: 2px;
  }
`;

export const ListCardText = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

export const ListCardActions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;

  button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;

    & + button {
      margin-left: 10px;
    }
    > span {
      color: #79ac34;
      font-size: 0.9rem;
    }
    > svg {
      width: 14px;
      height: 14px;
      color: #79ac34;
    }
  }
`;
