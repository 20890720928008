import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  hr {
    margin: 10px 0;
    border: solid 1px #dcdcdc;
  }
  ${(props) =>
    props.loading &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      span {
        color: #79ac34;
        margin-left: 20px;
      }
    `}
`;

export const RegisterFarmDialog = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;

  h3 {
    font-size: 1rem;
    text-align: left;
    margin-bottom: 5px;
  }

  span {
    font-size: 0.8rem;
    margin-bottom: 10px;
    color: #a3a3a3;
  }

  button {
    background: transparent;
    border: none;
    color: #79ac34;
    font-size: 0.8rem;
    font-weight: bold;
  }
`;

export const ContainerBackButton = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
`;

export const Form = styled.form``;
export const ErrorMessage = styled.p`
  font-size: 0.8rem;
  color: #d62222;
  text-align: center;
  margin: 2.5px;
`;
