import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField
} from '@mui/material';
import PuffLoader from 'react-spinners/PuffLoader';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../../../../../../components/Button';
import Input from '../../../../../../../../components/Input';
import SimpleButton from '../../../../../../../../components/SimpleButton';
import Toast from '../../../../../../../../components/Toast';
import InstructionsToast from '../../../../../../../../components/Toast/Instructions';
import { appConfig } from '../../../../../../../../config';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { useMap } from '../../../../../../../../hooks/Map';
import api from '../../../../../../../../services/api';
import { DashboardContext } from '../../../../../../index';
import * as S from './styled';

export const RegisterFieldFromShapefileContext = createContext({});

export const RegisterFieldFromShapefileProvider = ({ children }) => {
  const [buttonDisable, setButtonDisable] = useState(true);

  return (
    <RegisterFieldFromShapefileContext.Provider
      value={{ buttonDisable, setButtonDisable }}
    >
      {children}
    </RegisterFieldFromShapefileContext.Provider>
  );
};

RegisterFieldFromShapefileProvider.propTypes = {
  children: PropTypes.node
};

const RegisterFieldFromShapefile = () => {
  const { setDashboardState } = useContext(DashboardContext);
  const { token, user } = useAuth();
  const { removeGeometries, removeAllInterections, farm, field, setField } =
    useMap();
  const [loading, setLoading] = useState(false);
  const FieldFormSchemaValidation = Yup.object().shape({
    field_name: Yup.string().required('Nome do talhão é obrigatório')
  });
  const { handleSubmit, register, errors, control } = useForm({
    resolver: yupResolver(FieldFormSchemaValidation)
  });
  const [cropType, setCropType] = useState();
  const [oldCropType, setOldCropType] = useState();
  const [cropTypes, setCropTypes] = useState([]);
  const [cropSubType, setCropSubType] = useState();
  const [cropSubTypes, setCropSubTypes] = useState([]);

  const [cropTypeId, setCropTypeId] = useState('');
  const [oldCropTypeId, setOldCropTypeId] = useState('');
  const [cropSubTypeId, setCropSubTypeId] = useState('');

  useEffect(async () => {
    try {
      const cropTypesResponse = await api.get(
        `${appConfig.apiHosts.field}/Crop_Type/GetActiveCrop_TypeList`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setCropTypes(cropTypesResponse.data.data);
      // setCropSubTypes(cropSubTypesResponse.data.data);
    } catch (error) {
      setCropTypes([]);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);

  const getCropSubType = async (id) => {
    const params = new URLSearchParams([['cropTypeId', id]]);
    try {
      const cropSubTypesResponse = await api.get(
        `${appConfig.apiHosts.field}/Crop_Subtype/GetActiveCrop_SubtypeList`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setCropSubTypes(cropSubTypesResponse.data.data);
    } catch (error) {
      setCropSubTypes([]);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const onSubmit = async (data) => {
    field.properties.field_name = data.field_name;
    field.properties.farm_id = farm.features[0].properties.farm_id;
    field.properties.created_by = user.user_id;
    const newField = {
      type: 'FeatureCollection',
      features: [field]
    };

    if (newField.features[0].geometry.type !== 'MultiPolygon') {
      newField.features[0].geometry.type = 'MultiPolygon';
      newField.features[0].geometry.coordinates[0] = [
        ...newField.features[0].geometry.coordinates
      ];
    }
    try {
      setLoading(true);
      const response = await api.post(
        `${appConfig.apiHosts.field}/Field/PostField`,
        newField,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const message = response.data.message.replaceAll("'", '');
      toast(<Toast title={message} />, { type: 'success' });
      setField(newField);
      setLoading(false);
      setDashboardState('dashboard@fields');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    toast(
      <InstructionsToast title="Shapefile importado com sucesso">
        <p>Preencha os dados cadastrais do talhão</p>
      </InstructionsToast>,
      {
        type: 'success',
        autoClose: 5000
      }
    );
  }, []);
  return (
    <RegisterFieldFromShapefileProvider>
      <S.Container loading={loading}>
        {loading ? (
          <>
            <PuffLoader
              color="#79ac34"
              loading={loading}
              size={30}
              style={{ margin: 25 }}
            />
            <span>Salvando Talhão...</span>
          </>
        ) : (
          <>
            <S.ContainerBackButton>
              <SimpleButton
                text="Voltar"
                onClick={() => {
                  setDashboardState('dashboard@farm');
                  removeGeometries();
                  removeAllInterections();
                }}
              />
            </S.ContainerBackButton>
            <S.RegisterFieldDialog>
              <h3>Adicionar Talhão</h3>
              <span>
                Cadastre sua fazenda e comece a usar as funcionalidades da
                plataforma.
              </span>
            </S.RegisterFieldDialog>
            <S.FieldForm onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="field_name"
                control={control}
                defaultValue=""
                render={({ onChange, value }) => (
                  <TextField
                    type="text"
                    name="field_name"
                    id="outlined-basic"
                    label="Nome do talhão"
                    variant="outlined"
                    ref={register}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {!!errors?.field_name && (
                <S.ErrorMessage>{errors?.field_name?.message}</S.ErrorMessage>
              )}
              <FormControl>
                <InputLabel>Cultura Anterior</InputLabel>
                <Select
                  label="Cultura Anterior"
                  placeholder="Cultura Anterior"
                  name="previous_crop_type"
                  value={oldCropType}
                  onChange={(e) => {
                    setOldCropType(e.target.value);
                    const COldTypeId = cropTypes.filter(
                      (value) => value.name === e.target.value ?? value
                    )[0];
                    setOldCropTypeId(COldTypeId.value_Id);
                  }}
                >
                  {cropTypes.map((v) => (
                    <MenuItem value={v.name} key={v.value_Id}>
                      {v.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Cultura</InputLabel>
                <Select
                  label="Cultura"
                  placeholder="Cultura"
                  name="crop_type"
                  value={cropType}
                  onChange={(e) => {
                    setCropType(e.target.value);
                    const CTypeId = cropTypes.filter(
                      (value) => value.name === e.target.value ?? value
                    )[0];
                    setCropTypeId(CTypeId.value_Id);
                  }}
                >
                  {cropTypes.map((v) => (
                    <MenuItem value={v.name} key={v.value_Id}>
                      {v.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Cultivar</InputLabel>
                <Select
                  label="Cultivar"
                  placeholder="Cultivar"
                  name="crop_subtype"
                  value={cropSubType}
                  onChange={(e) => {
                    setCropSubType(e.target.value);
                    const CSubTypeId = cropSubTypes.filter(
                      (value) => value.name === e.target.value ?? value
                    )[0];
                    setCropSubTypeId(CSubTypeId.value_Id);
                  }}
                >
                  {cropSubTypes.map((v) => (
                    <MenuItem value={v.name} key={v.value_Id}>
                      {v.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button text="Cadastrar Talhão" />
            </S.FieldForm>
          </>
        )}
      </S.Container>
    </RegisterFieldFromShapefileProvider>
  );
};

export default RegisterFieldFromShapefile;
