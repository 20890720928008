import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, NavItem } from './styled';
import { DashboardContext } from '../../pages/Dashboard';
import { dashboardStates } from '../../pages/Dashboard/states';
import { useAuth } from '../../hooks/Auth';

const NavBar = () => {
  const { dashboardState, setDashboardState } = useContext(DashboardContext);
  const { user, role } = useAuth();
  const [perfil, modulo, subModulo] = role.split('|');
  const navigate = useNavigate();
  useEffect(() => {
    if (dashboardState === 'dashboard@farm') navigate('/dashboard');
  }, []);

  return (
    <Container>
      {dashboardStates.map((item) =>
        item.navIcon === undefined ? null : modulo.includes(item.roles) ? (
          <button
            type="button"
            key={item.id}
            onClick={() => {
              setDashboardState(item.path);
              item?.route && navigate(item.route);
            }}
          >
            <NavItem className={dashboardState === item.path ? 'active' : ''}>
              {item.navIcon}
            </NavItem>
          </button>
        ) : null
      )}
    </Container>
  );
};

export default NavBar;
