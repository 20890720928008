import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  > h3 {
    font-size: 16px;
    margin: 16px 0;
  }

  .buttonAddMap {
    background-color: #ffffff;
    border: 1px solid #79ac34;
    color: #79ac34;

    &:disabled {
      border: 1px solid #dcdcdc;
      color: #dcdcdc;
    }
  }
`;

export const FarmCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #a3a3a3;
  padding: 10px 12px;
  border-radius: 8px;
  box-shadow: 1px 3px 6px #0000001a;
  border: 1px solid #dcdcdc;

  h3 {
    font-size: 16px;
  }

  span {
    font-size: 12px;
  }

  & + div {
    margin-top: 10px;
  }

  .active {
    border: 1px solid #79ac34;
  }
`;

export const FarmCardHeader = styled.div`
  display: flex;
`;

export const FarmCardText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden !important;
  margin-bottom: 16px;

  h3 {
    font-size: 0.9rem;
  }

  span {
    font-size: 0.7rem;
    margin-left: 10px;
  }
`;

export const ViewAction = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    height: 100%;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FarmCardActions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;

    & + button {
      margin-left: 10px;
    }
    > span {
      color: #79ac34;
      font-size: 12px;
    }
    > svg {
      width: 16px;
      height: 16px;
      color: #79ac34;
    }
  }
`;
