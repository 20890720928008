/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useRef, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
} from '@mui/material';
import Checkbox from 'react-custom-checkbox';
import { MdCheck } from 'react-icons/md';
import { toast } from 'react-toastify';
import PuffLoader from 'react-spinners/PuffLoader';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import Button from '../../../../../../../../components/Button';
import { useAuth } from '../../../../../../../../hooks/Auth';
import api from '../../../../../../../../services/api';
import { appConfig } from '../../../../../../../../config';
import Toast from '../../../../../../../../components/Toast';
import { useMap } from '../../../../../../../../hooks/Map';
import * as S from './styled';

const ModalRecommendationRevaluation = () => {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const {
    selectedManagement,
    uploadedFiles: files,
    deleteFile,
    handleUpload,
    setUploadedFiles,
    setUpdateScreen,
    listTypeManager,
    setSelectTypeProblemList,
    selectTypeRoutineList,
    setSelectTypeRoutineList,
    selectTypeCoverageList,
    setSelectTypeCoverageList,
    setSelectedTypeAppZoneList,
    selectedTypeAppZoneList
  } = useContext(DashboardContext);
  const { selectedFarmId } = useMap();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [selectedTimeCourse, setSelectedTimeCourse] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [title, setTitle] = useState('');
  const [commits, setCommits] = useState('');
  const [selectedCoverage, setSelectedCoverage] = useState('');

  const clearFields = () => {
    setSelectedType('');
    setSelectedTimeCourse('');
    setSelectedDate('');
    setTitle('');
    setCommits('');
    setSelectedCoverage('');
  };

  const createRoutine = async () => {
    setLoading(true);

    const newRoutine = {
      routine_type_id: selectedType,
      encompass_id: selectedCoverage,
      status: '31bcbb77-b020-42c0-bb18-422a7ac40b71',
      title,
      date: new Date(selectedDate).toISOString(),
      period: selectedTimeCourse,
      note: commits,
      farm_id: selectedFarmId,
      created_by: user.user_id
    };

    try {
      await api.post(
        `${appConfig.apiHosts.prague}/Routine/Create`,
        newRoutine,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast(<Toast title="Rotina criada com sucesso" />, {
        type: 'success'
      });

      setUpdateScreen(true);

      if (checked) {
        clearFields();
        setLoading(false);
      } else {
        clearFields();
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast(<Toast title="Não foi possível criar a rotina" />, {
        type: 'error'
      });
    }
  };

  const getTypeRoutine = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Rotina')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectTypeRoutineList(response.data.data.values);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeCoverage = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Abrangência')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectTypeCoverageList(response.data.data.values);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeAppZone = async () => {
    const params = new URLSearchParams([
      ['farmId', selectedFarmId],
      ['userId', user.user_id]
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.field}/Field/GetActiveFieldListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedTypeAppZoneList(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTypeRoutine();
    getTypeCoverage();
    getTypeAppZone();
  }, []);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Reavaliação de Recomendação</S.Title>
        <S.FormContainer>
          <S.InputWrapper>
            <FormControl className="selectStyle">
              <span>Tipo</span>
              <Select
                labelId="type_recomm"
                name="type_recomm"
                value={selectedType}
                onChange={(e) => {
                  setSelectedType(e.target.value);
                }}
                size="small"
              >
                {selectTypeRoutineList?.map((routine) => (
                  <MenuItem value={routine.value_Id} key={routine.value_Id}>{routine.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="selectStyle">
              <span>Abrangência</span>
              <Select
                labelId="coverage"
                name="coverage"
                value={selectedCoverage}
                onChange={(e) => {
                  setSelectedCoverage(e.target.value);
                }}
                size="small"
              >
                {selectedTypeAppZoneList.map((appZone) => (
                  <MenuItem
                    value={appZone.features[0].properties.field_id}
                    key={appZone.features[0].properties.field_id}
                  >
                    Talhão {appZone.features[0].properties.field_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </S.InputWrapper>

          <S.InputWrapper>
            <S.InputContainer>
              <span>Título</span>
              <TextField
                name="title"
                id="title"
                margin="dense"
                fullWidth
                size="small"
                value={title}
                placeholder="Ex.: Monitoramento de Ervas Daninhas"
                onChange={(e) => setTitle(e.target.value)}
              />
            </S.InputContainer>
            <S.InputContainer>
              <span>ID (Gerado Automaticamente</span>
              <TextField
                name="id"
                id="id"
                margin="dense"
                fullWidth
                size="small"
                value=""
                disabled
                style={{ backgroundColor: '#EFEFEF' }}
              />
            </S.InputContainer>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.InputContainer>
              <span>Data</span>
              <TextField
                type="date"
                name="date"
                id="date"
                margin="dense"
                fullWidth
                size="small"
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                }}
              />
            </S.InputContainer>
            <S.InputContainer>
              <FormControl className="selectStyle">
                <span>Horário</span>
                <Select
                  labelId="time_course"
                  name="time_course"
                  placeholder="Selecione..."
                  value={selectedTimeCourse}
                  onChange={(e) => {
                    setSelectedTimeCourse(e.target.value);
                  }}
                  size="small"
                >
                  <MenuItem value="tipo">Noturno</MenuItem>
                </Select>
              </FormControl>
            </S.InputContainer>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.InputContainer>
              <span style={{ marginBottom: '10px' }}>Observações</span>
              <S.CommitArea
                value={commits}
                onChange={(e) => setCommits(e.target.value)}
              />
            </S.InputContainer>
          </S.InputWrapper>
          <S.ButtonWrapper>
            <Button
              text="Cancelar"
              className="cancelBtn"
              onClick={() => {
                clearFields();
                closeModal();
              }}
            />
            <S.CheckboxContainer>
              <Checkbox
                checked={checked}
                icon={(
                  <MdCheck
                    color="#507919"
                  />
                )}
                borderColor="#A0BD39"
                borderRadius={3}
                borderWidth={1}
                size={20}
                style={{ cursor: 'pointer' }}
                onChange={() => {
                  setChecked(!checked);
                }}
              />
              <label htmlFor="register">Continuar Cadastrando</label>
            </S.CheckboxContainer>
            <Button
              text={loading ? (
                <>
                  <PuffLoader
                    color="#79ac34"
                    loading={loading}
                    size={30}
                  />
                </>
              ) : 'Criar Nova Rotina'}
              disabled={loading}
              className="saveBtn"
              onClick={createRoutine}
            />
          </S.ButtonWrapper>
        </S.FormContainer>
      </S.Container>
    </Modal>
  );
};

export default ModalRecommendationRevaluation;
