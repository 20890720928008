export enum TypeEnum {
  New = 'new',
  Edit = 'edit'
}

type types = 'new' | 'edit' | 'datails';

export interface UserFormProps {
  type: types;
}

export interface UserForm {
  user_id?: string;
  fullname: string;
  email: string;
  cpf_cnpj: string;
  phone_number: string;
  active: boolean;
  password: string;
  isFarmOwner?: boolean;
  nomeOrganizacao?: string;
  organizacao_id?: string;
}
