import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Toast from '../../components/Toast';
import api from '../../services/api';
import {
  Background,
  Container,
  Content,
  SignIn,
  Form,
  ErrorContainer
} from './styled';
import { appConfig } from '../../config';
import authService from '../../services/auth';

const ForgotPassword = () => {
  const signInValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-mail é inválido')
      .required('E-mail é obrigatório')
  });

  const { handleSubmit, register, errors, setError } = useForm({
    resolver: yupResolver(signInValidationSchema)
  });

  const onSubmit = async (data) => {
    try {
      await authService.forgotPassword(data);

      toast(
        <Toast
          title="Senha enviada com sucesso!"
          content="Verifique sua caixa de entrada."
        />,
        {
          type: 'success'
        }
      );
    } catch (error) {
      setError('email', {
        message: `${error.response.data.message}`
      });
      toast(
        <Toast
          title="Não foi possível enviar o email de recuperação de senha."
          content="Verifique se o email está correto."
        />,
        {
          type: 'error'
        }
      );
    }
  };
  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <span>Recuperar senha</span>
          <p>Para recuperar sua senha, digite o e-mail no campo abaixo:</p>
          <Input
            type="email"
            name="email"
            placeholder="E-mail"
            ref={register}
            error={!!errors.email?.message}
          />
          <ErrorContainer>{errors.email?.message}</ErrorContainer>

          <Button text="Recuperar senha" />
          <SignIn>
            <p>Lembrou a senha?</p>
            <Link to="/">Faça o login</Link>
          </SignIn>
        </Form>
      </Content>
      <Background>
        <p>© 2020 Fundação MT - Todos os direitos estão reservados.</p>
      </Background>
    </Container>
  );
};

export default ForgotPassword;
