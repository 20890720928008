/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { OrderedTable } from '../../../../../../../components/OrderedTable';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import { SubModule } from '../utils/systemModule,types';
import { useSubModuleListRequest } from './hooks/useSubModuleListRequest';
import { ResearchField } from '../../../../../../../components/ResearchField';

const SubModuleList: FC = () => {
  const { request, setFilter } = useSubModuleListRequest();

  const headCells: HeadCell<SubModule>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Nome'
    },

    {
      id: 'activated',
      numeric: false,
      disablePadding: false,
      label: 'Ativo'
    }
  ];

  return (
    <>
      <ResearchField onChange={setFilter} />
      <OrderedTable
        rows={request.items}
        HeadCell={headCells}
        // IconCreate={PostAddIcon}
        title="Sub-Módulo"
        routeNameAdd="./new"
        routeNameEdit="./edit"
        checkBox={false}
      />
      <Outlet />
    </>
  );
};

export default SubModuleList;
