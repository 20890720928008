/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../../../../../../../../hooks/Auth';
import UserAPI from '../../../../../../../../APi/FarmApi/UserAPI';
import { UserProfile } from '../utils/userProfileList.types';

export const useUserProfileListRequest = () => {
  const { token, user } = useAuth();
  const useApi = useMemo(() => new UserAPI(token), []);
  const [useFilter, setUserFilter] = useState<string>('');

  const onChangeFilter = (search: string) => setUserFilter(search);

  const UserData = useQuery({
    queryKey: [useApi.listaAtrelamentoUsuarioPerfil, { useFilter }],
    queryFn: async () =>
      useApi.ListarAtrelarUsuarioPerfil(useFilter, user.organizacao_id)
  });

  function userMap(User: any, index): UserProfile {
    return {
      idEndity: User.id,
      id: index + 1,
      name: User.name,
      file: User.nameAggregate
    };
  }

  return {
    request: {
      items: UserData.data?.data.data.map(userMap) ?? []
    },
    onChangeFilter
  };
};
