/* eslint-disable prettier/prettier */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField
} from '@mui/material';
import * as Yup from 'yup';
import { format } from 'date-fns';
import Button from '../../../../../../../../components/Button';
import Input from '../../../../../../../../components/Input';
import SimpleButton from '../../../../../../../../components/SimpleButton';
import InstructionsToast from '../../../../../../../../components/Toast/Instructions';
import { appConfig } from '../../../../../../../../config';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { useMap } from '../../../../../../../../hooks/Map';
import api from '../../../../../../../../services/api';
import { DashboardContext } from '../../../../../../index';
import * as S from './styled';

export const EditingFieldContext = createContext({});

const EditingField = () => {
  const {
    farm,
    setViewFields,
    removeAllInterections,
    setAddingField,
    field,
    removeFieldGeometries
  } = useMap();

  const {
    setDashboardState,
    cropTypeName,
    setCropTypeName,
    oldCropTypeName,
    setOldCropTypeName,
    cropSubTypeName,
    setCropSubTypeName
  } = useContext(DashboardContext);
  const { user, token } = useAuth();
  const FieldFormSchemaValidation = Yup.object().shape({
    field_name: Yup.string().required('Nome do talhão é obrigatório')
  });
  const { handleSubmit, register, errors, control } = useForm({
    resolver: yupResolver(FieldFormSchemaValidation)
  });
  const [cropTypeId, setCropTypeId] = useState(
    field.features[0].properties.crop_type_id
  );
  const [oldCropTypeId, setOldCropTypeId] = useState(
    field.features[0].properties.previous_crop_type_id
  );
  const [cropSubTypeId, setCropSubTypeId] = useState(
    field.features[0].properties.crop_subtype_id
  );
  const [cropTypes, setCropTypes] = useState([]);
  const [cropSubTypes, setCropSubTypes] = useState([]);
  const [cropType, setCropType] = useState();
  const [oldCropType, setOldCropType] = useState();
  const [cropSubType, setCropSubType] = useState();

  useEffect(async () => {
    try {
      const params = new URLSearchParams([
        ['type_id', 'c826052d-3a59-4398-aec1-81193ccd2e42']
      ]);
      const cropTypesResponse = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setCropTypes(cropTypesResponse.data.data.values);

      const filterCropType = cropTypesResponse.data.data.values.filter(
        (v) => v.value_Id === field.features[0].properties.crop_type_id
      )[0].name;

      setCropType(filterCropType);
    } catch (error) {
      setCropTypes([]);
      // eslint-disable-next-line no-console
      console.error(error);
    }

    try {
      const params = new URLSearchParams([
        ['type_id', '4f7bca40-7ffd-4485-9627-e5d2ba90776c']
      ]);
      const cropTypesResponse = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setCropSubTypes(cropTypesResponse.data.data.values);
    } catch (error) {
      setCropSubTypes([]);
      console.error(error);
    }
  }, []);

  const getCropSubType = async (id) => {
    try {
      const params = new URLSearchParams([
        ['type_id', '4f7bca40-7ffd-4485-9627-e5d2ba90776c']
      ]);
      const cropTypesResponse = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setCropSubTypes(cropTypesResponse.data.data.values);
    } catch (error) {
      setCropSubTypes([]);
      console.error(error);
    }
  };

  useEffect(() => {
    setCropType(cropTypes.find((value) => value.value_Id === cropTypeId)?.name);
    setOldCropType(
      cropTypes.find((value) => value.value_Id === oldCropTypeId)?.name
    );
    setCropSubType(
      cropSubTypes.find((value) => value.value_Id === cropSubTypeId)?.name
    );
  }, [cropTypeId, oldCropTypeId, cropSubTypeId, cropSubTypes, cropTypes]);

  useEffect(() => {
    setCropType(cropTypes.find((value) => value.value_Id === cropTypeId)?.name);
    setOldCropType(
      cropTypes.find((value) => value.value_Id === oldCropTypeId)?.name
    );
    setCropSubType(
      cropSubTypes.find((value) => value.value_Id === cropSubTypeId)?.name
    );
  }, []);

  const onSubmit = async (data) => {
    field.features[0].properties.field_name = data.field_name;
    field.features[0].properties.date_planting = data.date_planting;
    field.features[0].properties.farm_id = farm.features[0].properties.farm_id;
    field.features[0].properties.created_by = user.user_id;
    field.features[0].properties.crop_type_id = cropTypeId;
    field.features[0].properties.crop_subtype_id = cropSubTypeId;
    field.features[0].properties.previous_crop_type_id = oldCropTypeId;

    if (field.features[0].geometry.type === 'Polygon') {
      field.features[0].geometry.type = 'MultiPolygon';
      field.features[0].geometry.coordinates[0] = [
        ...field.features[0].geometry.coordinates
      ];
    }

    try {
      await api
        .put(`${appConfig.apiHosts.field}/Field/UpdateField`, field, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .finally(() => {
          setViewFields(false);
          removeAllInterections();
          setAddingField(false);
          removeFieldGeometries();
          setDashboardState('dashboard@fields');
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const formatDate = (date) => {
    const data = new Date(date);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    return `${ano}-${mes}-${dia}`;
  };

  useEffect(() => {
    toast(
      <InstructionsToast title="Altere a área do talhão da sua propriedade no mapa">
        <p>
          1. Use o mouse para alterar a área correspondente.
          <br />
          2. Após editar o polígono no mapa clique em editar talhão.
        </p>
      </InstructionsToast>,
      {
        type: 'default',
        autoClose: 10000,
        hideProgressBar: true,
        style: { marginTop: '25%' }
      }
    );
  }, []);
  return (
    <EditingFieldContext.Provider value={{}}>
      <S.Container>
        <div style={{ width: '100%' }}>
          <SimpleButton
            text="Voltar"
            onClick={() => {
              setViewFields(false);
              removeAllInterections();
              setAddingField(false);
              setDashboardState('dashboard@fields');
            }}
          />
        </div>
        <S.Title>Editar talhão</S.Title>
        <S.SubTitle>
          Modifique o talhão demarcado e continue monitorando a região da sua
          propriedade.
        </S.SubTitle>

        <S.FieldForm onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="field_name"
            control={control}
            defaultValue={field.features[0].properties.field_name}
            render={({ onChange, value }) => (
              <TextField
                type="text"
                defaultValue={field.features[0].properties.field_name}
                name="field_name"
                id="outlined-basic"
                label="Nome do talhão"
                variant="outlined"
                ref={register}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {!!errors?.field_name && (
            <S.ErrorMessage>{errors?.field_name?.message}</S.ErrorMessage>
          )}
          <Controller
            name="crop_type"
            control={control}
            defaultValue={cropTypeName}
            render={({ onChange, value }) => (
              <FormControl>
                <InputLabel>Cultura</InputLabel>
                <Select
                  label="Cultura"
                  placeholder="Cultura"
                  name="crop_type"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                    setCropType(e.target.value);
                    const CTypeId = cropTypes.filter(
                      (v) => v.name === e.target.value ?? v
                    )[0];
                    setCropTypeId(CTypeId.value_Id);
                  }}
                >
                  {cropTypes.map((v) => (
                    <MenuItem value={v.name} key={v.value_Id}>
                      {v.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="previous_crop_type"
            control={control}
            defaultValue={oldCropTypeName}
            render={({ onChange, value }) => (
              <FormControl>
                <InputLabel>Cultura Anterior</InputLabel>
                <Select
                  label="Cultura Anterior"
                  placeholder="Cultura Anterior"
                  name="previous_crop_type"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                    setOldCropType(e.target.value);
                    const COldTypeId = cropTypes.filter(
                      (v) => v.name === e.target.value ?? v
                    )[0];
                    setOldCropTypeId(COldTypeId.value_Id);
                  }}
                >
                  {cropTypes.map((v) => (
                    <MenuItem value={v.name} key={v.value_Id}>
                      {v.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />

          <Controller
            name="crop_subtype"
            control={control}
            defaultValue={cropSubTypeName}
            render={({ onChange, value }) => (
              <FormControl>
                <InputLabel>Cultivar</InputLabel>
                <Select
                  label="Cultivar"
                  placeholder="Cultivar"
                  defaultValue={cropSubType}
                  name="crop_subtype"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                    setCropSubType(e.target.value);
                    const CSubTypeId = cropSubTypes.filter(
                      (v) => v.name === e.target.value ?? v
                    )[0];
                    setCropSubTypeId(CSubTypeId.value_Id);
                  }}
                >
                  {cropSubTypes.map((v) => (
                    <MenuItem value={v.name} key={v.value_Id}>
                      {v.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />

          <FormControl>
            <span>Data de plantio</span>
            <Controller
              name="date_planting"
              control={control}
              defaultValue={
                field.features[0].properties.date_planting
                  ? formatDate(field.features[0].properties.date_planting)
                  : null
              }
              render={({ onChange, value }) => (
                <TextField
                  type="date"
                  margin="dense"
                  fullWidth
                  size="medium"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </FormControl>
          <Button text="Alterar Talhão" />
        </S.FieldForm>
      </S.Container>
    </EditingFieldContext.Provider>
  );
};

export default EditingField;
