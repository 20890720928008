/* eslint-disable no-duplicate-case */
/* eslint-disable indent */
export const getDepth = (value) => {
  switch (value) {
    case '0-10':
      return '0-10';

    case '0-20':
      return '0-20';

    case '0-20-40':
      return '20-40';

    case '0-10-20':
      return '10-20';

    case '0-10-20-40':
      return ' 20-40';

    case '0-10-20-30-40':
      return '30-40';

    case '0-10-20-30-40-60':
      return '40-60';

    case '0-10':
      return '0-10';

    case '0-20':
      return '0-20';

    case '20-40':
      return '20-40';

    case '10-20':
      return '0-10-20';

    case ' 20-40':
      return '20-40';

    case '30-40':
      return '0-10-20-30-40';

    case '40-60':
      return '0-10-20-30-40-60';

    default:
      return '';
  }
};

export const setDepth = (value) => {
  switch (value) {
    case '0-10':
      return '0-10';

    case '0-20':
      return '0-20';

    case '20-40':
      return '0-20-40';

    case '10-20':
      return '0-10-20';

    case ' 20-40':
      return '0-10-20-40';

    case '30-40':
      return '0-10-20-30-40';

    case '40-60':
      return '0-10-20-30-40-60';

    case '0-10':
      return '0-10';

    case '0-20':
      return '0-20';

    case '0-20-40':
      return '20-40';

    case '0-10-20':
      return '10-20';

    case '0-10-20-40':
      return ' 20-40';

    case '0-10-20-30-40':
      return '30-40';

    case '0-10-20-30-40-60':
      return '40-60';
    default:
      return '';
  }
};
