import styled from 'styled-components';
import { shade } from 'polished';
import signinBackgroundImg from '../../assets/sign-in-background.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;
`;

export const Form = styled.form`
  max-width: 450px;
  width: 100%;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;

  img {
    width: 210px;
    height: auto;
    margin-left: -18px;
  }

  > span {
    color: #444444;
    font-size: 1.3rem;
    font-weight: bold;
    margin: 46px 0px 16px 0px;
  }

  p {
    color: #a3a3a3;
    font-size: 1rem;
    margin-bottom: 40px;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signinBackgroundImg}) no-repeat center;
  background-size: cover;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  > p {
    padding: 15px;
    font-weight: bold;
    font-size: 0.8rem;
    color: #fff;
    @media (max-width: 1000px) {
      display: none;
    }
  }
`;

export const PasswordOptions = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: center; */

  a {
    color: #79ac34;
    font-weight: bold;
    text-decoration: none;
    font-size: 1rem;

    &:hover {
      color: ${shade(0.2, '#79ac34')};
    }
  }
`;
export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  label {
    padding: 0px 0px 0px 10px;
  }

  /* Create a custom checkbox */
  span {
    position: absolute;
    top: 0px;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #eee;
    border-radius: 3px;
    border: 1px solid #a3a3a3;
  }

  &:hover input ~ span {
    background-color: #ccc;
  }

  input:checked ~ span {
    background-color: #79ac34;
  }

  span:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    right: 3px;
    top: 2px;
    width: 3px;
    height: 5px;
    border: solid white;
    border-width: 0 2px 2px 0;
    border-radius: 1px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
export const SignUp = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;

  p {
    color: #444444;
  }

  a {
    color: #79ac34;
    margin-left: 5px;
    font-weight: bold;

    &:hover {
      color: ${shade(0.2, '#79ac34')};
    }
  }
`;

export const ErrorContainer = styled.section`
  margin: 3px 15px 5px;
  /* font-weight: bold; */
  font-size: 0.9rem !important;
  color: #d62222 !important;
`;
