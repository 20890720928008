/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
} from '@mui/material';
import { MdOutlineCloudUpload, MdCheck, MdClose, MdError, MdLink, MdMoodBad } from 'react-icons/md';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import Button from '../../../../../../../../components/Button';
import { appConfig } from '../../../../../../../../config';
import api from '../../../../../../../../services/api';
import { useAuth } from '../../../../../../../../hooks/Auth';
import * as S from './styled';

const ViewProblem = () => {
  const { openModal } = useModal();
  const { token, user, getNotifications } = useAuth();

  const {
    setItemProblemVisible,
    setOpendedModal,
    setItemSelectedVisible,
    problemId,
    selectedProblem,
    setSelectedProblem,
    currentModal,
    setCurrentModal
  } = useContext(DashboardContext);

  return (
    <S.Container>
      <S.CloseButton onClick={() => {
        setSelectedProblem('');
        setItemSelectedVisible('');
      }}
      >
        <MdClose />
      </S.CloseButton>
      <S.Title>Problema</S.Title>
      <S.PhotoContainer><img src={selectedProblem?.image} /></S.PhotoContainer>
      <S.Subtitle>Espécie</S.Subtitle>
      <S.ContentRow>{selectedProblem?.prague_name}</S.ContentRow>
      <S.Subtitle>Unidade de Medida</S.Subtitle>
      <S.ContentRow>{selectedProblem?.measurement_unit}</S.ContentRow>
      <S.Subtitle>Cultura</S.Subtitle>
      <S.ContentRow>{selectedProblem?.crop_type_id_name}</S.ContentRow>
      <S.Subtitle>Cultivar Resistente</S.Subtitle>
      <S.ContentRow>{selectedProblem?.plant}</S.ContentRow>
      <S.ButtonContainer>
        <Button
          type="button"
          text="Editar Problema"
          className="btnStyle"
          onClick={() => {
            setCurrentModal('modalEditProblem');
            setItemSelectedVisible('');
            openModal();
          }}
        />
      </S.ButtonContainer>
    </S.Container>
  );
};

export default ViewProblem;
