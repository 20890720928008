import React from 'react';
import { MdClose } from 'react-icons/md';
import { useMap } from '../../hooks/Map';
import Button from '../Button';
import * as S from './styled';

const SelectSatelliteCard = () => {
  const { setControlState, satellite, setSatellite } = useMap();
  return (
    <S.Container>
      <S.Header>
        <h1>Selecionar satélite</h1>
        <button
          type="button"
          onClick={() => {
            setControlState(undefined);
          }}
        >
          <MdClose />
        </button>
      </S.Header>
      <S.Form>
        <label htmlFor="Sentinel-2 L1C">
          Sentinel-2 L1C
          <input
            type="radio"
            name="satellite"
            id="Sentinel-2 L1C"
            value={satellite === 'Sentinel-2 L1C'}
            onClick={() => setSatellite('sentinel-2-l1c')}
          />
        </label>
        <label htmlFor="Sentinel-2 L2A">
          Sentinel-2 L2A
          <input
            type="radio"
            name="satellite"
            id="Sentinel-2 L2A"
            value={satellite === 'Sentinel-2 L2A'}
            onClick={() => setSatellite('sentinel-2-l2a')}
          />
        </label>
        <label htmlFor="Landsat 8">
          Landsat 8
          <input
            type="radio"
            name="satellite"
            id="Landsat 8"
            value={satellite === 'Landsat 8'}
            onClick={() => setSatellite('landsat-ot-l1')}
          />
        </label>
        <label htmlFor="DEM">
          DEM
          <input
            type="radio"
            name="satellite"
            id="DEM"
            value={satellite === 'DEM'}
            onClick={() => setSatellite('dem')}
          />
        </label>
        <label htmlFor="MODIS">
          MODIS
          <input
            type="radio"
            name="satellite"
            id="MODIS"
            value={satellite === 'MODIS'}
            onClick={() => setSatellite('modis')}
          />
        </label>
        <label htmlFor="Sentinel-1">
          Sentinel-1
          <input
            type="radio"
            name="satellite"
            id="Sentinel-1"
            value={satellite === 'Sentinel-1'}
            onClick={() => setSatellite('sentinel-1-grd')}
          />
        </label>
      </S.Form>
    </S.Container>
  );
};

export default SelectSatelliteCard;
