/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useAuth } from '../../../../../../../../hooks/Auth';
import SystemModuleApi from '../../../../../../../../APi/FarmApi/SystemModuleApi';
import { ProfileFormProps } from '../../../Profile/ProfileList/utils/profile.types';
import SubModuleApi from '../../../../../../../../APi/FarmApi/SubModuleApi';

export interface subModuleForm {
  id?: string;
  name: string;
  active: boolean;
}

const useSubModuleForm = ({ type }: ProfileFormProps) => {
  const { token } = useAuth();
  const subModuleApi = useMemo(() => new SubModuleApi(token), []);
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [mensage, setMensage] = useState<boolean>(false);

  const FormValidationSchema = Yup.object().shape({
    name: Yup.string().required()
  });

  const methods = useForm<subModuleForm>({
    defaultValues: {
      id: '',
      name: '',
      active: false
    },
    resolver: yupResolver(FormValidationSchema)
  });

  function systemModuleMap(date: any): subModuleForm {
    return {
      id: date.id,
      name: date.name,
      active: date.active
    };
  }
  const systemModuleSuccess = (data: subModuleForm) => {
    methods.reset({
      ...methods.getValues(),
      ...data
    });
  };
  const systemModuleQuery = useQuery({
    queryKey: [subModuleApi.getSubModuleById, { id }],
    queryFn: async () => subModuleApi.RetornarSubModuloById(id.toString()),
    onSuccess: async (data) =>
      systemModuleSuccess(systemModuleMap(data.data.data))
  });

  useEffect(() => {
    setFormValid(!methods.control.getValues('name'));
  }, [methods.control.watchInternal()]);

  const onSelectActivated = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setIsSelect(checked);
    methods.setValue('active', checked);
  };

  const systemModuleCreate = useMutation({
    mutationFn: async (data: subModuleForm) =>
      subModuleApi.createSubModule<subModuleForm>(data),
    onSuccess: async () => {
      setMensage(true);
      navigate(-1);
      await queryClient.invalidateQueries(subModuleApi.getSubModule);
    }
  });

  const systemModuleUpdate = useMutation({
    mutationFn: async (data: subModuleForm) => {
      data.id = id.toString();
      subModuleApi.updateSubModule<subModuleForm>(data);
    },
    onSuccess: async () => {
      setMensage(true);
      navigate(-1);

      await queryClient.invalidateQueries(subModuleApi.getSubModule);
    }
  });

  const submit = methods.handleSubmit((form: subModuleForm) => {
    if (type === 'new') {
      systemModuleCreate.mutate(form);
    } else {
      systemModuleUpdate.mutate(form);
    }
  });

  const title: Record<string, string> = {
    new: 'Adicionar Sub-Modulo',
    edit: 'Editar Sub-Modulo'
  };
  const mensageSuccess: Record<string, { title: string; content: string }> = {
    new: {
      title: 'Sub-Módulo criado com sucesso',
      content: 'Sub-Módulo foi criado com sucesso'
    },
    edit: {
      title: 'Sub-Módulo atualizado com sucesso',
      content: 'Sub-Módulo foi atualizado com sucesso'
    }
  };
  return {
    form: {
      methods,
      submit,
      control: methods.control,
      isValid: formValid
    },
    onSelectActivated,
    title,
    mensageSuccess,
    mensage
  };
};

export default useSubModuleForm;
