/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Tooltip, Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar/Toolbar';
import { alpha } from '@mui/material/styles';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { EnhancedTableToolbarProps } from '../../../OrderedTable/utils/OrderedTable.types';

const TilteTable: FC<EnhancedTableToolbarProps> = (props) => {
  const { numSelected, title, routeNameAdd, IconCreate } = props;
  const navigate = useNavigate();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            )
        })
      }}
      style={{ backgroundColor: '#A0BD39' }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%', color: '#fff' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          <p>
            {numSelected === 1
              ? `${numSelected} selecionado`
              : `${numSelected} selecionados`}
          </p>
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%', color: '#fff' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}
    </Toolbar>
  );
};

export default TilteTable;
