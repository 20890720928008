import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from '../Auth';
import { MapProvider } from '../Map';
import { ModalProvider } from '../Modal';

const Providers = ({ children }: { children: ReactNode }) => {
  const queryClient = new QueryClient();
  return (
    <AuthProvider>
      <MapProvider>
        <QueryClientProvider client={queryClient}>
          <ModalProvider>{children}</ModalProvider>
        </QueryClientProvider>
      </MapProvider>
    </AuthProvider>
  );
};

export default Providers;
