/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import { useModal } from '../../../../../../../hooks/Modal';
import { ResearchField } from '../../../../../../../components/ResearchField';
import Toast from '../../../../../../../components/Toast';
import { Profile } from '../../Profile/ProfileList/utils/profile.types';
import { User } from '../../Users/utils/users.types';
import { useModuleProfileFormRequest } from './hooks/useModuleProfileFormRequest';
import * as s from './utils/userProfile.style';
import useUserProfileForm from './hooks/useModuleProfileForm';
import { ModuleProfileFormProps } from '../utils/moduleProfile.types';
import TableCreate from '../../../../../../../components/TableCreate/TableCreate';

const ModuleProfileForm: FC<ModuleProfileFormProps> = ({ type }) => {
  const { request, filter, setProfileId, listModule } =
    useModuleProfileFormRequest();
  const { form, title, setIsSuccess, mensageSuccess } = useUserProfileForm({
    type,
    listIdaggregate: request.listIdaggregate,
    moduleList: listModule
  });
  const { modalCreatUserStyles } = useModal();
  const navigate = useNavigate();

  useEffect(() => {
    form.methods.setValue(
      'module_id',
      request.listIdaggregate ? request.listIdaggregate : []
    );
  }, [request.isRedy]);

  if (form.isSuccess) {
    setTimeout(() => {
      return toast(<Toast title={mensageSuccess[type]} content="" />, {
        type: 'success'
      });
    }, 100);
    setIsSuccess(false);
  }
  const moduleColumn: HeadCell<User>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'nome do usuário'
    }
  ];

  const profileColumn: HeadCell<Profile>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Perfil'
    }
  ];

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen
      style={{
        ...modalCreatUserStyles,
        ...{
          content: {
            width: '66%',
            height: '79% ',
            transform: 'translate(15vw, 4vw)'
          }
        }
      }}
    >
      <s.container>
        <h1>{title[type]}</h1>
        <s.containerTable>
          <Controller
            name="user_profile_id"
            control={form.methods.control}
            render={({ onChange }) => (
              <div>
                <div style={{ paddingLeft: '2%' }}>
                  <ResearchField
                    onChange={filter.setFilterProfile}
                    placeholder="Filtro de Perfil"
                  />
                </div>

                <TableCreate
                  rows={request.itemsProfiles}
                  HeadCell={profileColumn}
                  IconCreate={AddIcon}
                  title="Perfis"
                  onChange={(selected) => {
                    onChange(selected[0]);
                    setProfileId(selected[0]);
                  }}
                  checkBox
                  multSelec={false}
                  idStartSelect={[request.idProfile]}
                  checkboxDisabled={type === 'edit'}
                />
              </div>
            )}
          />
          <Controller
            name="module_id"
            control={form.methods.control}
            render={({ onChange }) => (
              <div>
                <div style={{ paddingLeft: '2%' }}>
                  <ResearchField
                    onChange={filter.setSystemModuleFilter}
                    placeholder="Filtro de Modulo"
                  />
                </div>
                <TableCreate
                  rows={request.itemsModule}
                  HeadCell={moduleColumn}
                  IconCreate={AddIcon}
                  title="Modulo"
                  onChange={(selected) => {
                    onChange(selected);
                  }}
                  checkBox
                  multSelec={false}
                  idStartSelect={
                    request.idProfile ? request.listIdaggregate : []
                  }
                  idStartSelectRedy={request.isRedy}
                />
              </div>
            )}
          />
        </s.containerTable>
        <s.containerbotton>
          <button
            type="button"
            className="containerbotton__bottoncancel"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </button>
          <button
            type="button"
            className={`containerbotton__bottonregister${
              form.isValid ? '--disabled' : ''
            }`}
            onClick={form.submit}
            disabled={form.isValid}
          >
            Registrar
          </button>
        </s.containerbotton>
      </s.container>
    </Modal>
  );
};

export default ModuleProfileForm;
