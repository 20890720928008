import { kml } from '@tmcw/togeojson';
import JSZip from 'jszip';
import proj4 from 'proj4';
import React from 'react';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as shp from 'shpjs';
import { useMap } from '../../hooks/Map';
import Toast from '../Toast';
import * as S from './styled';

const UploadShapefileCard = () => {
  const { setControlState, renderShapefileOfCard } = useMap();

  function renderShapefile(geojson) {
    const generated = [];
    if (geojson.features.length > 1) {
      geojson.features.forEach((feature) => {
        try {
          const parsedCoordinates = feature.geometry.coordinates[0].map((v) =>
            proj4('EPSG:4326', 'EPSG:3857', [v[0], v[1]])
          );
          feature.geometry.coordinates[0] = parsedCoordinates;
          generated.push(feature);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      });
      geojson.features = generated;
      renderShapefileOfCard(geojson);
      return toast(
        <Toast
          title="Shapefile importado com sucesso."
          content="Sua geometria foi renderizada em tela."
        />,
        { type: 'success' }
      );
    }
    const parsedCoordinates = geojson.features[0].geometry.coordinates[0].map(
      (value) => proj4('EPSG:4326', 'EPSG:3857', [value[0], value[1]])
    );
    geojson.features[0].geometry.coordinates[0] = parsedCoordinates;
    renderShapefileOfCard(geojson);
    toast(
      <Toast
        title="Shapefile importado com sucesso."
        content="Sua geometria foi renderizada em tela."
      />,
      { type: 'success' }
    );
  }

  function convertToLayerZip(buffer) {
    shp(buffer).then((geojson) => renderShapefile(geojson));
  }

  const convertToLayerKml = (buffer) => {
    const blob = new Blob([buffer], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    fetch(url)
      .then((response) => response.text())
      .then((xml) => {
        const kmlReadResult = kml(
          new DOMParser().parseFromString(xml, 'text/xml')
        );
        renderShapefile(kmlReadResult);
      });
  };

  const convertToLayerKmz = async (buffer) => {
    const blob = new Blob([buffer], { type: 'application/zip' });
    const zip = new JSZip();
    await zip.loadAsync(blob).then(async (zipped) => {
      const file = await zipped.file('doc.kml').async('blob');
      const url = URL.createObjectURL(file);
      fetch(url)
        .then((response) => response.text())
        .then((xml) => {
          const kmlReadResult = kml(
            new DOMParser().parseFromString(xml, 'text/xml')
          );
          renderShapefile(kmlReadResult);
        });
    });
  };

  const handleUploadFile = (file, type) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState !== 2 || reader.error) {
        return null;
      }
      if (
        type === 'application/zip' ||
        type === 'zip' ||
        type === 'application/x-zip-compressed'
      ) {
        return convertToLayerZip(reader.result);
      }
      if (type === 'application/vnd.google-earth.kml+xml' || type === 'kml') {
        return convertToLayerKml(reader.result);
      }
      if (type === 'application/vnd.google-earth.kmz' || type === 'kmz') {
        return convertToLayerKmz(reader.result);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <S.Container>
      <S.Header>
        <h1>Adicionar shapefile</h1>
        <button
          type="button"
          onClick={() => {
            setControlState(undefined);
          }}
        >
          <MdClose />
        </button>
      </S.Header>
      <span>Formatos aceitos: .zip, .kml, .kmz</span>
      <S.UploadButton>
        <label htmlFor="upload-file-card" className="label-upload">
          <span>Fazer o upload do shapefile</span>
          <input
            type="file"
            accept=".zip, .kml, .kmz"
            id="upload-file-card"
            multiple={false}
            onChange={(e) => {
              const qtdArray = e.target.files.length;
              const ext = e.target.files[qtdArray - 1].name.split('.');
              if (
                e.target.files[qtdArray - 1].type !== 'application/zip' &&
                e.target.files[qtdArray - 1].type !==
                  'application/vnd.google-earth.kml+xml' &&
                e.target.files[qtdArray - 1].type !==
                  'application/vnd.google-earth.kmz' &&
                e.target.files[qtdArray - 1].type !==
                  'application/x-zip-compressed' &&
                ext[ext.length - 1] !== 'kmz' &&
                ext[ext.length - 1] !== 'kml' &&
                ext[ext.length - 1] !== 'zip'
              ) {
                return (
                  null,
                  toast(
                    <Toast
                      title="Shapefile no formato incorreto"
                      content="Formatos aceitos: .zip, .kml, .kmz "
                    />,
                    {
                      type: 'error'
                    }
                  )
                );
              }
              handleUploadFile(
                e.target.files[qtdArray - 1],
                e.target.files[qtdArray - 1].type === ''
                  ? ext[ext.length - 1]
                  : e.target.files[qtdArray - 1].type
              );
            }}
          />
        </label>
      </S.UploadButton>
    </S.Container>
  );
};

export default UploadShapefileCard;
