/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import ProfileApi from '../../../../../../../../APi/FarmApi/ProfileApi';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { Profile } from '../../../Profile/ProfileList/utils/profile.types';
import UserAPI from '../../../../../../../../APi/FarmApi/UserAPI';
import SystemModuleApi from '../../../../../../../../APi/FarmApi/SystemModuleApi';
import { UserProfile } from '../../utils/moduleProfile.types';

export const useModuleProfileFormRequest = () => {
  const {
    token,
    user: { organizacao_id }
  } = useAuth();
  const profileApi = useMemo(() => new ProfileApi(token), []);
  const systemModuleApi = useMemo(() => new SystemModuleApi(token), []);
  const [filterProfile, setFilterProfile] = useState<string>('');
  const [systemModuleFilter, setSystemModuleFilter] = useState<string>('');
  const [idProfile, setIdProfile] = useState<string>('');
  const { id, idaggregate } = useParams();
  const [filter, setFilter] = useState<string>('');

  function moduleProfileMap(moduleProfile: any) {
    return moduleProfile.idaggregate;
  }

  const moduleProfileQuery = useQuery({
    queryKey: [
      profileApi.ListaAtrelarPerfilModulo,
      { idProfile, organizacao_id }
    ],
    queryFn: async () =>
      profileApi.ListarAtrelarPerfilModulo(
        '',
        idProfile ?? id,
        organizacao_id ?? ''
      ),

    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  const ProfileDate = useQuery({
    queryKey: [profileApi.getProfile, { filterProfile, organizacao_id }],
    queryFn: () => profileApi.ProfileList(filterProfile, organizacao_id)
  });

  function profileMap(date: any): Profile {
    return {
      id: date.userprofile_id,
      name: date.description
    };
  }

  const setProfileId = (ids: string) => {
    setIdProfile(ids);
  };

  const moduleData = useQuery({
    queryKey: [
      systemModuleApi.getSystemModule,
      { systemModuleFilter, organizacao_id }
    ],
    queryFn: async () =>
      systemModuleApi.ListarModulos(systemModuleFilter, organizacao_id)
  });

  function moduleDataMap(module: any) {
    return {
      id: module.id,
      name: module.name
    };
  }
  function moduleListMap(module: any) {
    return {
      id: module.id,
      name: module.name,
      acronym: module.acronym
    };
  }
  const listaAtrelados: any[] =
    moduleProfileQuery.data?.data.data.map(moduleProfileMap) ?? [];
  return {
    request: {
      itemsProfiles: id
        ? ProfileDate.data?.data.data
            .filter((x: any) => x.userprofile_id === id)
            ?.map(profileMap) ?? []
        : ProfileDate.data?.data.data.map(profileMap) ?? [],
      itemsModule: id
        ? moduleData.data?.data.data.map(moduleDataMap) ?? []
        : idProfile
        ? moduleData.data?.data.data
            .filter((x: any, index: number) => {
              return !listaAtrelados.includes(x.id);
            })
            ?.map(moduleDataMap) ?? []
        : moduleData.data?.data.data?.map(moduleDataMap) ?? [],
      listIdaggregate: listaAtrelados.filter((x) => x === idaggregate),
      idProfile: id?.toString(),
      isRedy: moduleProfileQuery.isLoading || moduleProfileQuery.isRefetching
    },
    listModule: moduleData.data?.data.data.map(moduleListMap),
    filter: {
      setFilterProfile,
      setSystemModuleFilter
    },
    setProfileId
  };
};
