/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState } from 'react';
import { MdDelete, MdSearch } from 'react-icons/md';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Pagination,
  Paper,
  TextField,
  MenuItem,
  Select,
  FormControl,
  ListItemText,
  Checkbox
} from '@mui/material';
import PuffLoader from 'react-spinners/PuffLoader';
import { DashboardContext } from '../../../../index';
import * as S from './styled';
import { useModal } from '../../../../../../hooks/Modal';
import Button from '../../../../../../components/Button';
import { useMap } from '../../../../../../hooks/Map';
import { useAuth } from '../../../../../../hooks/Auth';
import api from '../../../../../../services/api';
import { appConfig } from '../../../../../../config';

export interface NextRoutineProps {
  routine_id: string;
  routine_type_id: string;
  routine_type_name: string;
  encompass_id: string;
  encompass_name: string;
  title: string;
  init_date: string;
  end_date: string;
  period: string;
  period_name: string | null;
  crop_type_name: string | null;
  tecnician_id: string | null;
  technician_name: string | null;
  instant_monitoring: boolean;
  note: string | null;
  status: string;
  status_name: string;
  farm_id: string;
  created_by: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  executor_id: string | null;
  executor_name: string | null;
}

enum TypeMonitoring {
  AGENDADO = 'AGENDADO',
  CONCLUIDO = 'CONCLUIDO',
  AMBOS = 'AMBOS'
}
interface TypeMonitoringSelect {
  value: TypeMonitoring;
  text: string;
}
export interface NextRoutineResponse {
  data: {
    routines: NextRoutineProps[];
    pagination: {
      page: number;
      itensPerPage: number;
      totalRows: number;
      totalPages: number;
    };
    isReturned: boolean;
  };
}

const Monitoring = () => {
  const { selectedFarmId } = useMap();
  const { token, user, getNotifications } = useAuth();
  const { openModal } = useModal();
  const { role } = useAuth();
  const [perfil, modulo, subModulo] = role.split('|');
  const {
    setOpendedModal,
    setItemSelectedVisible,
    setUpdateScreen,
    setSelectedRoutine,
    updateScreen,
    setSelectedMonitoring,
    setSelectedTypeAppZoneList
  } = useContext(DashboardContext);

  const [loading, setLoading] = useState(false);
  const [nextRoutineList, setNextRoutineList] = useState<NextRoutineProps[]>(
    []
  );
  const [doneRoutineList, setDoneRoutineList] = useState([]);
  const [totalPagesNextRoutine, setTotalPagesNextRoutine] = useState<number>(0);
  const [totalPagesDoneRoutine, setTotalPagesDoneRoutine] = useState();
  const [searchField, setSearchField] = useState('');
  const [executorList, setExecutorList] = useState<any[]>([]);
  const [appZoneMapping, setAppZoneMapping] = useState<any[]>([]);
  const [appExecutoMapping, setAppExecutoMapping] = useState<any[]>([]);
  const [initDate, setInitDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();
  const [selectLocal, setSelectLocal] = useState<string[]>([]);
  const [monitoringSelect, setMonitoringSelect] = useState<TypeMonitoring>(
    TypeMonitoring.AMBOS
  );
  const monitoringOptionSelect: TypeMonitoringSelect[] = [
    {
      text: 'Concluido',
      value: TypeMonitoring.CONCLUIDO
    },
    {
      text: 'Ambos',
      value: TypeMonitoring.AMBOS
    },
    {
      text: 'Agendado/Andamento',
      value: TypeMonitoring.AGENDADO
    }
  ];
  const getTypeAppZone = async () => {
    const params = new URLSearchParams([
      ['farmId', selectedFarmId],
      ['userId', user.user_id]
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.field}/Field/GetActiveFieldListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedTypeAppZoneList(response.data.data);
      const appZones = response.data.data.map((appZone: any) => {
        const name = appZone.features[0].properties.field_name;
        const id = appZone.features[0].properties.field_id;
        return {
          name: `Talhão ${name}`,
          id
        };
      });

      appZones.unshift({
        id: 'fa9a6de6-ecb3-11ec-8ea0-0242ac120002',
        name: 'Avaliação de Toda Fazenda'
      });

      setAppZoneMapping(appZones);
    } catch (error) {
      console.error(error);
    }
  };
  const getExecutorsByFarmId = async () => {
    const params = new URLSearchParams([['FarmId', selectedFarmId]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.auth}/UserFundacao/ListUserFundacaoByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      // setExecutorList(response.data.data.usersFundacao);

      const appExecuto = response.data.data.usersFundacao.map(
        (appZone: any) => {
          const name = appZone.fullname;
          const id = appZone.user_id;
          return {
            name,
            id
          };
        }
      );

      setAppExecutoMapping(appExecuto);
    } catch (error) {
      console.error(error);
    }
  };

  const getNextRoutine = async (pageNumber = 1) => {
    setLoading(true);
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['status', 'af01fe97-b627-45d4-b620-43cbd5958adb'], // status ativo
        ['page', pageNumber],
        ['itensPerPage', 999],
        ['filter', searchField || ''],
        [
          'initDate',
          initDate != null &&
          [TypeMonitoring.AMBOS, TypeMonitoring.AGENDADO].includes(
            monitoringSelect
          )
            ? initDate
            : ''
        ],
        [
          'endDate',
          endDate != null &&
          [TypeMonitoring.AMBOS, TypeMonitoring.AGENDADO].includes(
            monitoringSelect
          )
            ? endDate
            : ''
        ],
        [
          'selectLocal',
          [TypeMonitoring.AMBOS, TypeMonitoring.AGENDADO].includes(
            monitoringSelect
          )
            ? selectLocal.map((date) => `'${date}'`).toString()
            : ''
        ],
        [
          'executorList',
          [TypeMonitoring.AMBOS, TypeMonitoring.AGENDADO].includes(
            monitoringSelect
          )
            ? executorList.map((date) => `'${date}'`).toString()
            : ''
        ]
      ]);
      const response = await api.get<NextRoutineResponse>(
        `${appConfig.apiHosts.prague}/Routine/ListByStatus?itensPerPage=99999`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setNextRoutineList(response.data.data.routines);
      setTotalPagesNextRoutine(response.data.data.pagination.totalPages);

      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getDoneRoutine = async (pageNumber = 1) => {
    setLoading(true);
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['status', 'bb4d9732-1a3c-4eff-a09a-43bdd44de297'], // status concluído
        ['page', pageNumber],
        ['itensPerPage', 999],
        ['filter', searchField || ''],
        [
          'initDate',
          initDate != null &&
          [TypeMonitoring.AMBOS, TypeMonitoring.CONCLUIDO].includes(
            monitoringSelect
          )
            ? initDate
            : ''
        ],
        [
          'endDate',
          endDate != null &&
          [TypeMonitoring.AMBOS, TypeMonitoring.CONCLUIDO].includes(
            monitoringSelect
          )
            ? endDate
            : ''
        ],
        [
          'selectLocal',
          [TypeMonitoring.AMBOS, TypeMonitoring.CONCLUIDO].includes(
            monitoringSelect
          )
            ? selectLocal.map((date) => `'${date}'`).toString()
            : ''
        ],
        [
          'executorList',
          [TypeMonitoring.AMBOS, TypeMonitoring.CONCLUIDO].includes(
            monitoringSelect
          )
            ? executorList.map((date) => `'${date}'`).toString()
            : ''
        ]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Routine/ListByStatus?itensPerPage=99999`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setDoneRoutineList(response.data.data.routines);
      setTotalPagesDoneRoutine(response.data.data.pagination.totalPages);

      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getRoutineById = async (id: any, selectedVisible: any) => {
    const params = new URLSearchParams([['routine_id', id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Routine/ListByRoutineId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setSelectedRoutine(response.data.data);

      if (selectedVisible) {
        setItemSelectedVisible('viewRoutine');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePageNextRoutine = (_event: any, value: any) => {
    console.log('value', value);
    getNextRoutine(value);
  };

  const handleChangePageDoneRoutine = (_event: any, value: any) => {
    getDoneRoutine(value);
  };

  const formatDate = (date: any) => {
    const data = new Date(date);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  useEffect(() => {
    (async () => {
      await getNextRoutine();
      await getDoneRoutine();
      await getExecutorsByFarmId();
      await getTypeAppZone();
    })();
  }, [
    updateScreen,
    searchField,
    initDate,
    endDate,
    selectLocal,
    executorList,
    selectedFarmId
  ]);

  // useEffect(() => {
  //   if (updateScreen) {
  //     getNextRoutine();
  //     getDoneRoutine();
  //     getExecutorsByFarmId();
  //     getTypeAppZone();
  //   }
  // }, [updateScreen]);

  // useEffect(() => {
  //   if (searchField === '') {
  //     getNextRoutine();
  //     getDoneRoutine();
  //     getExecutorsByFarmId();
  //     getTypeAppZone();
  //   }
  // }, [searchField]);

  return (
    <S.Container>
      <S.SearchAreaContainer>
        <S.SearchArea>
          <MdSearch
            size={28}
            onClick={() => {
              getNextRoutine();
              getDoneRoutine();
            }}
          />
          <S.SearchInput
            type="text"
            placeholder="Pesquisa por pragas, talhões, etc..."
            value={searchField}
            onChange={(e) => {
              setSearchField(e.target.value);
            }}
          />
        </S.SearchArea>

        {/* <S.FilterWrapper>
          <MdFilterList />
          <span>Filtrar Por...</span>
        </S.FilterWrapper> */}
        {subModulo.includes('NovaRotina') && (
          <S.AddBtnContainer>
            <Button
              type="button"
              text={<p>Nova Rotina</p>}
              className="addBtn"
              onClick={() => {
                setOpendedModal('modalNewRoutine');
                openModal();
              }}
            />
          </S.AddBtnContainer>
        )}
      </S.SearchAreaContainer>
      <S.AddBtnContainer>
        <div style={{ display: 'flex', gap: '12px' }}>
          <S.InputContainer>
            <span>Data de Inicio </span>

            <TextField
              type="date"
              margin="dense"
              fullWidth
              size="small"
              onChange={(data) => {
                data.target.value !== ''
                  ? setInitDate(data.target.value)
                  : setInitDate(undefined);
              }}
            />
          </S.InputContainer>
          <S.InputContainer>
            <span>Data final </span>

            <TextField
              type="date"
              margin="dense"
              fullWidth
              size="small"
              onChange={(data) => {
                data.target.value !== ''
                  ? setEndDate(data.target.value)
                  : setEndDate(undefined);
              }}
            />
          </S.InputContainer>
          <S.InputContainer>
            <FormControl className="selectStyle" style={{ width: '100%' }}>
              <span>Executor</span>
              <Select
                placeholder="Selecione..."
                size="small"
                multiple
                onChange={(data) => {
                  setExecutorList(data.target.value as string[]);
                }}
                value={executorList}
                style={{ marginTop: 9, width: '100%' }}
                renderValue={(selected) => {
                  const selectedNames = selected.map((id) => {
                    const appZoneObject = appExecutoMapping.find(
                      (obj) => obj.id === id
                    );
                    return appZoneObject ? appZoneObject.name : null;
                  });
                  return selectedNames
                    .filter((name) => name !== null)
                    .join(', ');
                }}
              >
                {appExecutoMapping.map((appZone) => (
                  <MenuItem key={appZone.id} value={`${appZone.id}`}>
                    <Checkbox
                      checked={
                        executorList.filter((item) => item === appZone.id)
                          .length > 0
                      }
                    />
                    <ListItemText primary={appZone.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </S.InputContainer>
          <S.InputContainer>
            <FormControl className="selectStyle" style={{ width: '100%' }}>
              <span>Local</span>
              <Select
                size="small"
                placeholder="Selecione..."
                value={selectLocal}
                multiple
                style={{ marginTop: 9, width: '100%' }}
                onChange={(data) => {
                  setSelectLocal(data.target.value as string[]);
                }}
                renderValue={(selected) => {
                  const selectedNames = selected.map((id) => {
                    const appZoneObject = appZoneMapping.find(
                      (obj) => obj.id === id
                    );
                    return appZoneObject ? appZoneObject.name : null;
                  });
                  return selectedNames
                    .filter((name) => name !== null)
                    .join(', ');
                }}
              >
                {appZoneMapping.map((appZone) => (
                  <MenuItem key={appZone.id} value={`${appZone.id}`}>
                    <Checkbox
                      checked={
                        selectLocal.filter((item) => item === appZone.id)
                          .length > 0
                      }
                    />
                    <ListItemText primary={appZone.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </S.InputContainer>
          <S.InputContainer>
            <FormControl className="selectStyle" style={{ width: '100%' }}>
              <span>Tipo de Monitoramento</span>
              <Select
                size="small"
                style={{ marginTop: 9, width: '100%' }}
                value={monitoringSelect}
                onChange={({ target }) =>
                  setMonitoringSelect(target.value as TypeMonitoring)
                }
              >
                {monitoringOptionSelect.map((routine) => (
                  <MenuItem value={routine.value} key={routine.value}>
                    {routine.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </S.InputContainer>
        </div>
        <S.SubTitle>Em Andamento / Próximas</S.SubTitle>
      </S.AddBtnContainer>
      <TableContainer
        className="tabela"
        style={{
          borderRadius: '8px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {loading ? (
          <>
            <PuffLoader color="#79ac34" loading={loading} size={30} />
          </>
        ) : (
          <Table size="small" component={Paper}>
            <TableHead>
              <TableRow
                style={{ backgroundColor: '#EFEFEF', fontWeight: 'bold' }}
              >
                <TableCell align="center" style={{ fontWeight: 'bold' }}>
                  Data
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>
                  Tipo
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>
                  Local
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>
                  Status
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nextRoutineList?.map((row, i) => (
                <TableRow style={{ cursor: 'pointer' }} key={row.routine_id}>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getRoutineById(row.routine_id, true);
                    }}
                  >
                    {formatDate(row.init_date)}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getRoutineById(row.routine_id, true);
                    }}
                  >
                    {row.routine_type_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getRoutineById(row.routine_id, true);
                    }}
                  >
                    {row.encompass_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getRoutineById(row.routine_id, true);
                    }}
                  >
                    {row.status_name}
                  </TableCell>
                  <TableCell align="center">
                    <S.ButtonWrapper>
                      <button
                        type="button"
                        className="actionBtn"
                        onClick={() => {
                          getRoutineById(row.routine_id, false);
                          setOpendedModal('modalDeleteRoutine');
                          openModal();
                        }}
                      >
                        {/* <span style={{ color: '#00F' }}>Editar</span> */}
                        <MdDelete style={{ color: '#f00' }} />
                      </button>
                    </S.ButtonWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Stack spacing={1} style={{ marginBottom: '10px' }}>
        <Pagination
          count={totalPagesNextRoutine}
          variant="outlined"
          shape="rounded"
          size="small"
          onChange={handleChangePageNextRoutine}
        />
      </Stack>
      <S.AddBtnContainer>
        <S.SubTitle>Monitoramentos Concluídos</S.SubTitle>
      </S.AddBtnContainer>
      <TableContainer
        className="tabela"
        style={{
          borderRadius: '8px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {loading ? (
          <>
            <PuffLoader color="#79ac34" loading={loading} size={30} />
          </>
        ) : (
          <Table size="small" component={Paper}>
            <TableHead>
              <TableRow
                style={{ backgroundColor: '#EFEFEF', fontWeight: 'bold' }}
              >
                <TableCell align="center" style={{ fontWeight: 'bold' }}>
                  Data
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>
                  Tipo
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>
                  Local
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>
                  Data de conclusão
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>
                  Responsavel
                </TableCell>

                <TableCell align="center" style={{ fontWeight: 'bold' }}>
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {doneRoutineList?.map((row: any, i) => (
                <TableRow key={i}>
                  <TableCell align="center">
                    {formatDate(row.init_date)}
                  </TableCell>
                  <TableCell align="center">{row.routine_type_name}</TableCell>
                  <TableCell align="center">{row.encompass_name}</TableCell>
                  <TableCell align="center">
                    {formatDate(row.end_date)}
                  </TableCell>
                  <TableCell align="center">{row.executor_name}</TableCell>
                  <TableCell align="center">
                    <S.ButtonWrapper>
                      <button
                        type="button"
                        style={{
                          backgroundColor: 'yellow',
                          borderRadius: '5px'
                        }}
                        className="actionBtn"
                        onClick={() => {
                          setSelectedMonitoring(row);
                          setOpendedModal('ModaExportRoutine');
                          openModal();
                        }}
                      >
                        <KeyboardDoubleArrowDownIcon
                          style={{ color: '#000' }}
                        />
                      </button>
                    </S.ButtonWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Stack spacing={1}>
        <Pagination
          count={totalPagesDoneRoutine}
          variant="outlined"
          shape="rounded"
          size="small"
          onChange={handleChangePageDoneRoutine}
        />
      </Stack>
    </S.Container>
  );
};

export default Monitoring;
