/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { OrderedTable } from '../../../../../../../components/OrderedTable';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import { useProfileListRequest } from './hooks/useProfileListRequest';
import { ResearchField } from '../../../../../../../components/ResearchField';
import { Profile } from './utils/profile.types';

const ProfileList: FC = () => {
  const { request, setFilter } = useProfileListRequest();

  const headCells: HeadCell<Profile>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Nome'
    },

    {
      id: 'activated',
      numeric: false,
      disablePadding: false,
      label: 'Ativo'
    }
  ];

  return (
    <>
      <ResearchField onChange={setFilter} />
      <OrderedTable
        rows={request.items}
        HeadCell={headCells}
        IconCreate={PostAddIcon}
        title="Perfil de Usuários"
        routeNameAdd="./new"
        routeNameEdit="./edit"
        checkBox={false}
        selectInLine
      />
      <Outlet />
    </>
  );
};

export default ProfileList;
