import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { ModuleProfileFormProps } from '../../utils/moduleProfile.types';
import { organizacaoProfileFormProps } from '../utils/organizacaoProfileFormProps';
import OrganizacaoApi from '../../../../../../../../APi/FarmApi/OrganizacaoApi';

const useProfileOrganizacaoForm = ({
  type,
  listaAtrelados = []
}: ModuleProfileFormProps) => {
  const { token } = useAuth();
  const organizacaoApi = useMemo(() => new OrganizacaoApi(token), []);
  const [formValid, setFormValid] = useState<boolean>(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [mensage, setMensage] = useState<boolean>(false);

  const FormValidationSchema = Yup.object().shape({
    user_profile_id: Yup.string().required(),
    organizacao_id: Yup.array().required()
  });

  const methods = useForm({
    defaultValues: {
      user_profile_id: [],
      organizacao_id: []
    },
    resolver: yupResolver(FormValidationSchema)
  });

  const moduleProfileFormCreate = useMutation({
    mutationFn: async (form: organizacaoProfileFormProps) =>
      form.organizacao_id.forEach((item) => {
        organizacaoApi.AtrelarPerfilOrganizacao({
          user_profile_id: form.user_profile_id,
          organizacao_id: item
        });
      }),
    onSuccess: async () => {
      setMensage(true);
      navigate(-1);
      await queryClient.invalidateQueries(
        organizacaoApi.retomarListaOrganizacaoAtrelamento
      );
    }
  });
  const moduleProfileFormUpdate = useMutation({
    mutationFn: async (form: organizacaoProfileFormProps) => {
      const moduleList = form.organizacao_id.filter(
        (x: any) => !listaAtrelados.includes(x)
      );
      const moduleListOld = form.organizacao_id.filter((x: any) =>
        listaAtrelados.includes(x)
      );
      const desSelect = listaAtrelados.filter(
        (x) => !moduleList.concat(moduleListOld).includes(x)
      );

      desSelect.forEach(async (item) => {
        await organizacaoApi.DeletarPerfilOrganizacao({
          user_profile_id: form.user_profile_id,
          organizacao_id: item
        });
      });

      moduleList.forEach(async (item) => {
        await organizacaoApi.AtrelarPerfilOrganizacao({
          user_profile_id: form.user_profile_id,
          organizacao_id: item
        });
      });
    },
    onSuccess: async () => {
      setMensage(true);
      navigate(-1);
      await queryClient.invalidateQueries(
        organizacaoApi.retomarListaOrganizacaoAtrelamento
      );
    }
  });
  useEffect(() => {
    setFormValid(
      !methods.control.getValues('user_profile_id') ||
        methods.control.getValues('organizacao_id').length === 0
    );
  }, [methods.control.watchInternal()]);

  const submit = methods.handleSubmit(
    async (form: organizacaoProfileFormProps) => {
      if (type === 'new') {
        await moduleProfileFormCreate.mutateAsync(form);
      } else {
        await moduleProfileFormUpdate.mutateAsync(form);
      }
    }
  );

  const title: Record<string, string> = {
    new: 'Atrelamento de Perfil x Organização',
    edit: 'Editar atrelamento de Perfil x Organização'
  };

  const mensageSuscess: Record<string, string> = {
    new: 'Atrelamento feito com sucesso',
    edit: 'Edição do atrelamento feito com sucesso'
  };

  return {
    form: {
      methods,
      submit,
      isValid: formValid,
      isSuccess: moduleProfileFormCreate.isSuccess
    },
    mensage,
    title,
    mensageSuscess
  };
};

export default useProfileOrganizacaoForm;
