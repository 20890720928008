import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 10px 20px;
  background: #e4e4e4;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 15px;

  ${(props) =>
    props.selected &&
    css`
      background: #bfd76c;
    `}
`;

export const CheckBox = styled.input`
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    top: -3px;
    left: -3px;
    background-color: #fff;
    border: 1px solid #fff;
  }

  &:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    top: -3px;
    left: -3px;
    background-color: #aed329;
    border: 3px solid #fff;
  }
`;

export const Text = styled.span`
  color: #000000;
  ${(props) =>
    props.selected &&
    css`
      color: #000000;
    `}
`;
