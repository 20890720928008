/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { AxiosResponse } from 'axios';
import api from './configFarmApi';

class UserAPI {
  constructor(token: string) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  get getUser() {
    return 'Listar-Usuarios';
  }

  get userDetails() {
    return 'Detalhes-Usuarios';
  }

  get listaAtrelamentoUsuarioPerfil() {
    return 'ListaAtrelamento-UsuarioPerfil';
  }

  async UserList(Filter: any, organizacao_id?: string): Promise<AxiosResponse> {
    return api.get(
      `/Farm/ListarUsuarios?filtro=${Filter}&organizacao_id=${
        organizacao_id || ''
      }`
    );
  }
  async VerificarCpf(cpf: any): Promise<AxiosResponse> {
    return api.get(`/Farm/VerificarCpf?cpf=${cpf}`);
  }
  async VerificarOrganizacao(organizacao: any): Promise<AxiosResponse> {
    return api.get(`/Farm/VerificarOrganizacao?organizacao=${organizacao}`);
  }
  async VerificarEmail(email: any): Promise<AxiosResponse> {
    return api.get(`/Farm/VerificarEmail?email=${email}`);
  }
  async UserRegister<T>(data: T): Promise<AxiosResponse> {
    return api.post('/Farm/Usuario', data);
  }

  async UserUpdate<T>(data: T): Promise<AxiosResponse> {
    return api.put('/Farm/Usuario', data);
  }

  async UserDetalis(id: string): Promise<AxiosResponse> {
    return api.get(`/Farm/RetornarUsuario?id=${id}`);
  }

  async UserFileList(Filter: any): Promise<AxiosResponse> {
    return api.get(`/Farm/ListarAtrelarUsuarioPerfil?filtro=${Filter}`);
  }

  async ListarAtrelarUsuarioPerfil(
    Filter: any,
    organizacaoId?: string
  ): Promise<AxiosResponse> {
    return api.get(
      `/Farm/ListarAtrelarUsuarioPerfil?filtro=${Filter}&organizacao_id=${
        organizacaoId ?? ''
      }`
    );
  }
}

export default UserAPI;
