import styled from 'styled-components';

export const tableContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
  height: 310px;

  hr {
    border: solid 1px #dcdcdc;
  }

  h1 {
    font-size: 1.2rem;
    margin-bottom: 16px;
    margin-top: 16px;
    color: #444444;
  }
`;

export const TableRecomm = styled.table`
  border-collapse: collapse;
  font-size: 10px;
  text-align: center;
  /* overflow: auto; */

  .buttonTable {
    height: 20px;
    font-size: 0.6rem;
    border-radius: 4px;
    margin: 0px;
    font-weight: normal;
  }

  tr:nth-child(odd) {
    background: #ffffff;
  }

  tr:nth-child(even) {
    background: #eeeeee;
  }

  td {
    white-space: nowrap;
  }

  .title {
    padding: 10px;
    font-weight: bold;
    color: #444444;
    font-size: 12px;

    td {
      padding: 10px;
    }
  }

  .content {
    td {
      color: #444444;
      border-right: 1px solid #ddd;
      padding: 10px 20px;
      font-size: 10px;
    }
  }
`;

export const FilterContainer = styled.div`
  padding: 20px;
`;

export const SelectFilterContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;
