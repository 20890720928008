import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.header`
  height: 100px;
  width: 100%;
  padding: 40px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;

  > div {
    padding: 20px;
    img {
      width: 195px;
      height: auto;
    }
  }
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;

  span {
    font-size: 16px;
  }
`;
export const NotificationOptions = styled.div`
  position: relative;
  display: inline-block;

  button {
    display: flex;
    align-items: center;
    border: none;
    background: #fff;
    div {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 22px;
        height: 22px;
      }
    }
    span {
      margin: 0 5px;
      margin-left: 0px;
    }
  }

  .dropdown {
    position: absolute;
    top: 100%;
    right: 1;
    max-width: 300px;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.3);
  }

  ul {
    list-style: none;
  }
`;

export const FarmOptions = styled.div`
  position: relative;
  display: inline-block;

  button {
    display: flex;
    align-items: center;
    border: none;
    background: #fff;
    div {
      width: 100%;
      height: 50%;
      border-radius: 6px;
      display: flex;
      align-items: start;
      justify-content: center;

      svg {
        width: 22px;
        height: 22px;
      }
    }
    span {
      margin: 0 5px;
      margin-left: 0px;
    }
  }

  .dropdown {
    position: absolute;
    top: 100%;
    right: 1;
    max-width: 300px;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.3);
  }

  ul {
    list-style: none;
  }
`;

export const Notifications = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 30px;

  div {
    position: relative;
    display: inline-block;
    > span {
      font-size: 11px;
      position: absolute;
      top: -3px;
      right: -5px;
      padding: 1px 4px;
      border-radius: 50%;
      background: #a0bd39;
      color: white;
      border: 1px solid #fff;
    }
  }

  > svg {
    width: 18px;
    height: 18px;
  }
`;
export const UserOptions = styled.div`
  position: relative;
  display: inline-block;

  .dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    max-width: 300px;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    button {
      width: 100%;
      height: 100%;
      padding: 10px;

      &:hover {
        background: ${shade(0.2, '#FFF')};
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    border: none;
    background: #fff;

    span {
      margin-left: 5px;
    }

    div {
      background: #a0bd39;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 18px;
        height: 18px;
        color: #fff;
      }
    }
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const NotificationsContainer = styled.div`
  flex: 1;
  min-height: 50px;
  max-height: 300px;
  overflow: auto;
  padding: 5px;

  ${(props) =>
    props.loading === 'true' &&
    css`
      display: flex;
      justify-content: center;
      flex-direction: row;
    `}
`;

export const DropDownNotification = styled.ul`
  width: 100%;
  min-width: 200px;
  height: 250px;
  overflow-y: auto;
  padding: 5px;
  background: #ffffff;
`;
export const DropDownFarm = styled.ul`
  width: 100%;
  min-width: 300px;
  height: 250px;
  overflow-y: auto;
  padding: 5px;
  background: #ffffff;
`;
// export const Container = styled.div`
//   height: 100%;
//   width: 100%;

//   hr {
//     margin-bottom: 16px;
//     border: solid 1px #ededed;
//   }

//   span {
//     font-weight: bold;
//   }
// `;

export const MessageFarm = styled.span`
  font-weight: bold;
  color: ${(props) => (props.color ? props.color : '#79ac34')};
`;

export const Title = styled.h1`
  font-size: 21px;
  color: #444444;
  margin-bottom: 16px;
`;
export const SubTitle = styled.h2`
  font-size: 16px;
  color: #444444;
  margin-bottom: 16px;
`;

export const ListFarmsContainer = styled.div`
  width: 100%;
  min-height: 50px;
  max-height: 330px;
  overflow: auto;
  padding: 5px;

  ${(props) =>
    props.loading &&
    css`
      display: flex;
      justify-content: center;
      flex-direction: row;
    `}
`;

export const RegisterFarmDialog = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;

  h3 {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 16px;
  }

  span {
    font-size: 12px;
    color: #a3a3a3;
    margin-bottom: 32px;
  }

  button {
    background: transparent;
    border: none;
    color: #79ac34;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  /* p {
    color: #79ac34;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
  } */
`;

export const CardImportShapefile = styled.button`
  label {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    cursor: pointer;
  }

  padding: 10px 0px;
  display: flex;
  width: 100%;
  height: 100px;
  border: none;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  box-shadow: 1px 3px 6px #0000001a;
  align-items: center;
  justify-content: space-around;

  span {
    color: #444444;
    font-size: 14px;
    font-weight: bold;
  }

  svg {
    width: 43px;
    height: 43px;
    padding: 0;
    margin: 0;
  }

  input[type='file'] {
    display: none;
  }

  .subtitleBtn {
    font-size: 12px;
    font-weight: normal;
  }

  & + button {
    margin-bottom: 10px;
  }

  /* label {
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
  display: flex;
  padding: 5px;
  width: 100%;
  height: 40px;
  border: none;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
  align-items: center;
  justify-content: space-around;

  span {
    color: #79ac34;
    font-size: 0.8rem;
    font-weight: bold;
  }

  input[type='file'] {
    display: none;
  } */
`;

export const FarmCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #dcdcdc;
  padding: 16px 24px;
  border-radius: 8px;
  /* box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3); */

  & + div {
    margin-top: 10px;
  }

  .active {
    border: 1px solid #79ac34;
  }
`;

export const FarmCardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FarmCardText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h3 {
    font-size: 0.9rem;
  }

  .talhao {
    font-size: 0.7rem;
  }
`;

export const ViewAction = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    height: 100%;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FarmCardActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  gap: 2px;

  button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;

    & + button {
      //margin-left: 10px;
    }
    > span {
      color: #79ac34;
      font-size: 0.8rem;
    }
    > svg {
      width: 14px;
      height: 14px;
      color: #79ac34;
    }
  }
`;
