import { AxiosResponse } from 'axios';
import api from './configFarmApi';

class OrganizacaoApi {
  constructor(token: string) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  get getOrganizacao() {
    return 'get-organizacao';
  }

  get getOrganizacaoById() {
    return 'get-organizacao-id';
  }

  get retornarOrganizacoes() {
    return 'retornar-Organizacoes';
  }

  get retomarListaOrganizacaoAtrelamento() {
    return 'retomar-Lista';
  }

  async GetOrganizacao(
    id: string,
    organizacao_id?: string
  ): Promise<AxiosResponse> {
    return api.get(
      `/Farm/ListarOrganizacoes?filtro=${id}&organizacao_id=${organizacao_id}`
    );
  }

  async RetornarOrganizacao(id: string): Promise<AxiosResponse> {
    return api.get(`/Farm/RetornarOrganizacao?id=${id}`);
  }

  async createOrganizacao<T>(data: T): Promise<AxiosResponse> {
    return api.post('/Farm/Organizacao', data);
  }

  async updateOrganizacao<T>(data: T): Promise<AxiosResponse> {
    return api.put('/Farm/Organizacao', data);
  }

  async ListarAtrelarPerfilOrganizacao(
    filtro: string,
    profileId?: string,
    organizacaoId?: string
  ): Promise<AxiosResponse> {
    return api.get(
      `/Farm/ListarAtrelarPerfilOrganizacao?user_profile_id=${
        profileId || ''
      }&filtro=${filtro}&organizacao_id=${organizacaoId || ''}`
    );
  }

  async AtrelarPerfilOrganizacao(data: any): Promise<AxiosResponse> {
    const result = await api.put(
      `/Farm/AtrelarPerfilOrganizacao?user_profile_id=${data.user_profile_id}&organizacao_id=${data.organizacao_id}`
    );
    return result;
  }

  async DeletarPerfilOrganizacao(data: any): Promise<AxiosResponse> {
    const result = await api.delete(
      `/Farm/DeletarPerfilOrganizacao?user_profile_id=${data.user_profile_id}&organizacao_id=${data.organizacao_id}`
    );
    return result;
  }
}

export default OrganizacaoApi;
