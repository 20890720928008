import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  h1 {
    font-size: 21px;
    margin: 16px 0;
  }

  hr {
    border: solid 1px #dcdcdc;
    margin-bottom: 16px;
  }
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  //align-items: center;

  > p {
    /* margin-top: 40px; */
    color: #a3a3a3;
    font-size: 12px;
    text-align: left;
    margin-bottom: 16px;
  }

  .subTitle {
    font-size: 16px;
  }
`;

export const ActionCard = styled.button`
  padding: 10px 0px;
  display: flex;
  width: 100%;
  height: 100px;
  border: none;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  box-shadow: 1px 3px 6px #0000001a;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 5px;

  span {
    color: #444444;
    font-size: 14px;
    font-weight: bold;
  }

  svg {
    width: 43px;
    height: 43px;
    padding: 0;
    margin: 0;
  }

  input[type='file'] {
    display: none;
  }

  & + button {
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
  }

  .label-upload {
    width: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    cursor: pointer;

    label {
      cursor: pointer;
    }

    input {
      display: none;
    }
  }
`;

export const MappingsContainer = styled.div`
  overflow: auto;
  max-height: 235px;
  width: 100%;
`;

export const MappingsCardContainer = styled.div`
  padding: 10px 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #dcdcdc;
  box-shadow: 1px 3px 6px #0000001a;

  & + div {
    margin-top: 5px;
  }

  button {
    background: transparent;
    border: 0;
    color: #79ac34;
    font-size: 0.8rem;
  }
`;
export const MappingsCardHeader = styled.div`
  display: flex;

  > h4 {
    font-size: 14px;
    color: #444;
    margin-bottom: 0px;
  }
`;
export const MappingsCardTextContainer = styled.div`
  width: 100%;
  margin-left: 10px;

  > span {
    font-size: 12px;

    //font-weight: bold;
  }

  > p {
    font-size: 11px;
    margin-top: 2px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 5px 0;
`;

export const CleanMappings = styled.button`
  margin-bottom: 16px;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9fbc38;
  font-size: 14px;
  font-weight: bold;
  width: 100%;

  svg {
    width: 14px;
    margin: 0 5px;
  }
`;

export const AddMappingButton = styled.button`
  border: 0;
  background: #fff;
  color: #79ac34;
  font-weight: bold;
  font-size: 0.8rem;

  &:disabled {
    color: #dcdcdc;
    cursor: default;
  }
`;
