/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { v4 as uuid } from 'uuid';
import * as S from './styled';
import api from '../../../../../../services/api';
import { DashboardContext } from '../../../../index';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import Button from '../../../../../../components/Button';

const Table = () => {
  const { token } = useAuth();
  const { selectedFarmId, tableResults } = useMap();
  const { farms, setFarms } = useContext(DashboardContext);
  const [filter, setFilter] = useState([
    {
      id: 0,
      type: 'Sem Filtro',
      collumn: '',
      values: []
    },
    {
      id: 1,
      type: 'Profundidade',
      collumn: 'prof',
      values: [...new Set(tableResults.map((result) => result.prof))]
    },
    {
      id: 2,
      type: 'Safra',
      collumn: 'safra',
      values: [...new Set(tableResults.map((result) => result.safra))]
    },
    {
      id: 3,
      type: 'Laboratorio',
      collumn: 'laboratorio',
      values: [...new Set(tableResults.map((result) => result.laboratorio))]
    },
    {
      id: 4,
      type: 'Subdivisão',
      collumn: 'sub01',
      values: [...new Set(tableResults.map((result) => result.sub01))]
    },
    {
      id: 5,
      type: 'Talhão',
      collumn: 'campo',
      values: [...new Set(tableResults.map((result) => result.campo))]
    }
  ]);
  const [selectedFilter, setSelectedFilter] = useState('Sem Filtro');
  const [selectedValue, setSelectedValue] = useState('');

  const data = {
    columns: [
      { field: 'id', hide: true },
      { headerName: 'Proprietário', field: 'proprietario', width: 150 },
      {
        headerName: 'Propriedade',
        field: 'propriedade',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Município',
        field: 'municipio',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'UF',
        field: 'uf',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Laboratorio',
        field: 'laboratorio',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Observação',
        field: 'observacao',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Safra',
        field: 'safra',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Data',
        field: 'formatedDate',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'N. Lab.',
        field: 'nLab',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Original',
        field: 'original',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Campo',
        field: 'campo',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Sub01',
        field: 'sub01',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Sub02',
        field: 'sub02',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Sub03',
        field: 'sub03',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'AB',
        field: 'ab',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Prof',
        field: 'prof',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'P res IAC',
        field: 'pResIAC',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'P res AgrAn.',
        field: 'pResAgrAn',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'P remanescente',
        field: 'pRemanescente',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'pH_água',
        field: 'pH_agua',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'pH_CaCl2',
        field: 'pH_CaCl2',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'P',
        field: 'p',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'K',
        field: 'k',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Ca_Mg',
        field: 'ca_Mg',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Ca',
        field: 'ca',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Mg',
        field: 'mg',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Al',
        field: 'al',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'H',
        field: 'h',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'MO',
        field: 'mo',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Areia',
        field: 'areia',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Silte',
        field: 'silte',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Argila',
        field: 'argila',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Sb',
        field: 'sb',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'CTC',
        field: 'ctc',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'V%',
        field: 'v',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'CaMg',
        field: 'caMg',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'CaK',
        field: 'caK',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'MgK',
        field: 'mgK',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Ca%',
        field: 'cap',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Mg%',
        field: 'mgp',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Al%',
        field: 'alp',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'K%',
        field: 'kp',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'H%',
        field: 'hp',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Sat. Al',
        field: 'satAl',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Zn',
        field: 'zn',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Cu',
        field: 'cu',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Fe',
        field: 'fe',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Mn',
        field: 'mn',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'S',
        field: 's',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'B',
        field: 'b',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      }
    ],
    rows: tableResults.map((tR) => ({
      id: uuid(),
      formatedDate: new Date(tR.data).toLocaleDateString(),
      ...tR
    }))
  };

  return (
    <S.tableContainer>
      <hr />
      {/* <h1>Tabela</h1> */}
      {tableResults === '' ? (
        <S.TableRecomm>
          <tr>
            <td>{null}</td>
          </tr>
        </S.TableRecomm>
      ) : (
        <>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={data.rows}
              columns={data.columns}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </div>
          {/* <S.FilterContainer>
            Filtros
            <S.SelectFilterContainer>
              <SelectInput
                placeholder="Filtro"
                id="filter_type"
                name="filter_type"
                options={filter.map((value) => value.type)}
                value={selectedFilter}
                onChange={(e) => {
                  setSelectedFilter(e);
                }}
                style={{ width: 200 }}
              />
              {selectedFilter !== 'Sem Filtro' && (
                <SelectInput
                  placeholder="Valor"
                  id="filter_values"
                  name="filter_values"
                  options={
                    filter.filter((vl) => vl.type === selectedFilter)[0].values
                  }
                  value={selectedValue}
                  onChange={(e) => {
                    setSelectedValue(e);
                  }}
                  style={{ width: 200 }}
                />
              )}
            </S.SelectFilterContainer>
          </S.FilterContainer>

          <S.TableRecomm>
            <tr className="title">
              <td>Proprietário</td>
              <td>Propriedade</td>
              <td>Município</td>
              <td>UF</td>
              <td>Laboratorio</td>
              <td>Observação</td>
              <td>Safra</td>
              <td>Data</td>
              <td>N. Lab.</td>
              <td>Original</td>
              <td>Campo</td>
              <td>Sub01</td>
              <td>Sub02</td>
              <td>Sub03</td>
              <td>AB</td>
              <td>Prof</td>
              <td>P res IAC</td>
              <td>P res AgrAn.</td>
              <td>P remanescente</td>
              <td>pH_água</td>
              <td>pH_CaCl2</td>
              <td>P</td>
              <td>K</td>
              <td>Ca_Mg</td>
              <td>Ca</td>
              <td>Mg</td>
              <td>Al</td>
              <td>H</td>
              <td>MO</td>
              <td>Areia</td>
              <td>Silte</td>
              <td>Argila</td>
              <td>Sb</td>
              <td>CTC</td>
              <td>V%</td>
              <td>CaMg</td>
              <td>CaK</td>
              <td>MgK</td>
              <td>Ca%</td>
              <td>Mg%</td>
              <td>Al%</td>
              <td>K%</td>
              <td>H%</td>
              <td>Sat. Al</td>
              <td>Zn</td>
              <td>Cu</td>
              <td>Fe</td>
              <td>Mn</td>
              <td>S</td>
              <td>B</td>
              <td>Ação</td>
            </tr>
            {tableResults.map((item) =>
              selectedFilter === 'Sem Filtro' ? (
                <tr className="content">
                  <td>{item.proprietario}</td>
                  <td>{item.propriedade}</td>
                  <td>{item.municipio}</td>
                  <td>{item.uf}</td>
                  <td>{item.laboratorio}</td>
                  <td>{item.observacao}</td>
                  <td>{item.safra}</td>
                  <td>{item.data}</td>
                  <td>{item.nLab}</td>
                  <td>{item.original}</td>
                  <td>{item.campo}</td>
                  <td>{item.sub01}</td>
                  <td>{item.sub02}</td>
                  <td>{item.sub03}</td>
                  <td>{item.ab}</td>
                  <td>{item.prof}</td>
                  <td>{item.pResIAC}</td>
                  <td>{item.pResAgrAn}</td>
                  <td>{item.pRemanescente}</td>
                  <td>{item.pH_agua}</td>
                  <td>{item.pH_CaCl2}</td>
                  <td>{item.p}</td>
                  <td>{item.k}</td>
                  <td>{item.ca_Mg}</td>
                  <td>{item.ca}</td>
                  <td>{item.mg}</td>
                  <td>{item.al}</td>
                  <td>{item.h}</td>
                  <td>{item.mo}</td>
                  <td>{item.areia}</td>
                  <td>{item.silte}</td>
                  <td>{item.argila}</td>
                  <td>{item.sb}</td>
                  <td>{item.ctc}</td>
                  <td>{item.v}</td>
                  <td>{item.caMg}</td>
                  <td>{item.caK}</td>
                  <td>{item.mgK}</td>
                  <td>{item.cap}</td>
                  <td>{item.mgp}</td>
                  <td>{item.alp}</td>
                  <td>{item.kp}</td>
                  <td>{item.hp}</td>
                  <td>{item.satAl}</td>
                  <td>{item.zn}</td>
                  <td>{item.cu}</td>
                  <td>{item.fe}</td>
                  <td>{item.mn}</td>
                  <td>{item.s}</td>
                  <td>{item.b}</td>
                  <td>
                    <Button className="buttonTable" text="Aplicar" />
                  </td>
                </tr>
              ) : selectedFilter !== 'Sem Filtro' &&
                item[
                  filter.filter((f) => f.type === selectedFilter)[0].collumn
                ] === selectedValue ? (
                <tr className="content">
                  <td>{item.proprietario}</td>
                  <td>{item.propriedade}</td>
                  <td>{item.municipio}</td>
                  <td>{item.uf}</td>
                  <td>{item.laboratorio}</td>
                  <td>{item.observacao}</td>
                  <td>{item.safra}</td>
                  <td>{item.sata}</td>
                  <td>{item.nLab}</td>
                  <td>{item.original}</td>
                  <td>{item.campo}</td>
                  <td>{item.sub01}</td>
                  <td>{item.sub02}</td>
                  <td>{item.sub03}</td>
                  <td>{item.ab}</td>
                  <td>{item.prof}</td>
                  <td>{item.pResIAC}</td>
                  <td>{item.pResAgrAn}</td>
                  <td>{item.pRemanescente}</td>
                  <td>{item.pH_agua}</td>
                  <td>{item.pH_CaCl2}</td>
                  <td>{item.p}</td>
                  <td>{item.k}</td>
                  <td>{item.ca_Mg}</td>
                  <td>{item.ca}</td>
                  <td>{item.mg}</td>
                  <td>{item.al}</td>
                  <td>{item.h}</td>
                  <td>{item.mo}</td>
                  <td>{item.areia}</td>
                  <td>{item.silte}</td>
                  <td>{item.argila}</td>
                  <td>{item.sb}</td>
                  <td>{item.ctc}</td>
                  <td>{item.v}</td>
                  <td>{item.caMg}</td>
                  <td>{item.caK}</td>
                  <td>{item.mgK}</td>
                  <td>{item.cap}</td>
                  <td>{item.mgp}</td>
                  <td>{item.alp}</td>
                  <td>{item.kp}</td>
                  <td>{item.hp}</td>
                  <td>{item.satAl}</td>
                  <td>{item.zn}</td>
                  <td>{item.cu}</td>
                  <td>{item.fe}</td>
                  <td>{item.mn}</td>
                  <td>{item.s}</td>
                  <td>{item.b}</td>
                  <td>
                    <Button className="buttonTable" text="Aplicar" />
                  </td>
                </tr>
              ) : null
            )}
          </S.TableRecomm> */}
        </>
      )}
    </S.tableContainer>
  );
};

export default Table;
