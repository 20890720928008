/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { OrganizacaoList } from './OrganizacaoList';
import { OrganizacaoForm } from './OrganizacaoForm';

const OrganizacaoRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<OrganizacaoList />}>
        <Route path="/new" element={<OrganizacaoForm type="new" />} />
        <Route path="/edit/:id" element={<OrganizacaoForm type="edit" />} />
      </Route>
    </Routes>
  );
};

export default OrganizacaoRouter;
