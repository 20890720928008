import React, { useContext } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField
} from '@mui/material';
import { DashboardContext } from '../../../../index';
import Table from '../Table';
import { Container } from './styled';

const ResultReport = () => {
  const { showResult, selectedOption, setSelectedOption, options } =
    useContext(DashboardContext);

  const handleChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <Container>
      {/* <Content> */}
      {/* <div className="selectContainer">
          <h1>Resultados dos gráficos</h1>
          <h2>Selecione a Amostra</h2>
           <FormControl>
              <InputLabel>
                Cultura
              </InputLabel>
              <Select
                label="Cultura"
                placeholder="Cultura"
                name="select-sample"
                value={selectedOption}
                onChange={(e) => {
                  handleChange(e.target.value)
                }}
              >
                {options.map((v) => (
                  <MenuItem value={v.name} key={v.value_Id}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          <AddButton>Gerar Grafico</AddButton>
        </div> */}
      {/* <div>
          <GraphicContent>
            <GraphicReport />
          </GraphicContent>
        </div> */}
      {/* </Content> */}
      <Table />
    </Container>
  );
};
export default ResultReport;
