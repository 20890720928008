/* eslint-disable prettier/prettier */
import proj4 from 'proj4';
import GeoJSON from 'ol/format/GeoJSON';

const GeoJson = new GeoJSON();

export const parseCoordinates = async (geojson) => {
  const coordinates = await geojson.features[0].geometry.coordinates[0].map((value) =>
    proj4('EPSG:4326', 'EPSG:3857', [value[0], value[1]]));
  geojson.features[0].geometry.coordinates[0] = coordinates;
  return geojson;
};

export const parseCoordinatesTo4326 = (geojson) => {
  const coordinates = geojson.features[0].geometry.coordinates[0].map((value) =>
    proj4('EPSG:3857', 'EPSG:4326', [value[0], value[1]]));
  geojson.features[0].geometry.coordinates[0] = coordinates;
  return geojson;
};

export const parseCoordinatesLineStringTo4326 = (geojson) => {
  const coordinates = geojson.features[0].geometry.coordinates.map((value) =>
    proj4('EPSG:3857', 'EPSG:4326', value));
  geojson.features[0].geometry.coordinates = coordinates;
  return geojson;
};

export const parseCoordinatesMultiPolygonTo3857 = (geojson) => {
  const parsed = [];
  geojson.features[0].geometry.coordinates.map((value) => {
    const coordinate = value[0].map((coord) => {
      return proj4('EPSG:4326', 'EPSG:3857', [coord[0], coord[1]]);
    });
    return parsed.push([coordinate]);
  });

  geojson.features[0].geometry.coordinates = parsed;
  return geojson;
};

export const parseCoordinatesPointTo3857 = (geojson) => {
  const coordinates = proj4('EPSG:4326', 'EPSG:3857', geojson.features[0].geometry.coordinates);
  geojson.features[0].geometry.coordinates = coordinates;
  return geojson;
};

export const parseCoordinatesMultiPolygonMultiFeaturesTo3857 = (geojson) => {
  const features = GeoJson.readFeatures(geojson);
  const geoJsonStr = GeoJson.writeFeatures(
    features,
    { dataProjection: 'EPSG:3857', featureProjection: 'EPSG:4326' }
  );
  return JSON.parse(geoJsonStr);
};

export const parseCoordinatesMultiWalkMultiFeaturesTo3857 = (geojson) => {
  const features = GeoJson.readFeatures(geojson);
  const geoJsonStr = GeoJson.writeFeatures(
    features,
    { dataProjection: 'EPSG:3857', featureProjection: 'EPSG:4326' }
  );
  return JSON.parse(geoJsonStr);
};

export const parseMultiPolygonToPolygon = (geoJson) => {
  const polygon = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: geoJson.features[0].geometry.coordinates[0]
        }
      }
    ]
  };
  return polygon;
};

export const parseMultiPolygonToPolygonShapefile = (geoJson) => {
  const polygon = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: geoJson.features[0].properties,
        geometry: {
          type: 'Polygon',
          coordinates: geoJson.features[0].geometry.coordinates[0]
        }
      }
    ]
  };
  return polygon;
};
