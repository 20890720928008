import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;

  h1 {
    margin-left: 5px;
    font-size: 21px;
    margin-bottom: 15px;
  }

  hr {
    border: solid 1px #dcdcdc;
    margin-bottom: 16px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .button {
    height: 40px;
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 0.9rem;
  }
`;

export const Title = styled.h3`
  margin: 5px 0 3px 0;
  font-size: 16px;
  margin-bottom: 16px;
`;
export const SubTitle = styled.span`
  color: #a3a3a3;
  font-size: 12px;
  text-align: justify;
  margin-bottom: 10px;
`;

export const SubdivionsContainer = styled.div`
  margin-bottom: 10px;
  max-height: 270px;
  overflow: auto;
`;

export const Subdivision = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  padding: 0px 10px;
  margin: 5px 0;
`;

export const SubdivisionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;

  h4 {
    flex: 1;
    margin-left: 4px;
    font-size: 0.9rem;
  }

  button {
    background: transparent;
    border: 0;
  }
`;

export const SubdivisionInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -7px;

  span {
    font-size: 0.7rem;
  }
`;

export const SubdivisionState = styled.div`
  background: #a8a8a8;
  ${(props) =>
    props.status === 'Coletado' &&
    css`
      background: #62904e;
    `}
  ${(props) =>
    props.status === 'Em Coleta' &&
    css`
      background: #d8c267;
    `}
  ${(props) =>
    props.status === 'Divergente' &&
    css`
      background: #d04040;
    `}
  padding: 2px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin: 3px;
  color: #fff;
  font-weight: bold;
  font-size: 0.7rem;
  max-width: 160px;
  margin: 2px auto;
`;

export const SubdivisionActions = styled.div`
  display: flex;
  justify-content: space-around;

  button {
    background: transparent;
    border: 0;
    color: #79ac34;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    gap: 2px;
  }
`;

export const SendToLaboratoryButton = styled.button`
  background: transparent;
  border: 0;
  color: #79ac34;
`;

export const DeleteSubdivisionSelectedButton = styled.button`
  display: flex;
  align-items: center;
  margin: 10px 10px 0px 0px;
  background: transparent;
  border: 0;
  text-align: left;
  color: #79ac34;
  padding: 0;
  font-size: 12px;

  .selectAll {
    margin-left: 4px;
    padding-top: 2px;
  }
`;

export const CardImportFile = styled.button`
  margin-top: 20px;
  label {
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
  display: flex;
  padding: 5px;
  width: 100%;
  height: 40px;
  border: none;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
  align-items: center;
  justify-content: space-around;

  span {
    color: #79ac34;
    font-size: 0.8rem;
    font-weight: bold;
  }

  input[type='file'] {
    display: none;
  }
`;

export const AddSubdivisionButton = styled.button`
  border: 0;
  background: #fff;
  margin-top: 10px;
  padding: 10px;
  /* border: 1px solid #000; */
  color: #79ac34;
  font-weight: bold;
  font-size: 0.8rem;
  border-radius: 8px;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.35);

  &:disabled {
    color: #dcdcdc;
    cursor: default;
  }
`;

export const ActionCard = styled.button`
  margin-top: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.35);
  margin-bottom: 5px;

  & + button {
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
  }
  p {
    font-size: 12px;
  }

  .label-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    background: #fff;
    cursor: pointer;

    label {
      cursor: pointer;
    }

    input {
      display: none;
    }
  }
`;

export const DelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ExportSubdivisionShapeFileButton = styled.button`
  width: 100%;
  background-color: transparent;
  border: none;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #a0bd39;
  border: 1px solid #a0bd39;
  padding: 5px;
  border-radius: 5px;
  justify-content: center;

  &:disabled {
    color: #b4b4b4;
    border: 1px solid #b4b4b4;
  }
`;
