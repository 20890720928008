import ImgDepth010 from '../assets/0-10.svg';
import ImgDepth020 from '../assets/0-20.svg';
import ImgDepth1020 from '../assets/10-20.svg';
import ImgDepth2030 from '../assets/20-30.svg';
import ImgDepth2040 from '../assets/20-40.svg';
import ImgDepth3040 from '../assets/30-40.svg';
import ImgDepth4060 from '../assets/40-60.svg';

export const depthImages = [
  {
    depth: '0-10',
    image: ImgDepth010
  },
  {
    depth: '0-10-20',
    image: ImgDepth1020
  },
  {
    depth: '0-10-20-40',
    image: ImgDepth2030
  },
  {
    depth: '0-10-20-30-40',
    image: ImgDepth3040
  },
  {
    depth: '0-20',
    image: ImgDepth020
  },
  {
    depth: '0-20-40',
    image: ImgDepth2040
  },
  {
    depth: '0-10-20-30-40-60',
    image: ImgDepth4060
  }
];
