/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import PropTypes, { func } from 'prop-types';
import { toast } from 'react-toastify';
import api from '../services/api';
import Toast from '../components/Toast';
import { appConfig } from '../config';
import notificationService from '../services/notification';
import authService from '../services/auth';

const AuthContext = createContext<any>({});

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@FundacaoMT:token');
    const user = localStorage.getItem('@FundacaoMT:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const getNotifications = async (email: string, token: string) => {
    try {
      const response = await notificationService.getNotifications(email, token);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const signIn = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        const signedIn = await authService.login({ email, password });
        const {
          token,
          email: userEmail,
          fullname,
          role,
          user_id,
          organizacao_id
        } = signedIn;
        const user = {
          fullname,
          email: userEmail,
          role,
          user_id,
          organizacao_id
        };

        await getNotifications(email, token);

        localStorage.setItem('@FundacaoMT:token', token);
        localStorage.setItem('@FundacaoMT:user', JSON.stringify(user));

        setData({ token, user });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@FundacaoMT:token');
    localStorage.removeItem('@FundacaoMT:user');

    setData({});
  }, []);

  const verifyToken = useCallback(async (token: string) => {
    try {
      await authService.authenticated(token);
    } catch (error) {
      signOut();
      toast(
        <Toast title="Sessão expirada." content="Faça o login novamente." />,
        { type: 'info' }
      );
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        verifyToken,
        token: data.token,
        getNotifications,
        role: data.user?.role
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

AuthProvider.propTypes = {
  children: PropTypes.node
};

export { AuthProvider, useAuth };
