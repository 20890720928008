import styled from 'styled-components';

export const Container = styled.div`
  max-width: 300px;
  width: 100%;
  position: relative;
  h1 {
    margin-left: 5px;
    font-size: 21px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  hr {
    border: solid 1px #dcdcdc;
    margin-bottom: 32px;
  }
`;

export const ActionCard = styled.button`
  width: 100%;
  min-width: 100px;
  margin-top: 10px;
  height: 100px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  box-shadow: 1px 3px 6px #0000001a;

  & + button {
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
  }
  p {
    font-size: 12px;
  }

  svg {
    width: 43px;
    height: 43px;
  }

  .label-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    background: #fff;
    cursor: pointer;

    label {
      cursor: pointer;
    }

    input {
      display: none;
    }
  }
`;
