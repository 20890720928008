/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'react-modal';
import { MenuItem, FormControl, TextField, ListItemText } from '@mui/material';
import Select from '@mui/material/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import Checkbox from 'react-custom-checkbox';
import { MdCheck } from 'react-icons/md';
import { toast } from 'react-toastify';
import PuffLoader from 'react-spinners/PuffLoader';
import moment from 'moment';
import { useModal } from '../../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../../index';
import Button from '../../../../../../../../../components/Button';
import { useAuth } from '../../../../../../../../../hooks/Auth';
import api from '../../../../../../../../../services/api';
import { appConfig } from '../../../../../../../../../config';
import Toast from '../../../../../../../../../components/Toast';
import { useMap } from '../../../../../../../../../hooks/Map';
import * as S from './styled';
import { localDateTime } from '../../../../../../../../../util';

const ModalNewRoutine = () => {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const {
    selectedManagement,
    uploadedFiles: files,
    deleteFile,
    handleUpload,
    setUploadedFiles,
    setUpdateScreen,
    listTypeManager,
    setSelectTypeProblemList,
    selectTypeRoutineList,
    setSelectTypeRoutineList,
    selectTypeCoverageList,
    setSelectTypeCoverageList,
    setSelectedTypeAppZoneList,
    selectedTypeAppZoneList,
    selectTypePeriodList,
    setSelectTypePeriodList
  } = useContext(DashboardContext);
  const { selectedFarmId } = useMap();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [executorList, setExecutorList] = useState([]);
  const [appZoneMapping, setAppZoneMapping] = useState([]);
  const [showRecurrenceInputs, setShowRecurrenceInputs] = useState(false);

  const ModalNewRoutineSchema = Yup.object().shape({
    type_routine: Yup.string().required('Tipo de rotina é obrigatório'),
    coverage: Yup.array()
      .of(Yup.string())
      .required('Abrangência é obrigatório'),
    title: Yup.string().required('Título é obrigatório'),
    date_init: Yup.date().required('Data inicial é obrigatório'),
    time_course: Yup.string().required('Horário é obrigatório'),
    commits: Yup.string()
  });

  const { handleSubmit, register, errors, control, watch, reset } = useForm({
    resolver: yupResolver(ModalNewRoutineSchema)
  });

  const values = watch();

  function handleRecorrence() {
    const dates = [];

    const initialMoment = moment(values.date_init, 'YYYY-MM-DD');
    const finalMoment = moment(values.end_in, 'YYYY-MM-DD');
    const daysInterval = Number(values.days_of_interval);

    const currentMoment = initialMoment.clone();

    while (currentMoment.isSameOrBefore(finalMoment)) {
      dates.push(moment(currentMoment).format('YYYY-MM-DD'));
      currentMoment.add(daysInterval, 'days');
    }
    return dates;
  }

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const newRoutine = {
        routine_type_id: data.type_routine,
        status: 'af01fe97-b627-45d4-b620-43cbd5958adb',
        title: data.title,
        period: data.time_course,
        note: data.commits,
        farm_id: selectedFarmId,
        created_by: user.user_id,
        executor_id: data.executor_id,
        recurrence: values.recurrence,
        created_at: localDateTime(new Date()),
      };

      const coverages = data.coverage;

      const dates = handleRecorrence();

      await Promise.all(
        coverages.map(async (coverage, index) => {
          const uuid = uuidv4();

          if (values.recurrence === '9fbb8e5c-8b91-4d12-b8d8-2161613c41e3') {
            await Promise.all(
              dates.map(async (date) => {
                const newRoutineMap = {
                  ...newRoutine,
                  encompass_id: coverage,
                  init_date: date,
                  lote: uuid
                };
                await api.post(
                  `${appConfig.apiHosts.prague}/Routine/Create`,
                  newRoutineMap,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`
                    }
                  }
                );
              })
            );
          } else {
            const initialDate = moment(values.date_init).format('YYYY-MM-DD');
            const newRoutineMap = {
              ...newRoutine,
              encompass_id: coverage,
              init_date: initialDate,
              lote: uuid
            };
            await api.post(
              `${appConfig.apiHosts.prague}/Routine/Create`,
              newRoutineMap,
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            );
          }
        })
      );

      toast(<Toast title="Rotina criada com sucesso" />, {
        type: 'success'
      });

      setUpdateScreen(true);

      if (checked) {
        reset();
        setLoading(false);
      } else {
        reset();
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast(<Toast title="Não foi possível criar a rotina" />, {
        type: 'error'
      });
    }
  };

  const getTypeRoutine = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Rotina')[0]
      .type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const filterRoutineByActive = response.data.data.values.filter(
        (v) => v.status === true
      );
      setSelectTypeRoutineList(filterRoutineByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeCoverage = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Abrangência')[0]
      .type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectTypeCoverageList(response.data.data.values);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeAppZone = async () => {
    const params = new URLSearchParams([
      ['farmId', selectedFarmId],
      ['userId', user.user_id]
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.field}/Field/GetActiveFieldListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedTypeAppZoneList(response.data.data);
      const appZones = response.data.data.map((appZone) => {
        const name = appZone.features[0].properties.field_name;
        const id = appZone.features[0].properties.field_id;
        return {
          name: `Talhão ${name}`,
          id
        };
      });

      appZones.unshift({
        id: 'fa9a6de6-ecb3-11ec-8ea0-0242ac120002',
        name: 'Avaliação de Toda Fazenda'
      });

      setAppZoneMapping(appZones);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypePeriod = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Horário')[0]
      .type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterPeriodByActive = response.data.data.values.filter(
        (v) => v.status === true
      );
      setSelectTypePeriodList(filterPeriodByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getExecutorsByFarmId = async () => {
    const params = new URLSearchParams([['FarmId', selectedFarmId]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.auth}/UserFundacao/ListUserFundacaoByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setExecutorList(response.data.data.usersFundacao);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (values.recurrence === '9fbb8e5c-8b91-4d12-b8d8-2161613c41e3') {
      setShowRecurrenceInputs(true);
    }

    if (values.recurrence === 'd464c549-05b3-45b4-97d1-2d5929c5f580') {
      setShowRecurrenceInputs(false);
    }
  }, [values]);

  useEffect(() => {
    getTypeRoutine();
    getTypeCoverage();
    getTypeAppZone();
    getTypePeriod();
    getExecutorsByFarmId();
  }, []);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Agendamento de Nova Rotina</S.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <S.InputWrapper>
              <S.InputContainer>
                <Controller
                  name="type_routine"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Tipo</span>
                      <Select value={value} onChange={onChange} size="small">
                        {selectTypeRoutineList?.map((routine) => (
                          <MenuItem
                            value={routine.value_Id}
                            key={routine.value_Id}
                          >
                            {routine.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.type_routine?.message && (
                  <S.InputError>{errors?.type_routine?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="coverage"
                  control={control}
                  defaultValue={[]}
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Abrangência</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                        placeholder="Selecione..."
                        multiple
                        renderValue={(selected) => {
                          const selectedNames = selected.map((id) => {
                            const appZoneObject = appZoneMapping.find(
                              (obj) => obj.id === id
                            );
                            return appZoneObject ? appZoneObject.name : null;
                          });
                          return selectedNames
                            .filter((name) => name !== null)
                            .join(', ');
                        }}
                      >
                        {appZoneMapping.map((appZone) => (
                          <MenuItem key={appZone.id} value={appZone.id}>
                            <Checkbox
                              checked={
                                value.filter((item) => item === appZone.id)
                                  .length > 0
                              }
                            />
                            <ListItemText primary={appZone.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.coverage?.message && (
                  <S.InputError>{errors?.coverage?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="executor_id"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Executor</span>
                      <Select
                        placeholder="Selecione..."
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {executorList.map((executor) => (
                          <MenuItem
                            value={executor.user_id}
                            key={executor.user_id}
                          >
                            {executor.fullname}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.executor_id?.message && (
                  <S.InputError>{errors?.executor_id?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.InputContainer>
                <span>Título</span>
                <Controller
                  name="title"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      placeholder="Ex.: Monitoramento de Ervas Daninhas"
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.title?.message && (
                  <S.InputError>{errors?.title?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <span>ID (Gerado Automaticamente)</span>
                <TextField
                  name="id"
                  id="id"
                  margin="dense"
                  fullWidth
                  size="small"
                  value=""
                  disabled
                  style={{ backgroundColor: '#EFEFEF' }}
                />
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer>
                <span>Data</span>
                <Controller
                  name="date_init"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextField
                      type="date"
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.date_init?.message && (
                  <S.InputError>{errors?.date_init?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="time_course"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Horário</span>
                      <Select
                        placeholder="Selecione..."
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {selectTypePeriodList.map((period) => (
                          <MenuItem
                            value={period.value_Id}
                            key={period.value_Id}
                          >
                            {period.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.time_course?.message && (
                  <S.InputError>{errors?.time_course?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer>
                <Controller
                  name="recurrence"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Recorrência</span>
                      <Select
                        placeholder="Selecione..."
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        <MenuItem value="d464c549-05b3-45b4-97d1-2d5929c5f580">
                          Monitoramento unico
                        </MenuItem>
                        <MenuItem value="9fbb8e5c-8b91-4d12-b8d8-2161613c41e3">
                          Recorrência
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.time_course?.message && (
                  <S.InputError>{errors?.time_course?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>
            {showRecurrenceInputs && (
              <S.InputWrapper>
                <S.InputContainer>
                  <Controller
                    name="days_of_interval"
                    control={control}
                    defaultValue=""
                    render={({ onChange, value }) => (
                      <FormControl className="selectStyle">
                        <span>Repetir a cada (dias)</span>
                        <TextField
                          type="number"
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          value={value}
                          onChange={onChange}
                        />
                      </FormControl>
                    )}
                  />
                  {errors?.time_course?.message && (
                    <S.InputError>{errors?.time_course?.message}</S.InputError>
                  )}
                </S.InputContainer>
                <S.InputContainer>
                  <span>Termina em</span>
                  <Controller
                    name="end_in"
                    control={control}
                    defaultValue=""
                    render={({ onChange, value }) => (
                      <TextField
                        type="date"
                        margin="dense"
                        fullWidth
                        size="small"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors?.date_init?.message && (
                    <S.InputError>{errors?.date_init?.message}</S.InputError>
                  )}
                </S.InputContainer>
              </S.InputWrapper>
            )}
            <S.InputWrapper>
              <S.InputContainer style={{ height: '200px' }}>
                <span style={{ marginBottom: '10px' }}>Observações</span>
                <Controller
                  name="commits"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <S.CommitArea value={value} onChange={onChange} />
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>
            <S.ButtonWrapper>
              <Button
                text="Cancelar"
                className="cancelBtn"
                onClick={() => {
                  reset();
                  closeModal();
                }}
              />
              <S.CheckboxContainer>
                <Checkbox
                  checked={checked}
                  icon={<MdCheck color="#507919" />}
                  borderColor="#A0BD39"
                  borderRadius={3}
                  borderWidth={1}
                  size={20}
                  style={{ cursor: 'pointer' }}
                  onChange={() => {
                    setChecked(!checked);
                  }}
                />
                <label htmlFor="register">Continuar Cadastrando</label>
              </S.CheckboxContainer>
              <Button
                text={
                  loading ? (
                    <>
                      <PuffLoader color="#79ac34" loading={loading} size={30} />
                    </>
                  ) : (
                    'Criar Nova Rotina'
                  )
                }
                disabled={loading}
                className="saveBtn"
                type="submit"
              />
            </S.ButtonWrapper>
          </S.FormContainer>
        </form>
      </S.Container>
    </Modal>
  );
};

export default ModalNewRoutine;
