import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content, Title } from './styled';

const IntructionsToast = ({ title, children }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Container>
  );
};
IntructionsToast.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default IntructionsToast;
