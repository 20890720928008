import styled from 'styled-components';

export const Container = styled.div`
  width: 500px;
  height: 100%;
  padding: 20px;
  overflow: auto;
`;

export const PrintContainer = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const TagBody = styled.div``;

export const PrintQrCodeButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #62904e;
`;
