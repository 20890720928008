import styled, { css } from 'styled-components';

export const Container = styled.div`
  hr {
    border: 1px solid #444;
    margin-bottom: 10px;
  }

  .selectStyle {
    flex: 1;

    span {
      margin-bottom: 8px;
    }
  }

  .uploadFile {
    border: 1px solid #b8b8b8;
    padding: 5px;
    border-radius: 4px;
    background-color: #efefef;
  }

  .table__addButton {
    display: flex;
    align-items: center;
    border: none;
    background: #a0bd39;
    cursor: pointer;
    border-radius: 7px;
    padding: 3px 6px;

    svg {
      color: #fff;
      font-size: 21px;
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 440px;
  flex-direction: column;
`;

export const TableTitle = styled.h2`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 25px;
`;

export const TableSubTitle = styled.h5`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 13px;
  margin-bottom: 25px;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;

  span {
    font-weight: bold;
    font-size: 16px;
    color: #4d4d4d;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 10px;

  span {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const SubTitle = styled.h2`
  font-weight: bold;
  font-size: 14px;
`;

export const TextContainer = styled.div`
  padding: 0 5px;
  font-size: 14px;
`;

export const ExportButton = styled.div`
  display: flex;
  margin: 10px;
  justify-content: center;

  .exportButton {
    display: flex;
    align-items: center;
    border: none;
    background: #a0bd39;
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
    padding: 3px 16px;
    margin: 10px;
  }
`;

export const MediasContentArea = styled.div`
  display: flex;
  width: 100%;
  height: 420px;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow-y: scroll;
  gap: 3%;
`;

export const MediaContainer = styled.div`
  width: 30%;
  height: 200px;
  background-color: #f2f2f2;
`;
