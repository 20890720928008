import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
html, body, #root{
  height: 100%;
  width: 100%;
}
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: #FFF;
    color: #444444;
    -webkit-font-smoothing: antialiased;
  }
iframe{
  display: none;
}
  body, input, button {
    font-family: 'Helvetica Neue', Roboto, sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
  }

  button {
    cursor: pointer;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  }
  /*Change text in autofill textbox*/
  input:-webkit-autofill {
    -webkit-text-fill-color: #444444 !important;

  }
  .Toastify__toast--success {
    background: #79ac34 !important;
  }
  .Toastify__toast--warning {
    background: #FFC300 !important;
  }
  .Toastify__toast--error {
    background: #d62222 !important;
  }
  .Toastify__toast--default {
    color: #444444 !important;
  }


  .ol-zoom {
    position: absolute;
    bottom: 0;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 5px;
    padding: 2px;
    top:69%;
    margin-bottom: 30px;
    &:hover {
      background: transparent;
    }
  }
  .ol-zoom {
    button{
    background-color: #FFF !important;
    color: #000;
    border: none;
    width: 35px;
    height: 35px;
    width: 45px;
    height: 45px;
    margin: 0;
  }}
  .ol-swipe {
    background: transparent;
    height: 100%;
    padding-top: 30%;
    &::after {
      content: '';
      position: absolute;
      width: 3px;
      background-color: white;
      top: 0;
      bottom: 0;
      left: 50%;
      margin-left: -1px;
    }
    button {
      width: 20px;
      height: 20px;
      position: relative;
      z-index: 2;
      background-color: #a0bd39 !important;
      &:hover {
        background-color: #FFF;
      }
    }
  }
  .ol-scale-line {
   background: #FFF;
 }
 .ol-scale-line-inner{
   color: #000;
   border-color: #000;
 }

 img.olTileImage {
    -webkit-transform:inherit;
    -moz-transform: inherit;
    -o-transform:inherit ;
    -ms-transform: inherit;
    transform:inherit ;
    -webkit-backface-visibility: inherit;
    -moz-backface-visibility:inherit ;
    -ms-backface-visibility:inherit ;
    backface-visibility:inherit ;
    -webkit-perspective:inherit ;
    -moz-perspective:inherit ;
    -ms-perspective:inherit ;
    perspective:inherit ;

}
`;
