import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  hr {
    margin: 10px 0;
    border: solid 1px #dcdcdc;
  }
`;

export const RegisterFarmDialog = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;
  margin: 16px 0px;

  h3 {
    font-size: 16px;
    text-align: left;
    color: #444;
    margin-bottom: 16px;
  }

  span {
    font-size: 12px;
    margin-bottom: 10px;
    color: #a3a3a3;
  }

  button {
    background: transparent;
    border: none;
    color: #79ac34;
    font-size: 0.8rem;
    font-weight: bold;
  }
`;

export const ContainerBackButton = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
`;

export const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InputError = styled.span`
  color: red;
  font-size: 0.8rem;
`;
