/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { Profile } from '../../../Profile/ProfileList/utils/profile.types';
import ProfileApi from '../../../../../../../../APi/FarmApi/ProfileApi';
import SubModuleApi from '../../../../../../../../APi/FarmApi/SubModuleApi';
import { SubModulo } from '../../utils/moduleProfile.types';

export const useProfileSubModuleFormRequest = () => {
  const {
    token,
    user: { organizacao_id }
  } = useAuth();
  const subModuleApi = useMemo(() => new SubModuleApi(token), []);
  const profileApi = useMemo(() => new ProfileApi(token), []);
  const [filterSubModule, setFilterSubModule] = useState<string>('');
  const [perfilFilter, setPerfilFilter] = useState<string>('');
  const [idProfile, setIdProfile] = useState<string>('');
  const { id, idAggregate } = useParams();

  function moduleSubModuloMap(moduleSubModule: any): SubModulo {
    return moduleSubModule.idaggregate;
  }

  const profileSubModuloQuery = useQuery({
    queryKey: [profileApi.ListaAtrelarPerfilSubModulo, { idProfile }],
    queryFn: async () =>
      profileApi.ListarAtrelarPerfilSubmodulo('', id ?? idProfile),
    refetchOnWindowFocus: false
  });

  const subModuleDate = useQuery({
    queryKey: [subModuleApi.getSubModule, { filterSubModule, organizacao_id }],
    queryFn: () => subModuleApi.GetSubModule(filterSubModule, organizacao_id),
    refetchOnWindowFocus: false
  });

  function subModuleMap(date: any) {
    return {
      id: date.id,
      name: date.name
    };
  }
  function subModuleListMap(date: any) {
    return {
      id: date.id,
      name: date.name,
      acronym: date.acronym
    };
  }
  const perfilData = useQuery({
    queryKey: [profileApi.getProfiles, { perfilFilter, organizacao_id }],
    queryFn: async () => profileApi.ProfileList(perfilFilter, organizacao_id),
    refetchOnWindowFocus: false
  });

  function profileMap(date: any): Profile {
    return {
      id: date.userprofile_id,
      name: date.description
    };
  }

  const listaAtrelados: any[] =
    profileSubModuloQuery.data?.data.data.map(moduleSubModuloMap) ?? [];
  return {
    request: {
      itemsPerfil: id
        ? perfilData.data?.data.data
            ?.filter((x: any) => x.userprofile_id === id)
            ?.map(profileMap) ?? []
        : perfilData.data?.data.data?.map(profileMap) ?? [],
      itemsSubModule: id
        ? subModuleDate.data?.data.data?.map(subModuleMap) ?? []
        : idProfile
        ? subModuleDate.data?.data.data
            ?.filter((x: any) => !listaAtrelados.includes(x.id))
            ?.map(subModuleMap) ?? []
        : subModuleDate.data?.data.data?.map(subModuleMap) ?? [],
      listIdaggregate: listaAtrelados.filter((x) => x === idAggregate) ?? [],
      idProfile: id as string,
      isRedy: profileSubModuloQuery.isLoading
    },
    listSubModule: subModuleDate.data?.data.data?.map(subModuleListMap),
    filter: {
      setFilterSubModule,
      setPerfilFilter
    },
    setIdModule: setIdProfile
  };
};
