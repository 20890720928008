export const parseCpfCnpj = (value) => {
  const parsedValue = value
    .replace('.', '')
    .replace('.', '')
    .replace('/', '')
    .replace('-', '');
  return parsedValue;
};

export const parsePhone = (value) => {
  const parsedValue = value
    .replace('-', '')
    .replace('(', '')
    .replace(')', '')
    .replace(' ', '');
  return parsedValue;
};
