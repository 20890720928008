/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { OrderedTable } from '../../../../../../../components/OrderedTable';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import { SubModulo } from '../utils/moduleProfile.types';
import { useProfileSubModuleRequest } from './hooks/useProfileSubModuleRequest';
import { ResearchField } from '../../../../../../../components/ResearchField';

const ProfileSubModuleList: FC = () => {
  const { request, setFilter } = useProfileSubModuleRequest();

  const headCells: HeadCell<SubModulo>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Nome'
    },
    {
      id: 'submodulo',
      numeric: false,
      disablePadding: false,
      label: 'Sub-Módulo'
    }
  ];

  return (
    <>
      <ResearchField onChange={setFilter} />
      <OrderedTable
        rows={request.items}
        HeadCell={headCells}
        IconCreate={AddIcon}
        title="Perfil x Sub-Módulo"
        routeNameAdd="./new"
        routeNameEdit="./edit"
        checkBox={false}
        selectInLine
        deleteHitch
      />
      <Outlet />
    </>
  );
};
export default ProfileSubModuleList;
