/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { MdAddCircle, MdModeEdit, MdDelete, MdCheck } from 'react-icons/md';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from '@mui/material';
import { toast } from 'react-toastify';
import Checkbox from 'react-custom-checkbox';
import PuffLoader from 'react-spinners/PuffLoader';
import { DashboardContext } from '../../../../index';
import * as S from './styled';
import ModalAddNewPrague from './components/ModalAddNewPrague';
import ModalEditPrague from './components/ModalEditPrague';
import ModalViewPrague from './components/ModalViewPrague';
import ModalDeleteConfirm from './components/ModalDeleteConfirm';
import { useModal } from '../../../../../../hooks/Modal';
import { appConfig } from '../../../../../../config';
import api from '../../../../../../services/api';
import Button from '../../../../../../components/Button';
import { useAuth } from '../../../../../../hooks/Auth';
import Toast from '../../../../../../components/Toast';

const Pragues = () => {
  const {
    uploadedFiles: files,
    setUploadedFiles,
    selectedManagement,
    setSelectedManagement,
    setDashboardState,
    dashboardState,
    itemTypeSelected,
    setItemTypeSelected,
    updateScreen,
    setUpdateScreen,
    currentModal,
    setCurrentModal,
    selectedAdmItem,
    setSelectedAdmItem,
    selectTypeCultureList,
    setSelectTypeCultureList,
    selectedPrague,
    setSelectedPrague,
    prague_cropName,
    prague_plantName,
    setPrague_CropName,
    setPrague_PlantName
  } = useContext(DashboardContext);
  const { closeModal, openedModal, openModal, modalIsOpen } = useModal();
  const { token, user, getNotifications } = useAuth();

  const [pragueId, setPragueID] = useState('');
  const [listType, setListType] = useState([]);
  const [listPragues, setListPragues] = useState([]);
  const [pragueUpdate, setPragueUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const getTypeList = async () => {
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Type/List`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setListType(response.data.data.types);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeCulture = async () => {
    const params = new URLSearchParams([['type_id', 'c826052d-3a59-4398-aec1-81193ccd2e42']]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectTypeCultureList(response.data.data.values);
    } catch (error) {
      console.error(error);
    }
  };

  const getPragueList = async () => {
    setLoading(true);
    setListPragues([]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Prague/List`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // const sortResponse = response.data.data.pragues.sort((x, y) => {
      //   return x.status - y.status;
      // });

      const filterActive = response.data.data.pragues.filter((v) => v.active === true);

      setListPragues(filterActive);
      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleChangeItemSelected = async (event) => {
    setItemTypeSelected(event.target.value);
    const filterNameSelected = listType.filter((item) => item.type_Id === event.target.value);
    setSelectedManagement(filterNameSelected[0].name);
  };

  const handleDeletePrague = async (prague_id) => {
    setPragueID(prague_id);
    setCurrentModal('modalDeleteConfirm');
    openModal();
  };

  const updatePrague = async (item, checked) => {
    const params = new URLSearchParams([['pragueId', item.prague_id]]);

    let newValue = {};
    newValue = {
      type_id: item.type_id,
      plant: item.plant,
      name: item.name,
      scientificName: item.scientific_name,
      image: item.image,
      status: checked
    };

    try {
      const response = await api.put(
        `${appConfig.apiHosts.manager}/Prague/Update`,
        newValue,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (checked === true) {
        toast(<Toast title="Item ativado com sucesso!" />, {
          type: 'success'
        });
      } else {
        toast(<Toast title="Item desativado com sucesso!" />, {
          type: 'success'
        });
      }

      getPragueList();
    } catch (error) {
      console.error(error);
      toast(<Toast title="Não foi possível editar" />, {
        type: 'error'
      });
    }
  };

  const getPragueId = async (prague_id, action) => {
    try {
      const params = new URLSearchParams([['prague_id', prague_id]]);
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Prague/Id`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedPrague(response.data.data.prague);

      if (action === 'view') {
        setCurrentModal('modalViewPrague');
      } else {
        const plantName = [];
        const cropName = [];
        const uploadedPhotos = [];
        response?.data.data.prague?.crop_type.forEach((crop) => {
          cropName.push(crop?.name);
        });
        response?.data.data.prague?.plant.forEach((plant) => {
          plantName.push(plant?.name);
        });
        response?.data.data.prague?.prague_images.forEach((img) => {
          uploadedPhotos.push({
            error: false,
            file: { path: '', lastModified: '', lastModifiedDate: '', name: img?.prague_image_id, size: '', type: '', webkitRelativePath: '' },
            id: img?.prague_image_id,
            name: img?.prague_image_id,
            preview: img?.url,
            progress: 0,
            readableSize: '',
            uploaded: true,
            url: img?.url
          });
        });
        setUploadedFiles(uploadedPhotos);
        setPrague_CropName(cropName);
        setPrague_PlantName(plantName);
        setCurrentModal('modalEditPrague');
      }

      openModal();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPragueList();
  }, []);

  useEffect(() => {
    if (updateScreen) {
      getPragueList();
    }
  }, [updateScreen]);

  return (
    <S.Container>
      <S.Content>
        <S.AddBtnContainer>
          <Button
            text="Adicionar Nova Praga"
            style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center'
            }}
            className="content__addButton"
            onClick={() => {
              setCurrentModal('modalAddNewPrague');
              openModal();
            }}
          />
        </S.AddBtnContainer>
        <S.CardContainer>
          {loading ? (
            <>
              <PuffLoader color="#79ac34" loading={loading} size={30} />
            </>
          ) : (
            listPragues?.map((row) => (
              <S.Card>
                <S.CardImage onClick={() => getPragueId(row.prague_id, 'view')}>
                  {row?.main_photo && <img alt="" src={row.main_photo} />}
                </S.CardImage>
                <S.CardTextContainer onClick={() => getPragueId(row.prague_id, 'view')}>
                  <S.CardTitle>{row?.name}</S.CardTitle>
                  <S.CardDescription>{row?.scientific_name}</S.CardDescription>
                </S.CardTextContainer>
                <S.CardActionContainer>
                  <S.CardAction>
                    <button type="button" onClick={() => getPragueId(row.prague_id, 'edit')}>
                      <MdModeEdit />
                      Editar
                    </button>
                  </S.CardAction>
                  <S.CardAction>
                    <button
                      type="button"
                      onClick={() => handleDeletePrague(row.prague_id)}
                    >
                      <MdDelete />
                      Excluir
                    </button>
                  </S.CardAction>
                </S.CardActionContainer>
              </S.Card>
            ))
          )}
        </S.CardContainer>

      </S.Content>
      {currentModal === 'modalAddNewPrague' && <ModalAddNewPrague />}
      {currentModal === 'modalEditPrague' && <ModalEditPrague />}
      {currentModal === 'modalViewPrague' && <ModalViewPrague />}
      {currentModal === 'modalDeleteConfirm' && (
        <ModalDeleteConfirm
          messageDialog="Deseja realmente excluir esta praga?"
          prague_id={pragueId}
        />
      )}
    </S.Container>
  );
};

export default Pragues;
