import { AxiosResponse } from 'axios';
import api from './configFarmApi';

class ModuleProfileApi {
  constructor(token: string) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  async AtrelarPerfilModulo(data: any): Promise<AxiosResponse> {
    const result = await api.put(
      `/Farm/AtrelarPerfilModulo?user_profile_id=${data.user_profile_id}&module_id=${data.module_id}&acronym=${data.acronym}`
    );
    return result;
  }

  async DeletarPerfilModulo(data: any): Promise<AxiosResponse> {
    const result = await api.delete(
      `/Farm/DeletarPerfilModulo?user_profile_id=${data.user_profile_id}&module_id=${data.module_id}&acronym=${data.acronym}`
    );
    return result;
  }
}

export default ModuleProfileApi;
