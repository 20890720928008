/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import ProfileApi from '../../../../../../../../APi/FarmApi/ProfileApi';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { Profile } from '../../../Profile/ProfileList/utils/profile.types';
import SystemModuleApi from '../../../../../../../../APi/FarmApi/SystemModuleApi';
import OrganizacaoApi from '../../../../../../../../APi/FarmApi/OrganizacaoApi';

export const useUserRequestList = () => {
  const {
    token,
    user: { organizacao_id }
  } = useAuth();
  const profileApi = useMemo(() => new ProfileApi(token), []);
  const organizacaoApi = useMemo(() => new OrganizacaoApi(token), []);
  const [filterSubModule, setFilterSubModule] = useState<string>('');
  const [systemModuleFilter, setSystemModuleFilter] = useState<string>('');
  const [profileId, setProfileId] = useState<string>('');
  const { id } = useParams();
  function moduleProfileMap(moduleProfile: any) {
    return moduleProfile.idaggregate;
  }

  const moduleProfileQuery = useQuery({
    queryKey: [
      organizacaoApi.retomarListaOrganizacaoAtrelamento,
      { profileId, organizacao_id }
    ],
    queryFn: async () =>
      await organizacaoApi.ListarAtrelarPerfilOrganizacao(
        '',
        id ?? profileId,
        organizacao_id
      )
  });

  function subModuleMap(date: any): Profile {
    return {
      id: date.userprofile_id,
      name: date.description
    };
  }

  const organizacaoData = useQuery({
    queryKey: [organizacaoApi.getOrganizacao, { systemModuleFilter }],
    queryFn: async () =>
      organizacaoApi.GetOrganizacao(systemModuleFilter, organizacao_id)
  });

  function moduleDataMap(User: any) {
    return {
      id: User.id,
      name: User.name
    };
  }

  return {
    request: {
      itemsOrganizacao: organizacaoData.data?.data.data.map(moduleDataMap) ?? []
    },
    filter: {
      setFilterSubModule,
      setSystemModuleFilter
    },
    setProfileId
  };
};
