import React, { useState } from 'react';

import * as S from './styled';

export const SwitchButton = ({
  iconFirstOption,
  iconSecondOption,
  textFirstOption,
  textSecondOption,
  onClickFirstOptionAction,
  onClickSecondOptionAction,
  ...rest
}) => {
  const [actived, setActived] = useState(true);
  return (
    <S.Container {...rest}>
      <S.FirstOptionButton
        actived={actived}
        onClick={() => {
          setActived(true);
          onClickFirstOptionAction();
        }}
      >
        {iconFirstOption}
        {textFirstOption}
      </S.FirstOptionButton>
      <S.SecondOptionButton
        actived={!actived}
        onClick={() => {
          setActived(false);
          onClickSecondOptionAction();
        }}
      >
        {iconSecondOption}
        {textSecondOption}
      </S.SecondOptionButton>
    </S.Container>
  );
};
