import styled from 'styled-components';
import {
  Table,
  Select as MUISelect,
  TextField,
  Button as MUIButton
} from '@mui/material';

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  table {
    border-collapse: separate;
    border: 1px solid #dfdfdf;
    border-bottom: none;
    border-radius: 8px !important;
  }

  td,
  th {
    border-left: 1px solid #dfdfdf;
  }

  .selectStyle {
    width: 320px;
    font-size: 16px;
    margin-bottom: 16px;
  }

  hr {
    border: 1px solid #a0bd39;
  }

  .tabela {
    overflow: auto;
    max-height: 208px;
  }

  /* .MuiTable-root {
    border-collapse: separate;
    //border: 1px solid #dfdfdf;
    border-radius: '10px' !important;
  } */
`;

export const Title = styled.h1`
  font-size: 21px;
  margin-bottom: 16px;
`;

export const SubTitle = styled.span`
  font-size: 16px;
  color: #4d4d4d;
  font-weight: bold;
`;

export const NavContainer = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  padding: 0 60px;
`;

export const TableTitle = styled.h2`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  margin-bottom: 5px;
`;

export const AddBtnContainer = styled.div`
  width: auto;
  margin-bottom: 12px;

  .addBtn {
    width: 200px;
    height: 40px;
  }
`;

export const NavButton = styled.button`
  border: ${(props) => (props.active ? '2px solid #a0bd39' : 'none')};
  border-top-left-radius: ${(props) => (props.active ? '6px' : '0')};
  border-top-right-radius: ${(props) => (props.active ? '6px' : '0')};
  border-bottom: none;
  background-color: ${(props) => (props.active ? '#a0bd39' : '#FFF')};
  cursor: pointer;
  font-weight: bold;
  padding: 5px 10px;
  color: ${(props) => (props.active ? '#FFF' : '#4d4d4d')};
  //margin-bottom: -2px;

  svg {
    color: #a0bd39;
    width: 30px;
    height: 30px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;

  .actionBtn {
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: #79ac34;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const SearchArea = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  box-shadow: 1px 3px 6px #0000001a;
  height: 40px;
  //margin: 16px 0;
  width: 100%;

  svg {
    color: #bdbdbd;
    margin: 0 5px;
    cursor: pointer;
  }
`;

export const SearchInput = styled.input`
  height: 30px;
  flex: 1;
  color: #4d4d4d;
  border: none;
`;

export const SearchAreaContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;

  span {
    margin-left: 10px;
    width: 100px;
    color: #4d4d4d;
  }
`;

export const FilterWrapper = styled.button`
  margin: 0 20px;
  width: 200px;
  color: #4d4d4d;
  border: none;
  background: transparent;
`;
