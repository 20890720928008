/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { AxiosResponse } from 'axios';
import api from './configFarmApi';

class SubModuleApi {
  constructor(token: string) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  get getSubModule() {
    return 'get-SubModule';
  }

  get getSubModuleById() {
    return 'get-SubModule-id';
  }

  async GetSubModule(
    filtro: string,
    organizacao_id?: string
  ): Promise<AxiosResponse> {
    return api.get(
      `/Farm/ListarSubModulos?filtro=${filtro}&organizacao_id=${organizacao_id}`
    );
  }

  async createSubModule<T>(data: T): Promise<AxiosResponse> {
    return api.post('/Farm/SubModulo', data);
  }

  async updateSubModule<T>(data: T): Promise<AxiosResponse> {
    return api.put('/Farm/SubModulo', data);
  }

  async RetornarSubModuloById(id: string): Promise<AxiosResponse> {
    return api.get(`/Farm/RetornarSubModulo?id=${id || ''}`);
  }
}

export default SubModuleApi;
