/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useCallback } from 'react';
import Modal from 'react-modal';
import { TextField } from '@mui/material';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { useModal } from '../../../../../../../../hooks/Modal';
import api from '../../../../../../../../services/api';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { appConfig } from '../../../../../../../../config';
import Toast from '../../../../../../../../components/Toast';
import Button from '../../../../../../../../components/Button';
import { DashboardContext } from '../../../../../../index';
import storage from '../../../../../../../../services/firebase';
import * as S from './styled';

function ModalAddItem() {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  // eslint-disable-next-line prettier/prettier
  const {
    selectedManagement,
    uploadedFiles: files,
    deleteFile,
    handleUpload,
    itemTypeSelected,
    setUpdateScreen
  } = useContext(DashboardContext);

  const [userValue, setUserValue] = useState('');
  const clearFields = () => {
    setUserValue('');
  };

  const onDrop = useCallback(
    (file) => {
      handleUpload(file);
    },
    [handleUpload]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: ['image/jpeg', 'image/pjpeg', 'image/png', 'image/gif'],
      onDrop
    });

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return (
        <S.UploadMessage>Clique ou arraste uma imagem aqui</S.UploadMessage>
      );
    }

    if (isDragReject) {
      return (
        <S.UploadMessage type="error">
          Tipo de arquivo não suportado
        </S.UploadMessage>
      );
    }

    return (
      <S.UploadMessage type="success">Solte as imagens aqui</S.UploadMessage>
    );
  }, [isDragActive, isDragReject]);

  const changeProfileUser = async () => {
    const params = new URLSearchParams([
      ['email', userValue],
      ['adm', true]
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.auth}/UserFundacao/ChangeProfileUser`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response.data.success) {
        throw new Error();
      }

      const newValue = {
        name: userValue,
        type_id: itemTypeSelected,
        created_by: user.user_id
      };

      await api.post(`${appConfig.apiHosts.manager}/Value/Create`, newValue, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      toast(<Toast title="Adicionado com sucesso" />, {
        type: 'success'
      });

      setUpdateScreen(true);
      clearFields();
      closeModal();
    } catch (error) {
      console.error(error);
      toast(<Toast title="Não foi possível adicionar" />, {
        type: 'error'
      });
    }
  };

  const handleUploadFile = async (e) => {
    try {
      const fileData = await storage.ref(`/cultures/${e.name}`).put(e.file);
      const imageSrc = { id: e.id, url: await fileData.ref.getDownloadURL() };

      files.forEach((file) => URL.revokeObjectURL(file.preview));

      return imageSrc;
    } catch (error) {
      console.error(error);
    }
  };

  async function newValueFile(value) {
    try {
      const response = await api.post(
        `${appConfig.apiHosts.manager}/Value/Create`,
        value,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      toast(<Toast title="Adicionado com sucesso" />, {
        type: 'success'
      });

      setUpdateScreen(true);
      clearFields();
      closeModal();
    } catch (error) {
      console.error(error);
      toast(<Toast title="Não foi possível adicionar" />, {
        type: 'error'
      });
    }
  }

  const createValue = async () => {
    const imageUrl = [];
    let newValue = {
      name: userValue,
      type_id: itemTypeSelected,
      created_by: user.user_id
    };

    if (files.length > 0) {
      await files.forEach(async (file) => {
        const url = handleUploadFile(file);
        url
          .then((value) => {
            imageUrl.push(value);
          })
          .finally(async () => {
            if (imageUrl.length === files.length) {
              newValue = {
                ...newValue,
                photo: imageUrl[0].url || null
              };

              await newValueFile(newValue);
            }
          });
      });
    } else {
      await newValueFile(newValue);
    }
  };

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        {selectedManagement === 'Administradores' ? (
          <>
            <S.TableTitle>Administrador</S.TableTitle>
            <hr />
            <S.InputContainer>
              <span>E-mail</span>
              <TextField
                name="adm-email"
                id="adm-email"
                placeholder="Digite o e-mail do usuário"
                margin="dense"
                fullWidth
                size="small"
                value={userValue}
                onChange={(e) => setUserValue(e.target.value)}
              />
              <S.ButtonWrapper>
                <Button
                  text="Cancelar"
                  className="cancelBtn"
                  onClick={() => {
                    setUserValue('');
                    closeModal();
                  }}
                />
                <Button
                  text="Salvar"
                  className="saveBtn"
                  onClick={() => changeProfileUser()}
                />
              </S.ButtonWrapper>
            </S.InputContainer>
          </>
        ) : (
          <>
            <S.TableTitle>{selectedManagement}</S.TableTitle>
            <hr />
            <S.InputContainer>
              <S.InputWrapper>
                <S.InputArea>
                  <span>ID (Gerado Automaticamente)</span>
                  <TextField
                    name="id"
                    id="id"
                    disabled
                    margin="dense"
                    fullWidth
                    className="inputId"
                    size="small"
                  />
                </S.InputArea>
                <S.InputArea>
                  <span>Valor</span>
                  <TextField
                    name="description"
                    id="description"
                    placeholder="Ex: Pulverizar sobre o plantio"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={userValue}
                    onChange={(e) => setUserValue(e.target.value)}
                  />
                </S.InputArea>
              </S.InputWrapper>
              {selectedManagement === 'Culturas' && (
                <S.InputWrapper>
                  <S.InputArea>
                    <span style={{ paddingBottom: '10px' }}>Foto</span>
                    {files.length <= 0 ? (
                      <S.DropContainer {...getRootProps()}>
                        <input accept="image/*" {...getInputProps()} />
                        <div className="inputMessage">
                          <MdOutlineCloudUpload />
                          {renderDragMessage()}
                        </div>
                      </S.DropContainer>
                    ) : (
                      <S.ThumbsContainer>
                        {files.map((uploadedFile) => (
                          <S.ThumbItemContainer>
                            <S.ThumbItem>
                              <S.ThumbImage
                                className="thumbsContainer__button"
                                style={{
                                  border: '2px solid #47aa12'
                                }}
                              >
                                <img
                                  alt={uploadedFile.name}
                                  src={uploadedFile.preview}
                                  style={{
                                    border: '2px solid #FFF'
                                  }}
                                />
                              </S.ThumbImage>
                              <S.ThumbIconsContainer>
                                <S.ThumbButtonIcon
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    deleteFile(uploadedFile.id);
                                  }}
                                >
                                  <FaTrash size={14} color="#AF0000" />
                                </S.ThumbButtonIcon>
                              </S.ThumbIconsContainer>
                            </S.ThumbItem>
                          </S.ThumbItemContainer>
                        ))}
                      </S.ThumbsContainer>
                    )}
                  </S.InputArea>
                </S.InputWrapper>
              )}
              <S.ButtonWrapper>
                <Button
                  text="Cancelar"
                  className="cancelBtn"
                  onClick={closeModal}
                />
                <Button
                  text="Salvar"
                  className="saveBtn"
                  onClick={() => {
                    createValue();
                  }}
                />
              </S.ButtonWrapper>
            </S.InputContainer>
          </>
        )}
      </S.Container>
    </Modal>
  );
}

export default ModalAddItem;
