import React, { useContext, useEffect, useState } from 'react';
import { kml } from '@tmcw/togeojson';
import * as turf from '@turf/turf';
import JSZip from 'jszip';
import * as shp from 'shpjs';
import { toast } from 'react-toastify';
import { MdCloudUpload } from 'react-icons/md';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField
} from '@mui/material';
import { DashboardContext } from '../../../..';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';
import SimpleButton from '../../../../../../components/SimpleButton';
import Toast from '../../../../../../components/Toast';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import api from '../../../../../../services/api';
import { parseCoordinatesMultiPolygonMultiFeaturesTo3857 } from '../../../../../../helpers/parseCoordinates';
import { useFullLoading } from '../../../../../../hooks/FullLoading';
import * as S from './styled';
import { appConfig } from '../../../../../../config';

let timeoutWarning = null;

function GenerateCollectionPoints() {
  const { setDashboardState, subdivisions, setSubdivisions } =
    useContext(DashboardContext);
  const {
    pointCollectionGenerate,
    pointCollectionGenerateAlleatory,
    pointCollectionGenerateEquidistant,
    generatedCollectionPoints,
    removeAllInterections,
    removePointGeometries,
    removeMappingGeometries,
    renderSubdivision,
    subdivision,
    createCollectionPoints,
    selectedFarmId,
    renderSubdivisions,
    setListSubdivisions,
    setGeneratedCollectionPoints,
    qtdPoints,
    setQtdPoints,
    generatedCollectionPointsQtd,
    pointsDistance,
    setPointsDistance,
    setGeneratedCollectionPointsQtd,
    removeSubdivisionGeometries,
    selectedFarmGeoJson,
    renderCollectionPoints
  } = useMap();
  const [collectionType, setCollectionType] = useState('Quantidade');
  const [loading, setLoading] = useState(false);
  const [generatedCollectionPointList, setGeneratedCollectionPointList] =
    useState([]);
  const [creatingPoints, setCreatingPoints] = useState(false);
  const { token, user } = useAuth();
  const { activeLoading, desactiveLoading, setloadingMessage } =
    useFullLoading();

  const getPoints = async () => {
    const params = new URLSearchParams([
      ['farmId', selectedFarmId],
      ['userId', user.user_id]
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.field}/DataCollectionPoint/GetActiveDataCollectionPointListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const cPoints = await response.data.data.filter(
        (value) => value.features[0].properties.type === 'REFERENCIA_COLETA'
      );
      renderCollectionPoints(cPoints);
    } catch (error) {
      console.error(error);
    }
  };

  const registerCollectionPoints = async () => {
    try {
      if (generatedCollectionPoints.length > 0) {
        generatedCollectionPoints.map(async (collectionPoint) => {
          await api.post(
            `${appConfig.apiHosts.field}/DataCollectionPoint/PostDataCollectionPoint`,
            collectionPoint,
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
        });
      }

      if (generatedCollectionPointsQtd.length > 0) {
        generatedCollectionPointsQtd.map(async (collectionPointQtd) => {
          collectionPointQtd.map(async (point) => {
            await api.post(
              `${appConfig.apiHosts.field}/DataCollectionPoint/PostDataCollectionPoint`,
              point,
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            );
          });
        });
      }

      toast(<Toast title="Pontos de Coleta Cadastrados com Sucesso" />, {
        type: 'success'
      });

      removeAllInterections();
      removeMappingGeometries();
      setDashboardState('dashboard@harvestmap');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      removeAllInterections();
      setDashboardState('dashboard@harvestmap');
      toast(<Toast title="Não foi possivel cadastrar pontos de coleta" />, {
        type: 'error'
      });
    }
  };

  const getSubdivisions = async () => {
    removeSubdivisionGeometries();
    try {
      if (!selectedFarmId) {
        return toast(
          <Toast
            title="Por favor selecione uma fazenda"
            content="Para que as subdivisões sejam carregadas é necessário selecionar uma fazenda"
          />,
          { type: 'warning' }
        );
      }
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSubdivisions(response.data.data);
      renderSubdivisions(response.data.data);
      setListSubdivisions(response.data.data);
      getPoints();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const warningCard = () => {
    clearTimeout(timeoutWarning);
    timeoutWarning = setTimeout(() => {
      toast(
        <Toast
          title='Clique no botão "Gerar Pontos"'
          content='Para atualizar a quantidade de pontos de coleta, é necessário clicar no botão "Gerar Pontos".'
        />,
        { type: 'warning' }
      );
    }, 800);
  };

  const onMultiRegisterCollectionPoints = async (geojson) => {
    const subIntersect = subdivisions
      .map((f) => {
        if (
          turf.booleanIntersects(
            f.features[0].geometry,
            geojson.features[0].geometry
          )
        ) {
          return (geojson.features[0].properties = {
            farm_id: selectedFarmId,
            subdivision_id: f.features[0].properties.subdivision_id,
            type: 'REFERENCIA_COLETA',
            description: 'COLETA',
            automatically_generated: false,
            created_by: user.user_id
          });
        }
        return '';
      })
      .filter((value) => value !== '' ?? value);

    try {
      await api.post(
        `${appConfig.apiHosts.field}/DataCollectionPoint/PostDataCollectionPoint`,
        geojson,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const registerShapefile = async (geojson) => {
    const promisse = [];
    if (subdivisions.length < 1) {
      return toast(
        <Toast
          title="Arquivo não importado"
          content="Não há subdivisões cadastradas"
        />,
        {
          type: 'error'
        }
      );
    }
    if (geojson.features.length > 1 && subdivisions.length > 0) {
      activeLoading();
      setloadingMessage('O shapefile contém multiplos pontos de coleta');
      setTimeout(() => {
        setloadingMessage(
          'Aguarde, os pontos de coleta serão registrados e cadastrados'
        );
      }, 4000);
      setTimeout(() => {
        setloadingMessage(`Quantidade: ${geojson.features.length} pontos.`);
      }, 9000);

      geojson.features.forEach((value, index) => {
        const geoJson = {
          type: 'FeatureCollection',
          features: [value]
        };
        generatedCollectionPointList.push(
          parseCoordinatesMultiPolygonMultiFeaturesTo3857(geoJson)
        );
      });

      generatedCollectionPointList?.map(async (geoCollectionPoint, index) => {
        if (
          !turf.booleanIntersects(
            selectedFarmGeoJson.features[0].geometry,
            geoCollectionPoint.features[0].geometry
          )
        ) {
          return toast(
            <Toast
              title="Pontos não importados"
              content="A geometria não está dentro dos limites da fazenda"
            />,
            {
              type: 'error'
            }
          );
        }

        promisse.push(onMultiRegisterCollectionPoints(geoCollectionPoint));

        await Promise.all(promisse).then((values) => {
          return setTimeout(() => {
            desactiveLoading();
            setDashboardState('dashboard@harvestmap');
          }, 50000);
        });
      });
    }
  };

  function convertToLayerZip(buffer) {
    shp(buffer).then((geojson) => registerShapefile(geojson));
  }

  const convertToLayerKml = (buffer) => {
    const blob = new Blob([buffer], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    fetch(url)
      .then((response) => response.text())
      .then((xml) => {
        const kmlReadResult = kml(
          new DOMParser().parseFromString(xml, 'text/xml')
        );
        registerShapefile(kmlReadResult);
      });
  };

  const convertToLayerKmz = async (buffer) => {
    const blob = new Blob([buffer], { type: 'application/zip' });
    const zip = new JSZip();
    await zip.loadAsync(blob).then(async (zipped) => {
      const file = await zipped.file('doc.kml').async('blob');
      const url = URL.createObjectURL(file);
      fetch(url)
        .then((response) => response.text())
        .then((xml) => {
          const kmlReadResult = kml(
            new DOMParser().parseFromString(xml, 'text/xml')
          );
          registerShapefile(kmlReadResult);
        });
    });
  };

  const handleUploadFile = (file, type) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState !== 2 || reader.error) {
        return null;
      }
      if (
        type === 'application/zip' ||
        type === 'zip' ||
        type === 'application/x-zip-compressed'
      ) {
        return convertToLayerZip(reader.result);
      }
      if (type === 'application/vnd.google-earth.kml+xml' || type === 'kml') {
        return convertToLayerKml(reader.result);
      }
      if (type === 'application/vnd.google-earth.kmz' || type === 'kmz') {
        return convertToLayerKmz(reader.result);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    getSubdivisions();
    setGeneratedCollectionPoints([]);
    setGeneratedCollectionPointsQtd([]);
  }, []);

  useEffect(() => {
    setQtdPoints(20);
    removeAllInterections();
  }, [collectionType]);

  return (
    <S.Container>
      <SimpleButton
        text="Voltar"
        onClick={() => {
          removePointGeometries();
          removeMappingGeometries();
          setDashboardState('dashboard@harvestmap');
        }}
      />
      <h1>Gerar Pontos de Coleta</h1>
      <hr />
      <FormControl style={{ width: '100%' }}>
        <InputLabel>Configuração Geração de Pontos</InputLabel>
        <Select
          label="Configuração Geração de Pontos"
          placeholder="Cultura Anterior"
          name="collectionType"
          value={collectionType}
          onChange={(e) => {
            setCollectionType(e.target.value);
            removeAllInterections();
            setPointsDistance('');
          }}
          fullWidth
        >
          <MenuItem value="Quantidade" key="1">
            Quantidade
          </MenuItem>
          <MenuItem value="Equidistantes (mesma distancia)" key="2">
            Equidistantes (mesma distancia)
          </MenuItem>
          <MenuItem value="Aleatorio" key="3">
            Aleatório
          </MenuItem>
        </Select>
      </FormControl>
      {collectionType === 'Quantidade' && (
        <div style={{ marginTop: 20 }}>
          <Input
            id="qtdPoints"
            name="qtdPoints"
            type="number"
            placeholder="Quantidade de Pontos"
            min={1}
            value={qtdPoints}
            onChange={(e) => {
              setQtdPoints(e.target.value);
              warningCard();
            }}
          />
        </div>
      )}
      {collectionType === 'Equidistantes (mesma distancia)' && (
        <div style={{ marginTop: 20 }}>
          <Input
            id="distance"
            name="distance"
            type="number"
            placeholder="Distancia em Metros"
            min={1}
            value={pointsDistance}
            onChange={(e) => {
              setPointsDistance(e.target.value);
              warningCard();
            }}
          />
        </div>
      )}
      <Button
        text="Gerar Pontos"
        onClick={() => {
          setCreatingPoints(true);
          if (collectionType === 'Aleatorio') {
            return pointCollectionGenerateAlleatory(subdivisions);
          }
          if (collectionType === 'Equidistantes (mesma distancia)') {
            return pointCollectionGenerateEquidistant(subdivisions);
          }
          pointCollectionGenerate(subdivisions, qtdPoints);
        }}
      />
      <Button
        text="Salvar Pontos"
        onClick={() => {
          setCreatingPoints(false);
          registerCollectionPoints();
        }}
        disabled={!creatingPoints}
      />
      <Button
        text="Cancelar"
        onClick={() => {
          setCreatingPoints(false);
          removePointGeometries();
          removeAllInterections();
          removeMappingGeometries();
          setQtdPoints('');
          setPointsDistance('');
          setDashboardState('dashboard@harvestmap');
        }}
        disabled={!creatingPoints}
      />
      <S.ActionCard>
        <label htmlFor="upload-file-subdivision" className="label-upload">
          <MdCloudUpload size={28} color="#9FBC38" />
          <span>Fazer upload dos pontos de coleta</span>
          <p>Formatos Aceitos: Shapefile.zip, .kml, .kmz</p>
          <input
            type="file"
            accept=".zip, .kmz, .kml"
            id="upload-file-subdivision"
            multiple={false}
            onChange={(e) => {
              const qtdArray = e.target.files.length;
              const ext = e.target.files[qtdArray - 1].name.split('.');
              if (
                e.target.files[qtdArray - 1].type !== 'application/zip' &&
                e.target.files[qtdArray - 1].type !==
                  'application/vnd.google-earth.kml+xml' &&
                e.target.files[qtdArray - 1].type !==
                  'application/vnd.google-earth.kmz' &&
                e.target.files[qtdArray - 1].type !==
                  'application/x-zip-compressed' &&
                ext[ext.length - 1] !== 'kmz' &&
                ext[ext.length - 1] !== 'kml' &&
                ext[ext.length - 1] !== 'zip'
              ) {
                return (
                  null,
                  toast(
                    <Toast
                      title="Shapefile no formato incorreto"
                      content="Formatos aceitos: .zip, .kml, .kmz "
                    />,
                    {
                      type: 'error'
                    }
                  )
                );
              }
              handleUploadFile(
                e.target.files[qtdArray - 1],
                e.target.files[qtdArray - 1].type === ''
                  ? ext[ext.length - 1]
                  : e.target.files[qtdArray - 1].type
              );
            }}
          />
        </label>
      </S.ActionCard>
    </S.Container>
  );
}

export default GenerateCollectionPoints;
