/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';
import { stableSort } from 'ol/array';
import React, { FC, useEffect, useMemo, useState } from 'react';

import TilteTable from './Components/TilteTable/TilteTable';
import { OrderedTableProps } from '../OrderedTable/utils/OrderedTable.types';
import { EnhancedTableHead } from '../OrderedTable/components/EnhancedTableHead';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
type Order = 'asc' | 'desc';
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function TableCreate<T>(props: OrderedTableProps<T>) {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [dense, setDense] = useState<boolean>(true);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const {
    HeadCell,
    rows,
    title,
    routeNameAdd,
    IconCreate,
    onChange,
    checkBox,
    multSelec,
    idStartSelect = [],
    checkboxDisabled = false,
    idStartSelectRedy
  } = props;
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    onChange && onChange(selected);
  }, [selected]);

  useEffect(() => {
    if (idStartSelect.includes(undefined)) return;

    setSelected(idStartSelect);
  }, [idStartSelectRedy]);

  const handleRequestSort = (event: React.MouseEvent, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (multSelec) {
      newSelected =
        selectedIndex === -1
          ? [...selected, id]
          : selected.filter((x) => x !== id);
    } else {
      newSelected =
        selectedIndex === -1 ? [id] : selected.filter((x) => x !== id);
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  function stableSort<U, T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const visibleRows = useMemo(
    () =>
      stableSort<T, any>(rows, getComparator(order, orderBy as string)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box style={{ flex: 1, margin: 6 }}>
      <Paper sx={{ width: '100%', mt: 2 }}>
        <TilteTable
          numSelected={selected.length}
          title={title}
          routeNameAdd={routeNameAdd}
          IconCreate={IconCreate}
        />
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              HeadCell={HeadCell}
              checkBox={checkBox}
            />
            <TableBody>
              {visibleRows.map((row, inde) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${inde}`;
                const dates = Object.keys(row);
                delete dates[0];

                return (
                  <TableRow
                    hover
                    // onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row.id)}
                        name={row.id}
                        inputProps={{
                          'aria-labelledby': labelId
                        }}
                        disabled={checkboxDisabled}
                      />
                    </TableCell>
                    {dates.map((date, index) => (
                      <TableCell
                        component="th"
                        id={index === 0 ? labelId : ''}
                        scope="row"
                        padding="none"
                        style={{ paddingLeft: 15 }}
                      >
                        {row[date]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" /> */}
    </Box>
  );
}

export default TableCreate;
