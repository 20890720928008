import styled from 'styled-components';

export const Container = styled.div`
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .cancelButton {
    background-color: #fff;
    color: #79ac34;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

    &:hover {
      border: 1px solid #79ac34;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  margin: 16px 0;
  font-size: 16px;
`;

export const SubTitle = styled.span`
  color: #a3a3a3;
  font-size: 12px;
  text-align: justify;
  margin-bottom: 16px;
`;

export const SelectContainer = styled.div`
  margin: 20px 0;
`;

export const ButtonsContainer = styled.div``;
export const SimpleButtonContainer = styled.div``;
