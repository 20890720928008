import { appConfig } from '../config';
import api from './api';

const getNotifications = async (email, token) => {
  const params = new URLSearchParams([['email', email]]);
  try {
    const response = await api.get(
      `${appConfig.apiHosts.notification}/Notification/GetNotificationsByReceiverEmail`,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
};

const updateNotification = async (notification, token) => {
  try {
    await api.put(
      `${appConfig.apiHosts.notification}/Notification/UpdateNotification`,
      { ...notification, read: !notification.read },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  } catch (error) {
    console.error(error);
  }
};

const deleteNotification = async (notificationId, token) => {
  try {
    const params = new URLSearchParams([
      ['notificationId', notificationId],
      ['status', 'false']
    ]);
    await api.delete(
      `${appConfig.apiHosts.notification}/Notification/DeleteNotification`,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export default { updateNotification, deleteNotification, getNotifications };
