/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Controller } from 'react-hook-form';
import Modal from 'react-modal';
import AddIcon from '@mui/icons-material/Add';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import { useModal } from '../../../../../../../hooks/Modal';
import { ResearchField } from '../../../../../../../components/ResearchField';
import Toast from '../../../../../../../components/Toast';
import { Profile } from '../../Profile/ProfileList/utils/profile.types';
import { User } from '../../Users/utils/users.types';
import { useProfileSubModuleFormRequest } from './hooks/useProfileSubModuleFormRequest';
import * as s from './utils/userProfile.style';
import useProfileSubModuleForm from './hooks/useProfileSubModuleForm';
import TableCreate from '../../../../../../../components/TableCreate/TableCreate';
import { ProfileSubModuleFormProps } from './utils/profileSubModule.types';

const ProfileSubModuleForm: FC<ProfileSubModuleFormProps> = (props) => {
  const { type } = props;
  const { request, filter, setIdModule, listSubModule } =
    useProfileSubModuleFormRequest();
  const { form, mensage, title, mensageSuscess } = useProfileSubModuleForm({
    ...props,
    listIdaggregate: request.listIdaggregate,
    subModuleList: listSubModule
  });
  const { modalCreatUserStyles } = useModal();
  const navigate = useNavigate();

  useEffect(() => {
    if (mensage) {
      toast(<Toast title={mensageSuscess[type]} content="" />, {
        type: 'success'
      });
    }
  }, [mensage]);

  const subModuleColumn: HeadCell<User>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Sub-Modulo'
    }
  ];

  const profileColumn: HeadCell<Profile>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Perfil'
    }
  ];

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen
      style={{
        ...modalCreatUserStyles,
        ...{
          content: {
            width: '66%',
            height: '79% ',
            transform: 'translate(15vw, 4vw)'
          }
        }
      }}
    >
      <s.container>
        <h1>{title[type]}</h1>
        <s.containerTable>
          <Controller
            name="user_profile_id"
            control={form.methods.control}
            render={({ onChange }) => (
              <div>
                <div style={{ paddingLeft: '2%' }}>
                  <ResearchField
                    onChange={filter.setPerfilFilter}
                    placeholder="Filtro de Perfil"
                  />
                </div>

                <TableCreate
                  rows={request.itemsPerfil}
                  HeadCell={profileColumn}
                  IconCreate={AddIcon}
                  title="Perfil"
                  onChange={(selected) => {
                    onChange(selected[0]);
                    setIdModule(selected[0]);
                  }}
                  checkBox
                  checkboxDisabled={type === 'edit'}
                  idStartSelect={[request.idProfile]}
                  idStartSelectRedy={request.isRedy}
                />
              </div>
            )}
          />
          <Controller
            name="sub_module_id"
            control={form.methods.control}
            render={({ onChange }) => (
              <div>
                <div style={{ paddingLeft: '2%' }}>
                  <ResearchField
                    onChange={filter.setFilterSubModule}
                    placeholder="Filtro de Sub-Modulo"
                  />
                </div>

                <TableCreate
                  rows={request.itemsSubModule}
                  HeadCell={subModuleColumn}
                  IconCreate={AddIcon}
                  title="Sub-Modulo"
                  onChange={(selected) => {
                    onChange(selected);
                  }}
                  checkBox
                  idStartSelect={
                    request.idProfile ? request.listIdaggregate : []
                  }
                  multSelec={false}
                  idStartSelectRedy={request.isRedy}
                />
              </div>
            )}
          />
        </s.containerTable>
        <s.containerbotton>
          <button
            type="button"
            className="containerbotton__bottoncancel"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </button>
          <button
            type="button"
            className={`containerbotton__bottonregister${
              form.isValid ? '--disabled' : ''
            }`}
            onClick={form.submit}
            disabled={form.isValid}
          >
            Registrar
          </button>
        </s.containerbotton>
      </s.container>
    </Modal>
  );
};

export default ProfileSubModuleForm;
