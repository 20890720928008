import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
  height: 100%;
  background: #f4f2f2;
  border-right: 1px solid #dcdcdc;
  overflow-y: auto;
  min-width: 79px !important;
  max-width: 79px !important;
  button {
    border: none;
    width: 100%;
    background: #f4f2f2;
    div {
      width: 100%;
    }
  }

  .active {
    background: ${transparentize(0.9, '#9FBC38')};
    border-left: 5px solid #9fbc38;

    svg {
      color: #9fbc38;
    }
  }
`;

export const NavItem = styled.div`
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    color: #444444;
  }
`;
