/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 730px;
  padding: 10px 10px;

  .radioContainer {
    display: block;
    position: relative;
    padding-left: 25px;
    margin: 12px;
    cursor: default;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .radioContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px solid #A0BD39;
    border-radius: 50%;
  }

  /* When the radio button is checked, add a blue background */
  .radioContainer input:checked ~ .checkmark {
    background: white;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .radioContainer input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .radioContainer .checkmark:after {
    top: 4.2px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #A0BD39;
  }

  hr {
    border: 1px solid #444;
    margin-bottom: 10px;
  }

  .selectStyle {
    flex: 1;

    span {
      margin-bottom: 5px;
    }
  }

  .uploadFile {
    border: 3px solid #666;
    border-radius: 4px;
    border-bottom: 2px solid #666;
    height: 200px;
    display: flex;
    width: 500px;
    margin: 0 15px;
    scroll-behavior: smooth;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0;
  }

  .uploadFile::-webkit-scrollbar {
    display: none;
  }

  .inputContainer__title {
    margin-bottom: 8px;
  }

  .table__input--title {
    border: none;
    background: transparent;
    font-weight: bold;
    width: auto;
    text-align: center;
  }

  .table__addButton {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    height: 60px;
    padding-left: 5px;
    cursor: pointer;

    svg {
      color: #A0BD39;
      font-size: 26px;
    }
  }

  .viewPhoto {
    /* max-height: 280px;
    display: flex;
    justify-content: center; */
    //max-width:200px;
    //max-height:150px;
    max-width: 500px;
    height: 280px;

    img {
    width: auto;
    max-width: 500px;
    height: 280px;
    }
  }

  .textArea {
    min-height: 40px;
    resize: none;
    width: 100%;
    border-radius: 4px;
    resize: none;
    font-size: 16px;
    border: none;
    color: #444;
    background-color: #fff;
    padding: 0 5px;
    font-family: Arial;
  }
`;

export const UploadFileContainer = styled.div`
  display: flex;
  padding-left: 22px;
`;

export const UploadFileText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .uploadfile__span {
    color: #79ac34;
    font-size: 14px;
    margin-top: 2px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  width: 100%;
  display: flex;
  font-size: 18px;
  margin-bottom: 21px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  span {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const SubTitle = styled.h2`
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .cancelBtn {
    background-color: #dcdcdc;
    color: #444;
    width: 120px;
    padding: 10px;
    height: 40px;
  }

  .saveBtn {
    padding: 10px;
    height: 40px;
    width: 150px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;

  span {
    font-weight: bold;
    font-size: 16px;
    color: #4d4d4d;
  }
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .inputId {
    background-color: #efefef;
  }
`;

export const LabelFileInput = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 5px;
  color: #a8a8a8;
  border-radius: 4px;
  /* box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); */
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  transition: background-color 0.2s;

  background-color: #efefef;
  &:hover {
    background-color: #efefef;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }
`;

export const FileInput = styled.input`
  display: none;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  border: 1px dashed #a8a8a8;
  border-radius: 4px;
  width: 100%;
  height: 100%;

  svg {
    height: 30px;
    width: 30px;
  }
`;

export const ContainerDocument = styled.div`
  width: 100%;
  display: flex;
  flex-direction: Column;
  align-items: center;
  justify-content: center;
`;

export const RemoveBtn = styled.button`
  display: flex;
  background-color: transparent;
  align-items: flex-end;
  border: none;
  margin-top: 10px;
  cursor: pointer;

  span {
    font-size: 12px;
    color: #79ac34;
  }

  svg {
    height: 16px;
    width: 16px;
    color: #79ac34;
  }
`;

const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;

export const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const CarouselItem = styled.div`
  cursor: pointer;
  border: none;
`;

export const AddFiles = styled.div`
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  margin: 5px 5px;

  transition: height 0.2s ease;

  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};

  svg {
    color: #79ac34;
    font-size: 14px;
  }
`;

export const DropContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #666;
  border-radius: 4px;
  cursor: pointer;
  height: 280px;
  background-color: #efefef;
  width: 600px;

  transition: height 0.2s ease;

  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};

  .inputMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      height: 40px;
      width: 40px;
      color: #999;
    }
  }
`;

const messageColors = {
  default: '#999',
  error: '#e57878',
  success: '#78e5d5'
};

export const UploadMessage = styled.p`
  display: flex;
  color: ${(props) => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
`;

export const ContainerImage = styled.div`
  flex: none;
  width: 498px;
  height: 100%;
  position: relative;

  img {
    width: 498px;
    height: 100%;
    //object-fit: cover;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: rgba(255,0,0,0.20);
    padding-right: 3px;
    padding-top: 2px;
    cursor: pointer;
    width: 50px;
    height: 100%;

    svg {
      color: #FFF;
    }
  }
`;

export const FileInfoContainer = styled.div`
  //width: 100%;
  display: flex;
  align-items: center;
  //justify-content: flex-end;

  span {
    color: #000;
    font-size: 18px;
  }
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 3px 5px;

  button {
    border: none;
    background: transparent;
    cursor: pointer;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    strong {
      font-size: 12px;
      color: #111;
    }

    span {
      font-size: 10px;
      color: #111;

      button {
        border: none;
        background: transparent;
        color: #e57878;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
`;

export const Preview = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-bottom: 5px;
`;

export const CommitArea = styled.textarea`
  width: 100%;
  height: 132px;
  border-radius: 4px;
  resize: none;
  font-size: 16px;
  border: 1px solid #b4b4b4;
  color: #444;
  padding: 5px;
  font-family: Arial;
`;

export const TextAreaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;

  span {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ColorBtnContainer = styled.div`
  position: relative;
  height: 40px;
  width: 100px;
  display: flex;
  align-items: center;
  padding-top: 4px;

  button {
    width: 100%;
    height: 40px;
    border: 1px solid #d4d4d4;
    color: ${(props) => props.background === '#000000' ? '#FFF' : '#555'};
    background-color:${(props) => props.background ? props.background : ''};
    border-radius: 4px;
    cursor: pointer;
  }
`;

export const ColorPicker = styled.div`
  position: absolute;
  left: 45px;
  top: 0;
  z-index:999;
`;

export const InputError = styled.span`
  color: red !important;
  font-size: 0.6rem !important;
  font-weight: normal !important;
`;

export const ThumbsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 160px;
  height: 280px;
  gap: 5px;
  overflow-y: auto;
  padding: 0 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  img {
    height: 100%;
    width: 100%;
  }

  button {
    border: none;
    background-color: transparent;
  }
`;

export const ThumbItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    //height: 82px;

    &:hover {
      img {
        border: 2px solid #FFF;
      }
    }

    .thumbsContainer__button {
      width: 110px;
      height: 80px;
      background-color: transparente;
      cursor: default;
    }

    .thumbsContainer__buttonMain {
      color: #79ac34;
      background-color: #FFF;
      border: 1px solid #79ac34;
      height: 25px;
      font-size: 13px;
      width: 109px;
      padding: 0px;
      margin-top: 2px;
      border-radius: 2px;
    }

    .thumbsContainer__buttonMain:active {
      color: #608929;
      border: 1px solid #608929;
      transform: translateY(2px);
    }

    img {
      height: 100%;
    }
`;

export const ThumbItem = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ThumbIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  svg{
    margin-left: 5px;
  }
`;

export const ThumbButtonImage = styled.button`
  width: 110px;
  height: 80px;
  background-color: transparente;
  cursor: default;

  &:hover {
      border: 5px solid #79ac34;
  }
`;

export const ThumbButtonIcon = styled.button`
  border: none;
  background-color: transparent;
  margin-top: 3px;
  margin-bottom: 10px;
`;

export const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TextContainer = styled.div`
  padding: 0 5px;
`;

export const SubtitlePhotoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  font-size: 14px;
  color: #79ac34;
  margin: 10px 0;

  button {
    border: none;
    background-color: #79ac34;
    color: #FFF;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 13px;
    height: 40px;
    cursor: pointer;

    &:hover {
      background-color: #608929;
    }
  }
`;
