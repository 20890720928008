/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
  createRef
} from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  OutlinedInput,
  Input,
  TextareaAutosize
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  FaChevronCircleLeft, FaChevronCircleRight,
  FaUpload,
  FaTrash,
  FaCheckCircle
} from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { AddCircle } from '@mui/icons-material';
import { CompactPicker } from 'react-color';
import Checkbox from 'react-custom-checkbox';
import { useDropzone } from 'react-dropzone';
// import { useFiles } from "../../context/files";
import PuffLoader from 'react-spinners/PuffLoader';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  MdOutlineCloudUpload,
  MdMoodBad
} from 'react-icons/md';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
import filesize from 'filesize';
import { toast } from 'react-toastify';
import { Carousel } from 'react-responsive-carousel';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import storage from '../../../../../../../../services/firebase';
import Button from '../../../../../../../../components/Button';
import { useMap } from '../../../../../../../../hooks/Map';
import { appConfig } from '../../../../../../../../config';
import { useAuth } from '../../../../../../../../hooks/Auth';
import api from '../../../../../../../../services/api';
import Toast from '../../../../../../../../components/Toast';
import * as S from './styled';

const ModalAddNewDisease = () => {
  const inputFileRef = useRef(null);
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const {
    selectedManagement,
    uploadedFiles: files,
    setUploadedFiles,
    deleteFile,
    handleUpload,
    setUpdateScreen,
    itemTypeSelected,
    selectTypeCultureList,
    setSelectTypeCultureList,
    imageDiagrammaticScale,
    setImageDiagrammaticScale,
    filePhotoScale,
    setFilePhotoScale,
    setDisease_cropName,
    setDisease_plantName,
    setCurrentModal
  } = useContext(DashboardContext);

  const { selectedFarmId } = useMap();
  const theme = useTheme();
  const reader = new FileReader();

  const uploadFile = useRef(null);
  const carouselThumbs = useRef(null);
  const inputFiles = useRef(null);

  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('nao');
  const [checked, setChecked] = useState(true);
  const [diseaseTypeList, setDiseaseTypeList] = useState([]);

  const [severityLevel, setSeverityLevel] = useState([]);
  const [colorSeverityLevel, setColorSeverityLevel] = useState('');
  const [levelSeverityLevel, setLevelSeverityLevel] = useState('');
  const [valueSeverityLevel, setValueSeverityLevel] = useState('');
  const [commentsSeverityLevel, setCommentsSeverityLevel] = useState('');
  const [titleSeverityLevel, setTitleSeverityLevel] = useState('');
  const [colorPickerSeverityLevelVisible, setColorPickerSeverityLevelVisible] = useState(false);

  const [dossal, setDossal] = useState([]);
  const [colorDossal, setColorDossal] = useState('');
  const [valueDossal, setValueDossal] = useState('');
  const [titleDossal, setTitleDossal] = useState('');
  const [colorPickerDossalVisible, setColorPickerDossalVisible] = useState(false);

  const [selectedPhoto, setSelectedPhoto] = useState(0);
  const [mainPhoto, setMainPhoto] = useState('');
  const [photoSubtitle, setPhotoSubtitle] = useState('');
  const [selectedPhotoScale, setSelectedPhotoScale] = useState(0);

  const [cropList, setCropList] = useState([]);
  const [plantList, setPlantList] = useState([]);
  const [cropName, setCropName] = useState([]);
  const [plantName, setPlantName] = useState([]);

  const [messageSuccessVisible, setMessageSuccessVisible] = useState(false);
  const ModalAddNewPragueSchema = Yup.object().shape({
    disease_type: Yup.string().nullable(),
    name: Yup.string().required('Nome é obrigatório'),
    scientific_name: Yup.string().nullable(),
    description: Yup.string().nullable(),
    symptoms: Yup.string().nullable(),
  });

  const { handleSubmit, register, errors, control, reset, setValue } = useForm({
    resolver: yupResolver(ModalAddNewPragueSchema)
  });

  const handleUploadFile = async (e) => {
    try {
      const fileData = await storage.ref(`/images/${e.name}`).put(e.file);
      const imageSrc = { id: e.id, legend: e.legend, url: await fileData.ref.getDownloadURL() };

      files.forEach((file) => URL.revokeObjectURL(file.preview));

      return imageSrc;
    } catch (error) {
      console.error(error);
    }
  };

  const FileList = () => {
    if (!files.length) {
      return (
        <span>
          <MdMoodBad
            style={{ marginLeft: '45%', marginTop: 10 }}
            size={24}
            color="#d5d2d2"
          />
        </span>
      );
    }
  };

  const onDrop = useCallback(
    (file) => {
      handleUpload(file);
    },
    [handleUpload]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: ['image/jpeg', 'image/pjpeg', 'image/png', 'image/gif'],
      // maxFiles: 1,
      multiple: true,
      onDrop
    });

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return (
        <S.UploadMessage>Clique ou arraste uma imagem aqui</S.UploadMessage>
      );
    }

    if (isDragReject) {
      return (
        <S.UploadMessage type="error">
          Tipo de arquivo não suportado
        </S.UploadMessage>
      );
    }

    return (
      <S.UploadMessage type="success">Solte as imagens aqui</S.UploadMessage>
    );
  }, [isDragActive, isDragReject]);

  const addSeverityLevelInTable = () => {
    if (
      colorSeverityLevel !== '' &&
      levelSeverityLevel !== '' &&
      valueSeverityLevel !== '' &&
      commentsSeverityLevel !== ''
    ) {
      severityLevel.push({
        color: colorSeverityLevel,
        level: levelSeverityLevel,
        value: valueSeverityLevel,
        comments: commentsSeverityLevel
      });

      setColorSeverityLevel('');
      setLevelSeverityLevel('');
      setValueSeverityLevel('');
      setCommentsSeverityLevel('');
    } else {
      return toast(
        <Toast title="Preencha os campos para serem adicionados na tabela" />,
        {
          type: 'warning'
        }
      );
    }
  };

  const addDossalInTable = () => {
    if (
      valueDossal !== ''
    ) {
      dossal.push({
        canopy_plant: valueDossal,
      });

      setValueDossal('');
    } else {
      return toast(
        <Toast title="Preencha os campos para serem adicionados na tabela" />,
        {
          type: 'warning'
        }
      );
    }
  };

  const getCropType = async () => {
    const params = new URLSearchParams([
      ['type_id', 'c826052d-3a59-4398-aec1-81193ccd2e42']
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterCropByActive = response.data.data.values.filter(
        (v) => v.status === true
      );
      setCropList(filterCropByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getPlant = async () => {
    const params = new URLSearchParams([
      ['type_id', '4f7bca40-7ffd-4485-9627-e5d2ba90776c']
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterPlantByActive = response.data.data.values.filter(
        (v) => v.status === true
      );

      setPlantList(filterPlantByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getDiseaseTypeList = async () => {
    const params = new URLSearchParams([
      ['type_id', 'd1df7b84-217c-44f6-8bca-c42d7569dcf5']
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterClassByActive = response.data.data.values.filter(
        (v) => v.status === true
      );
      setDiseaseTypeList(filterClassByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeBackgroundSeverityLevel = (color) => {
    setColorSeverityLevel(color.hex);
    setColorPickerSeverityLevelVisible(false);
  };

  const createDisease = async (data) => {
    try {
      const response = await api.post(
        `${appConfig.apiHosts.manager}/Disease/Create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast(<Toast title="Doença criada com sucesso" />, {
        type: 'success'
      });

      return response.data.data;
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast(<Toast title="Não foi possível criar uma nova doença" />, {
        type: 'error'
      });
      return false;
    }
  };

  const createDiseaseImages = async (data) => {
    try {
      const response = await api.post(
        `${appConfig.apiHosts.manager}/DiseaseImage/Create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.error(error);
      return toast(<Toast title="Não foi possível salvar fotos" />, {
        type: 'error'
      });
    }
  };

  const createDiseaseDiagrammaticScale = async (data) => {
    try {
      const response = await api.post(
        `${appConfig.apiHosts.manager}/DiseaseDiagrammaticScale/Create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.error(error);
      toast(<Toast title="Não foi possível salvar escala diagramática" />, {
        type: 'error'
      });
    }
  };

  const clearFields = () => {
    setUploadedFiles([]);
    setSeverityLevel([]);
    setTitleSeverityLevel('');
    setCropName([]);
    setPlantName([]);
    setImageDiagrammaticScale([]);
    setDisease_cropName([]);
    setDisease_plantName([]);
    // setPhotoSubtitle('');
    setTitleDossal('');
    setDossal([]);
    setValueDossal('');
    setLevelSeverityLevel('');
    setValueSeverityLevel('');
    setColorSeverityLevel('');
    setCommentsSeverityLevel('');
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const imageUrl = [];
    const selectedCropsId = [];
    const selectedPlantsId = [];
    const photoUrl = [];
    const photoScaleUrl = [];
    const oldPhoto = [];
    const newPhoto = [];

    if (filePhotoScale.length > 0) {
      filePhotoScale.forEach((value) => {
        photoScaleUrl.push(value.url);
      });
    }

    files.forEach((image) => {
      if (image.url === '') {
        newPhoto.push(image);
      } else {
        oldPhoto.push({ id: image.id, url: image.url });
      }
    });

    cropList.forEach((value) => {
      cropName.forEach((v) => {
        if (value.name === v) {
          selectedCropsId.push(value.value_Id);
        }
      });
    });

    plantList.forEach((value) => {
      plantName.forEach((v) => {
        if (value.name === v) {
          selectedPlantsId.push(value.value_Id);
        }
      });
    });

    if (files.length > 0 && mainPhoto == '' || oldPhoto.length > 0 && mainPhoto == '') {
      setLoading(false);
      return toast(
        <Toast
          title="Não foi possível criar uma nova doença"
          content="Torne uma foto como principal"
        />,
        { type: 'warning' }
      );
    }

    let newDisease = {
      name: data.name,
      description: data.description || null,
      type_disease: data.disease_type || null,
      disease_severity_level: severityLevel || null,
      crop_type: selectedCropsId || null,
      plant: selectedPlantsId || null,
      canopy_plant: dossal || null,
      disease_severity_level_title: titleSeverityLevel || null,
      disease_canopy_plant_title: titleDossal,
      scientific_name: data.scientific_name || null,
      symptoms: data.symptoms || null,
      incidence: selectedValue === 'sim',
      active: true,
      created_by: user.user_id
    };

    try {
      if (files.length > 0) {
        await files.forEach(async (file) => {
          const url = handleUploadFile(file);
          url
            .then((value) => {
              imageUrl.push(value);
            })
            .finally(async () => {
              if (imageUrl.length === files.length) {
                const mainPhot = imageUrl.filter((v) => v.id === mainPhoto);

                if (imageUrl.length === files.length) {
                  newDisease = {
                    ...newDisease,
                    main_photo: mainPhot[0].url,
                  };

                  const responseCreateDisease = await createDisease(newDisease);

                  if (responseCreateDisease) {
                    imageUrl.forEach((value) => {
                      photoUrl.push(
                        {
                          url: value.url,
                          legend: value.legend,
                          disease_id: responseCreateDisease.disease.disease_id,
                          created_by: user.user_id
                        }
                      );
                    });

                    const diseaseImage = {
                      diseaseImage: photoUrl,
                    };

                    await createDiseaseImages(diseaseImage);

                    const diseaseImageScale = {
                      url: photoScaleUrl,
                      disease_id: responseCreateDisease.disease.disease_id,
                      created_by: user.user_id
                    };
                    await createDiseaseDiagrammaticScale(diseaseImageScale);

                    setLoading(false);
                    clearFields();
                    reset();
                    setUpdateScreen(true);
                    closeModal();
                  }
                }
              }
            });
        });
      } else {
        const responseCreateDisease = await createDisease(newDisease);

        if (responseCreateDisease) {
          if (filePhotoScale.length > 0) {
            const diseaseImageScale = {
              url: photoScaleUrl,
              disease_id: responseCreateDisease.disease.disease_id,
              created_by: user.user_id
            };
            await createDiseaseDiagrammaticScale(diseaseImageScale);
          }

          setLoading(false);
          clearFields();
          reset();
          setUpdateScreen(true);
          closeModal();
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast(<Toast title="Não foi possível criar uma nova doença" />, {
        type: 'error'
      });
    }
  };

  useEffect(() => {
    getCropType();
    getPlant();
    getDiseaseTypeList();
  }, []);

  const handleCropChange = (event) => {
    const {
      target: { value }
    } = event;
    setCropName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handlePlantChange = (event) => {
    const {
      target: { value }
    } = event;
    setPlantName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  function getStyles(name, pName, pTheme) {
    return {
      fontWeight:
        pName.indexOf(name) === -1
          ? pTheme.typography.fontWeightRegular
          : pTheme.typography.fontWeightMedium
    };
  }

  const removeDossalRowByIndex = (index) => {
    setDossal(dossal.filter((v, i) => i !== index));
  };

  const removeSeverityLevelRowByIndex = (index) => {
    setSeverityLevel(severityLevel.filter((v, i) => i !== index));
  };

  const handleLeftClick = (e) => {
    e.preventDefault();
    carouselThumbs.current.scrollLeft -= carouselThumbs.current.offsetWidth;
  };

  const handleRightClick = (e) => {
    e.preventDefault();
    carouselThumbs.current.scrollLeft += carouselThumbs.current.offsetWidth;
  };

  const savePhotoSubtitle = (indexPhoto) => {
    files[indexPhoto].legend = photoSubtitle;
    setMessageSuccessVisible(true);
  };

  const fileReader = async () => {
    const filesInput = [];
    const uploadFiles = [];
    filesInput.push(inputFiles.current.files);
    Object.keys(filesInput[0]).forEach((v) => uploadFiles.push(filesInput[0][v]));

    const newUploadedFiles = [];

    uploadFiles.map((file) => (
      newUploadedFiles.push({
        file,
        id: uuid(),
        name: file.name,
        readableSize: filesize(file.size),
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        url: '',
        legend: ''
      })));

    newUploadedFiles.forEach((file) => {
      imageDiagrammaticScale.push(file);
    });

    newUploadedFiles.forEach((file) => {
      const url = handleUploadFile(file);
      url
        .then((value) => {
          filePhotoScale.push(value);
        });
    });
  };

  const deleteFileScale = (id) => {
    const newList = imageDiagrammaticScale.filter((file) => file.id !== id);
    setImageDiagrammaticScale(newList);

    setFilePhotoScale(filePhotoScale.filter((file) => file.id !== id));
  };

  const clickInputFile = () => {
    inputFiles.current.click();
  };

  useEffect(() => {
    setImageDiagrammaticScale([]);
    setFilePhotoScale([]);
    setUploadedFiles([]);
    setMessageSuccessVisible(false);
    setPhotoSubtitle('');
  }, [closeModal]);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      shouldCloseOnOverlayClick={false}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Adicionar Doença</S.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <S.InputWrapper style={{ margin: 0 }}>
              <S.InputContainer>
                <Controller
                  name="disease_type"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span style={{ marginBottom: '8px' }}>
                        Tipo
                      </span>
                      <Select
                        placeholder="Selecione..."
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {diseaseTypeList.map((type) => (
                          <MenuItem value={type.value_Id} key={type.value_Id}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </S.InputContainer>
              <S.InputContainer>
                <span>Nome Comum</span>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextField
                      inputProps={{ maxlength: 42 }}
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.name?.message && (
                  <S.InputError>{errors?.name?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer>
                <span>Nome Científico</span>
                <Controller
                  name="scientific_name"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextField
                      inputProps={{ maxlength: 70 }}
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </S.InputContainer>
              <S.InputContainer>
                <S.CheckboxContainer>
                  <span style={{ marginRight: '10px' }}>Incidência</span>
                </S.CheckboxContainer>
                {checked && (
                  <S.CheckboxContainer>
                    <label
                      className="radioContainer"
                      style={{
                        color: selectedValue === 'sim' ? '#79AC34' : ''
                      }}
                    >
                      Sim
                      <input
                        type="checkbox"
                        checked={selectedValue === 'sim'}
                        onClick={(e) => {
                          e.target.checked
                            ? setSelectedValue('sim')
                            : setSelectedValue('nao');
                        }}
                      />
                      <span className="checkmark" />
                    </label>

                    <label
                      className="radioContainer"
                      style={{
                        color: selectedValue === 'nao' ? '#79AC34' : ''
                      }}
                    >
                      Não
                      <input
                        type="checkbox"
                        checked={selectedValue === 'nao'}
                        onClick={(e) => {
                          e.target.checked
                            ? setSelectedValue('nao')
                            : setSelectedValue('sim');
                        }}
                      />
                      <span className="checkmark" />
                    </label>
                  </S.CheckboxContainer>
                )}
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper style={{ margin: 0 }}>
              <S.InputContainer>
                <Controller
                  name="crop_type"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Cultura</span>
                      <Select
                        style={{ width: '351px' }}
                        multiple
                        displayEmpty
                        value={cropName}
                        onChange={handleCropChange}
                        size="small"
                        // input={<OutlinedInput />}
                        renderValue={(selected) => {
                          return selected.join(', ');
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        {cropList.map((v) => (
                          <MenuItem
                            value={v.name}
                            key={v.value_Id}
                            style={getStyles(name, cropName, theme)}
                          >
                            {v.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="plant"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Cultivar Resistente</span>
                      <Select
                        style={{ width: '351px' }}
                        multiple
                        displayEmpty
                        value={plantName}
                        onChange={handlePlantChange}
                        size="small"
                        // input={<OutlinedInput />}
                        renderValue={(selected) => {
                          return selected.join(', ');
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        {plantList.map((v) => (
                          <MenuItem
                            value={v.name}
                            key={v.value_Id}
                            style={getStyles(name, plantName, theme)}
                          >
                            {v.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper style={{ marginBottom: '15px' }}>
              <S.TextAreaContainer>
                <span className="inputContainer__title">Descrição</span>
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextareaAutosize
                      value={value}
                      onChange={onChange}
                      className="textArea"
                    />
                  )}
                />
                {errors?.description?.message && (
                  <S.InputError>{errors?.description?.message}</S.InputError>
                )}
              </S.TextAreaContainer>
            </S.InputWrapper>

            <S.InputWrapper style={{ marginBottom: '15px' }}>
              <S.TextAreaContainer>
                <span className="inputContainer__title">Sintomas</span>
                <Controller
                  name="symptoms"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextareaAutosize
                      value={value}
                      onChange={onChange}
                      className="textArea"
                    />
                  )}
                />
                {errors?.symptoms?.message && (
                  <S.InputError>{errors?.symptoms?.message}</S.InputError>
                )}
              </S.TextAreaContainer>
            </S.InputWrapper>
            <S.InputWrapper style={{ marginBottom: '15px' }}>
              <S.InputContainer style={{ height: 'auto' }}>
                <span style={{ paddingBottom: '10px' }}>Foto</span>
                <S.PhotoContainer>
                  {files.length <= 0 ? (
                    <S.DropContainer {...getRootProps()}>
                      <input
                        accept="image/*"
                        {...getInputProps()}
                      />
                      <div className="inputMessage">
                        <MdOutlineCloudUpload />
                        {renderDragMessage()}
                      </div>
                    </S.DropContainer>
                  ) : (
                    <>
                      <S.PhotoWrapper>
                        <S.AddFiles {...getRootProps()}>
                          <input accept="image/*" {...getInputProps()} />
                          <S.UploadFileText>
                            <FaUpload size={12} />
                            <h2 className="uploadfile__span">
                              Adicionar fotos
                            </h2>
                          </S.UploadFileText>
                        </S.AddFiles>
                        <S.CarouselBox>
                          <S.PhotoCarouselContainer>
                            <Carousel
                              width={500}
                              // swipeScrollTolerance={5}
                              showThumbs={false}
                              selectedItem={selectedPhoto}
                              showStatus={false}
                              showArrows={false}
                              showIndicators={false}
                              onChange={(e) => setSelectedPhoto(e)}
                            >
                              {files.map((uploadedFile) => (
                                <div className="viewPhoto">
                                  <img
                                    alt={uploadedFile.name}
                                    src={uploadedFile.preview}
                                    height="280"
                                    style={{ border: '2px solid #79ac34' }}
                                  />
                                  <S.SubtitlePhotoContainer>
                                    <TextField
                                      label="Legenda"
                                      variant="outlined"
                                      fullWidth
                                      size="small"
                                      value={photoSubtitle}
                                      onChange={(e) => {
                                        setPhotoSubtitle(e.target.value);
                                        setMessageSuccessVisible(false);
                                      }}
                                    />
                                    <button type="button" onClick={() => savePhotoSubtitle(selectedPhoto)}>Salvar</button>
                                  </S.SubtitlePhotoContainer>
                                </div>
                              ))}
                            </Carousel>
                          </S.PhotoCarouselContainer>
                          <S.ThumbsContainer>
                            {files.map((uploadedFile, index) => (
                              <S.ThumbItemContainer>
                                <S.ThumbItem>
                                  <S.ThumbButtonImage
                                    type="button"
                                    className="thumbsContainer__button"
                                    onClick={() => {
                                      setSelectedPhoto(index);
                                      setMessageSuccessVisible(false);
                                      setPhotoSubtitle(files[index].legend);
                                      setValue('photo_subtitle', files[index].legend);
                                    }}
                                    style={{
                                      border:
                                        selectedPhoto === index
                                          ? '5px solid #79ac34'
                                          : '2px solid #47aa12'
                                    }}
                                  >
                                    <img
                                      alt={uploadedFile.name}
                                      src={uploadedFile.preview}
                                      style={{
                                        border:
                                          selectedPhoto === index
                                            ? '2px solid #FFF'
                                            : 'none'
                                      }}
                                    />
                                  </S.ThumbButtonImage>
                                  <S.ThumbIconsContainer>
                                    <S.ThumbButtonIcon
                                      type="button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (uploadedFile.id === mainPhoto) {
                                          setMainPhoto('');
                                        }
                                        deleteFile(uploadedFile.id);
                                        setSelectedPhoto(0);
                                      }}
                                    >
                                      <FaTrash size={14} color="#AF0000" />
                                    </S.ThumbButtonIcon>
                                    {uploadedFile.id === mainPhoto && (
                                      <FaCheckCircle color="#79ac34" size={15} />
                                    )}
                                  </S.ThumbIconsContainer>
                                </S.ThumbItem>
                                {selectedPhoto === index &&
                                  uploadedFile.id !== mainPhoto && (
                                    <Button
                                      text="Tornar Principal"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setMainPhoto(uploadedFile.id);
                                      }}
                                      className="thumbsContainer__buttonMain"
                                    />
                                  )}
                              </S.ThumbItemContainer>
                            ))}
                          </S.ThumbsContainer>
                        </S.CarouselBox>
                        {messageSuccessVisible && (
                          <S.MessageText>
                            <FaCheckCircle color="#79ac34" size={12} />
                            <p>Legenda salva!</p>
                          </S.MessageText>
                        )}
                      </S.PhotoWrapper>
                    </>
                  )}
                </S.PhotoContainer>
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper style={{ marginBottom: '15px' }}>
              <S.InputContainer style={{ height: 'auto' }}>
                <span style={{ paddingBottom: '10px' }}>Escala Diagramática</span>
                <S.PhotoContainerScale>
                  {imageDiagrammaticScale.length <= 0 ? (
                    <S.DropContainerScale onClick={() => clickInputFile()}>
                      <input
                        id="input_file"
                        type="file"
                        accept="image/*"
                        ref={inputFiles}
                        className="photoContainerScale__input"
                        onChange={() => fileReader()}
                      />
                      <div className="inputMessage">
                        <MdOutlineCloudUpload />
                        {renderDragMessage()}
                      </div>
                    </S.DropContainerScale>
                  ) : (
                    <>
                      <Carousel
                        width={700}
                        // swipeScrollTolerance={5}
                        showThumbs={false}
                        selectedItem={selectedPhotoScale}
                        showStatus={false}
                        showArrows={false}
                        showIndicators={false}
                        onChange={(e) => setSelectedPhotoScale(e)}
                      >
                        {imageDiagrammaticScale.map((uploadedFileScale) => (
                          <div className="viewPhotoScale" key={uploadedFileScale.id}>
                            <img
                              alt={uploadedFileScale.name}
                              src={uploadedFileScale.preview}
                              height="280"
                              style={{ border: '2px solid #79ac34' }}
                            />
                            <S.ThumbButtonIconScale
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                deleteFileScale(uploadedFileScale.id || uploadedFileScale.diagrammatic_scale_id);
                                setSelectedPhotoScale(0);
                              }}
                              style={{ marginLeft: '10px' }}
                            >
                              <FaTrash size={14} color="#AF0000" />
                            </S.ThumbButtonIconScale>
                          </div>
                        ))}
                      </Carousel>
                      <S.UploadFileContainer>
                        {imageDiagrammaticScale.length > 0 && (
                          <S.AddFiles>
                            <input
                              id="input_file"
                              type="file"
                              accept="image/*"
                              ref={inputFiles}
                              multiple="multiple"
                              className="photoContainerScale__input"
                              onChange={() => fileReader()}
                            />
                          </S.AddFiles>
                        )}
                      </S.UploadFileContainer>
                    </>
                  )}
                </S.PhotoContainerScale>
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper style={{ alignItems: 'start', marginBottom: '15px' }}>
              <S.InputContainer style={{ height: 'auto' }}>
                <span style={{ paddingBottom: '10px', marginTop: '10px' }}>
                  Nível de Severidade
                </span>
                <TableContainer
                  style={{
                    overflow: 'auto',
                    width: '100%'
                  }}
                >
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: '#e4e4e4'
                          }}
                          colSpan={5}
                        >
                          <input
                            className="table__input--title"
                            type="text"
                            placeholder="Digite o título da tabela"
                            value={titleSeverityLevel}
                            onChange={(e) => setTitleSeverityLevel(e.target.value)}
                          // eslint-disable-next-line react/jsx-closing-bracket-location
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {severityLevel?.map((value, index) => (
                        <TableRow>
                          <TableCell align="center">
                            <div
                              style={{
                                height: '18px',
                                width: '18px',
                                background: value.color ? value.color : ''
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {value.level}
                          </TableCell>
                          <TableCell align="center">{value.value}</TableCell>
                          <TableCell align="center">{value.comments}</TableCell>
                          <TableCell align="center">
                            <S.DeleteRowButton type="button" onClick={() => removeSeverityLevelRowByIndex(index)}>
                              <FaTrash size={14} color="#AF0000" />
                            </S.DeleteRowButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <S.InputWrapper>
                  <S.ColorBtnContainer background={colorSeverityLevel}>
                    <button
                      type="button"
                      style={{ width: '40px' }}
                      onClick={(e) => {
                        e.preventDefault();
                        setColorPickerSeverityLevelVisible(!colorPickerSeverityLevelVisible);
                      }}
                    >
                      Cor
                    </button>
                    {colorPickerSeverityLevelVisible && (
                      <S.ColorPicker>
                        <CompactPicker
                          color={colorSeverityLevel}
                          onChange={(e) => handleChangeBackgroundSeverityLevel(e)}
                        />
                      </S.ColorPicker>
                    )}
                  </S.ColorBtnContainer>
                  <TextField
                    label="Nível"
                    name="level"
                    id="level"
                    margin="dense"
                    size="small"
                    value={levelSeverityLevel}
                    onChange={(e) => setLevelSeverityLevel(e.target.value)}
                    sx={{ width: '250px' }}
                  />
                  <TextField
                    label="Valor"
                    name="value"
                    id="value"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={valueSeverityLevel}
                    onChange={(e) => setValueSeverityLevel(e.target.value)}
                    sx={{ width: '250px' }}
                  />
                  <TextField
                    label="Comentários"
                    name="comments"
                    id="value"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={commentsSeverityLevel}
                    onChange={(e) => setCommentsSeverityLevel(e.target.value)}
                  />
                  <button
                    type="button"
                    className="table__addButton"
                    onClick={(e) => {
                      e.preventDefault();
                      addSeverityLevelInTable();
                    }}
                  >
                    <AddCircle />
                  </button>
                </S.InputWrapper>
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper style={{ alignItems: 'start', marginBottom: '15px' }}>
              <S.InputContainer style={{ height: 'auto' }}>
                <span style={{ paddingBottom: '10px', marginTop: '10px' }}>
                  Dossel de Planta
                </span>
                <TableContainer
                  style={{
                    overflow: 'auto',
                    width: '100%'
                  }}
                >
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: '#e4e4e4'
                          }}
                          colSpan={2}
                        >
                          <input
                            className="table__input--title"
                            type="text"
                            placeholder="Digite o título da tabela"
                            value={titleDossal}
                            onChange={(e) => setTitleDossal(e.target.value)}
                          // eslint-disable-next-line react/jsx-closing-bracket-location
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dossal?.map((value, index) => (
                        <TableRow>
                          <TableCell align="center">{value.canopy_plant}</TableCell>
                          <TableCell align="center">
                            <S.DeleteRowButton type="button" onClick={() => removeDossalRowByIndex(index)}>
                              <FaTrash size={14} color="#AF0000" />
                            </S.DeleteRowButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <S.InputWrapper>
                  <TextField
                    label="Valor"
                    name="value"
                    id="value"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={valueDossal}
                    onChange={(e) => setValueDossal(e.target.value)}
                  />
                  <button
                    type="button"
                    className="table__addButton"
                    onClick={(e) => {
                      e.preventDefault();
                      addDossalInTable();
                    }}
                  >
                    <AddCircle />
                  </button>
                </S.InputWrapper>
              </S.InputContainer>
            </S.InputWrapper>
            <S.ButtonWrapper>
              <Button
                text="Cancelar"
                className="cancelBtn"
                onClick={() => {
                  clearFields();
                  closeModal();
                }}
              />
              <Button
                text={
                  loading ? (
                    <>
                      <PuffLoader color="#79ac34" loading={loading} size={30} />
                    </>
                  ) : (
                    'Salvar'
                  )
                }
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                type="submit"
                disabled={loading}
                className="saveBtn"
              />
            </S.ButtonWrapper>
          </S.FormContainer>
        </form>
      </S.Container>
    </Modal>
  );
};

export default ModalAddNewDisease;
