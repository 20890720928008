export const metadataFileJhonDeere = (object) => ({
  Version: object.Version || '1.0',
  OrgId: object.OrgId || '',
  ClientId: object.ClientId || '',
  ClientName: object.ClientName || '',
  FarmId: object.FarmId || '',
  FarmName: object.FarmName || '',
  FieldId: object.FieldId || '',
  FieldName: object.FieldName || '',
  Operation: object.Operation || '',
  CropSeason: object.CropSeason || '',
  CropToken: object.CropToken || '',
  FileCreatedTimeStamp: object.CropToken || new Date(),
  DataAttributes: object.DataAttributes || []
});

export const generateFilenameNoExtFileJhonDeere = (object) =>
  `${object.ClientName}-${object.FarmName}-${object.FieldName}-${object.CropSeason}`;

export const generateFilenameMetadataFileJhonDeere = (object) =>
  `${object.ClientName}-${object.FarmName}-${object.FieldName}-${object.CropSeason}-Deere-Metadata.json`;

export const generateFilenameZipFileJhonDeere = (object) =>
  `${object.ClientName}-${object.FarmName}-${object.FieldName}-${object.CropSeason}.zip`;
// EXEMPLO DE DATAATTRIBUTES
// {
//   Name: 'DISTANCE',
//   Unit: 'ft',
//   Description: 'Distance Travelled From Previous Point'
// },
// {
//   Name: 'SWATHWIDTH',
//   Unit: 'ft',
//   Description: 'Width Of Element'
// },
// {
//   Name: 'VRYIELDVOL',
//   Unit: 'bu1ac-1',
//   Description: 'Yield as Volume'
// },
// {
//   Name: 'SECTIONID',
//   Description: 'Element Id'
// },
// {
//   Name: 'Crop',
//   Description: 'CropId'
// },
// {
//   Name: 'Moisture',
//   Unit: 'prcnt',
//   Description: 'Moisture'
// },
// {
//   Name: 'WetMass',
//   Unit: 'lb1ac-1',
//   Description: 'Wet Mass'
// },
// {
//   Name: 'Time',
//   Description: 'Timestamp'
// },
// {
//   Name: 'Heading',
//   Description: 'Machine Heading'
// },
// {
//   Name: 'VARIETY',
//   Description: 'Variety Name'
// },
// {
//   Name: 'Elevation',
//   Unit: 'ft',
//   Description: 'Elevation'
// },
// {
//   Name: 'IsoTime',
//   Description: 'Precise Timestamp'
// }
