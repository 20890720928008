/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { UserForm } from './UserForm';
import { UserList } from './UserList';

const UsersRouters: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<UserList />}>
        <Route path="/new" element={<UserForm type="new" />} />
        <Route path="/edit/:id" element={<UserForm type="edit" />} />
        <Route path="/details/:id" element={<UserForm type="datails" />} />
      </Route>
    </Routes>
  );
};

export default UsersRouters;
