import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { OrganizacaoProfileList } from './OrganizacaoProfileList';
import OrganizacaoProfileForm from './OrganizacaoProfileForm/OrganizacaoProfileForm';

const OrganizacaoProfileRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<OrganizacaoProfileList />}>
        <Route path="/new" element={<OrganizacaoProfileForm type="new" />} />
        <Route
          path="/edit/:id"
          element={<OrganizacaoProfileForm type="edit" />}
        />
      </Route>
    </Routes>
  );
};

export default OrganizacaoProfileRouter;
