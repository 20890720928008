import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const Title = styled.h3`
  font-size: 16px;
  margin: 16px 0;
  text-align: left;
  width: 100%;
`;
export const SubTitle = styled.p`
  font-size: 12px;
  color: #777777;
  text-align: justify;
  margin-bottom: 16px;
`;

export const ActionCard = styled.button`
  margin-top: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #0000001a;

  & + button {
    margin-bottom: 10px;
  }

  span {
    font-size: 0.9rem;
  }
  p {
    font-size: 0.6rem;
  }
`;

export const RegisteredFields = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 150px;
  height: 150px;
  margin: 5px 0 10px;
  padding: 5px;
`;

export const FarmContainer = styled.div``;

export const FieldCard = styled.div`
  display: flex;
  min-width: 220px;
  flex-direction: column;
  border: 1px solid #a3a3a3;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);

  & + div {
    margin-top: 5px;
  }

  span {
    margin-left: 5px;
  }
`;
export const CardHeader = styled.div`
  width: 100%;
  font-size: 0.7rem;
`;

export const FieldForm = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 13px;
`;

export const ErrorMessage = styled.p`
  font-size: 0.8rem;
  color: #d62222;
  text-align: center;
  margin: 2.5px;
`;
