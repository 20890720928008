import styled from 'styled-components';

export const Container = styled.div`
  width: 720px;
  padding: 10px;

  hr {
    border: 1px solid #444;
    margin-bottom: 10px;
  }
`;

export const TableTitle = styled.h2`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  margin-bottom: 5px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  span {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -10px;

  .cancelBtn {
    background-color: #dcdcdc;
    color: #444;
    width: 120px;
    padding: 10px;
    height: 40px;
  }

  .saveBtn {
    padding: 10px;
    height: 40px;
    width: 20%;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .inputId {
    background-color: #efefef;
  }
`;
