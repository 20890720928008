/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ModuleProfileList } from './ModuleProfileList';
import ModuleProfileForm from './ModuleProfileForm/ModuleProfileForm';

const ModuleProfileRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ModuleProfileList />}>
        <Route path="/new" element={<ModuleProfileForm type="new" />} />
        <Route
          path="/edit/:id/:idaggregate"
          element={<ModuleProfileForm type="edit" />}
        />
      </Route>
    </Routes>
  );
};

export default ModuleProfileRouter;
