/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FarmList } from './FarmList';
import FarmFormEdit from './FarmForm/FarmFormEdit';
import Farm from '../../../Farm';
import FarmFormCreate from './FarmForm/FarmFormCreate/FarmFormCreate';
import RegisterFarm from '../../../Farm/components/RegisterFarm';
import RegisterFarmFromShapefile from '../../../Farm/components/RegisterFarmFromShapefile';
import EditingFarm from '../../../Farm/components/EditingFarm';
import Field from '../../../Farm/components/Field';

const FarmRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<FarmList />}>
        <Route path="/" element={<Navigate to="./list" />} />
        <Route path="/list" element={<Farm />} />
        <Route path="/new" element={<RegisterFarm />} />
        <Route path="/edit/:id" element={<EditingFarm />} />
        <Route path="/selectFarm" element={<Field />} />
        <Route
          path="/RegisterFarmFromShapefile"
          element={<RegisterFarmFromShapefile />}
        />
      </Route>
    </Routes>
  );
};

export default FarmRouter;
