/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useRef, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
} from '@mui/material';
import Checkbox from 'react-custom-checkbox';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import PuffLoader from 'react-spinners/PuffLoader';
import { MdCheck } from 'react-icons/md';
import { toast } from 'react-toastify';
import { DoNotStep } from '@mui/icons-material';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import Button from '../../../../../../../../components/Button';
import { useAuth } from '../../../../../../../../hooks/Auth';
import api from '../../../../../../../../services/api';
import { appConfig } from '../../../../../../../../config';
import Toast from '../../../../../../../../components/Toast';
import { useMap } from '../../../../../../../../hooks/Map';
import * as S from './styled';

const ModalParametersNewRecommendation = () => {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const {
    setUpdateScreen,
    listTypeManager,
    selectTypeRecoomendationList,
    setSelectTypeRecoomendationList,
    selectTypeApplicationList,
    setSelectTypeApplicationList
  } = useContext(DashboardContext);
  const { selectedFarmId } = useMap();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [selectedAppMode, setSelectedAppMode] = useState('');
  const [activePrinciple, setActivePrinciple] = useState('');
  const [volume, setVolume] = useState('');
  const [dose, setDose] = useState('');
  const [commits, setCommits] = useState('');

  const ModalNewRecommendationSchema = Yup.object().shape({
    type_recomm: Yup.string().required('Tipo de recomendação é obrigatório'),
    active_principle: Yup.string().required('Principio ativo é obrigatório'),
    dose: Yup.string().required('Dose é obrigatório'),
    volume: Yup.string().required('Volume em calda é obrigatório'),
    application_mode: Yup.string().required('Modo de aplicação é obrigatório'),
    commits: Yup.string()
  });

  const { handleSubmit, register, errors, control, reset } = useForm({
    resolver: yupResolver(ModalNewRecommendationSchema)
  });

  const clearFields = async () => {
    setSelectedType('');
    setSelectedAppMode('');
    setActivePrinciple('');
    setVolume('');
    setCommits('');
  };

  const getTypeRecommendation = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Recomendação')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterRecommendationByActive = response.data.data.values.filter((v) => v.status === true);
      setSelectTypeRecoomendationList(filterRecommendationByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeApplication = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Aplicação')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterApplicationModeByActive = response.data.data.values.filter((v) => v.status === true);
      setSelectTypeApplicationList(filterApplicationModeByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const newRecommendation = {
      active_principle: data.active_principle,
      syrup_volume: data.volume,
      application_mode_id: data.application_mode,
      type_id: data.type_recomm,
      note: data.commits,
      dose: data.dose,
      farm_id: selectedFarmId,
      created_by: user.user_id,
    };

    try {
      await api.post(
        `${appConfig.apiHosts.prague}/Recomendation/Create`,
        newRecommendation,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast(<Toast title="Recomendação criada com sucesso" />, {
        type: 'success'
      });
      setUpdateScreen(true);

      if (checked) {
        reset();
        setLoading(false);
      } else {
        clearFields();
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast(<Toast title="Não foi possível criar a recomendação" />, {
        type: 'error'
      });
    }
  };

  useEffect(() => {
    getTypeRecommendation();
    getTypeApplication();
  }, []);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Novo Recomendação</S.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <S.InputWrapper>
              <S.InputContainer>
                <Controller
                  name="type_recomm"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Tipo</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {selectTypeRecoomendationList.map((recomm) => (
                          <MenuItem value={recomm.value_Id} key={recomm.value_Id}>{recomm.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.type_recomm?.message && (
                  <S.InputError>{errors?.type_recomm?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <span>Principio Ativo</span>
                <Controller
                  name="active_principle"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value, }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                      style={{ marginBottom: '7px' }}
                    />
                  )}
                />
                {errors?.active_principle?.message && (
                  <S.InputError>{errors?.active_principle?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <span>Dose</span>
                <Controller
                  name="dose"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value, }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                      style={{ marginBottom: '7px' }}
                    />
                  )}
                />
                {errors?.dose?.message && (
                  <S.InputError>{errors?.dose?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.InputContainer>
                <span>Volume em Calda</span>
                <Controller
                  name="volume"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value, }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                      style={{ marginBottom: '7px' }}
                    />
                  )}
                />
                {errors?.volume?.message && (
                  <S.InputError>{errors?.volume?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="application_mode"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Modo de Aplicação</span>
                      <Select
                        placeholder="Selecione..."
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {selectTypeApplicationList.map((applic) => (
                          <MenuItem value={applic.value_Id} key={applic.value_Id}>{applic.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.application_mode?.message && (
                  <S.InputError>{errors?.application_mode?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.InputContainer style={{ height: '132px' }}>
                <span style={{ marginBottom: '10px' }}> Observações</span>
                <Controller
                  name="commits"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <S.CommitArea
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>
            <S.ButtonWrapper>
              <Button
                text="Cancelar"
                className="cancelBtn"
                onClick={() => {
                  clearFields();
                  closeModal();
                }}
              />
              <S.CheckboxContainer>
                <Checkbox
                  checked={checked}
                  icon={(
                    <MdCheck
                      color="#507919"
                    />
                  )}
                  borderColor="#A0BD39"
                  borderRadius={3}
                  borderWidth={1}
                  size={20}
                  style={{ cursor: 'pointer' }}
                  onChange={() => {
                    setChecked(!checked);
                  }}
                />
                <label htmlFor="register">Continuar Cadastrando</label>
              </S.CheckboxContainer>
              <Button
                text={loading ? (
                  <>
                    <PuffLoader
                      color="#79ac34"
                      loading={loading}
                      size={30}
                    />
                  </>
                ) : 'Criar Recomendação'}
                disabled={loading}
                className="saveBtn"
                type="submit"
              />
            </S.ButtonWrapper>
          </S.FormContainer>
        </form>
      </S.Container>
    </Modal>
  );
};

export default ModalParametersNewRecommendation;
