export const appConfig = {
  limitHaFarm: 10000000,
  enviroment: process.env.REACT_APP_ENVIROMENT,
  verifyTimeMessageChat: 20000,
  verifyTimeNotifications: 20000,
  apiHosts: {
    auth: process.env.REACT_APP_URL_MS_AUTH,
    farm: process.env.REACT_APP_URL_MS_FARM,
    field: process.env.REACT_APP_URL_MS_FIELD,
    report: process.env.REACT_APP_URL_MS_REPORT,
    notification: process.env.REACT_APP_URL_MS_NOTIFICATION,
    chat: process.env.REACT_APP_URL_MS_CHAT,
    manager: process.env.REACT_APP_URL_MS_MANAGER,
    prague: process.env.REACT_APP_URL_MS_PRAGUE
  },
  servicesKey: {
    mapTiler: process.env.REACT_APP_MAP_TILER_KEY,
    sentinel: process.env.REACT_APP_MAP_SENTINEL_KEY,
    firebase: {
      prod: {
        apiKey: 'AIzaSyBKVkdwhRBQgz1fwiE1os0Lw6xS0xVKKT4',
        authDomain: 'fundacao-mt.firebaseapp.com',
        projectId: 'fundacao-mt',
        storageBucket: 'fundacao-mt.appspot.com',
        messagingSenderId: '345205655336',
        appId: '1:345205655336:android:c4208888b90c9aca29f21d'
      },
      dev: {
        apiKey: 'AIzaSyBKVkdwhRBQgz1fwiE1os0Lw6xS0xVKKT4',
        authDomain: 'fundacao-mt.firebaseapp.com',
        projectId: 'fundacao-mt',
        storageBucket: 'fundacao-mt.appspot.com',
        messagingSenderId: '345205655336',
        appId: '1:345205655336:android:c4208888b90c9aca29f21d'
      }
    }
  }
};
