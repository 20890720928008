import GeoJSON from 'ol/format/GeoJSON';
import { getArea } from 'ol/sphere';

export const calculateArea = (geoJson) => {
  const polygon = new GeoJSON().readFeature(geoJson.features[0]);
  return `${(
    Math.round(getArea(polygon.getGeometry()) * 100) /
    100 /
    10000
  ).toFixed(2)} ha`;
};
