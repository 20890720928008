/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React from 'react';
import { render } from 'react-dom';
import App from './App';
import Providers from './hooks/Providers';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById('root')
);
