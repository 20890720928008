/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';
import { appConfig } from '../../../../../../config';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import api from '../../../../../../services/api';
import { DashboardContext } from '../../../../index';
import * as S from './styled';

const MakeRecommendation = () => {
  const [fertSolid, setFertSolid] = useState('');
  const [dose, setDose] = useState('');
  const [fertilizerList, setFertilizerList] = useState([]);
  const [fertId, setFertId] = useState('');
  const [unitOfMeasurement, setUnitOfMeasurement] = useState('litros');
  const {
    setLegendRecommendationValue,
    recommendationZoneId,
    recommDepth,
    setRecommDepth,
    recommFertilizer,
    setRecommFertilizer,
    recommName,
    setRecommName,
    recommType,
    setRecommType,
    recommMeasurementUnit,
    setRecommMeasurementUnit,
    recommDose,
    setRecommDose,
    setRecommendationCompleted,
    recommZoneList
  } = useMap();
  const { token, user } = useAuth();
  const { setDashboardState } = useContext(DashboardContext);
  const getRecomendations = async () => {
    setFertilizerList([]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.report}/Recommendation/GetRecommendationList`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFertilizerList(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleGenerateLegend = async (parameter) => {
    try {
      const params = new URLSearchParams([['RecommendationId', parameter]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/RecommendationZoneColors/GetRecommendationMapLegendByRecommendationId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setLegendRecommendationValue(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const saveRecommZoneDrawed = async () => {
    const params = {
      recommendation_zone_id: recommendationZoneId,
      recommendation_id: recommendationZoneId,
      depth: recommDepth,
      recommendation_type: recommType,
      fertilize: recommFertilizer.replace(',', '.'),
      measurement_unit: recommMeasurementUnit,
      quantity: recommDose.replace(',', '.'),
      created_by: user.user_id
    };
    try {
      await api.post(
        `${appConfig.apiHosts.report}/RecommendationZoneRegister/PostRecommendationZoneRegister`,
        params,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setDashboardState('dashboard@resultsMap');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    getRecomendations();
  }, []);

  return (
    <S.Container>
      <S.FarmCardActions className="backButton">
        <button
          type="button"
          onClick={() => setDashboardState('dashboard@resultsMap')}
        >
          <MdChevronLeft />
          <span>Voltar</span>
        </button>
      </S.FarmCardActions>
      <h4>Nome Zona</h4>
      <p>Selecione os campos abaixo para realizar a recomendação desejada.</p>
      <S.Content>
        <S.SelectContainer>
          <S.TitleBox>Fertilizante sólido A </S.TitleBox>
          <Input
            className="inputDose"
            value={recommFertilizer}
            pattern="^\d+(\.|\,)\d{2}$"
            onChange={(e) => {
              // const regex = /^([0-9]+(\.|,)?[0-9]*|(\.|,)[0-9]+)$/;
              // if (!e.target.value.match(regex) && e.target.value !== '') {
              //   return;
              // }
              // const parsedValue = e.target.value.replace('.', ',');
              setRecommFertilizer(e.target.value);
            }}
            isFocused
          />
          <S.TitleBox>Dose</S.TitleBox>
          <Input
            className="inputDose"
            value={recommDose}
            // pattern={/^[aA-zZ0-9\s]+$/}
            onChange={(e) => {
              const regex = /^([0-9]+(\.|,)?[0-9]*|(\.|,)[0-9]+)$/;
              if (!e.target.value.match(regex) && e.target.value !== '') {
                return;
              }
              const parsedValue = e.target.value.replace('.', ',');
              setRecommDose(parsedValue);
            }}
            isFocused={false}
          />
        </S.SelectContainer>
        <S.MeasurementContainer>
          <S.TitleBox>Unidade de Medida</S.TitleBox>
          <S.UnitOfMeasurement>
            <S.ActionButtonCard
              status={recommMeasurementUnit === 'litros'}
              onClick={() => setRecommMeasurementUnit('litros')}
            >
              Litros
            </S.ActionButtonCard>
            <S.ActionButtonCard
              status={recommMeasurementUnit === 'kg'}
              onClick={() => setRecommMeasurementUnit('kg')}
            >
              Kg
            </S.ActionButtonCard>
          </S.UnitOfMeasurement>
        </S.MeasurementContainer>

        <S.ButtonContainer>
          <Button
            text="Salvar"
            onClick={() => {
              saveRecommZoneDrawed();
              setDashboardState('dashboard@resultsMap');
            }}
          />
          <Button
            text="Cancelar"
            onClick={() => {
              setRecommFertilizer('');
              setRecommDose('');
              setRecommMeasurementUnit('');
              setDashboardState('dashboard@resultsMap');
            }}
            outlined
          />
        </S.ButtonContainer>
      </S.Content>
    </S.Container>
  );
};

export default MakeRecommendation;
