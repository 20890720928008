import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { DashboardContext } from '../../../../index';
import { useMap } from '../../../../../../hooks/Map';
import { useAuth } from '../../../../../../hooks/Auth';
import SimpleButton from '../../../../../../components/SimpleButton';
import api from '../../../../../../services/api';
import Button from '../../../../../../components/Button';
import Toast from '../../../../../../components/Toast';
import * as S from './styled';
import { appConfig } from '../../../../../../config';

const RegisterSubdivision = () => {
  const { setDashboardState } = useContext(DashboardContext);
  const {
    subdivision,
    drawendSubdivisionAndPoint,
    setDrawendSubdivisionAndPoint,
    removeAllInterections,
    removeSubdivisionGeometries
  } = useMap();
  if (subdivision.features[0].geometry.type === 'Polygon') {
    subdivision.features[0].geometry.type = 'MultiPolygon';
    subdivision.features[0].geometry.coordinates[0] = [
      ...subdivision.features[0].geometry.coordinates
    ];
  }
  const { token } = useAuth();
  // eslint-disable-next-line no-console
  const updateSubdivision = async () => {
    try {
      await api.put(
        `${appConfig.apiHosts.field}/Subdivision/UpdateSubdivision`,
        subdivision,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      toast(<Toast title="Subdivisão editada com sucesso" />, {
        type: 'success'
      });
      setDashboardState('dashboard@harvestmap');
      removeSubdivisionGeometries();
    } catch (error) {
      setDrawendSubdivisionAndPoint(false);
      toast(<Toast title="Não foi possivel salvar a subdivisão" />, {
        type: 'error'
      });
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  return (
    <S.Container>
      <S.Content>
        <S.SimpleButtonContainer>
          <SimpleButton
            text="Voltar"
            onClick={() => {
              setDashboardState('dashboard@harvestmap');
              removeAllInterections();
            }}
          />
        </S.SimpleButtonContainer>
        <S.Title>Edição de Subdivisão</S.Title>
        <S.SubTitle>Edite a subdivisões no mapa ao lado.</S.SubTitle>
      </S.Content>
      <S.ButtonsContainer>
        <Button
          text="Salvar Subdivisão"
          disabled={drawendSubdivisionAndPoint}
          onClick={() => {
            updateSubdivision();
            setDrawendSubdivisionAndPoint(true);
            removeAllInterections();
          }}
        />
        <Button
          text="Cancelar"
          style={{
            background: '#FFF',
            color: '#79AC34',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 5%)'
          }}
          onClick={() => {
            setDashboardState('dashboard@harvestmap');
            removeAllInterections();
          }}
        />
      </S.ButtonsContainer>
    </S.Container>
  );
};

export default RegisterSubdivision;
