import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BarLoader } from 'react-spinners';

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 115%;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.85);
`;

const LoadingMessage = styled.span`
  font-weight: bold;
  text-align: center;
  color: #fff;
`;

const ImageContainer = styled.div`
  img {
    @keyframes bounceIn {
      0% {
        transform: scale(0.8);
      }

      50% {
        transform: scale(1);
      }

      100% {
        transform: scale(0.8);
      }
    }
    animation: bounceIn 1.5s infinite;
  }
`;

const FullLoadingContext = createContext();

const FullLoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setloadingMessage] = useState();
  const [imgUrl, setImgUrl] = useState(
    'https://www.fundacaomt.com.br/images/loader.png'
  );
  const activeLoading = () => setLoading(true);
  const desactiveLoading = () => setLoading(false);

  return (
    <FullLoadingContext.Provider
      value={{ activeLoading, desactiveLoading, setloadingMessage }}
    >
      {children}
      {loading && (
        <LoadingContainer>
          {imgUrl && (
            <ImageContainer>
              <img src={imgUrl} alt="Fundação MT" />
            </ImageContainer>
          )}
          {loadingMessage && <LoadingMessage>{loadingMessage}</LoadingMessage>}
          {loading && <BarLoader color="#A0BD39" loading size={40} />}
        </LoadingContainer>
      )}
    </FullLoadingContext.Provider>
  );
};

function useFullLoading() {
  const context = useContext(FullLoadingContext);

  if (!context) {
    throw new Error('useuseFullLoading must be used within an ModalProvider');
  }

  return context;
}

FullLoadingProvider.propTypes = {
  children: PropTypes.node
};

export { FullLoadingProvider, useFullLoading };
