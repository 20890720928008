import React from 'react';
import { MdClose } from 'react-icons/md';
import { useMap } from '../../hooks/Map';
import * as S from './styled';

const FilterCloudCard = () => {
  const { setControlState, maxClouds, setMaxClouds } = useMap();
  return (
    <S.Container>
      <S.Header>
        <h1>Filtrar Nuvens</h1>
        <button
          type="button"
          onClick={() => {
            setControlState(undefined);
          }}
        >
          <MdClose />
        </button>
      </S.Header>
      <S.ProgressBarContainer>
        <S.ProgressBar
          type="range"
          onChange={(e) => {
            setMaxClouds(e.target.value);
          }}
        />
      </S.ProgressBarContainer>
      <S.PercentLabel>{maxClouds} %</S.PercentLabel>
    </S.Container>
  );
};

export default FilterCloudCard;
