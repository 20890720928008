/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable no-console */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';
import SimpleButton from '../../../../../../components/SimpleButton';
import Toast from '../../../../../../components/Toast';
import InstructionsToast from '../../../../../../components/Toast/Instructions';
import { appConfig } from '../../../../../../config';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import api from '../../../../../../services/api';
import { DashboardContext } from '../../../../index';
import * as S from './styled';

const RegisterFarm = () => {
  const {
    createFarm,
    removeGeometries,
    removeAllInterections,
    testeArr,
    buttonRegisterFarmDisable,
    setButtonRegisterFarmDisable
  } = useMap();
  const { farm, setSelectedFarmId } = useMap();
  const { user, token } = useAuth();
  const { farms, setFarms, setDashboardState } = useContext(DashboardContext);
  const [laboratory, setLaboratory] = useState();
  const navigate = useNavigate();
  const FarmRegisterSchema = Yup.object().shape({
    farm_name: Yup.string().required('Nome da fazenda é obrigatório'),
    contact_name: Yup.string(),
    contact_number: Yup.string()
  });
  const { handleSubmit, register, errors, control } = useForm({
    resolver: yupResolver(FarmRegisterSchema)
  });
  useEffect(() => {
    createFarm();

    toast(
      <InstructionsToast title="Desenhe a área da sua propriedade no mapa">
        <p>
          1. Use o mouse para desenhar a área correspondente à sua propriedade.
          <br />
          2. Após demarcar o polígono no mapa clique em salvar.
        </p>
      </InstructionsToast>,
      {
        type: 'default',
        autoClose: 5000,
        hideProgressBar: true,
        style: { marginTop: '25%' }
      }
    );
  }, []);

  const onSubmit = async (data) => {
    farm.features[0].properties = data;
    farm.features[0].properties.owner_id = `${user.user_id}`;
    farm.features[0].properties.created_by = `${user.user_id}`;
    try {
      const newFarm = await api.post(
        `${appConfig.apiHosts.farm}/Farm/PostFarm`,
        farm,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const { message, data: registeredFarm } = newFarm.data;
      setFarms([...farms, registeredFarm]);
      setSelectedFarmId(newFarm.data.data.features[0].properties.farm_id);
      toast(
        <Toast
          title={message}
          content="Você foi redirecionado para a tela de Fazendas"
        />,
        {
          type: 'success'
        }
      );
      // setDashboardState('dashboard@farm');
      navigate('../farmList');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      toast(
        <Toast
          title="Não foi possível cadastrar a fazenda."
          content="Verifique os dados inseridos no formulário"
        />,
        { type: 'error' }
      );
      removeGeometries();
      createFarm();
    }
  };

  return (
    <S.Container>
      <S.ContainerBackButton>
        <SimpleButton
          text="Voltar"
          onClick={() => {
            // setDashboardState('dashboard@farm');
            removeGeometries();
            removeAllInterections();
            navigate(-1);
          }}
        />
      </S.ContainerBackButton>
      <S.RegisterFarmDialog>
        <h3>Adicionar Fazenda</h3>
        <span>
          Cadastre sua fazenda e comece a usar as funcionalidades da plataforma.
        </span>
      </S.RegisterFarmDialog>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="text"
          name="farm_name"
          placeholder="Nome da fazenda"
          ref={register}
          defaultValue=""
        />
        {errors?.farm_name?.message && (
          <S.InputError>{errors?.farm_name?.message}</S.InputError>
        )}
        <Input
          type="text"
          name="contact_number"
          id="contact_number"
          placeholder="Telefone de contato"
          ref={register}
          defaultValue=""
        />
        <Input
          type="text"
          name="contact_name"
          placeholder="Contato da fazenda"
          ref={register}
          defaultValue=""
        />
        <Button
          text="Cadastrar Fazenda"
          type="submit"
          disabled={buttonRegisterFarmDisable}
        />
      </S.Form>
    </S.Container>
  );
};

export default RegisterFarm;
