import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../../../../components/Button';
import SimpleButton from '../../../../../../components/SimpleButton';
import Toast from '../../../../../../components/Toast';
import { appConfig } from '../../../../../../config';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import api from '../../../../../../services/api';
import { DashboardContext } from '../../../../index';
import * as S from './styled';

const EditRecommendationZone = () => {
  const { setDashboardState } = useContext(DashboardContext);
  const {
    removeAllInterections,
    recommZoneDrawed,
    renderRecommendationZone,
    setSelectedRecommZone,
    selectedRecommZone,
    editRecommendationZone,
    finalizeEditRecommendationZone
  } = useMap();

  const { token, user } = useAuth();
  // eslint-disable-next-line no-console
  const editRecommendationZoneDrawed = async () => {
    const finalizeEditRecommendation = finalizeEditRecommendationZone();

    if (
      finalizeEditRecommendation.features[0].geometry.type !== 'MultiPolygon'
    ) {
      finalizeEditRecommendation.features[0].geometry.type = 'MultiPolygon';
      finalizeEditRecommendation.features[0].geometry.coordinates[0] = [
        ...finalizeEditRecommendation.features[0].geometry.coordinates
      ];
    }

    try {
      await api.put(
        `${appConfig.apiHosts.report}/RecommendationZone/UpdateRecommendationZone`,
        finalizeEditRecommendation,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      toast(<Toast title="Zona de recomendação editada com sucesso" />, {
        type: 'success'
      });

      setDashboardState('dashboard@resultsMap');
    } catch (error) {
      toast(<Toast title="Não foi possivel salvar a subdivisão" />, {
        type: 'error'
      });
    }
  };

  return (
    <S.Container>
      <S.Content>
        <S.SimpleButtonContainer>
          <SimpleButton
            text="Voltar"
            onClick={() => {
              setDashboardState('dashboard@resultsMap');
              removeAllInterections();
            }}
          />
        </S.SimpleButtonContainer>
        <S.Title>Editar Zona de Recomendação</S.Title>
        <S.SubTitle>Edite a zona de recomendação no mapa ao lado.</S.SubTitle>
      </S.Content>
      <S.ButtonsContainer>
        <Button
          text="Salvar Zona de Recomendação"
          onClick={() => {
            editRecommendationZoneDrawed();
            removeAllInterections();
          }}
        />
        <Button
          text="Cancelar"
          className="cancelButton"
          onClick={() => {
            setDashboardState('dashboard@resultsMap');
            removeAllInterections();
          }}
        />
      </S.ButtonsContainer>
    </S.Container>
  );
};

export default EditRecommendationZone;
