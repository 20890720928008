/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SubModuleList } from './SubModuleList';
import { SubModuleForm } from './SubModuleForm';

const SubModuleRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SubModuleList />}>
        <Route path="/new" element={<SubModuleForm type="new" />} />
        <Route path="/edit/:id" element={<SubModuleForm type="edit" />} />
      </Route>
    </Routes>
  );
};

export default SubModuleRouter;
