import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    margin-top: 16px;
    font-size: 16px;
  }

  button {
    margin-bottom: 30px;
  }
`;
