/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useRef, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import Checkbox from 'react-custom-checkbox';
import { useDropzone } from 'react-dropzone';
// import { useFiles } from "../../context/files";
import { CircularProgressbar } from 'react-circular-progressbar';
import { MdOutlineCloudUpload, MdCheck, MdClose, MdError, MdLink, MdMoodBad } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import filesize from 'filesize';
import { toast } from 'react-toastify';
import PuffLoader from 'react-spinners/PuffLoader';
import { useModal } from '../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../index';
import Button from '../../../../../../../../components/Button';
import { useAuth } from '../../../../../../../../hooks/Auth';
import api from '../../../../../../../../services/api';
import storage from '../../../../../../../../services/firebase';
import { appConfig } from '../../../../../../../../config';
import Toast from '../../../../../../../../components/Toast';
import { useMap } from '../../../../../../../../hooks/Map';
import * as S from './styled';

const ModalEditProblem = () => {
  const inputFileRef = useRef(null);
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user, getNotifications } = useAuth();
  const { selectedFarmId } = useMap();
  const {
    selectedManagement,
    uploadedFiles: files,
    deleteFile,
    handleUpload,
    setUploadedFiles,
    updateScreen,
    setUpdateScreen,
    selectedProblem,
    setSelectedProblem,
    listTypeManager,
    setListTypeManager,
    selectTypeProblemList,
    setSelectTypeProblemList,
    selectTypeCultureList,
    setSelectTypeCultureList,
    listPragues
  } = useContext(DashboardContext);

  const ModalEditProblemSchema = Yup.object().shape({
    type_problem: Yup.string().required('Tipo de problema é obrigatório'),
    id_problem: Yup.string().nullable(),
    unit_measurement: Yup.string().required('Unidade de medida é obrigatório'),
    species: Yup.string().nullable(),
    cultivar: Yup.string().nullable(),
    culture: Yup.string().nullable(),
    commit: Yup.string().nullable()
  });

  const { handleSubmit, register, errors, control, reset } = useForm({
    resolver: yupResolver(ModalEditProblemSchema)
  });

  const [loading, setLoading] = useState(false);

  const handleAddInvoiceFiles = async (e) => {
    try {
      const fileData = await storage.ref(`/images/${e.name}`).put(e.file);
      const imageSrc = await fileData.ref.getDownloadURL();

      files.forEach((file) => URL.revokeObjectURL(file.preview));

      return imageSrc;
    } catch (error) {
      console.error(error);
    }
  };

  const FileList = () => {
    if (!files.length) {
      return (
        <span>
          <MdMoodBad
            style={{ marginLeft: '45%', marginTop: 10 }}
            size={24}
            color="#d5d2d2"
          />
        </span>
      );
    }
  };

  const onDrop = useCallback((file) => {
    handleUpload(file);
  }, [handleUpload]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: ['image/jpeg', 'image/pjpeg', 'image/png', 'image/gif'],
    maxFiles: 1,
    multiple: null,
    onDrop,
  });

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return <S.UploadMessage>Arraste uma imagem aqui</S.UploadMessage>;
    }

    if (isDragReject) {
      return (
        <S.UploadMessage type="error">
          Tipo de arquivo não suportado
        </S.UploadMessage>
      );
    }

    return <S.UploadMessage type="success">Solte as imagens aqui</S.UploadMessage>;
  }, [isDragActive, isDragReject]);

  const deleteProblem = async () => {
    setLoading(true);

    try {
      const params = new URLSearchParams([
        ['farm_id', selectedFarmId],
        ['problem_id', selectedProblem.problem_id],
        ['status', 'false']
      ]);
      await api.delete(
        `${appConfig.apiHosts.prague}/Problem/Delete`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast(<Toast title="Problema excluido com sucesso" />, {
        type: 'success'
      });

      setUpdateScreen(true);
      closeModal();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast(<Toast title="Não foi possivel excluir o problema" />, {
        type: 'error'
      });
      console.error(error);
    }
  };

  const getTypeProblem = async () => {
    const type_id = listTypeManager.filter((v) => v.name === 'Problema')[0].type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterProblemByActive = response.data.data.values.filter((v) => v.status === true);
      setSelectTypeProblemList(filterProblemByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeCulture = async () => {
    const type_id = listTypeManager?.filter((v) => v?.name === 'Culturas')[0]?.type_Id;

    const params = new URLSearchParams([['type_id', type_id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterProblemByActive = response.data.data.values.filter((v) => v.status === true);
      setSelectTypeCultureList(filterProblemByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    let imageUrl;
    if (files.length > 0) {
      imageUrl = await handleAddInvoiceFiles(files[files.length - 1]);
    }

    const editedProblem = {
      problemtype_id: data.type_problem,
      measurement_unit: data.unit_measurement,
      kind: data.species,
      plant: data.cultivar,
      crop_type_id: data.culture,
      image: imageUrl || selectedProblem.image,
      note: data.commit
    };

    const params = new URLSearchParams([
      ['farm_id', selectedFarmId],
      ['problem_id', selectedProblem.problem_id]
    ]);

    try {
      const response = await api.put(
        `${appConfig.apiHosts.prague}/Problem/Update`,
        editedProblem,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      toast(<Toast title="Problema editado com sucesso" />, {
        type: 'success'
      });

      setSelectedProblem('');
      setUpdateScreen(true);
      closeModal();
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);

      toast(<Toast title="Não foi possível editar o problema" />, {
        type: 'error'
      });
    }
  };

  // useEffect(() => {
  //   getDataProblem();
  // }, [selectedProblem]);

  useEffect(() => {
    setUploadedFiles([]);
    getTypeCulture();
    getTypeProblem();
  }, []);

  useEffect(() => {
    if (updateScreen) {
      setUploadedFiles([]);
      getTypeCulture();
      getTypeProblem();
    }
  }, [updateScreen]);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.Title>Editar Problema</S.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <S.InputWrapper>
              <S.InputContainer>
                <Controller
                  name="type_problem"
                  control={control}
                  defaultValue={selectedProblem?.problemtype_id}
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Tipo</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                        error={!!errors?.type_problem?.message}
                      >
                        {selectTypeProblemList?.map((prob) => (
                          <MenuItem value={prob.value_Id} key={prob.value_Id}>{prob.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.type_problem?.message && (
                  <S.InputError>{errors?.type_problem?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <span>ID (Gerado Automaticamente)</span>
                <Controller
                  name="id_problem"
                  control={control}
                  defaultValue={selectedProblem?.problem_id}
                  render={({ onChange, value, }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      disabled
                      style={{ backgroundColor: '#EFEFEF' }}
                    />
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.InputContainer>
                <span>Unidade de Medida</span>
                <Controller
                  name="unit_measurement"
                  control={control}
                  defaultValue={selectedProblem?.measurement_unit}
                  render={({ onChange, value, }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                      error={!!errors?.unit_measurement?.message}
                    />
                  )}
                />
                {errors?.unit_measurement?.message && (
                  <S.InputError>{errors?.unit_measurement?.message}</S.InputError>
                )}
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="species"
                  control={control}
                  defaultValue={selectedProblem?.kind}
                  render={({ onChange, value }) => (
                    <FormControl className="selectStyle">
                      <span>Espécie</span>
                      <Select
                        value={value}
                        onChange={onChange}
                        size="small"
                        error={!!errors?.species?.message}
                      >
                        {listPragues?.map((prague) => (
                          <MenuItem value={prague.prague_id} key={prague.prague_id}>{prague.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors?.species?.message && (
                  <S.InputError>{errors?.species?.message}</S.InputError>
                )}
              </S.InputContainer>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.InputContainer>
                <span>Cultivar Resistente</span>
                <Controller
                  name="cultivar"
                  control={control}
                  defaultValue={selectedProblem?.plant}
                  render={({ onChange, value, }) => (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </S.InputContainer>
              <S.InputContainer>
                <Controller
                  name="culture"
                  control={control}
                  defaultValue={selectedProblem.crop_type_id}
                  render={({ onChange, value, }) => (
                    <FormControl className="selectStyle">
                      <span>Cultura</span>
                      <Select
                        placeholder="Selecione..."
                        value={value}
                        onChange={onChange}
                        size="small"
                      >
                        {selectTypeCultureList?.map((cult) => (
                          <MenuItem value={cult.value_Id} key={cult.value_Id}>{cult.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>
            <S.InputWrapper>
              <S.PhotoContainer>
                <span style={{ marginBottom: '10px' }}>Foto</span>
                <div className="uploadFile">
                  {files.length <= 0 ? (
                    <S.DropContainer {...getRootProps()}>
                      <img src={selectedProblem.image} />
                      <input accept="image/*" maxFiles={1} {...getInputProps()} />
                    </S.DropContainer>
                  ) : (
                    <S.DropContainer style={{ cursor: 'default', border: 'none' }}>
                      {files.map((uploadedFile) => (
                        <S.ContainerList
                          style={{
                            backgroundImage: `url(${uploadedFile.preview})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'left top'
                          }}
                          className="uploadedBox"
                        >
                          <li key={uploadedFile.id}>
                            <S.FileInfo>
                              {/* <S.Preview src={uploadedFile.preview} /> */}
                              <div>
                                <strong>{uploadedFile.name}</strong>
                                <span>
                                  {uploadedFile.readableSize}{' '}
                                </span>
                              </div>
                              <button onClick={(e) => deleteFile(files.id)}>
                                <MdClose />
                              </button>
                            </S.FileInfo>

                            <div>
                              {!uploadedFile.uploaded && !uploadedFile.error && (
                                <CircularProgressbar
                                  styles={{
                                    root: { width: 24 },
                                    path: { stroke: '#7159c1' },
                                  }}
                                  strokeWidth={10}
                                  text={String(uploadedFile.progress)}
                                  value={uploadedFile.progress || 0}
                                />
                              )}

                              {uploadedFile.url && (
                                <a
                                  href={uploadedFile.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <MdLink style={{ marginRight: 8 }} size={24} color="#222" />
                                </a>
                              )}
                              {uploadedFile.error && <MdError size={24} color="#e57878" />}
                            </div>
                          </li>
                        </S.ContainerList>
                      ))}
                    </S.DropContainer>
                  )}
                </div>
              </S.PhotoContainer>

              <S.InputContainer style={{ height: 'auto' }}>
                <span style={{ marginBottom: '10px' }}> Observações</span>
                <Controller
                  name="commit"
                  control={control}
                  defaultValue={selectedProblem?.note}
                  render={({ onChange, value, }) => (
                    <S.CommitArea
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </S.InputContainer>
            </S.InputWrapper>
            <S.ButtonWrapper>
              <Button
                text="Cancelar Edição"
                className="cancelBtn"
                onClick={closeModal}
              />
              <S.CheckboxContainer>
                <Button
                  text={loading ? (
                    <>
                      <PuffLoader
                        color="#79ac34"
                        loading={loading}
                        size={30}
                      />
                    </>
                  ) : 'Salvar'}
                  className="saveBtn"
                  disabled={loading}
                  type="submit"
                />
              </S.CheckboxContainer>
              <Button
                text={loading ? (
                  <>
                    <PuffLoader
                      color="#79ac34"
                      loading={loading}
                      size={30}
                    />
                  </>
                ) : 'Excluir'}
                disabled={loading}
                className="delBtn"
                onClick={() => deleteProblem()}
              />
            </S.ButtonWrapper>
          </S.FormContainer>
        </form>
      </S.Container>
    </Modal>
  );
};

export default ModalEditProblem;
