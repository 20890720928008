/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from '../../../../../../../../hooks/Auth';
import OrganizacaoApi from '../../../../../../../../APi/FarmApi/OrganizacaoApi';
import {
  OrganizacaoForm,
  OrganizacaoFormProps
} from '../../utils/organizacao.types';

export const useOrganizacaoForm = ({ type }: OrganizacaoFormProps) => {
  const {
    token,
    user: { organizacao_id }
  } = useAuth();
  const organizacaoApi = useMemo(() => new OrganizacaoApi(token), []);
  const [formValid, setFormValid] = useState<boolean>(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [statusCode, setStatusCode] = useState<number>(0);

  const FormValidationSchema = Yup.object().shape({
    name: Yup.string().required()
  });

  const methods = useForm<OrganizacaoForm>({
    defaultValues: {
      id: '',
      name: '',
      active: false
    },

    resolver: yupResolver(FormValidationSchema)
  });
  const organizacaoSuccess = (data: OrganizacaoForm) => {
    methods.reset({
      ...methods.getValues(),
      ...data
    });
  };
  const organizacaoquery = useQuery({
    queryKey: [organizacaoApi.getOrganizacaoById],
    queryFn: async () => organizacaoApi.RetornarOrganizacao(id?.toString()),
    onSuccess: (data) => organizacaoSuccess(data.data.data)
  });

  const onSelectActivated = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    methods.setValue('active', checked);
  };

  useEffect(() => {
    setFormValid(!methods.control.getValues('name'));
  }, [methods.control.watchInternal()]);

  const createOrganizacao = useMutation({
    mutationFn: async (form: OrganizacaoForm) =>
      await organizacaoApi.createOrganizacao(form),
    onSuccess: async (data) => {
      setStatusCode(data.data.status);
      navigate(-1);
      await queryClient.invalidateQueries(organizacaoApi.getOrganizacao);
    }
  });

  const updateOrganizacao = useMutation({
    mutationFn: async (form: OrganizacaoForm) =>
      await organizacaoApi.updateOrganizacao(form),
    onSuccess: async (data) => {
      setStatusCode(data.data.status);
      navigate(-1);
      await queryClient.invalidateQueries(organizacaoApi.getOrganizacao);
    }
  });

  const handleSubmit = methods.handleSubmit((form: OrganizacaoForm) => {
    form.organizacao_id = organizacao_id;
    if (type === 'new') {
      createOrganizacao.mutate(form);
    } else {
      form.id = id.toString();
      updateOrganizacao.mutate(form);
    }
  });

  const title: Record<string, string> = {
    new: 'Adicionar organização',
    edit: 'Editar organização'
  };
  const mensageSuccess: Record<string, { title: string; content: string }> = {
    new: {
      title: 'Organização criada com sucesso',
      content: 'Organização foi criada com sucesso'
    },
    edit: {
      title: 'Organização atualizada com sucesso',
      content: 'Organização foi atualizada com sucesso'
    }
  };
  return {
    form: {
      methods,
      handleSubmit,
      control: methods.control,
      isValid: formValid
    },
    onSelectActivated,
    title,
    statusCode,
    mensageSuccess
  };
};
