/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import { ResearchField } from '../../../../../../../components/ResearchField';
import { useModal } from '../../../../../../../hooks/Modal';
import Toast from '../../../../../../../components/Toast';
import { Profile } from '../../Profile/ProfileList/utils/profile.types';
import { User } from '../../Users/utils/users.types';
import { useUserProfileListFormRequest } from './hooks/useUserProfileListFormRequest';
import * as s from './utils/userProfile.style';
import useUserProfileForm from './hooks/useUserProfileForm';
import TableCreate from '../../../../../../../components/TableCreate/TableCreate';

export interface UserProfileFormProps {
  type: 'New' | 'Edit';
}

const UserProfileForm: FC<UserProfileFormProps> = ({ type }) => {
  const { request, filter } = useUserProfileListFormRequest();
  const { form, mensage } = useUserProfileForm(type);
  const { modalCreatUserStyles } = useModal();
  const navigate = useNavigate();

  useEffect(() => {
    if (mensage) {
      toast(<Toast title="Atrelamento feito com sucesso" content="" />, {
        type: 'success'
      });
    }
  }, [mensage]);

  const userColumn: HeadCell<User>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'nome do usuário'
    }
  ];

  const profileColumn: HeadCell<Profile>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Perfil'
    }
  ];

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen
      style={{
        ...modalCreatUserStyles,
        ...{
          content: {
            width: '66%',
            height: '79% ',
            transform: 'translate(15vw, 4vw)'
          }
        }
      }}
    >
      <s.container>
        <h1>Atrelamento de usuários com Perfil </h1>
        <s.containerTable>
          <Controller
            name="user_id"
            control={form.methods.control}
            render={({ onChange }) => (
              <div>
                <div style={{ paddingLeft: '2%' }}>
                  <ResearchField
                    onChange={filter.setUseFilter}
                    placeholder="Filtro de usuários"
                  />
                </div>

                <TableCreate
                  rows={request.itemsUser}
                  HeadCell={userColumn}
                  IconCreate={AddIcon}
                  idStartSelectRedy={form.isRedy}
                  idStartSelect={form.user ? [form.user] : []}
                  title="Usuários"
                  checkboxDisabled={type === 'Edit'}
                  onChange={(selected) => {
                    onChange(selected[0]);
                  }}
                  checkBox
                />
              </div>
            )}
          />
          <Controller
            name="user_profile_id"
            control={form.methods.control}
            render={({ onChange }) => (
              <div>
                <div style={{ paddingLeft: '2%' }}>
                  <ResearchField
                    onChange={filter.setFilterProfile}
                    placeholder="Filtro de perfil"
                  />
                </div>

                <TableCreate
                  rows={request.itemsProfiles}
                  HeadCell={profileColumn}
                  IconCreate={AddIcon}
                  idStartSelectRedy={form.isRedy}
                  idStartSelect={form.profile ? [form.profile] : []}
                  title="Perfis"
                  onChange={(selected) => {
                    onChange(selected[0]);
                  }}
                  checkBox
                />
              </div>
            )}
          />
        </s.containerTable>
        <s.containerbotton>
          <button
            type="button"
            className="containerbotton__bottoncancel"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </button>
          <button
            type="button"
            className={`containerbotton__bottonregister${
              form.isValid ? '--disabled' : ''
            }`}
            onClick={form.submit}
          >
            Registrar
          </button>
        </s.containerbotton>
      </s.container>
    </Modal>
  );
};

export default UserProfileForm;
