/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { MdAddCircle, MdModeEdit, MdDelete, MdCheck } from 'react-icons/md';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from '@mui/material';
import { toast } from 'react-toastify';
import Checkbox from 'react-custom-checkbox';
import { DashboardContext } from '../../../../index';
import * as S from './styled';
import ModalAddItem from './components/ModalAddITem';
import ModalEditItem from './components/ModalEditITem';
import ModalAddDevelopmentCulture from './components/ModalAddDevelopmentCulture';
import ModalEditDevelopmentCulture from './components/ModalEditDevelopmentCulture';
import { useModal } from '../../../../../../hooks/Modal';
import { appConfig } from '../../../../../../config';
import api from '../../../../../../services/api';
import { useAuth } from '../../../../../../hooks/Auth';
import Toast from '../../../../../../components/Toast';

const Management = () => {
  const {
    selectedManagement,
    setSelectedManagement,
    setDashboardState,
    dashboardState,
    itemTypeSelected,
    setItemTypeSelected,
    updateScreen,
    setUpdateScreen,
    currentModal,
    setCurrentModal,
    selectedAdmItem,
    setSelectedAdmItem,
    selectTypeCultureList,
    setSelectTypeCultureList,
    setImageCultures
  } = useContext(DashboardContext);
  const { closeModal, openedModal, openModal } = useModal();
  const { token, user, getNotifications } = useAuth();

  const [listType, setListType] = useState([]);
  const [tableContent, setTableContent] = useState();
  const [typeName, setTypeName] = useState('');
  const [selectedPrague, setSelectedPrague] = useState();

  const getTypeList = async () => {
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Type/List`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setListType(response.data.data.types);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeCulture = async () => {
    const params = new URLSearchParams([['type_id', 'c826052d-3a59-4398-aec1-81193ccd2e42']]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectTypeCultureList(response.data.data.values);
    } catch (error) {
      console.error(error);
    }
  };

  const getValueList = async (typeId) => {
    setTableContent([]);
    const params = new URLSearchParams([['type_id', typeId]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const sortResponse = response.data.data.values.sort((x, y) => {
        return x.status - y.status;
      });
      setTableContent(sortResponse.reverse());
      setUpdateScreen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getCultureDevelopmentListByType = async (typeId) => {
    setTableContent([]);
    const params = new URLSearchParams([['type_id', typeId]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/CultureDevelopment/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const sortResponse = response.data.data.cultureDevelopments.sort((x, y) => {
        return x.status - y.status;
      });
      setTableContent(sortResponse.reverse());
      setUpdateScreen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeItemSelected = async (event) => {
    setItemTypeSelected(event.target.value);
    const filterNameSelected = listType.filter((item) => item.type_Id === event.target.value);
    setSelectedManagement(filterNameSelected[0].name);
    if (event.target.value === '8d0f3ad4-29bc-43dd-b680-88424d413c0c') {
      getCultureDevelopmentListByType(event.target.value);
    } else {
      getValueList(event.target.value);
    }
  };

  const deleteUserAdmin = async (email) => {
    const params = new URLSearchParams([
      ['email', email],
      ['adm', false]
    ]);
    try {
      await api.get(
        `${appConfig.apiHosts.auth}/UserFundacao/ChangeProfileUser`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const deleteValue = async (valueId, email) => {
    const params = new URLSearchParams([
      ['type_id', itemTypeSelected],
      ['value_id', valueId],
      ['status', false]
    ]);
    try {
      await api.delete(
        `${appConfig.apiHosts.manager}/Value/Delete`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setUpdateScreen(true);

      toast(<Toast title="Removido com sucesso" />, {
        type: 'success'
      });

      if (typeName === 'Administradores') {
        deleteUserAdmin(email);
      }
    } catch (error) {
      console.error(error);
      toast(<Toast title="Não foi possível remover" />, {
        type: 'error'
      });
    }
  };

  const deleteCultureDevelopment = async (cultureDevelopmentId) => {
    const params = new URLSearchParams([
      ['type_id', itemTypeSelected],
      ['culture_development_id', cultureDevelopmentId],
      ['status', false]
    ]);
    try {
      await api.delete(
        `${appConfig.apiHosts.manager}/CultureDevelopment/Delete`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setUpdateScreen(true);

      toast(<Toast title="Removido com sucesso" />, {
        type: 'success'
      });
    } catch (error) {
      console.error(error);
      toast(<Toast title="Não foi possível remover" />, {
        type: 'error'
      });
    }
  };

  const updateStatusUser = async (item, checked) => {
    const params = new URLSearchParams([
      ['email', item.name],
      ['adm', checked]
    ]);
    try {
      await api.get(
        `${appConfig.apiHosts.auth}/UserFundacao/ChangeProfileUser`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const urlParams = new URLSearchParams([['valueId', item.value_Id]]);
      let newValue = {};
      newValue = {
        name: item.name,
        status: checked,
        type_id: itemTypeSelected
      };

      const response = await api.put(
        `${appConfig.apiHosts.manager}/Value/Update`,
        newValue,
        {
          params: urlParams,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (checked === true) {
        toast(<Toast title="Item ativado com sucesso!" />, {
          type: 'success'
        });
      } else {
        toast(<Toast title="Item desativado com sucesso!" />, {
          type: 'success'
        });
      }

      getValueList(itemTypeSelected);
    } catch (error) {
      console.error(error);
      toast(<Toast title="Não foi possível alterar o status" />, {
        type: 'error'
      });
    }
  };

  const updateValue = async (item, checked) => {
    const params = new URLSearchParams([['valueId', item.value_Id]]);
    let newValue = {};
    newValue = {
      name: item.name,
      photo: item.photo,
      status: checked,
      type_id: itemTypeSelected
    };

    try {
      const response = await api.put(
        `${appConfig.apiHosts.manager}/Value/Update`,
        newValue,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (checked === true) {
        toast(<Toast title="Item ativado com sucesso!" />, {
          type: 'success'
        });
      } else {
        toast(<Toast title="Item desativado com sucesso!" />, {
          type: 'success'
        });
      }

      getValueList(itemTypeSelected);
    } catch (error) {
      console.error(error);
      toast(<Toast title="Não foi possível alterar o status" />, {
        type: 'error'
      });
    }
  };

  useEffect(() => {
    getTypeList();
    getTypeCulture();
  }, []);

  useEffect(() => {
    if (updateScreen) {
      if (itemTypeSelected === '8d0f3ad4-29bc-43dd-b680-88424d413c0c') {
        getCultureDevelopmentListByType(itemTypeSelected);
      } else {
        getValueList(itemTypeSelected);
      }
    }
  }, [updateScreen]);

  return (
    <S.Container>
      <FormControl className="selectStyle">
        <InputLabel id="select_management">Selecione o Tipo de Administração</InputLabel>
        <Select
          labelId="select_management"
          label="Selecione o Tipo de Administração"
          placeholde
          name="select_management"
          value={itemTypeSelected}
          onChange={(e) => {
            handleChangeItemSelected(e);
            setTypeName(listType.filter((v) => v.type_Id === e.target.value)[0].name);
          }}
        >
          {listType?.map((item) => (
            <MenuItem value={item.type_Id} key={item.type_Id}>{item.name}</MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedManagement && (
        <S.Content>
          <S.TableTitle>
            {selectedManagement}
          </S.TableTitle>
          <hr />
          <S.AddBtnContainer>
            <S.AddButton onClick={() => {
              if (itemTypeSelected === '8d0f3ad4-29bc-43dd-b680-88424d413c0c') {
                setCurrentModal('modalAddDevelopmentCulture');
              } else {
                setCurrentModal('modalAddItem');
              }
              openModal();
            }}
            >
              <MdAddCircle />
            </S.AddButton>
          </S.AddBtnContainer>
          <TableContainer style={{ borderRadius: '8px', height: '369px', overflow: 'auto' }}>
            <Table size="small" cellspacing="0">
              <TableHead>
                <TableRow style={{ backgroundColor: '#EFEFEF', fontWeight: 'bold' }}>
                  <TableCell align="center" style={{ fontWeight: 'bold', borderLeft: 'none' }}>Ativar/Desativar</TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>Valor</TableCell>
                  {itemTypeSelected === '8d0f3ad4-29bc-43dd-b680-88424d413c0c' && <TableCell align="center" style={{ fontWeight: 'bold' }}>Cultura</TableCell>}
                  {itemTypeSelected === '8d0f3ad4-29bc-43dd-b680-88424d413c0c' && <TableCell align="center" style={{ fontWeight: 'bold' }}>Desenvolvimento da cultura</TableCell>}
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>Ativo</TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemTypeSelected === '8d0f3ad4-29bc-43dd-b680-88424d413c0c' && tableContent?.map((row) => (
                  <TableRow>
                    <TableCell align="center" style={{ borderLeft: 'none' }}>
                      <S.DeletePointSelectedButton>
                        <Checkbox
                          className="table__checkbox"
                          checked={row.status === true || row.active === true}
                          onChange={(e) => {
                            if (typeName === 'Administradores') {
                              updateStatusUser(row, e);
                            } else {
                              updateValue(row, e);
                            }
                          }}
                          icon={(
                            <MdCheck
                              color="#507919"
                              size={20}
                              style={{ margin: 0 }}
                            />
                          )}
                          borderColor="#A0BD39"
                          borderRadius={2}
                          borderWidth={1}
                          size={14}
                        />
                      </S.DeletePointSelectedButton>
                    </TableCell>
                    <TableCell align="center">
                      {row.type_name}
                    </TableCell>
                    <TableCell align="center">
                      {row.value_name}
                    </TableCell>
                    <TableCell align="center">
                      {row.description}
                    </TableCell>
                    <TableCell align="center">
                      {row.active === true ? 'Ativo' : 'Inativo'}
                    </TableCell>
                    <TableCell align="center">
                      <S.ButtonWrapper>
                        {typeName !== 'Administradores' && (
                          <button
                            type="button"
                            className="actionBtn"
                            onClick={() => {
                              setSelectedAdmItem(row);
                              setCurrentModal('modalEditDevelopmentCulture');
                              openModal();
                            }}
                          >
                            {/* <span style={{ color: '#00F' }}>Editar</span> */}
                            <MdModeEdit style={{ color: '#00F' }} />
                          </button>
                        )}
                        <button
                          type="button"
                          className="actionBtn"
                          onClick={() => {
                            deleteCultureDevelopment(row.culture_development_id);
                          }}
                        >
                          {/* <span style={{ color: '#f00' }}>Deletar</span> */}
                          <MdDelete style={{ color: '#f00' }} />
                        </button>
                      </S.ButtonWrapper>
                    </TableCell>
                  </TableRow>
                ))}
                {itemTypeSelected !== '8d0f3ad4-29bc-43dd-b680-88424d413c0c' && tableContent?.map((row) => (
                  <TableRow>
                    <TableCell align="center" style={{ borderLeft: 'none' }}>
                      <S.DeletePointSelectedButton>
                        <Checkbox
                          className="table__checkbox"
                          checked={row.status === true}
                          onChange={(e) => {
                            if (typeName === 'Administradores') {
                              updateStatusUser(row, e);
                            } else {
                              updateValue(row, e);
                            }
                          }}
                          icon={(
                            <MdCheck
                              color="#507919"
                              size={20}
                              style={{ margin: 0 }}
                            />
                          )}
                          borderColor="#A0BD39"
                          borderRadius={2}
                          borderWidth={1}
                          size={14}
                        />
                      </S.DeletePointSelectedButton>
                    </TableCell>
                    <TableCell align="center">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">
                      {row.status === true ? 'Ativo' : 'Inativo'}
                    </TableCell>
                    <TableCell align="center">
                      <S.ButtonWrapper>
                        {typeName !== 'Administradores' && (
                          <button
                            type="button"
                            className="actionBtn"
                            onClick={() => {
                              setSelectedAdmItem(row);
                              setCurrentModal('modalEditItem');
                              if (row.photo) {
                                setImageCultures([row]);
                              }
                              openModal();
                            }}
                          >
                            {/* <span style={{ color: '#00F' }}>Editar</span> */}
                            <MdModeEdit style={{ color: '#00F' }} />
                          </button>
                        )}
                        <button
                          type="button"
                          className="actionBtn"
                          onClick={() => {
                            deleteValue(row.value_Id, row.name);
                          }}
                        >
                          {/* <span style={{ color: '#f00' }}>Deletar</span> */}
                          <MdDelete style={{ color: '#f00' }} />
                        </button>
                      </S.ButtonWrapper>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </S.Content>
      )}
      {
        dashboardState === 'dashboard@dataManagement'
          && currentModal !== 'modalEditDevelopmentCulture'
          && currentModal !== 'modalAddDevelopmentCulture'
          && currentModal !== 'modalEditItem'
          && (
          <ModalAddItem />
        )
      }
      {currentModal === 'modalAddItem' && <ModalAddItem />}
      {currentModal === 'modalEditItem' && <ModalEditItem />}
      {currentModal === 'modalAddDevelopmentCulture' && <ModalAddDevelopmentCulture />}
      {currentModal === 'modalEditDevelopmentCulture' && <ModalEditDevelopmentCulture />}
    </S.Container>
  );
};

export default Management;
