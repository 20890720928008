/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../../../../../../../../hooks/Auth';
import ModuleProfileApi from '../../../../../../../../APi/FarmApi/ModuleProfileApi';
import { UserProfile } from '../../utils/moduleProfile.types';
import ProfileApi from '../../../../../../../../APi/FarmApi/ProfileApi';

export const useModuleProfileListRequest = () => {
  const {
    token,
    user: { organizacao_id }
  } = useAuth();
  const moduleProfileApi = useMemo(() => new ProfileApi(token), []);
  const [filter, setFilter] = useState<string>('');

  function moduleProfileMap(moduleProfile: any, index): UserProfile {
    return {
      idEndity: moduleProfile.id,
      id: index + 1,
      name: moduleProfile.name,
      nameAggregate: moduleProfile.nameaggregate,
      idaggregate: moduleProfile.idaggregate,
      acronym: moduleProfile.acronym
    };
  }

  const moduleProfileQuery = useQuery({
    queryKey: [
      moduleProfileApi.ListaAtrelarPerfilModuloParaTabela,
      { filter, organizacao_id }
    ],
    queryFn: async () =>
      moduleProfileApi.ListarAtrelarPerfilModulo(filter, '', organizacao_id)
  });

  return {
    request: {
      items: moduleProfileQuery.data?.data.data.map(moduleProfileMap) ?? []
    },
    setFilter
  };
};
