import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import FarmApi from '../../../../../../../../APi/FarmApi/FarmApi';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { Farm } from '../../../Farm/FarmList/utils/farmList.types';
import UserAPI from '../../../../../../../../APi/FarmApi/UserAPI';
import { UserFarmForm } from '../utils/userFarmsForm.types';

const useFarmListRequest = () => {
  const { user, token } = useAuth();
  const farmApi = useMemo(() => new FarmApi(token), []);
  const userApi = useMemo(() => new UserAPI(token), []);
  const [filterUser, setFilterUser] = useState<string>('');
  const [filterFarm, setFilterFarm] = useState<string>('');

  const farmDate = useQuery({
    queryKey: [farmApi.getFarm],
    queryFn: async () => farmApi.GetFarms(user.user_id)
  });

  const userDate = useQuery({
    queryKey: [userApi.getUser, { filterUser }],
    queryFn: async () => userApi.UserList(filterUser)
  });

  function farmMap(date: any): Farm {
    const farm = date.features[0].properties;
    return {
      id: farm.farm_id,
      nameFarm: farm.farm_name
    };
  }

  function userMap(User: any) {
    return {
      id: User.user_id,
      name: User.fullname
    };
  }

  return {
    request: {
      itemsFarm: farmDate.data?.data.data.map(farmMap) ?? [],
      itemsUser: userDate.data?.data.data.map(userMap) ?? []
    },
    filter: {
      setFilterUser,
      setFilterFarm
    }
  };
};

export default useFarmListRequest;
