import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  border: 1px solid #ffffff;
  grid-area: 2 / 2 / 3 / 3;
`;

export const Controls = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;

  button {
    background: #fff;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 5px;

    &:hover {
      background: ${shade(0.2, '#FFF')};
    }
  }

  .true,
  .buttonActived {
    background: #79ac34;
    svg {
      color: #fff;
    }
  }
`;

export const ContainerLegend = styled.div`
  ${(props) =>
    props.numberOfMaps === 4 &&
    css`
      .legendImgP {
        width: 65%;
        max-width: 520px;
        padding: 10px 10px !important;
        overflow-x: auto !important;
      }

      .legendImgMo {
        width: 65%;
        max-width: 525px;
        padding: 10px 10px 0px !important;
        overflow-x: auto !important;
      }
    `}
`;

export const LegendResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  width: 20%;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  margin: 0px 0px 30px 60px;
  bottom: 20px;
  min-width: 350px;
  max-height: 60%;
  overflow: auto;

  .row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const LegendResultsBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;

  ${(props) =>
    props.minimized &&
    css`
      display: none;
    `}
`;

export const LegendResultsValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  position: relative;
  margin-bottom: 10px;
`;

export const LegendResultsValueColor = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 5px;
  margin-left: 3px;

  ${(props) =>
    props.colored &&
    css`
      background: ${props.colored};
    `}
`;

export const LegendResultsDescription = styled.span`
  font-size: 0.7rem;
`;

export const LegendResultsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background: transparent;
    border: none;
    margin-bottom: 5px;
  }

  h3 {
    font-size: 0.8rem;
    color: #444444;
    margin-bottom: 20px;
    font-weight: normal;
  }
`;

export const ButtonExibitionContainer = styled.div`
  position: absolute;
  margin: 0px 10px 0px 0px;
  right: 0;
  bottom: 0;
  padding: 0px;
  clear: both;
`;

export const ButtonExibition = styled.button`
  background-color: #ffffff;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  position: relative;
  right: 0;
  padding: 0;
  margin: 0;
  border-radius: 8px 8px 0px 0px;
`;

export const CardImportFile = styled.button`
  margin-top: 20px;
  margin-left: 20px;
  width: 250px;
  label {
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
  display: flex;
  padding: 5px;
  height: 40px;
  border: none;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
  align-items: center;
  justify-content: space-around;

  span {
    color: #79ac34;
    font-size: 0.8rem;
    font-weight: bold;
  }

  input[type='file'] {
    display: none;
  }
`;

export const TextExibitionContainer = styled.div`
  position: absolute;
  margin: 0px 10px 0px 0px;
  right: 0;
  bottom: 0;
  padding: 0px;
`;

export const TextExibition = styled.span`
  background-color: #ffffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 25px;
  position: relative;
  padding: 5px;
  padding-bottom: 0px;
  font-size: 12px;
  color: #000;
  font-weight: bold;
  border-radius: 8px 8px 0px 0px;
`;

export const LegendRecommContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  width: auto;
  height: auto;
  background: #fff;
  border-radius: 10px;

  position: absolute;
  margin: 0px 0px 12px 10px;
  top: 10px;
  left: 0;

  h3 {
    font-size: 0.8rem;
    color: #444444;
    margin-bottom: 10px;
    font-weight: normal;
  }

  .row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const LegendRecommBody = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 100%;

  /* ${(props) =>
    props.minimized &&
    css`
      display: none;
    `} */
`;

export const LegendRecommValueColor = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 5px;
  border: 1px solid #ddd;

  ${(props) =>
    props.colored &&
    css`
      background: ${props.colored};
    `}
`;

export const LegendRecommValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
`;

export const TableResultsContainer = styled.div`
  width: 100%;
  border: 0;
  background-color: transparent;

  ${(props) =>
    props.show &&
    css`
      position: absolute;
      z-index: 999;
      bottom: 0;
      height: 350px;
      border: 0;

      .tableResult {
        position: absolute;
        margin: 0px 10px 0px 0px;
        right: 0;
        top: 0;
        padding: 0px;
      }

      .resultContainer {
        margin-top: 40px;
        background-color: #fff;
        border: none;
      }
    `}
`;
