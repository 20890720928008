/* eslint-disable no-console */
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  createContext
} from 'react';
import { QRCode } from 'react-qrcode-logo';
import { MdPrint } from 'react-icons/md';
import { toast } from 'react-toastify';
import { IndeterminateCheckBox } from '@mui/icons-material';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField
} from '@mui/material';
import api from '../../../../../../services/api';
import { useAuth } from '../../../../../../hooks/Auth';
import { DashboardContext } from '../../../..';
import SimpleButton from '../../../../../../components/SimpleButton';
import logo from '../../../../../../assets/icon.png';
import Button from '../../../../../../components/Button';
import * as S from './styled';
import { useMap } from '../../../../../../hooks/Map';
import ModalPrint from '../ModalPrint';
import { useModal } from '../../../../../../hooks/Modal';
import Toast from '../../../../../../components/Toast';
import { appConfig } from '../../../../../../config';

export const SendLaboratoryContext = createContext({});

const SendLaboratory = () => {
  const { setDashboardState, selectedHarvestMap } =
    useContext(DashboardContext);
  const { selectedFarmId } = useMap();
  const { openModal } = useModal();

  const [laboratory, setLaboratory] = useState('');
  const [laboratoriesList, setLaboratoriesList] = useState([]);
  const [subdivisions, setSubdivisions] = useState([]);
  const [sendSubdivisions, setSendSubdivisions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token, user } = useAuth();
  const getSubdivisions = async () => {
    try {
      if (!selectedFarmId) {
        return toast(
          <Toast
            title="Por favor selecione uma fazenda"
            content="Para que as subdivisões sejam carregadas é necessário selecionar uma fazenda"
          />,
          { type: 'warning' }
        );
      }
      setLoading(true);
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSubdivisions(response.data.data);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getLaboratory = async () => {
    try {
      const response = await api.get(
        `${appConfig.apiHosts.report}/Laboratory/GetActiveLaboratoryList`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setLaboratoriesList(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddSubdivisionToSendLaboratory = (subdivisionId) => {
    const subdivisionExists = sendSubdivisions.filter(
      (value) => subdivisionId === value
    )[0];
    if (subdivisionExists) {
      const newSendToLaboratory = sendSubdivisions.filter(
        (value) => subdivisionExists !== value
      );
      return setSendSubdivisions(newSendToLaboratory);
    }
    setSendSubdivisions([...sendSubdivisions, subdivisionId]);
  };

  useEffect(() => {
    getLaboratory();
    getSubdivisions();
  }, []);

  return (
    <SendLaboratoryContext.Provider
      value={{ sendSubdivisions, setSendSubdivisions, subdivisions }}
    >
      <S.Container>
        <SimpleButton
          text="Voltar"
          onClick={() => {
            setDashboardState('dashboard@harvestmap');
          }}
        />
        <span>Enviar para o laboratório</span>
        <S.SubdivisionsContainer>
          {subdivisions.map(
            (subdivision) =>
              subdivision.features[0].properties.status_id ===
                '51209056-d5ce-4a27-a757-302e9a1a184a' && (
                <S.Subdivision
                  key={subdivision.features[0].properties.subdivision_id}
                >
                  <S.SubdivisionBody>
                    <S.SubdivisionTitle>
                      {subdivision.features[0].properties.description}
                    </S.SubdivisionTitle>
                    <S.SubdivisionInformations>
                      <S.SubdivisionInformation>
                        Camada:{' '}
                        {
                          subdivision.features[0].properties
                            .depthDataCollectionPointList[0]
                        }
                      </S.SubdivisionInformation>
                      <S.SubdivisionInformation>
                        Status: Coletado
                      </S.SubdivisionInformation>
                    </S.SubdivisionInformations>
                  </S.SubdivisionBody>
                  <S.SubdivisionCheckbox
                    type="checkbox"
                    name="subdivision"
                    // id="subdivision"
                    // value={subdivision.features[0].properties.subdivision_id}
                    onClick={(e) => {
                      handleAddSubdivisionToSendLaboratory(
                        subdivision.features[0].properties.subdivision_id
                      );
                    }}
                  />
                </S.Subdivision>
              )
          )}
        </S.SubdivisionsContainer>

        <FormControl style={{ width: '100%' }}>
          <InputLabel>Laboratório</InputLabel>
          <Select
            label="Laboratório"
            placeholder="Cultura"
            name="laboratory"
            value={laboratory}
            onChange={(e) => {
              setLaboratory(e.target.value);
            }}
          >
            {laboratoriesList.map((v, index) => (
              <MenuItem value={v.name} key={index}>
                {v.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <input
          type="date"
          min={`${new Date().getFullYear()}-${
            new Date().getMonth() + 1
          }-${new Date().getDate()}`}
        />
        <Button
          text="Gerar código de barras"
          onClick={() => openModal()}
          disabled={!sendSubdivisions.length > 0}
        />
        {/* <Button text="Salvar" /> */}
        <ModalPrint />
      </S.Container>
    </SendLaboratoryContext.Provider>
  );
};

export default SendLaboratory;
