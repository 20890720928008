import styled from 'styled-components';
import { shade } from 'polished';
import signinBackgroundImg from '../../../assets/sign-in-background.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* @media (min-width: 1000px) {
    padding-left: 50px;
  } */

  width: 100%;
  max-width: 700px;
`;

export const Form = styled.form`
  max-width: 690px;
  height: 100%;
  width: 100%;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;

  > span {
    color: #444444;
    font-size: 1rem;
    font-weight: bold;
    margin: 16px 0px 16px 0px;
  }

  p {
    color: #a3a3a3;
    font-size: 1rem;
    margin-bottom: 10px;
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signinBackgroundImg}) no-repeat center;
  background-size: cover;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  > p {
    padding: 15px;
    font-weight: bold;
    font-size: 0.8rem;
    color: #fff;
    @media (max-width: 1000px) {
      display: none;
    }
  }
`;

export const SignIn = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;

  p {
    color: #444444;
  }

  a {
    color: #79ac34;
    margin-left: 5px;
    font-weight: bold;

    &:hover {
      color: ${shade(0.2, '#79ac34')};
    }
  }
`;

export const ErrorContainer = styled.section`
  padding: 5px;
  /* border: 1px solid #d62222; */
  border-radius: 5px;
  width:
  margin: 3px 15px 5px;
  /* font-weight: bold; */
  font-size: 0.7rem !important;
  color: #d62222 !important;
`;
