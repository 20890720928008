/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { TextField, MenuItem, Select } from '@mui/material';
import { toast } from 'react-toastify';
import { useModal } from '../../../../../../../../hooks/Modal';
import api from '../../../../../../../../services/api';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { appConfig } from '../../../../../../../../config';
import Toast from '../../../../../../../../components/Toast';
import Button from '../../../../../../../../components/Button';
import { DashboardContext } from '../../../../../../index';
import * as S from './styled';

function ModalAddDevelopmentCulture() {
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const { token, user } = useAuth();
  // eslint-disable-next-line prettier/prettier
  const { selectedManagement, itemTypeSelected, setUpdateScreen } = useContext(DashboardContext);

  const [description, setDescription] = useState('');
  const [cultures, setCultures] = useState([]);
  const [selectedCulture, setSelectedCulture] = useState();
  const clearFields = () => {
    setDescription('');
  };

  const getValuesByType = async () => {
    const params = new URLSearchParams([
      ['type_id', 'c826052d-3a59-4398-aec1-81193ccd2e42']
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const filterCulturesByActive = response.data.data.values.filter(
        (v) => v.status === true
      );
      setCultures(filterCulturesByActive);
    } catch (error) {
      console.error(error);
    }
  };

  const createCultureDevelopment = async () => {
    const newValue = {
      type_id: itemTypeSelected,
      value_id: selectedCulture,
      description,
      created_by: user.user_id
    };
    try {
      const response = await api.post(
        `${appConfig.apiHosts.manager}/CultureDevelopment/Create`,
        newValue,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      toast(<Toast title="Adicionado com sucesso" />, {
        type: 'success'
      });

      setUpdateScreen(true);
      clearFields();
      closeModal();
    } catch (error) {
      console.error(error);
      toast(<Toast title="Não foi possível adicionar" />, {
        type: 'error'
      });
    }
  };

  useEffect(() => {
    getValuesByType();
  }, []);

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <S.Container>
        <S.TableTitle>{selectedManagement}</S.TableTitle>
        <hr />
        <S.InputContainer>
          <S.InputWrapper>
            <S.InputArea>
              <span>ID (Gerado Automaticamente)</span>
              <TextField
                name="id"
                id="id"
                disabled
                margin="dense"
                fullWidth
                className="inputId"
                size="small"
              />
            </S.InputArea>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.InputArea className="selectStyle">
              <span style={{ marginBottom: '8px' }}>Culturas</span>
              <Select
                placeholder="Selecione..."
                value={selectedCulture}
                onChange={(e) => setSelectedCulture(e.target.value)}
                size="small"
              >
                {cultures.map((culture) => (
                  <MenuItem value={culture.value_Id}>{culture.name}</MenuItem>
                ))}
              </Select>
            </S.InputArea>
            <S.InputArea>
              <span>Desenvolvimento da Cultura</span>
              <TextField
                name="description"
                id="description"
                placeholder="Ex: Pulverizar sobre o plantio"
                margin="dense"
                fullWidth
                size="small"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </S.InputArea>
          </S.InputWrapper>
          <S.ButtonWrapper>
            <Button
              text="Cancelar"
              className="cancelBtn"
              onClick={closeModal}
            />
            <Button
              text="Salvar"
              className="saveBtn"
              onClick={() => {
                createCultureDevelopment();
              }}
            />
          </S.ButtonWrapper>
        </S.InputContainer>
      </S.Container>
    </Modal>
  );
}

export default ModalAddDevelopmentCulture;
