/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SystemModuleList } from './SystemModuleList';
import { SystemModuleForm } from './SystemModuleForm';

const SystemModuleRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SystemModuleList />}>
        <Route path="/new" element={<SystemModuleForm type="new" />} />
        <Route path="/edit/:id" element={<SystemModuleForm type="edit" />} />
      </Route>
    </Routes>
  );
};

export default SystemModuleRouter;
