import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../../../../../hooks/Auth';
import HitchingUserFarmApi from '../../../../../../../../APi/FarmApi/HitchingUserFarmApi';

interface UserFarmsForm {
  users: string[];
  farms: string[];
}

const useUserFarmsForm = () => {
  const { token } = useAuth();
  const hitchingUserFarmApi = useMemo(() => new HitchingUserFarmApi(token), []);
  const [formValid, setFormValid] = useState<boolean>(false);
  const navigate = useNavigate();

  const FormValidationSchema = Yup.object().shape({
    users: Yup.array().required(),
    farms: Yup.array().required()
  });

  const methods = useForm({
    defaultValues: {
      users: [],
      farms: []
    },
    resolver: yupResolver(FormValidationSchema)
  });

  const userFarmsCreate = useMutation({
    mutationFn: async (form: UserFarmsForm) =>
      hitchingUserFarmApi.UserFarmCreate(form),
    onSuccess: () => navigate(-1)
  });

  useEffect(() => {
    setFormValid(
      methods.control.getValues('users')?.length === 0 ||
        methods.control.getValues('farms')?.length === 0
    );
  }, [methods.control.watchInternal()]);

  const submit = methods.handleSubmit((form: UserFarmsForm) => {
    userFarmsCreate.mutate(form);
  });

  return {
    form: {
      methods,
      submit,
      isValid: formValid,
      isSuccess: userFarmsCreate.isSuccess
    }
  };
};

export default useUserFarmsForm;
