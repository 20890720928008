/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { OrderedTable } from '../../../../../../../components/OrderedTable';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import { useOrganizacaoListRequest } from './hooks/useOrganizacaoListRequest';
import { ResearchField } from '../../../../../../../components/ResearchField';
import { Organizacao } from '../utils/organizacao.types';

const OrganizacaoList: FC = () => {
  const { request, setFilter } = useOrganizacaoListRequest();

  const headCells: HeadCell<Organizacao>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Nome'
    },

    {
      id: 'activated',
      numeric: false,
      disablePadding: false,
      label: 'Ativo'
    }
  ];

  return (
    <>
      <ResearchField onChange={setFilter} />
      <OrderedTable
        rows={request.items}
        HeadCell={headCells}
        IconCreate={PostAddIcon}
        title="Organização"
        routeNameAdd="./new"
        routeNameEdit="./edit"
        checkBox={false}
        selectInLine
      />
      <Outlet />
    </>
  );
};

export default OrganizacaoList;
