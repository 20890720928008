import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  border: solid 1px #8d8d8d;
  border-radius: 5px;
  gap: 3px;
`;

export const FirstOptionButton = styled.button`
  width: 50%;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  ${(props) =>
    props.actived &&
    css`
      background: #79ac34;
      color: #fff;
    `}
`;
export const FirstOptionText = styled.span``;

export const SecondOptionButton = styled.button`
  width: 50%;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  ${(props) =>
    props.actived &&
    css`
      background: #79ac34;
      color: #fff;
    `}
`;
export const SecondOptionText = styled.span``;
