/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  MdPerson,
  MdNotifications,
  MdArrowDropDown,
  MdOutlineMenuBook
} from 'react-icons/md';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../services/api';
import Button from '../Button';
import { useAuth } from '../../hooks/Auth';
import { useMap } from '../../hooks/Map';
import { DashboardContext } from '../../pages/Dashboard/index';
import {
  Container,
  Navigation,
  Notifications,
  UserOptions,
  NotificationOptions,
  DropDownNotification,
  DropDownFarm,
  FarmOptions
} from './styled';
import * as S from './styled';
import { calculateArea } from '../../helpers/calculateArea';
import logo from '../../assets/MARCA_FUNDACAO_MT_versao_horizontal_colorida.png';
import NotificationCard from '../NotificationCard';
import Toast from '../Toast';
import { appConfig } from '../../config';
import { dashboardStates } from '../../pages/Dashboard/states';
import serviceNotification from '../../services/notification';

const Header = ({ fullname }: any) => {
  const { signOut, getNotifications, user, token } = useAuth();
  const {
    setSelectedFarmId,
    setSelectedFarmGeoJson,
    farmName,
    setFarmName,
    disableQueryNotification,
    setDisableQueryNotification,
    setOwnerName,
    setSelectedFarmHa,
    renderFarm,
    setListFields,
    renderFields,
    selectedFarmId
  } = useMap();

  const [userOptionsState, setUserOptionsState] = useState(false);
  const [notificationOptionsState, setNotificationOptionsState] =
    useState(false);
  const [farmOptionsState, setFarmOptionsState] = useState(false);
  const [enableNotificationQuery, setEnable] = useState(true);
  const refUserOptions = useRef<any>();
  const handleOpenUserOptions = () => setUserOptionsState(!userOptionsState);
  const handleOpenNotification = () =>
    setNotificationOptionsState(!notificationOptionsState);

  const handleOpenFarm = () => setFarmOptionsState((prev) => !prev);
  const handleClickOutside = (event: any) => {
    return refUserOptions.current &&
      !refUserOptions.current.contains(event.target)
      ? setUserOptionsState(false)
      : null;
  };

  const {
    setNotifications,
    notifications,
    chatNotifications,
    setChatNotifications,
    farms,
    setFields
  } = useContext<any>(DashboardContext);

  const handleReadNotification = async (notification: any) => {
    try {
      await serviceNotification.updateNotification(notification, token);
      getNotifications(user.email, token).then((v: any) =>
        setNotifications(v.notifications)
      );
    } catch (error) {
      toast(
        <Toast title="Não foi possivel executar a marcação de leitura da notificação" />,
        {
          type: 'error'
        }
      );
      console.error(error);
    }
  };

  const getFields = async () => {
    try {
      setFields([]);
      setListFields([]);
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Field/GetActiveFieldListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFields(response.data.data);
      setListFields(response.data.data);
      renderFields(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setFields([]);
    }
  };

  useEffect(() => {
    getFields();
  }, [selectedFarmId]);

  const handleDeleteNotification = async (notificationId: any) => {
    try {
      await serviceNotification.deleteNotification(notificationId, token);
      getNotifications(user.email, token).then((v: any) =>
        setNotifications(v.notifications)
      );
      toast(<Toast title="Notificação excluida com sucesso" />, {
        type: 'success'
      });
    } catch (error) {
      toast(<Toast title="Não foi possivel excluir a notificação" />, {
        type: 'error'
      });
      console.error(error);
    }
  };

  useEffect(() => {
    getNotifications(user.email, token).then((response: any) => {
      setNotifications(response.notifications);
      setChatNotifications(response.chatNotifications);
    });
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.addEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const messageChatNotification = (quantityMsg: any, name: any) => {
    if (quantityMsg > 1) {
      return `Você possui ${quantityMsg} Mensagens do Usuario ${name}`;
    }
    return `Você possui ${quantityMsg} Mensagem do Usuario ${name}`;
  };

  const notificationsTextBadge = () => {
    if (notifications !== null || notifications !== undefined) {
      const countNotifications = notifications.filter(
        (value: any) => value.read === false
      ).length;
      return countNotifications + chatNotifications.length;
    }
    return '';
  };

  const queryClientNotifications = useQueryClient();

  const { status, data, isFetching } = useQuery(
    'syncNotifications',
    async () => {
      await getNotifications(user.email, token).then((response: any) => {
        setNotifications(response.notifications);
        setChatNotifications(response.chatNotifications);
        if (chatNotifications.length < response.chatNotifications.length) {
          const audio = new Audio('src/assets/alert_msg.mp3');
          audio.play();
        }
      });
    },
    {
      enabled: disableQueryNotification,
      refetchInterval: appConfig.verifyTimeNotifications
    }
  );

  // const addMutation = useMutation(
  //   (email, accesstoken) =>
  //     getNotifications(email, accesstoken).then((response) => {
  //       setNotifications(response.notifications);
  //       setChatNotifications(response.chatNotifications);
  //     }),
  //   {
  //     onSuccess: () =>
  //       queryClientNotifications.invalidateQueries('syncNotifications')
  //   }
  // );

  // const clearMutation = useMutation(
  //   (email, accesstoken) =>
  //     getNotifications(email, accesstoken).then((response) => {
  //       setNotifications(response.notifications);
  //       setChatNotifications(response.chatNotifications);
  //     }),
  //   {
  //     onSuccess: () =>
  //       queryClientNotifications.invalidateQueries('syncNotifications')
  //   }
  // );
  // eslint-disable-next-line prefer-template
  return (
    <Container>
      <div>
        <img src={logo} alt="Fundação MT" />
      </div>
      <Navigation>
        {farmName !== '' && (
          <FarmOptions>
            <Notifications>
              <button type="button" onClick={handleOpenFarm}>
                <p>
                  <strong>Fazenda:</strong> {farmName}
                </p>
                <MdArrowDropDown />
              </button>
              {farmOptionsState && (
                <div className="dropdown">
                  <DropDownFarm>
                    {farms?.map((farm: any) => {
                      return (
                        <button
                          type="button"
                          style={{ width: '100%', padding: 5 }}
                          onClick={() => {
                            renderFarm(farm);
                            setSelectedFarmId(
                              farm.features[0].properties.farm_id
                            );
                            setSelectedFarmHa(
                              Number(farm.features[0].properties.geometry_ha)
                            );
                            setSelectedFarmGeoJson(farm);
                            setFarmName(farm.features[0].properties.farm_name);
                            setOwnerName(
                              farm.features[0].properties.contact_name
                            );
                            getFields();
                            handleOpenFarm();
                          }}
                        >
                          <S.FarmCardContainer
                            key={farm.features[0].properties.farm_id}
                          >
                            <S.FarmCardHeader>
                              <S.FarmCardText>
                                <h3>{farm.features[0].properties.farm_name}</h3>
                                <p className="talhao">
                                  {`${farm.features[0].properties.count_fields} Talhoes Cadastrados`}
                                </p>
                                <p className="talhao">{calculateArea(farm)}</p>
                              </S.FarmCardText>
                            </S.FarmCardHeader>
                          </S.FarmCardContainer>
                        </button>
                      );
                    })}
                  </DropDownFarm>
                </div>
              )}
            </Notifications>
          </FarmOptions>
        )}
        <NotificationOptions>
          <Notifications>
            <button type="button" onClick={handleOpenNotification}>
              <div>
                {notificationsTextBadge() > 0 && (
                  <span>{notificationsTextBadge()}</span>
                )}

                <MdNotifications />
              </div>
              <span>Notificações</span>
              <MdArrowDropDown />
            </button>
            {notificationOptionsState && (
              <div className="dropdown">
                <DropDownNotification>
                  {chatNotifications?.map((notificationChat: any) => {
                    return (
                      <li key={notificationChat.chat_id}>
                        <NotificationCard
                          read={false}
                          actions={false}
                          onClickDelete={() => {
                            console.log('');
                          }}
                          onClickMarkRead={() => {
                            console.log('');
                          }}
                        >
                          {messageChatNotification(
                            notificationChat.quantityUnreadMessage,
                            notificationChat.userOrigin_name
                          )}
                        </NotificationCard>
                      </li>
                    );
                  })}
                  {notifications?.map((notification: any) => {
                    const dataNotificacao = new Date(notification.created_at);
                    const dia = String(dataNotificacao.getDate()).padStart(
                      2,
                      '0'
                    );
                    const mes = String(dataNotificacao.getMonth() + 1).padStart(
                      2,
                      '0'
                    );
                    const ano = dataNotificacao.getFullYear();
                    const horas = String(dataNotificacao.getHours()).padStart(
                      2,
                      '0'
                    );
                    const minutos = String(
                      dataNotificacao.getMinutes()
                    ).padStart(2, '0');
                    const segundos = String(
                      dataNotificacao.getSeconds()
                    ).padStart(2, '0');
                    return (
                      <li key={notification.notification_id}>
                        <NotificationCard
                          read={notification.read}
                          onClickMarkRead={() => {
                            handleReadNotification(notification);
                          }}
                          onClickDelete={() => {
                            handleDeleteNotification(
                              notification.notification_id
                            );
                          }}
                        >
                          <>
                            <p>{notification.notification_message}</p>
                            <p style={{ fontSize: 10 }}>
                              {`${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`}
                            </p>
                          </>
                        </NotificationCard>
                      </li>
                    );
                  })}

                  {notifications.length === 0 &&
                    chatNotifications.length ===
                    (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          alignContent: 'space-between',
                          justifyContent: 'center',
                          width: '100%',
                          height: '100%'
                        }}
                      >
                        <MdNotifications color="#DCEBA9" size={100} />
                        Você não tem notificações
                      </div>
                    )}
                </DropDownNotification>
              </div>
            )}
          </Notifications>
        </NotificationOptions>

        <UserOptions ref={refUserOptions}>
          <button type="button" onClick={handleOpenUserOptions}>
            <div>
              <MdPerson size={18} />
            </div>
            <span>{fullname}</span>
            <MdArrowDropDown />
          </button>
          {userOptionsState && (
            <div className="dropdown">
              <ul>
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedFarmId('');
                      setSelectedFarmGeoJson('');
                      setFarmName('');
                      setDisableQueryNotification(false);
                      signOut();
                    }}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          )}
        </UserOptions>
      </Navigation>
    </Container>
  );
};

Header.propTypes = {
  fullname: PropTypes.string.isRequired
};

export default Header;
