import styled from 'styled-components';

export const Container = styled.div`
  max-width: 300px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    margin-left: 5px;
    font-size: 1.1rem;
    margin-bottom: 15px;
  }

  hr {
    border: solid 1px #dcdcdc;
  }

  p {
    margin-top: 25px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 12px;
    color: #8d8d8d;
    opacity: 1;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  padding-bottom: 70px;

  .cardContainer {
    height: 100%;
  }
`;

export const ZoneCardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ZoneCardText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h3 {
    font-size: 0.9rem;
  }

  > span {
    font-size: 0.7rem;
  }
`;

export const ViewAction = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    height: 100%;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ZoneCardActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 10px;

  .actionBtn {
    display: flex;
    background: transparent;
    border: none;

    & + button {
      margin-left: 10px;
    }
    > span {
      color: #79ac34;
      font-size: 0.8rem;
    }
    > svg {
      width: 16px;
      height: 16px;
      color: #79ac34;
    }
  }
`;

export const ActionButtonCard = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.status ? '#79ac34' : 'transparent')};
  border: 1px solid #79ac34;
  color: ${(props) => (props.status ? '#FFF' : '#79ac34')};
  padding: 5px;
  width: 100px;
  border-radius: 5px;
`;

export const RecommendationButton = styled.button`
  width: 100%;
  height: 45px;
  background-color: #ffffff;
  color: #79ac34;
  font-size: 13px;
  font-weight: bold;
  margin-top: 10px;
  border: 1px solid #79ac34;
  border-radius: 8px;
  opacity: 1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;

export const ZoneCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #a3a3a3;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);

  & + div {
    margin-top: 10px;
  }

  .active {
    border: 1px solid #79ac34;
  }

  .recommMade {
    border: none;
    color: #62904e;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DepthContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  margin-bottom: 5px;
`;

export const TitleBox = styled.div`
  text-align: left;
  color: #444444;
  opacity: 1;
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const tableContainer = styled.div`
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  height: 200px;
  margin-top: 15px;
  //margin-bottom: -150px;

  hr {
    //border: solid 1px #dcdcdc;
    width: 100%;
  }
`;

export const TableRecomm = styled.table`
  //border-collapse: collapse;
  font-size: 11px;
  text-align: center;
  width: 100%;
  /* overflow: auto; */

  tr:nth-child(odd) {
    background: #ffffff;
  }

  tr:nth-child(even) {
    background: #eeeeee;
  }

  td {
    white-space: nowrap;
  }

  .titleTable {
    font-weight: bold;
    color: #444444;
    font-size: 11px;

    td {
      padding: 0 2px;
    }
  }

  .content {
    td {
      color: #444444;
      border-right: 1px solid #ddd;
      font-size: 11px;
      padding: 0 3px;
    }
  }
`;
