/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProfileList } from './ProfileList';
import { ProfileForm } from './ProfileForm';

const ProfileRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ProfileList />}>
        <Route path="/new" element={<ProfileForm type="new" />} />
        <Route path="/edit/:id" element={<ProfileForm type="edit" />} />
      </Route>
    </Routes>
  );
};

export default ProfileRouter;
