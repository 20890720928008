import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { appConfig } from '../config';

const firebaseConfig =
  appConfig.enviroment === 'DEV'
    ? appConfig.servicesKey.firebase.dev
    : appConfig.servicesKey.firebase.prod;
const app = firebase.initializeApp(firebaseConfig);
const db = app.storage();
export default db;
