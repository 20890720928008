import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { OrderedTable } from '../../../../../../../components/OrderedTable';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import { SystemModule } from '../utils/systemModule,types';
import { useSystemModuleListRequest } from './hooks/useSystemModuleListRequest';
import { ResearchField } from '../../../../../../../components/ResearchField';

const SystemModuleList: FC = () => {
  const { request, setFilter } = useSystemModuleListRequest();

  const headCells: HeadCell<SystemModule>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Nome'
    },

    {
      id: 'activated',
      numeric: false,
      disablePadding: false,
      label: 'Ativo'
    }
  ];

  return (
    <>
      <ResearchField onChange={setFilter} />
      <OrderedTable
        rows={request.items}
        HeadCell={headCells}
        title="Modulo de Sistema"
        routeNameAdd="./new"
        routeNameEdit="./edit"
        checkBox={false}
      />
      <Outlet />
    </>
  );
};

export default SystemModuleList;
