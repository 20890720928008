import styled from 'styled-components';

export const Container = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #79ac34;
  font-size: 16px;
  font-weight: bold;
  > svg {
    width: 18px;
    color: #79ac34 !important;
  }
`;
