import { AxiosResponse } from 'axios';
import api from './configFarmApi';

class HitchingUserFarmApi {
  constructor(token: string) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  get getUserFarmList() {
    return 'farm-list';
  }

  async UserFarmList(
    filter: string,
    organizacao_id?: string
  ): Promise<AxiosResponse> {
    return api.get(
      `/Farm/ListarAtrelarUsuarioFazenda?filtro=${filter}&organizacao_id=${
        organizacao_id || ''
      }`
    );
  }

  async UserFarmCreate(data: any): Promise<AxiosResponse> {
    return api.put(
      `/Farm/AtrelarUsuarioPerfil?user_id=${data.user_id}&user_profile_id=${data.user_profile_id}&organizacao_id=${data.organizacao_id}`
    );
  }

  async UserFarmGet(id: string): Promise<AxiosResponse> {
    return api.get(`/Farm/ListarAtrelarUsuarioPerfil?user_id=${id}`);
  }

  async UserFarmDelete(id: string): Promise<AxiosResponse> {
    return api.delete(`/Farm/AtrelarUsuarioFazenda?id=${id}`);
  }
}

export default HitchingUserFarmApi;
