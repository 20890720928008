export const UnityByParam = (param) => {
  switch (param) {
    case 'Argila':
      return 'g kg -¹';
    case 'MO':
      return 'g kg -¹';
    case 'PH':
      return '-';
    case 'Ca':
      return 'cmolc dm-³';
    case 'Mg':
      return 'cmolc dm-³';
    case 'K':
      return 'mg dm-³';
    case 'AI':
      return 'cmolc dm-³';
    case 'CTC':
      return 'cmolc dm-³';
    case 'V%':
      return '%';
    case 'Sal. AI':
      return '%';
    case 'P':
      return 'mg dm-³';
    case 'S':
      return 'mg dm-³';
    case 'MO x Arg':
      return 'g kg -¹';
    default:
      return '-';
  }
};
