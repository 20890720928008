/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { useModal } from '../../../../../../../hooks/Modal';
import * as s from './utils/userFarmsForm.style';
import { Farm } from '../../Farm/FarmList/utils/farmList.types';
import useUserFarmsForm from './hooks/useUserFarmsForm';
import { UserFarmForm, UserFarmsFormProps } from './utils/userFarmsForm.types';
import useFarmListRequest from './hooks/useFarmListRequest';
import { ResearchField } from '../../../../../../../components/ResearchField';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import Toast from '../../../../../../../components/Toast';
import TableCreate from '../../../../../../../components/TableCreate/TableCreate';

const UserFarmsForm: FC = () => {
  const { request, filter } = useFarmListRequest();
  const { form } = useUserFarmsForm();
  const { modalCreatUserStyles } = useModal();
  const navigate = useNavigate();

  if (form.isSuccess) {
    setTimeout(() => {
      return toast(<Toast title="Atrelamento feito com sucesso" content="" />, {
        type: 'success'
      });
    }, 100);
  }
  const userColumn: HeadCell<UserFarmForm>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'nome do usuário'
    }
  ];

  const farmColumn: HeadCell<Farm>[] = [
    {
      id: 'nameFarm',
      numeric: false,
      disablePadding: false,
      label: 'Nome da Fazenda'
    }
  ];

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen
      style={{ ...modalCreatUserStyles, ...{ content: { width: '94%' } } }}
    >
      <s.container>
        <h1>Atrelamento de usuários com Fazendas </h1>
        <s.containerTable>
          <Controller
            name="users"
            control={form.methods.control}
            render={({ onChange }) => (
              <div>
                <ResearchField
                  onChange={filter.setFilterUser}
                  placeholder="Filtro de usuários"
                />
                <TableCreate
                  rows={request.itemsUser}
                  HeadCell={userColumn}
                  IconCreate={AddIcon}
                  title="Usuários"
                  onChange={(selected) => {
                    onChange(selected);
                  }}
                />
              </div>
            )}
          />
          <Controller
            name="farms"
            control={form.methods.control}
            render={({ onChange }) => (
              <div>
                <ResearchField
                  onChange={filter.setFilterFarm}
                  placeholder="Filtro de Fazenda"
                />
                <TableCreate
                  rows={request.itemsFarm}
                  HeadCell={farmColumn}
                  IconCreate={AddIcon}
                  title="Fazendas"
                  onChange={(selected) => {
                    onChange(selected);
                  }}
                />
              </div>
            )}
          />
        </s.containerTable>
        <s.containerbotton>
          <button
            type="button"
            className="containerbotton__bottoncancel"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </button>
          <button
            type="button"
            className={`containerbotton__bottonregister${
              form.isValid ? '--disabled' : ''
            }`}
            onClick={form.submit}
          >
            Registrar
          </button>
        </s.containerbotton>
      </s.container>
    </Modal>
  );
};

export default UserFarmsForm;
