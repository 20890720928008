/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { MdAddCircle, MdModeEdit, MdDelete, MdCheck } from 'react-icons/md';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from '@mui/material';
import { toast } from 'react-toastify';
import Checkbox from 'react-custom-checkbox';
import PuffLoader from 'react-spinners/PuffLoader';
import { DashboardContext } from '../../../../index';
import * as S from './styled';
import ModalAddNewWeed from './components/ModalAddNewWeed';
import ModalEditWeed from './components/ModalEditWeed';
import ModalViewWeed from './components/ModalViewWeed';
import ModalDeleteConfirm from './components/ModalDeleteConfirm';
import { useModal } from '../../../../../../hooks/Modal';
import { appConfig } from '../../../../../../config';
import api from '../../../../../../services/api';
import Button from '../../../../../../components/Button';
import { useAuth } from '../../../../../../hooks/Auth';
import Toast from '../../../../../../components/Toast';

const Weeds = () => {
  const {
    uploadedFiles: files,
    selectedManagement,
    setSelectedManagement,
    setDashboardState,
    dashboardState,
    itemTypeSelected,
    setItemTypeSelected,
    updateScreen,
    setUpdateScreen,
    currentModal,
    setCurrentModal,
    selectedAdmItem,
    setSelectedAdmItem,
    selectTypeCultureList,
    setSelectTypeCultureList,
    selectedPrague,
    setSelectedPrague,
    selectedWeed,
    setSelectedWeed,
    prague_cropName,
    setPrague_CropName,
    prague_plantName,
    setPrague_PlantName,
    setUploadedFiles
  } = useContext(DashboardContext);
  const { closeModal, openedModal, openModal } = useModal();
  const { token, user, getNotifications } = useAuth();

  const [weedId, setWeedId] = useState('');
  const [listType, setListType] = useState([]);
  const [listWeeds, setListWeeds] = useState([]);
  const [pragueUpdate, setPragueUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const getWeedList = async () => {
    setLoading(true);
    setListWeeds([]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Weed/List`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setListWeeds(response.data.data.weeds);
      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleChangeItemSelected = async (event) => {
    setItemTypeSelected(event.target.value);
    const filterNameSelected = listType.filter((item) => item.type_Id === event.target.value);
    setSelectedManagement(filterNameSelected[0].name);
  };

  const handleDeletePrague = async (weed_id) => {
    setWeedId(weed_id);
    setCurrentModal('modalDeleteConfirm');
    openModal();
  };

  const getWeedId = async (weed_id, action) => {
    const imageFiles = [];
    try {
      setCurrentModal('');
      const params = new URLSearchParams([['weed_id', weed_id]]);
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Weed/Id`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setSelectedWeed(response.data.data.weed);

      if (action === 'view') {
        setCurrentModal('modalViewWeed');
      } else {
        response?.data.data.weed?.weed_images.forEach((img) => {
          imageFiles.push({
            error: false,
            file: { path: '', lastModified: '', lastModifiedDate: '', name: img?.weed_image_id, size: '', type: '', webkitRelativePath: '' },
            id: img?.weed_image_id,
            name: img?.weed_image_id,
            preview: img?.url,
            progress: 0,
            readableSize: '',
            uploaded: true,
            url: img?.url
          });
        });
        setUploadedFiles(imageFiles);

        setCurrentModal('modalEditWeed');
      }

      openModal();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getWeedList();
  }, []);

  useEffect(() => {
    if (updateScreen) {
      getWeedList();
    }
  }, [updateScreen]);

  return (
    <S.Container>
      <S.Content>
        <S.AddBtnContainer>
          <Button
            text="Adicionar Nova Daninha"
            style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center'
            }}
            className="content__addButton"
            onClick={() => {
              setCurrentModal('modalAddNewWeed');
              openModal();
            }}
          />
        </S.AddBtnContainer>
        <S.CardContainer>
          {loading ? (
            <>
              <PuffLoader color="#79ac34" loading={loading} size={30} />
            </>
          ) : (
            listWeeds?.map((row) => (
              <S.Card>
                <S.CardImage onClick={() => getWeedId(row.weed_id, 'view')}>
                  {row?.main_photo && <img alt="" src={row.main_photo} />}
                </S.CardImage>
                <S.CardTextContainer onClick={() => getWeedId(row.weed_id, 'view')}>
                  <S.CardTitle>{row?.name}</S.CardTitle>
                  <S.CardDescription>{row?.scientific_name}</S.CardDescription>
                </S.CardTextContainer>
                <S.CardActionContainer>
                  <S.CardAction>
                    <button type="button" onClick={() => getWeedId(row.weed_id, 'edit')}>
                      <MdModeEdit />
                      Editar
                    </button>
                  </S.CardAction>
                  <S.CardAction>
                    <button
                      type="button"
                      onClick={() => handleDeletePrague(row.weed_id)}
                    >
                      <MdDelete />
                      Excluir
                    </button>
                  </S.CardAction>
                </S.CardActionContainer>
              </S.Card>
            ))
          )}
        </S.CardContainer>

      </S.Content>
      {currentModal === 'modalAddNewWeed' && <ModalAddNewWeed />}
      {currentModal === 'modalEditWeed' && <ModalEditWeed />}
      {currentModal === 'modalViewWeed' && <ModalViewWeed />}
      {currentModal === 'modalDeleteConfirm' && (
        <ModalDeleteConfirm
          messageDialog="Deseja realmente excluir esta daninha?"
          weed_id={weedId}
        />
      )}
    </S.Container>
  );
};

export default Weeds;
